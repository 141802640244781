import { host } from "../env";

export const parseVanityNameAndDeckLink = (
  userInputUrl: string,
): { isDeckUrl: boolean; vanityName: string; deckName: string } => {
  const urlWithProtocol =
    !userInputUrl.startsWith("http://") && !userInputUrl.startsWith("https://")
      ? `http://${userInputUrl}`
      : userInputUrl;

  const slicedUrl = urlWithProtocol.endsWith("/")
    ? urlWithProtocol.slice(0, -1)
    : urlWithProtocol;

  const url = new URL(slicedUrl);

  if (!host.includes(url.host)) {
    return { isDeckUrl: false, vanityName: "", deckName: "" };
  }

  const splitedPath = url.pathname.split("/");

  if (
    splitedPath.length !== 3 ||
    splitedPath[1] === "" ||
    splitedPath[2] === ""
  ) {
    return { isDeckUrl: false, vanityName: "", deckName: "" };
  }

  return {
    isDeckUrl: true,
    vanityName: splitedPath[1],
    deckName: splitedPath[2],
  };
};
