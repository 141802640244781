/* eslint-disable import/namespace */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable unicorn/no-document-cookie */
/* eslint-disable class-methods-use-this */

import { PaymentRegion } from "@earthtoday/contract";
import axios from "axios";
import { millisecondsToHours } from "date-fns";
import { action, computed, flow, makeObservable, observable } from "mobx";
import { CancellablePromise } from "mobx/dist/internal";
import { toFlowGeneratorFunction } from "to-flow-generator-function";
import { v4 as uuidv4 } from "uuid";

import {
  CookieConsentSignupData,
  TokenSwitchPayload,
} from "../../shared/apis/UserSessionApi";
import {
  AUTO_ACCEPT_COOKIE_CONSENT,
  BROWSING_SESSION_EXPIRE_TIME,
  BROWSING_SESSION_ID,
  COOKIE_CONSENT,
  COOKIE_CONSENT_CREATEDAT,
  LAST_ACTIVE_AT,
  USER_CLOSE_CARD_INTRO,
  USER_CLOSE_OVERLAY,
  USER_SESSION_EXPIRE_TIME,
  USER_SESSION_ID,
} from "../../shared/constants";
import { ETLocalStorage } from "../../shared/helpers/EtStorages";
import hashPassword from "../../shared/helpers/hashPassword";
import { isBrowser } from "../../shared/helpers/isBrowser";
import { shouldMakeNewSession } from "../../shared/helpers/shouldMakeNewSession";
import {
  isAxiosError,
  translateAPIError,
} from "../../shared/helpers/translateApiError";
import { wait } from "../../shared/helpers/wait";
import { Logger } from "../../shared/models/Logger";
import {
  CookieConsent,
  GroupCuratorRoles,
  User,
  UserType,
} from "../../shared/models/User";
import { logger } from "../../shared/requireCrossEnv";
import {
  initSnowplow,
  ISnowplowUser,
  snowplowHandleConsentUpdated,
  snowplowSetUserId,
} from "../../shared/snowplow";
import { AuthUser, AuthUserModel } from "../../stores/AuthUserModel";
import { DonateScreen } from "../../stores/DonateStore/DonateStore";
import { ModalType } from "../../stores/ModalStore";
import { RootStore } from "../../stores/rootStore";
import { ISignUpSubmission } from "../ModalSignUpForm/ModalSignUpFormLayout";
import { SocialUserFacebook } from "../ModalSignUpSocialBtnFacebook/ModalSignUpSocialBtnFacebook";
import { ToastMessageStatus } from "../TheMessage/TheMessageStore";

export interface UserSessionData {
  didInit: boolean;
  isCookieConsentRegion: boolean;
  sessionCookieConsent: CookieConsent;
  user: AuthUser | null;
}

export interface IUserSessionStore {
  user: AuthUserModel | null;
  hasAcceptedSocialCookies: boolean;
  isCookieConsentRegion: boolean;
  hasAcceptedBasicCookies: boolean;
  hasUserCloseOverlay: boolean;
  didInit: boolean;
  isLoadingAuthUser: boolean;
  isGroupToken: boolean;
  isGroupPublished: boolean;
  isBypassQRCookieCheck: boolean;
  isCurator: boolean;
  disabledCardIntroHistory: Array<string>;
  snowplowUser: ISnowplowUser | null;
  paymentRegion: PaymentRegion;

  checkRefreshDraftCards: () => boolean;
  checkRefreshPublishedCards: () => boolean;
  resendVerificationEmail(): void;
  loginWithEmail(payl: { email: string; password: string }): void;
  loginWithGoogle(token: string): void;
  loginWithFacebook(socialUser: SocialUserFacebook.Root): void;
  checkIfExistingEmailAddress(payl: {
    emailAddress: string;
  }): CancellablePromise<{ isUsed: boolean }>;
  onRegister(payl: ISignUpSubmission): void;
  onAcceptTerm(): CancellablePromise<void>;
  onLogout(redirect?: () => void): void;
  setUser(user: User | null): void;
  acceptAllCookies(): void;
  setUserCloseOverlay(): void;
  removeUserCloseOverlay(): void;
  updateHasUserCloseOverlay(): void;
  setOldCode(code: string): void;
  initLoginStatus(options?: { force: boolean }): void;
  switchToken(
    payload: TokenSwitchPayload,
    redirect?: () => void,
  ): CancellablePromise<void>;
  exitGroup(
    userId: string,
    groupId: string,
    redirect?: () => void,
  ): CancellablePromise<void>;

  setUserCloseCardIntro(pages: string): void;

  getIsUserClosedCardIntro(): Array<string>;

  updatePagesClosedCardIntro(): void;

  onCloseOrSwitchModal(modalTypes: ModalType): void;

  consentDataSignup(): CookieConsentSignupData;
}

export class UserSessionStore implements IUserSessionStore {
  private logger: Logger;

  @observable didInit = false;
  @observable isCookieConsentRegion = true; // TODO : detect and set value later
  @observable sessionCookieConsent: CookieConsent = CookieConsent.NONE;
  @observable user: AuthUserModel | null = null;
  @observable hasUserCloseOverlay: boolean = true;

  @observable disabledCardIntroHistory: Array<string> = [];
  @observable oldCode: string = "";
  @observable isLoadingAuthUser: boolean = false;
  @observable refreshPublishedCards: boolean = false;
  @observable refreshDraftCards: boolean = false;
  @observable isBypassQRCookieCheck = false;
  @observable lastActiveTime: Date = new Date();

  private userSessionInitCallbackFns: {
    resolve: (value: void | User | PromiseLike<void | User>) => void;
    reject: (reason?: any) => void;
  }[] = [];

  constructor(private rootStore: RootStore) {
    this.logger = rootStore.logger.child({ container: this.constructor.name });
    makeObservable(this);
  }

  dehydrate(): UserSessionData {
    return {
      didInit: this.didInit,
      isCookieConsentRegion: this.isCookieConsentRegion,
      sessionCookieConsent: this.sessionCookieConsent,
      user: this.user?.toJSON() || null,
    };
  }

  @action.bound hydrate(data: UserSessionData): void {
    this.didInit = data.didInit;
    this.isCookieConsentRegion = data.isCookieConsentRegion;
    this.sessionCookieConsent = data.sessionCookieConsent;
    this.user = data.user
      ? new AuthUserModel(data.user, this, this.rootStore.tokenStore)
      : null;
  }

  @action.bound initialSessionCookieConsent = flow(function* (
    this: UserSessionStore,
  ) {
    const sessionCookieConsent =
      this.rootStore.cookieManager.get(COOKIE_CONSENT);
    console.debug("UserSessionStore :: initialSessionCookieConsent", {
      sessionCookieConsent,
    });
    this.sessionCookieConsent = (sessionCookieConsent ||
      CookieConsent.NONE) as CookieConsent;
  });

  @computed get cookieConsent(): CookieConsent {
    if (this.user && this.user.cookieConsent) {
      return this.user.cookieConsent;
    }

    return this.sessionCookieConsent || CookieConsent.BASIC;
  }

  @computed get isCurator(): boolean {
    if (this.user?.groupMemberRole) {
      // logging in as group member
      return (
        !!this.user.group?.isVerified &&
        GroupCuratorRoles.includes(this.user.groupMemberRole)
      );
    }

    return this.user?.curator || false;
  }

  @computed get hasAutoAcceptConsent(): boolean {
    return !!ETLocalStorage.getItem(AUTO_ACCEPT_COOKIE_CONSENT);
  }

  @computed get hasAcceptedSocialCookies(): boolean {
    // prefer browser session, consent from api may be cached
    return (
      this.sessionCookieConsent.toLowerCase() === CookieConsent.SOCIAL_MEDIA ||
      this.cookieConsent.toLowerCase() === CookieConsent.SOCIAL_MEDIA
    );
  }

  @computed get shouldShowCookieBanner() {
    if (!this.didInit) {
      return false;
    }

    return !this.hasAcceptedSocialCookies;
  }

  @computed get hasAcceptedBasicCookies(): boolean {
    return (
      this.cookieConsent === CookieConsent.BASIC ||
      this.sessionCookieConsent === CookieConsent.BASIC
    );
  }

  @computed get isGroupToken(): boolean {
    return this.user?.userType === UserType.GROUP_ADAPTER;
  }

  // * to track publish status after redirect from Mollie
  @computed get isGroupPublished(): boolean {
    return this.rootStore.donateStore.isPublishGroupSuccessful || false;
  }

  @computed get snowplowUser(): ISnowplowUser | null {
    if (!this.user) {
      return null;
    }

    return {
      vanityName: this.user.vanityName,
      fullName: this.user.fullName,
      emailAddress: this.user.emailAddress,
      userType: this.user.userType,
      uonStoreId: this.user.details?.uonStoreId?.toString() || "",
      joined: this.user.joined?.toString() || "",
      termsAccepted: this.user.termsAccepted,
      cookieConsent: this.user.cookieConsent || CookieConsent.BASIC,
      loginTimes: this.user.loginTimes,
      lastLoginAt: this.user.lastLoginAt,
      isNew: this.user.isFirstLoginToday,
      groupMemberRole: this.user.groupMemberRole,
      groupId: this.user.group?.id,
      groupName: this.user.group?.name,
      isGroupVerified: this.user.group?.isVerified || false,
      groupType: this.user.group?.profileType || undefined,
      groupState: this.user.group?.state,
      groupCreatedBy: this.user.group?.createdBy,
      isGroupVerificationPending:
        this.user.group?.isVerificationPending || false,
      groupRoleRequest: this.user.group?.roleRequest || undefined,
      userSessionId: this.userSessionId,
      browsingSessionId: this.browsingSessionId,
    };
  }

  @action autoAcceptCookieConsent = (): void => {
    ETLocalStorage.setItem(AUTO_ACCEPT_COOKIE_CONSENT, "true");
  };

  @action checkRefreshPublishedCards = (): boolean => {
    if (this.refreshPublishedCards) {
      this.refreshPublishedCards = false;
      return true;
    }

    return false;
  };

  @action checkRefreshDraftCards = (): boolean => {
    if (this.refreshDraftCards) {
      this.refreshDraftCards = false;
      return true;
    }

    return false;
  };

  consentDataSignup(): CookieConsentSignupData {
    return {
      consent:
        (this.rootStore.cookieManager.get(COOKIE_CONSENT) as CookieConsent) ||
        CookieConsent.BASIC,
      cookieConsentCreatedAt: isBrowser()
        ? ETLocalStorage.getItem(COOKIE_CONSENT_CREATEDAT) ||
          new Date().toISOString()
        : undefined,
    };
  }

  @action.bound updateBypassQRCookieCheck(b: boolean): void {
    this.isBypassQRCookieCheck = b;
  }

  @action.bound onAcceptAllCookies = flow(function* onAcceptAllCookies(
    this: UserSessionStore,
  ) {
    try {
      yield this.acceptAllCookies();
    } catch (error) {
      this.rootStore.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    }
  });

  @action.bound acceptAllCookies = flow(function* acceptAllCookies(
    this: UserSessionStore,
  ) {
    this.rootStore.tokenStore.setCookieConsent();

    if (isBrowser()) {
      ETLocalStorage.setItem(
        COOKIE_CONSENT_CREATEDAT,
        new Date().toISOString(),
      );
    }

    this.sessionCookieConsent = CookieConsent.SOCIAL_MEDIA;

    if (this.user) {
      yield this.rootStore.userSessionApi.updateCookieContent(
        CookieConsent.SOCIAL_MEDIA,
      );
      this.user.setCookieConsent(CookieConsent.SOCIAL_MEDIA);
      snowplowHandleConsentUpdated(CookieConsent.SOCIAL_MEDIA);
    }
  });

  @action.bound acceptBasicCookies = flow(function* acceptBasicCookies(
    this: UserSessionStore,
  ) {
    if (isBrowser()) {
      ETLocalStorage.setItem(
        COOKIE_CONSENT_CREATEDAT,
        new Date().toISOString(),
      );
    }

    this.rootStore.cookieManager.set(COOKIE_CONSENT, CookieConsent.BASIC, {
      path: "/",
      expires: new Date("2038-01-19 04:14:07"),
      maxAge: 60 * 60 * 24 * 365 * 10,
    });
    this.sessionCookieConsent = CookieConsent.BASIC;
    if (this.user) {
      yield this.rootStore.userSessionApi.updateCookieContent(
        CookieConsent.BASIC,
      );
      this.user.setCookieConsent(CookieConsent.BASIC);
    }
  });

  @action.bound saveConsentAfterLogin = (
    consent: CookieConsent,
    joined: string,
  ): void => {
    if (this.rootStore.cookieManager.get(COOKIE_CONSENT)) {
      return;
    }

    ETLocalStorage.setItem(COOKIE_CONSENT_CREATEDAT, joined);
    this.rootStore.cookieManager.set(COOKIE_CONSENT, consent, {
      path: "/",
      expires: new Date("2038-01-19 04:14:07"),
      maxAge: 60 * 60 * 24 * 365 * 10,
    });
    this.sessionCookieConsent = consent;
  };

  get localStorageLastActiveTime(): string | null {
    return ETLocalStorage.getItem(LAST_ACTIVE_AT);
  }

  @action.bound public setUser = flow(function* setUser(
    this: UserSessionStore,
    user: User | null,
  ) {
    const isSwitchedUser: boolean = this.didInit && this.user?.id !== user?.id;

    this.user = user
      ? new AuthUserModel(user, this, this.rootStore.tokenStore)
      : null;
    this.rootStore.tokenStore.syncTokenPayloadCookie();

    this.onUserActiveDetected();
    snowplowSetUserId(user?.id || null);
    yield this.rootStore.featureFlaggingStore.fetchFlags(this, isSwitchedUser);
  });

  @observable paymentRegion: PaymentRegion = PaymentRegion.EU;

  @action.bound detectUserRegion = flow(function* (this: UserSessionStore) {
    try {
      const { paymentRegion } =
        yield this.rootStore.userSessionApi.detectUserRegion();
      this.paymentRegion = paymentRegion;
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }

      this.rootStore.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "Error",
        content: translateAPIError(error),
      });
    }
  });

  private deduplicatedInitLoginStatus = async (options?: {
    force: boolean;
  }): Promise<User | void> => {
    if ((!options || !options.force) && this.didInit) {
      return;
    }
    await this.detectUserRegion();
    await this.initialSessionCookieConsent();

    if (this.rootStore.tokenStore.isAnonymous()) {
      this.user = null;
      await this.rootStore.featureFlaggingStore.fetchFlags(this);
      this.didInit = true;

      initSnowplow(null, this.cookieConsent);
      return;
    }

    try {
      this.isLoadingAuthUser = true;
      const user = await this.rootStore.userSessionApi.getMe();
      await this.setUser(user);
      this.didInit = true;
      initSnowplow(user.id, this.cookieConsent);
    } catch (error) {
      this.logger.error({ error }, "Init login status failed");
      await this.setUser(null);
    } finally {
      this.isLoadingAuthUser = false;
    }
  };

  @action.bound initLoginStatus = flow(function* initLoginStatus(
    this: UserSessionStore,
    options?: { force: boolean },
  ) {
    yield new Promise(async (resolve, reject) => {
      try {
        if (this.userSessionInitCallbackFns.length === 0) {
          this.userSessionInitCallbackFns.push({ resolve, reject });

          await this.deduplicatedInitLoginStatus(options);

          if (this.userSessionInitCallbackFns.length > 0) {
            for (const userSessionInitCallbackFn of this
              .userSessionInitCallbackFns) {
              userSessionInitCallbackFn.resolve();
            }

            this.userSessionInitCallbackFns.length = 0;
          }

          return;
        }

        this.userSessionInitCallbackFns.push({ resolve, reject });
      } catch (error) {
        if (this.userSessionInitCallbackFns.length > 0) {
          for (const userSessionInitCallbackFn of this
            .userSessionInitCallbackFns) {
            userSessionInitCallbackFn.reject(error);
          }

          this.userSessionInitCallbackFns.length = 0;
        }
      }
    });
  });

  @action.bound onLogout = flow(function* onLogout(
    this: UserSessionStore,
    redirect?: () => void,
  ) {
    try {
      yield this.rootStore.userSessionApi.logout();
    } catch (error) {
      if (
        !isAxiosError(error) ||
        !error.response ||
        !error.response.data ||
        !(error.response?.data as { tokenExpired: string }).tokenExpired
      ) {
        throw error;
      }
    }

    this.rootStore.tokenStore.removeAccessToken();
    this.rootStore.tokenStore.removeRefreshToken();
    yield this.setUser(null);

    if (redirect) {
      redirect();
    }
  });

  @action.bound loginWithEmail = flow(function* loginWithEmail(
    this: UserSessionStore,
    payl: {
      email: string;
      password: string;
    },
  ) {
    const loginInfo = {
      emailAddress: payl.email,
      preHashedPassword: hashPassword(payl.password, payl.email),
      preHashedPasswordWithLowercaseEmail: hashPassword(
        payl.password,
        payl.email.toLowerCase(),
      ),
    };

    // NOTE : don't try catch this.
    const user = yield this.rootStore.userSessionApi.login(loginInfo);

    yield this.setUser(user);
    this.saveConsentAfterLogin(user.cookieConsent, user.joined);
    this.refreshDraftCards = true;
    this.refreshPublishedCards = true;
  });

  @action.bound onRegister = flow(function* onRegister(
    this: UserSessionStore,
    payl: ISignUpSubmission,
  ) {
    const info = {
      emailAddress: payl.email.toLowerCase(),
      firstName: payl.firstName,
      lastName: payl.lastName,
      preHashedPassword: hashPassword(payl.password, payl.email.toLowerCase()),
      ...this.consentDataSignup(),
    };

    yield this.rootStore.userSessionApi.register(info);
    yield this.onAcceptTerm();

    this.rootStore.theMessageStore.showMessage(
      {
        typeMessage: "Close",
        title: "Email Verification",
        content: "toast-message.email-verification.content-update",
      },
      {
        closeDuration: "never",
      },
    );
    if (this.rootStore.modalStore.activeModals.includes("donate") === true) {
      this.rootStore.donateStore.updateScreen(DonateScreen.SelectAmount);
    }
    if (this.rootStore.modalStore.activeModals.includes("donate") === false) {
      this.rootStore.modalStore.openModal("");
    }
  });

  @action.bound checkIfExistingEmailAddress = flow(
    function* checkIfExistingEmailAddress(
      this: UserSessionStore,
      payl: {
        emailAddress: string;
      },
    ) {
      const result =
        yield this.rootStore.userSessionApi.checkIfExistingEmailAddress(payl);
      return result;
    },
  );

  @action.bound verifyEmail = flow(function* verifyEmail(
    this: UserSessionStore,
    code: string,
  ) {
    try {
      this.rootStore.theMessageStore.close();
      yield this.rootStore.userSessionApi.verifyEmail(code);
      this.rootStore.theMessageStore.showMessage(
        {
          typeMessage: "Close",
          title: "toast-message.email-verified.title",
          content: "toast-message.email-verified.content",
        },
        { closeDuration: 10000 },
      );
      yield this.deduplicatedInitLoginStatus({ force: true });
    } catch {
      this.rootStore.theMessageStore.showMessage(
        {
          typeMessage: "Action",
          status: ToastMessageStatus.WARNING,
          title: "toast-message.email-verfication-failed.title",
          content: "toast-message.email-verfication-failed.content",
          actions: [
            {
              key: "retry",
              name: "toast-message.general.action-retry",
              action: () => this.retryVerifyEmail(code),
            },
            {
              key: "resend",
              name: "toast-message.general.action-resend",
              action: () => {
                this.resendVerificationEmail();
              },
            },
            {
              key: "close",
              name: "toast-message.general.action-close",
              action: () => this.rootStore.theMessageStore.close(),
            },
          ],
        },
        {
          closeDuration: "never",
        },
      );
    }
  });

  @action.bound retryVerifyEmail = flow(function* retryVerifyEmail(
    this: UserSessionStore,
    code: string,
  ) {
    this.rootStore.theMessageStore.close();
    yield this.verifyEmail(code);
  });

  @action.bound resendVerificationEmail = flow(
    function* resendVerificationEmail(this: UserSessionStore) {
      try {
        this.rootStore.theMessageStore.close();

        yield this.rootStore.userSessionApi.requestVerificationEmail(
          this.oldCode,
        );

        this.rootStore.theMessageStore.showMessage({
          typeMessage: "Close",
          title: "Email Verification",
          content: "We've sent a verification link to your email.",
        });
      } catch (error) {
        const [title, content] = ((): [string, string] => {
          if (
            isAxiosError(error) &&
            (error.response?.data as { type: string })?.type ===
              "SendingLimitExceeded"
          ) {
            return [
              "toast-message.email-verification.title",
              "toast-message.email-exceeded",
            ];
          }
          return [
            "toast-message.email-verfication-failed.title",
            "toast-message.email-verification.content",
          ];
        })();

        this.rootStore.theMessageStore.showMessage(
          {
            typeMessage: "Action",
            status: ToastMessageStatus.WARNING,
            title,
            content,
            actions: [
              {
                key: "close",
                name: "toast-message.general.action-close",
                action: () => this.rootStore.theMessageStore.close(),
              },
              {
                key: "resend",
                name: "toast-message.general.action-resend",
                action: () => {
                  this.resendVerificationEmail();
                },
              },
            ],
          },
          {
            closeDuration: "never",
          },
        );
      }
    },
  );

  @action.bound loginWithFacebook = flow(function* loginWithFacebook(
    this: UserSessionStore,
    socialUser: SocialUserFacebook.Root,
  ) {
    const user = yield this.rootStore.userSessionApi.facebookConnect(
      socialUser,
      this.consentDataSignup(),
    );

    if (this.rootStore.modalStore.activeModals.includes("donate") === true) {
      this.rootStore.donateStore.updateScreen(DonateScreen.SelectAmount);
    }
    if (this.rootStore.modalStore.activeModals.includes("donate") === false) {
      this.rootStore.modalStore.openModal("");
    }
    if (user && !user.termsAccepted) {
      this.rootStore.modalStore.openModal("acceptTermAndPolicy", {
        keepPreviousModal: true,
      });
    }

    yield this.setUser(user);
  });

  @action.bound loginWithGoogle = flow(function* loginWithGoogle(
    this: UserSessionStore,
    token: string,
  ) {
    const user = yield this.rootStore.userSessionApi.googleConnect(
      token,
      this.consentDataSignup(),
    );

    if (this.rootStore.modalStore.activeModals.includes("donate") === true) {
      this.rootStore.donateStore.updateScreen(DonateScreen.SelectAmount);
    }
    if (this.rootStore.modalStore.activeModals.includes("donate") === false) {
      this.rootStore.modalStore.openModal("");
    }
    if (user && !user.termsAccepted) {
      this.rootStore.modalStore.openModal("acceptTermAndPolicy", {
        keepPreviousModal: true,
      });
    }

    yield this.setUser(user);
  });

  @action.bound onAcceptTerm = flow(function* onAcceptTerm(
    this: UserSessionStore,
  ) {
    try {
      const user = yield this.rootStore.userSessionApi.acceptTerm();
      yield this.setUser(user);
      return user;
    } catch (error) {
      this.rootStore.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    }
  });

  @action.bound onDeleteAccount = flow(function* onDeleteAccount(
    this: UserSessionStore,
  ) {
    try {
      const { settingsApi: api } = this.rootStore;

      yield api.deleteAccount();

      this.rootStore.tokenStore.removeAccessToken();
      this.rootStore.tokenStore.removeRefreshToken();

      yield this.setUser(null);
    } catch (error) {
      this.rootStore.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    }
  });

  setUserCloseOverlay = (): void => {
    this.rootStore.cookieManager.set(USER_CLOSE_OVERLAY, "true", {
      path: "/",
      expires: new Date("2038-01-19 04:14:07"),
      maxAge: 60 * 60 * 24 * 365 * 10,
    });
  };

  removeUserCloseOverlay = (): void => {
    document.cookie = `${USER_CLOSE_OVERLAY}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    this.rootStore.cookieManager.remove(USER_CLOSE_OVERLAY, { path: "/" });
  };

  removeAcceptCookieConsent = (): void => {
    this.rootStore.cookieManager.remove(COOKIE_CONSENT, { path: "/" });
  };

  setUserCloseCardIntro = (page: string): void => {
    this.disabledCardIntroHistory.push(page);
    this.rootStore.cookieManager.set(
      USER_CLOSE_CARD_INTRO,
      this.disabledCardIntroHistory.toString(),
      {
        path: "/",
        expires: new Date("2038-01-19 04:14:07"),
        maxAge: 60 * 60 * 24 * 365 * 10,
      },
    );
  };

  getIsUserClosedCardIntro = (): string[] => {
    const cardIntros = this.rootStore.cookieManager.get(USER_CLOSE_CARD_INTRO);
    return cardIntros ? cardIntros.split(",") : [];
  };

  @action.bound updateHasUserCloseOverlay(): void {
    this.hasUserCloseOverlay =
      !!this.rootStore.cookieManager.get(USER_CLOSE_OVERLAY);
  }

  @action.bound updatePagesClosedCardIntro(): void {
    this.disabledCardIntroHistory = this.getIsUserClosedCardIntro();
  }

  @action.bound setOldCode(code: string): void {
    this.oldCode = code;
  }

  @action.bound switchToken = flow(function* (
    this: UserSessionStore,
    payload: TokenSwitchPayload,
    redirect?: () => void,
  ) {
    yield this.rootStore.userSessionApi.switchToken(payload);

    const hasCurrentUserAcceptedSocialCookies =
      this.user?.cookieConsent?.toLowerCase() === CookieConsent.SOCIAL_MEDIA;
    const switchedUser: User = yield this.rootStore.userSessionApi.getMe();
    yield this.setUser(switchedUser);

    if (
      hasCurrentUserAcceptedSocialCookies &&
      switchedUser.details?.type === UserType.GROUP_ADAPTER &&
      switchedUser.cookieConsent?.toLowerCase() !== CookieConsent.SOCIAL_MEDIA
    ) {
      yield this.acceptAllCookies();
    }

    if (redirect) {
      redirect();
    }
  });

  @action.bound exitGroup = flow(function* (
    this: UserSessionStore,
    userId: string,
    groupId: string,
    redirect?: () => void,
  ) {
    try {
      yield this.rootStore.settingsApi.deleteGroupMember(userId, groupId);
      const user: User = yield this.rootStore.userSessionApi.getMe();
      this.setUser(user);

      if (redirect) {
        // make sure that setUser's done
        yield wait(200);
        redirect();
      }
    } catch (error) {
      this.rootStore.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    }
  });

  @action.bound onCloseOrSwitchModal(modalTypes: ModalType): void {
    this.rootStore.modalStore.openModal(modalTypes);
  }

  @computed get shouldRefreshUser(): boolean {
    const hours = millisecondsToHours(
      Date.now() - this.lastActiveTime.getTime(),
    );

    return hours > 12;
  }

  @action.bound onUserIdleDetected() {
    this.lastActiveTime = new Date();
    ETLocalStorage.setItem(LAST_ACTIVE_AT, this.lastActiveTime.toISOString());
  }

  @action.bound onUserActiveDetected = flow(function* (this: UserSessionStore) {
    const currentTime = new Date();

    logger.debug(":: onActiveDetected ::", {
      lastActiveTime: this.lastActiveTime,
      currentTime,
    });

    if (
      (this.localStorageLastActiveTime &&
        shouldMakeNewSession(
          new Date(),
          new Date(this.localStorageLastActiveTime),
          USER_SESSION_EXPIRE_TIME,
        )) ||
      this.user?.id !== this.userIdFromUserSessionId
    ) {
      this.setUserSessionId(this.user?.id);
    }

    if (
      !this.browsingSessionId ||
      (this.localStorageLastActiveTime &&
        shouldMakeNewSession(
          new Date(),
          new Date(this.localStorageLastActiveTime),
          BROWSING_SESSION_EXPIRE_TIME,
        ))
    ) {
      this.setBrowsingSessionId();
    }

    if (this.shouldRefreshUser) {
      yield this.deduplicatedInitLoginStatus({ force: true });
    }

    this.lastActiveTime = currentTime;
    ETLocalStorage.setItem(LAST_ACTIVE_AT, this.lastActiveTime.toISOString());
  });

  get userSessionId(): string {
    return ETLocalStorage.getItem(USER_SESSION_ID) || "";
  }

  get userIdFromUserSessionId(): string {
    return this.userSessionId.split("-")[0];
  }

  @action.bound setUserSessionId(userId?: string) {
    if (!userId) {
      ETLocalStorage.setItem(USER_SESSION_ID, "");
      return;
    }

    const startSesstionAt = new Date().toISOString();
    const newUserSessionId = `${userId}-${startSesstionAt}-${uuidv4()}`;
    ETLocalStorage.setItem(USER_SESSION_ID, newUserSessionId);
  }

  get browsingSessionId(): string {
    return ETLocalStorage.getItem(BROWSING_SESSION_ID) || "";
  }

  @action.bound setBrowsingSessionId() {
    const newBrowsingSessionId = `${new Date().toISOString()}-${uuidv4()}`;
    ETLocalStorage.setItem(BROWSING_SESSION_ID, newBrowsingSessionId);
  }
}
