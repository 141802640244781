// ts-gql-integrity:6da3e95b3f15845e04ecd9929fe0aaf3
/*
ts-gql-meta-begin
{
  "hash": "0200575b51e0202a04b30f810cd2f6d6"
}
ts-gql-meta-end
*/

import * as SchemaTypes from "./@schema";
import { TypedDocumentNode } from "@ts-gql/tag";

type brandClaimCountUpdatedSubscriptionVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID'];
}>;


type brandClaimCountUpdatedSubscription = { readonly __typename: 'Subscription', readonly brandClaimCountUpdated: { readonly __typename: 'UonCountOutput', readonly count: number } | null };


      
export type type = TypedDocumentNode<{
  type: "subscription";
  result: brandClaimCountUpdatedSubscription;
  variables: brandClaimCountUpdatedSubscriptionVariables;
  documents: SchemaTypes.TSGQLDocuments;
  fragments: SchemaTypes.TSGQLRequiredFragments<"none">
}>

declare module "./@schema" {
  interface TSGQLDocuments {
    brandClaimCountUpdated: type;
  }
}

export const document = JSON.parse("{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"subscription\",\"name\":{\"kind\":\"Name\",\"value\":\"brandClaimCountUpdated\"},\"variableDefinitions\":[{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"ID\"}}},\"directives\":[]}],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"brandClaimCountUpdated\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"}}}],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"count\"},\"arguments\":[],\"directives\":[]}]}}]}}]}")
