import { observer } from "mobx-react-lite";

import { useTranslation } from "../../../shared/translate/NextI18next";
import { sc } from "./SectionSeven.styled";
export const SectionSeven = observer(() => {
  const { t } = useTranslation("LandingPage");

  return (
    <sc.Section className="visual-reg">
      <sc.SectionContent>
        <sc.Title>{t("landing-page.section-seven.title")}</sc.Title>
        <sc.Description>
          {t("landing-page.section-seven.description")}
        </sc.Description>
        <sc.ActionBlock>
          <sc.UnionOfNatureLink target="_blank" href="https://uon.org">
            {t("landing-page.section-seven.uon-btn")}
          </sc.UnionOfNatureLink>
          <sc.EarthTodayLink target="_blank" href="https://earthtoday.com">
            {t("landing-page.section-seven.earthtoday-btn")}
          </sc.EarthTodayLink>
        </sc.ActionBlock>
      </sc.SectionContent>
    </sc.Section>
  );
});
