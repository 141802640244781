import { CardSize } from "../models/Card";

export function getCardDimensionsByCardSize(cardSize: CardSize): {
  width: number;
  height: number;
} {
  switch (cardSize) {
    case CardSize.DOUBLE: {
      return { width: 680, height: 496 };
    }
    case CardSize.HEADER:
    case CardSize.TRIPLE: {
      return { width: 1032, height: 496 };
    }
    default: {
      return { width: 328, height: 496 };
    }
  }
}
