import styled from "styled-components";

import googleIcon from "../../assets/svgs/google-icon.svg";
import { etBlack, etWhite } from "../../shared/colors";

namespace sc {
  export const Wrapper = styled.div<{ paddingTop?: string }>`
    position: relative;
    min-width: 280px;
    max-width: 280px;
    min-height: 46.4px;

    padding-top: ${(props) => props.paddingTop || "11px"};
    cursor: pointer;
  `;
  export const CustomComponent = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    color: ${etBlack};

    background-color: ${etWhite};
    width: 100%;
    height: 100%;

    pointer-events: none;
  `;

  export const HoverableDiv = styled.div`
    min-height: 40px;
    max-height: 40px;
    overflow: hidden;
    border-radius: 4px;

    &:hover {
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
        0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    }
  `;

  export const GoogleIconCollectForm = styled.div`
    width: 19px;
    height: 19px;
    margin-right: 8px;
    background-size: 103%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${googleIcon});
  `;
}

export { sc };
