/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable class-methods-use-this */
import { ImageResponseDto } from "@earthtoday/contract";
import { action, computed, flow, observable } from "mobx";

import {
  CARD_ITEM_MUSIC_DOUBLE_SIZE_HEIGHT,
  CARD_ITEM_MUSIC_DOUBLE_SIZE_WIDTH,
  CARD_ITEM_MUSIC_HEIGHT,
  CARD_ITEM_MUSIC_WIDTH,
} from "../components/CardItemMusic/CardItemMusic.styled";
import { CardRemovedContentDriver } from "../components/CardRemovedContent/CardRemovedContent";
import { IUserSessionStore } from "../components/ModalLogin/UserSessionStore";
import {
  CARD_OPEN_FOOTER_IMAGE_HEIGHT,
  CARD_OPEN_FOOTER_IMAGE_WIDHT,
} from "../components/OpenCardFooter/OpenCardFooter.styled";
import {
  CARD_OPEN_TYPE_ARTICLE_IMAGE_HEIGHT,
  CARD_OPEN_TYPE_ARTICLE_IMAGE_MOBILE_HEIGHT,
  CARD_OPEN_TYPE_ARTICLE_IMAGE_MOBILE_WIDTH,
  CARD_OPEN_TYPE_ARTICLE_IMAGE_WIDTH,
  CARD_OPEN_TYPE_ARTICLE_WITHOUT_TITLE_IMAGE_HEIGHT,
  CARD_OPEN_TYPE_ARTICLE_WITHOUT_TITLE_IMAGE_MOBILE_HEIGHT,
} from "../components/OpenCardTypeArticle/OpenCardTypeArticle.styled";
import { DeckEditData } from "../shared/apis/DeckDetailApi";
import { BREAK_POINT_ACTIVE_DARK_MODE_VALUE } from "../shared/breakpoints";
import { etBlack, etWhite } from "../shared/colors";
import { MAX_CARDS_IN_DECK } from "../shared/constants";
import { copyLinkToClipboard } from "../shared/helpers/copyLinkToClipboard";
import { getContentData } from "../shared/helpers/getContentData";
import { getLogoColor } from "../shared/helpers/getLogoColor";
import { getResizeImageUrl } from "../shared/helpers/getResizeImageUrl";
import { isRepostCardByEditorInDeckCategory } from "../shared/helpers/isRepostCardByEditorInDeckCategory";
import { wait } from "../shared/helpers/wait";
import {
  CardArticleLayoutType,
  CardRegular,
  CardSize,
  CardType,
  ICardItem,
  MasterLinkType,
  MetaData,
  RepostContentData,
} from "../shared/models/Card";
import { CardDeckHeaderModel } from "./CardDeckHeaderModel";
import { DeckDetailCardItem } from "./DeckDetailStore/DeckDetailStore";
import { IDeviceStore } from "./DeviceStore";
import { FeatureFlaggingData } from "./FeatureFlaggingStore";
import { IFollowingRequest } from "./FollowingStore";
import { IModalStore, ModalType } from "./ModalStore";

export const CARD_HEIGHT_WITH_TITLE = 304;
export const CARD_HEIGHT_WITHOUT_TITLE = 390;
export const CARD_HEIGHT_IS_NOT_CARD_LAYOUT_EDITORIAL = 408;
export const CARD_HEIGHT_IS_NOT_CARD_SIZE_DOUBLE = 204;
const CARD_WIDTH_SIZE_DOUBLE = 656;
export const CARD_WIDTH_DEFAULT = 304;
export const CARD_MUSIC_BACKGROUND_WIDTH = 328;
const CARD_MUSIC_BACKGROUND_HEIGHT = 496;
const CARD_MUSIC_DOUBLE_SIZE_BACKGROUND_WIDTH = 680;
const CARD_MUSIC_DOUBLE_SIZE_BACKGROUND_HEIGHT = 496;

export type CardBaseContentType =
  | CardType.LINK
  | CardType.LINK_REPOST
  | CardType.DECK_REPOST
  | CardType.PROFILE_REPOST
  | CardType.AUTOPLAY
  | CardType.CAMPAIGN
  | CardType.REMOVEDCONTENT
  | CardType.USER
  | CardType.AUTOPLAY_FOLLOWING_PERSONALIZATION;

type DeckDetailStore =
  | ({
      cards: Array<DeckDetailCardItem>;
      updateStarCard(id: string, starred: boolean): void;
      onDeleteCard(
        cardId: string,
        promotionId?: string,
        isAutoplayedCard?: boolean,
      ): void;
      toggleFollowing(data: IFollowingRequest): void;
      onCardSizeChanged(cardId: string, size: CardSize): void;
      onChangeCardLayout(id: string, layoutType: CardArticleLayoutType): void;
      updateFeatureCard(cardId: string, featured: boolean): void;
      deckDetail: CardDeckHeaderModel | null;
      isCurrentUser: boolean;
    } & {
      isLoggedinUser?: boolean;
      updateOrderOfCards?: (cardIds: string[]) => void;
    })
  | null;
export class CardBaseModel implements CardRemovedContentDriver {
  @observable public card: CardRegular;

  @observable isContextMenuActive = false;

  @observable shareActive = false;

  @observable starActive = false;

  @observable cardSharingError = "";

  @observable metadata: MetaData = { starred: false };

  constructor(
    card: CardRegular,
    protected userSessionStore: IUserSessionStore,
    protected featureFlagging: FeatureFlaggingData,
    protected deviceStore: IDeviceStore,
    protected deckDetailStore: DeckDetailStore,
    protected modalStore: IModalStore,
    protected renderKey?: string,
  ) {
    this.card = card;
  }

  toJSON(): CardRegular {
    return this.card;
  }

  get shareTitle(): string {
    return "card-general.sharing.title";
  }

  @computed get deckId(): string | undefined {
    return this.card.deck?.id;
  }

  @computed get componentKey(): string {
    return (
      this.renderKey || this.card.id + (this.card.contentType?.toString() || "")
    );
  }

  @computed get contextMenuActive(): boolean {
    if (this.shareActive) {
      return false;
    }
    return this.isContextMenuActive;
  }

  @computed get isSaveButtonEnabled(): boolean {
    if (!this.isUserLoggedin) {
      return true;
    }
    return this.userSessionStore.isCurator;
  }

  @action
  openContextMenu = (): void => {
    this.getCardOrder();
    this.isContextMenuActive = true;
  };

  @action
  closeContextMenu = (): void => {
    this.isContextMenuActive = false;
  };
  @action.bound onIconMoreClicked(): void {
    if (!this.isContextMenuActive) {
      this.getCardOrder();
    }
    this.isContextMenuActive = !this.isContextMenuActive;
  }

  @action
  toggleShareActive = (): void => {
    this.shareActive = !this.shareActive;
  };

  @action.bound onShareButtonClicked(): void {
    this.toggleShareActive();
  }

  @action
  toggleStarActive = (): void => {
    this.starActive = !this.starActive;
  };

  @action
  updateCardSharingError = (err: string): void => {
    this.cardSharingError = err;
  };

  @computed get cover(): ImageResponseDto | null {
    if (!this.cardContent) return null;
    return getContentData(this.cardContent.content).cover;
  }

  @computed get title(): string {
    if (!this.cardContent) return "";
    return getContentData(this.cardContent.content).title;
  }

  @computed get summary(): string {
    if (!this.cardContent) return "";
    return getContentData(this.cardContent.content).summary;
  }

  @computed get cardContent(): ICardItem {
    return this.card.content?.type === MasterLinkType.LINK_REPOST
      ? (this.card.content.original as ICardItem)
      : (this.card as ICardItem);
  }

  @computed get updatedDate(): string {
    return this.card.updated || "";
  }

  @computed get publishedDate(): string {
    return this.card.published || "";
  }

  @computed get cardSource(): string {
    return this.cardContent?.content?.source?.url || "";
  }

  @computed get cardType(): MasterLinkType {
    return this.cardContent?.content?.type || MasterLinkType.ARTICLE;
  }

  @computed get isCardArtical(): boolean {
    return this.cardType === MasterLinkType.ARTICLE;
  }

  @computed get previewImage(): string {
    if (!this.cardContent) return "";
    if (this.cardContent.content.image) {
      return (
        this.cardItemPreviewImage || this.cardContent.content.image.url || ""
      );
    }
    return "";
  }

  @computed get previewImageData(): ImageResponseDto | null {
    return this.cardContent.content.image || null;
  }

  @computed get cardHeight(): number {
    if (!this.isCardLayoutEditorial) {
      return CARD_HEIGHT_IS_NOT_CARD_LAYOUT_EDITORIAL;
    }

    if (!this.isCardSizeDouble) {
      if (this.cardType === MasterLinkType.MUSIC) {
        return CARD_ITEM_MUSIC_HEIGHT;
      }

      return CARD_HEIGHT_IS_NOT_CARD_SIZE_DOUBLE;
    }

    if (this.cardType === MasterLinkType.MUSIC) {
      return CARD_ITEM_MUSIC_DOUBLE_SIZE_HEIGHT;
    }

    if (this.cardContent.content.title) return CARD_HEIGHT_WITH_TITLE;

    return CARD_HEIGHT_WITHOUT_TITLE;
  }
  @computed get cardWidth(): number {
    if (this.isCardSizeDouble) {
      if (
        this.isCardLayoutEditorial &&
        this.cardType === MasterLinkType.MUSIC
      ) {
        return CARD_ITEM_MUSIC_DOUBLE_SIZE_WIDTH;
      }

      return CARD_WIDTH_SIZE_DOUBLE;
    }

    if (this.cardType === MasterLinkType.MUSIC) {
      return CARD_ITEM_MUSIC_WIDTH;
    }

    return CARD_WIDTH_DEFAULT;
  }
  @computed get cardItemPreviewImage(): string {
    return getResizeImageUrl(this.cardContent.content.image || null, {
      width: this.cardWidth,
      height: this.cardHeight,
    });
  }

  @computed get cardMusicPreviewImageBackground(): string {
    const width = this.isCardSizeDouble
      ? CARD_MUSIC_DOUBLE_SIZE_BACKGROUND_WIDTH
      : CARD_MUSIC_BACKGROUND_WIDTH;
    const height = this.isCardSizeDouble
      ? CARD_MUSIC_DOUBLE_SIZE_BACKGROUND_HEIGHT
      : CARD_MUSIC_BACKGROUND_HEIGHT;
    return getResizeImageUrl(this.cardContent.content.image || null, {
      width,
      height,
    });
  }

  @computed get cardDraftMusicPreviewImageBackground(): string {
    return getResizeImageUrl(this.cardContent.content.image || null, {
      width: CARD_MUSIC_BACKGROUND_WIDTH,
      height: CARD_MUSIC_BACKGROUND_HEIGHT,
    });
  }
  @computed get previewImageOpenedCardTypeArticle(): string {
    const { browserWidth } = this.deviceStore;
    if (!this.cardContent.content) {
      console.warn("Missing card content"); // prevent: https://urbn8.slack.com/archives/G0166VBKT18/p1696992582682119?thread_ts=1696990817.364419&cid=G0166VBKT18
      return "";
    }
    if (browserWidth <= BREAK_POINT_ACTIVE_DARK_MODE_VALUE) {
      return getResizeImageUrl(this.cardContent.content.image || null, {
        width: CARD_OPEN_TYPE_ARTICLE_IMAGE_MOBILE_WIDTH,
        height: this.isCardLayoutEditorial
          ? CARD_OPEN_TYPE_ARTICLE_IMAGE_MOBILE_HEIGHT
          : CARD_OPEN_TYPE_ARTICLE_WITHOUT_TITLE_IMAGE_MOBILE_HEIGHT,
      });
    }

    return getResizeImageUrl(this.cardContent.content.image || null, {
      width: CARD_OPEN_TYPE_ARTICLE_IMAGE_WIDTH,
      height: this.isCardLayoutEditorial
        ? CARD_OPEN_TYPE_ARTICLE_IMAGE_HEIGHT
        : CARD_OPEN_TYPE_ARTICLE_WITHOUT_TITLE_IMAGE_HEIGHT,
    });
  }

  @computed get categoryPrinciples(): string[] {
    return this.card.categoryPrinciples || [];
  }

  @computed get categoryPrinciple(): string {
    if (this.categoryPrinciples.length === 0) {
      return "";
    }

    return this.card.categoryPrinciples[0];
  }

  @computed get featuredForProfile(): boolean {
    return this.card.featuredForProfile || false;
  }

  @computed get cardSize(): CardSize {
    return this.card.size || CardSize.SINGLE;
  }

  @computed get layoutType(): CardArticleLayoutType | null {
    if (this.cardType !== MasterLinkType.ARTICLE) return null; // card layout type only apply to card type article
    return this.card.layoutType || CardArticleLayoutType.EDITORIAL;
  }

  @computed get isCardValidToPublish(): boolean {
    return this.card.valid || false;
  }

  @computed get isFeaturedProfile(): boolean {
    return this.card.featuredForProfile || false;
  }

  @computed get isCardDeleteOptionVisible(): boolean {
    return this.userSessionStore.user?.administrator || this.isCardOwner;
  }

  @computed get isContextMenuCardEditVisible(): boolean {
    return this.userSessionStore.user?.administrator || this.isCardOwner;
  }

  @computed get adminOrOwner(): boolean {
    return this.userSessionStore.user?.administrator || this.isCardOwner;
  }

  @computed get isCardSizeDouble(): boolean {
    return this.card.size === CardSize.DOUBLE;
  }

  @computed get isUserLoggedin(): boolean {
    return !!this.userSessionStore.user;
  }

  @computed get isCardOwner(): boolean {
    const loggedInUserVanityName = this.userSessionStore.user?.vanityName;
    const currentCuratorVanityName = this.card.curator.vanityName;

    if (this.card.content?.type !== MasterLinkType.LINK_REPOST) {
      return loggedInUserVanityName === currentCuratorVanityName;
    }

    const originalCuratorVanityName =
      this.card.content?.original?.curator?.vanityName;
    const prevCuratorVanityName =
      this.card.content?.repost?.curator?.vanityName;

    return (
      loggedInUserVanityName === originalCuratorVanityName ||
      loggedInUserVanityName === currentCuratorVanityName ||
      loggedInUserVanityName === prevCuratorVanityName
    );
  }

  @computed get sharingTitle(): string {
    return this.cardContent?.content.title || "";
  }

  @computed get contentUrl(): string {
    return this.cardContent?.content?.url || "";
  }

  @computed get isRepostCardByEditorInDeckCategory(): boolean {
    return isRepostCardByEditorInDeckCategory(
      this.card.content,
      this.card.curator.vanityName,
      this.card.deck?.name || "",
    );
  }

  @computed get curatorImage(): string {
    return this.isRepostCardByEditorInDeckCategory
      ? getResizeImageUrl(
          (this.card?.content as RepostContentData)?.original?.curator.image ||
            null,
          {
            width: CARD_OPEN_FOOTER_IMAGE_WIDHT,
            height: CARD_OPEN_FOOTER_IMAGE_HEIGHT,
          },
        )
      : getResizeImageUrl(this.card.curator.image, {
          width: CARD_OPEN_FOOTER_IMAGE_WIDHT,
          height: CARD_OPEN_FOOTER_IMAGE_HEIGHT,
        });
  }

  @computed get category(): string {
    return this.categoryPrinciple;
  }

  @computed get categoryColor(): string {
    return getLogoColor(this.categoryPrinciple || this.category);
  }

  @computed get contentType(): CardBaseContentType {
    return this.card.contentType;
  }

  @computed get isCardLayoutEditorial(): boolean {
    if (this.cardType !== MasterLinkType.ARTICLE) return true; //To use default layout for other card type are not article

    return this.layoutType === CardArticleLayoutType.EDITORIAL;
  }

  @computed get isCardLayoutPhoto(): boolean {
    return this.layoutType === CardArticleLayoutType.PHOTO;
  }

  @computed get isCardLayoutText(): boolean {
    return this.layoutType === CardArticleLayoutType.TEXT;
  }

  @computed get cardLayoutTextColor(): string {
    if (["people", "keys", "spirit"].includes(this.categoryPrinciple)) {
      return etWhite;
    }

    return etBlack;
  }

  @observable cardOrder: number | null = null;
  @action.bound getCardOrder(): void {
    if (!this.deckDetailStore) {
      this.cardOrder = null;
      return;
    }
    const foundIndex = this.deckDetailStore.cards.findIndex(
      (card) => card.id === this.card.id,
    );
    this.cardOrder = foundIndex > -1 ? foundIndex + 1 : null;
  }

  copyCardLinkToClipboard = flow(function* copyCardLinkToClipboard(
    this: CardBaseModel,
    cardUrl: string,
  ) {
    copyLinkToClipboard(cardUrl);
    yield wait(1000);
    this.toggleShareActive();
  });

  @computed get cardOrderInput(): string | null {
    return this.cardOrder?.toString() || null;
  }
  @computed get sortNumberInputError(): string | null {
    if (this.cardOrder === null) return null;

    return this.cardOrder > MAX_CARDS_IN_DECK || this.cardOrder < 1
      ? `Card's order should be in range from 1 to ${MAX_CARDS_IN_DECK}`
      : null;
  }
  @action.bound onCardOrderChanged(order: string): void {
    const orderNumber = Number(order);

    if (orderNumber > MAX_CARDS_IN_DECK) {
      this.cardOrder = MAX_CARDS_IN_DECK;
      return;
    }

    this.cardOrder = orderNumber;
  }

  @computed get isCurrentUser(): boolean | undefined {
    return this.deckDetailStore?.isCurrentUser;
  }

  @action.bound openModal(modalType: ModalType): void {
    this.modalStore.openModal(modalType);
  }
}
