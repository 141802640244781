import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import FlagPoleIcon from "../../assets/img/flagpole-2.png";
import { protectPageSectionOneNumberAnimation } from "../../shared/helpers/protectPageSectionOneNumberAnimation";
import { useTranslation } from "../../shared/translate/NextI18next";
import { LazyImage } from "../LazyImage/LazyImage";
import { TheLoader } from "../TheLoader/TheLoader";
import { M2Icon } from "../UonCountNumberAnimation/M2Icon";
import { UonCountNumberLocation } from "../UonCountNumberAnimation/StylesUonCountNumberAnimation";
import { UonCountNumberAnimation } from "../UonCountNumberAnimation/UonCountNumberAnimation";
import { sc } from "./MobileLandingPage.styled";
import { Policies, PoliciesDriver } from "./Policies";
export interface MobileLandingPageDriver {
  protectedCount: number | null;
  currentYear: number;
  flags: {
    counterFlapEffectDuration: number;
    enableOneFlow: boolean;
  };
  policiesDriver: PoliciesDriver;
  isPolicesOverlayVisible: boolean;

  onMount(): void;
  onUnmount(): void;
  onVisitButtonClicked(): void;
  onDisclaimerClicked(): void;
  onPrivacyPolicyClicked(): void;
  onCookiePolicyClicked(): void;
  onClosePolicyButtonClicked(): void;
}
interface IProps {
  driver: MobileLandingPageDriver;

  /* storybook */
  noAnimationCounter?: boolean;
}
export const MobileLandingPage = observer((props: IProps) => {
  const { t } = useTranslation("EtHeaderCard");
  const { t: tFooter } = useTranslation("Footer");

  useEffect(() => {
    props.driver.onMount();

    return () => {
      props.driver.onUnmount();
    };
  }, []);

  return (
    <sc.Container data-cy="MobileLandingPage-Container" className="visual-reg">
      <sc.StartProtectingCard>
        <sc.BackgroundContainer>
          <sc.OneFlowSpace data-cy="MobileLandingPage-Space" />
        </sc.BackgroundContainer>
        <sc.Content>
          <sc.TopIcon />
          <sc.Title data-cy="MobileLandingPage-Title">
            <sc.Text>{t("etheadercard.start-protecting")}</sc.Text>
          </sc.Title>

          <sc.CounterWrapper data-cy="MobileLandingPage-CounterContainer">
            {props.driver.protectedCount === null ? (
              <TheLoader className="visual-reg-remove" />
            ) : (
              <>
                {protectPageSectionOneNumberAnimation(
                  props.driver.protectedCount,
                ).map((count, i) => (
                  <UonCountNumberAnimation
                    dataCy={"MobileLandingPage-CountNumber"}
                    flags={props.driver.flags}
                    noAnimationCounter={props.noAnimationCounter}
                    countNumbers={count.countNumbers}
                    lastNumber={count.lastNumber}
                    key={`${count.lastNumber}${i}`}
                    location={UonCountNumberLocation.HeaderCard}
                  />
                ))}
                <M2Icon
                  location={UonCountNumberLocation.HeaderCard}
                  enableOneFlow={true}
                />
              </>
            )}
          </sc.CounterWrapper>

          <sc.Description data-cy="MobileLandingPage-Description">
            {t("etheadercard.meter-x-meter")}
          </sc.Description>

          <sc.VisitUonEarthButton
            data-cy="MobileLandingPage-VisitUonEarthButton"
            onClick={props.driver.onVisitButtonClicked}
          >
            {t("etheadercard.visit-uon-earth")}
          </sc.VisitUonEarthButton>
        </sc.Content>
      </sc.StartProtectingCard>

      <sc.BecomePartner>
        <sc.BecomePartnerTitle>
          {t("etheadercard.become-partner.title")}
        </sc.BecomePartnerTitle>
        <sc.BecomePartnerDescription>
          {t("etheadercard.become-partner.description")}
        </sc.BecomePartnerDescription>
      </sc.BecomePartner>

      <sc.Footer>
        <sc.Logo data-cy="MobileLandingPage-Logo" />
        <sc.LogoText data-cy="MobileLandingPage-LogoText">
          {tFooter("footer.copyright")} &copy; {props.driver.currentYear}
        </sc.LogoText>
      </sc.Footer>
      <sc.Line />
      <sc.Policies>
        <span onClick={props.driver.onDisclaimerClicked}>
          {tFooter("footer.disclaimer")}
        </span>
        &nbsp;-&nbsp;
        <span onClick={props.driver.onPrivacyPolicyClicked}>
          {tFooter("footer.privacy-policy")}
        </span>
        &nbsp;-&nbsp;
        <span onClick={props.driver.onCookiePolicyClicked}>
          {tFooter("footer.cookie-policy")}
        </span>
      </sc.Policies>
      {props.driver.isPolicesOverlayVisible && (
        <sc.PoliciesOverlay id="PoliciesOverlay">
          <sc.ClosePoliciesButton
            onClick={props.driver.onClosePolicyButtonClicked}
          />
          <Policies driver={props.driver.policiesDriver} />
        </sc.PoliciesOverlay>
      )}
    </sc.Container>
  );
});
