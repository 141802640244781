import styled from "styled-components";

import {
  mediumScreenUpperLimit,
  smallScreenUpperLimit,
} from "../../../shared/breakpoints";
import {
  etBrightLightBlue,
  etNevadaGrey,
  etWhite,
} from "../../../shared/colors";

export namespace sc {
  export const Section = styled.section`
    background-color: ${etWhite};
    padding: 48px;
    position: relative;

    @media (max-width: ${smallScreenUpperLimit}) {
      padding: 24px;
    }
  `;
  export const SectionContent = styled.div``;
  export const LogoBlock = styled.div`
    display: flex;

    @media (max-width: ${mediumScreenUpperLimit}) {
      justify-content: space-between;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      flex-direction: column-reverse;
      gap: 16px;
      justify-content: center;
      align-items: center;
      margin-bottom: 12px;
    }
  `;
  export const UonEarthLogo = styled.div`
    background-image: url(${require("./assets/logo-uonearth.png")});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 40px;
    width: 200px;
  `;
  export const DownloadOptionsContainer = styled.div`
    width: 100vw;
    position: absolute;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;

    @media (max-width: ${mediumScreenUpperLimit}) {
      position: relative;
      width: fit-content;
      gap: 12px;
    }
  `;
  export const CopyrightBlock = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: ${smallScreenUpperLimit}) {
      width: 100%;
      gap: 12px;
      flex-direction: column;
    }
  `;
  export const CopyrightText = styled.span`
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
    color: ${etNevadaGrey};
  `;
  export const SocialContacts = styled.span`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 16px;
    align-items: center;
    width: fit-content;
  `;
  export const Logo = styled.a`
    &:hover {
      svg > g > g {
        fill: ${etBrightLightBlue};
      }
    }
  `;
  export const BreakLine = styled.hr`
    margin-top: 40px;
    border: 1px solid rgba(210, 210, 210, 1);

    @media (max-width: ${mediumScreenUpperLimit}) {
      margin-top: 20px 0;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      margin-top: 15px 0;
    }
  `;
  export const SplitLine = styled.span`
    @media (max-width: ${smallScreenUpperLimit}) {
      display: none;
    }
  `;
  export const PoliciesBlock = styled.div`
    display: flex;
    gap: 4px;
    width: fit-content;
    color: ${etNevadaGrey};

    @media (max-width: ${smallScreenUpperLimit}) {
      width: 100%;
      flex-direction: column;
      align-items: center;
    }
  `;
  export const PolicyLink = styled.a`
    color: ${etNevadaGrey};
    width: fit-content;
    :hover {
      text-decoration: none;
      color: ${etNevadaGrey};
    }
  `;
}
