import styled from "styled-components";

export namespace sc {
  export const WrapperLazyImage = styled.div<{
    width: string;
    height: string;
    maxWidth: string;
    maxHeight: string;
    display: string;
    position:
      | "static"
      | "absolute"
      | "fixed"
      | "relative"
      | "sticky"
      | "initial"
      | "inherit";
    top: string;
    left: string;
    zIndex: string;
  }>`
    > img {
      width: ${(props) => props.width};
      max-width: ${(props) => props.maxWidth};
      height: ${(props) => props.height};
      max-height: ${(props) => props.maxHeight};
      display: ${(props) => props.display};
      position: ${(props) => props.position};
      top: ${(props) => props.top};
      left: ${(props) => props.left};
      z-index: ${(props) => props.zIndex};
    }
  `;
}
