import { TokenInterceptorStore } from "../../stores/TokenInterceptorStore";
import { IMAGE_UPLOADS } from "../constants";
import { getAPIBaseUrl } from "../env";
import { ImagePresignUploadStatus } from "../models/Image";
import { Logger } from "../models/Logger";
import { ETLocalStorage } from "./EtStorages";
import { ImageUpload } from "./imageUploadsStorage";
import { isBrowser } from "./isBrowser";

const deleteAndMoveToCorruptedImage = async (
  tokenInterceptorStore: TokenInterceptorStore,
  uploadToken: string,
  reason: string,
) => {
  await tokenInterceptorStore.call({
    method: "DELETE",
    url: `${getAPIBaseUrl()}/images/presign`,
    data: {
      uploadToken,
      reason,
    },
  });
};

export const handleUnCommitImage = {
  async execute(
    tokenInterceptorStore: TokenInterceptorStore,
    logger: Logger,
  ): Promise<void> {
    if (!isBrowser()) return;

    const imagesUploadJson = ETLocalStorage.getItem(IMAGE_UPLOADS);
    if (!imagesUploadJson) {
      return;
    }

    const imagesUpload: Array<ImageUpload> = JSON.parse(imagesUploadJson);

    await Promise.all([
      imagesUpload.map((cs) =>
        this.handleByStatus(tokenInterceptorStore, cs, logger),
      ),
    ]);

    ETLocalStorage.removeItem(IMAGE_UPLOADS);
  },

  async handleByStatus(
    tokenInterceptorStore: TokenInterceptorStore,
    cs: ImageUpload,
    logger: Logger,
  ): Promise<void> {
    // don't show error
    switch (cs.status) {
      case ImagePresignUploadStatus.uploading: {
        try {
          await deleteAndMoveToCorruptedImage(
            tokenInterceptorStore,
            cs.uploadToken,
            "Image not uploaded",
          );
        } catch (error) {
          logger.warn("handle uncommit image fail", error);
        }
        return;
      }
      case ImagePresignUploadStatus.uploaded:
      case ImagePresignUploadStatus.committing:
      default: {
        try {
          await deleteAndMoveToCorruptedImage(
            tokenInterceptorStore,
            cs.uploadToken,
            "Commit fail",
          );
        } catch (error) {
          logger.warn("handle uncommit image fail", error);
        }
      }
    }
  },
};
