import {
  PaymentMethodHealthStatus,
  PaymentMethodName,
  PaymentMethodResponse,
} from "../apis/PaymentApi";
import { PaymentMethod } from "../models/Payment";

export function paymentMethodsMapper(
  paymentMethodsResponse: PaymentMethodResponse[],
): PaymentMethod[] {
  const paymentMethods: PaymentMethod[] = [];
  for (const method of paymentMethodsResponse) {
    if (method.healthStatus === PaymentMethodHealthStatus.ERROR) continue;
    switch (method.paymentMethodName) {
      case PaymentMethodName.MOLLIE_IDEAL: {
        paymentMethods.push(PaymentMethod.IDeal);
        break;
      }
      case PaymentMethodName.MOLLIE_CREDITCARD:
      case PaymentMethodName.STRIPE_CREDITCARD: {
        paymentMethods.push(PaymentMethod.Card);
        break;
      }
      default: {
        throw new Error(`Unexpected method name ${method.paymentMethodName}`);
      }
    }
  }

  return paymentMethods;
}
