import { computed, makeObservable } from "mobx";

import emptyFeaturedCardDarkMode from "../../assets/img/dark/empty-featured-card-dark-mode.svg";
import logoDarkMode from "../../assets/img/dark/et-logo-dark-mode.png";
import emptyCardBg from "../../assets/img/empty-card-bg.svg";
import emptyCardDarkBg from "../../assets/img/empty-card-dark-bg.svg";
import emptyFeaturedCard from "../../assets/img/empty-featured-card.svg";
import {
  etBlack,
  etBrightLightBlue,
  etGray,
  etGrayBold,
  etGrayDark,
  etGrayIconMenu,
  etGrayLight,
  etLicoriceBlue,
  etRegentGray,
  etSolitudeLight,
  etWhite,
} from "../../shared/colors";
import transparentize from "../../shared/helpers/transparentize";
import { IDeviceStore } from "../DeviceStore";

export interface ThemeDriver {
  defaultBackground: string;
  defaultLayoutBackground: string;
  defaultLargeTextColor: string;
  defaultButtonBorderColor: string;
  defaultSmallTextColor: string;
  notificationButtonBorder: string;
  notificationButtonActiveBackground: string;
  notificationButtonActiveColor: string;
  logo: any;
  searchIconColor: string;
  notificationTitleColor: string;

  /* ********** following page ********** */
  emptyStateFollowingPage: any;

  /* ********** profile page ********** */
  emptyStateFeatureCard: string;

  /* ********** card menu/joining ********** */
  cardMenuBackgroundColor: string;
  cardJoiningTextColor: string;
  cardJoiningOptionBackgroundColor: string;

  /* ********** nav bar ********** */
  theNavbarBottomColor: string;
  theNavbarSearchResultItemBorderColor: string;
  theNavbarSearchInputBorderColor: string;
  theNavbarMenuButtonColor: string;
  theNavbarMenuButtonColorForTablet: string;
  theNavbarMenuButtonActiveColor: string;

  searchIconActiveColor: string;
  menuTextColor: string;
  menuPlayIconBackground: string;
  theFooterBackgroundColor: string;
}
export class ThemeStore {
  private lightTheme: ThemeDriver = {
    defaultBackground: etWhite,
    defaultLayoutBackground: "transparent",
    defaultLargeTextColor: etBlack,
    defaultSmallTextColor: etGray,
    defaultButtonBorderColor: etGray,
    notificationButtonBorder: etGray,
    notificationButtonActiveBackground: etGray,
    notificationButtonActiveColor: etWhite,
    notificationTitleColor: etLicoriceBlue,
    logo: logoDarkMode,
    emptyStateFollowingPage: emptyCardBg,
    searchIconColor: etGray,
    searchIconActiveColor: etBrightLightBlue,

    /* ********** profile page ********** */
    emptyStateFeatureCard: emptyFeaturedCard,

    /* ********** nav bar ********** */
    cardMenuBackgroundColor: etWhite,
    cardJoiningTextColor: etGrayDark,
    cardJoiningOptionBackgroundColor: etGrayLight,

    theNavbarBottomColor: etGrayBold, // deprecated

    theNavbarSearchResultItemBorderColor: etGrayLight,
    theNavbarSearchInputBorderColor: etBrightLightBlue,
    theNavbarMenuButtonColor: etGray,
    theNavbarMenuButtonColorForTablet: etGray,
    theNavbarMenuButtonActiveColor: etBrightLightBlue,

    menuTextColor: etGrayIconMenu,
    menuPlayIconBackground: etWhite,
    theFooterBackgroundColor: etSolitudeLight,
  };

  private darkTheme: ThemeDriver = {
    defaultBackground: etBlack,
    defaultLayoutBackground: etBlack,
    defaultLargeTextColor: etSolitudeLight,
    defaultSmallTextColor: etSolitudeLight,
    defaultButtonBorderColor: etSolitudeLight,
    notificationButtonBorder: etSolitudeLight,
    notificationButtonActiveBackground: etSolitudeLight, // rgb(235, 238, 240);
    notificationButtonActiveColor: etLicoriceBlue,
    notificationTitleColor: etSolitudeLight,
    logo: logoDarkMode,
    emptyStateFollowingPage: emptyCardDarkBg,
    searchIconColor: etWhite,
    searchIconActiveColor: etBrightLightBlue,

    /* ********** profile page ********** */
    emptyStateFeatureCard: emptyFeaturedCardDarkMode,

    /* ********** nav bar ********** */
    cardMenuBackgroundColor: etBlack,
    cardJoiningTextColor: etSolitudeLight,
    cardJoiningOptionBackgroundColor: etRegentGray,

    theNavbarBottomColor: etSolitudeLight,
    theNavbarSearchResultItemBorderColor: etSolitudeLight,
    theNavbarSearchInputBorderColor: etBlack,
    theNavbarMenuButtonColor: etSolitudeLight,
    theNavbarMenuButtonColorForTablet: etGray,
    theNavbarMenuButtonActiveColor: etBrightLightBlue,

    menuTextColor: etSolitudeLight,
    menuPlayIconBackground: transparentize(etRegentGray, 0.25),
    theFooterBackgroundColor: etBlack,
  };

  constructor(private device: IDeviceStore) {
    this.device = device;
    makeObservable(this);
  }

  @computed get deviceStore(): IDeviceStore {
    return this.device;
  }

  @computed get isWebpSupported(): boolean {
    if (!this.deviceStore) {
      return false;
    }
    return this.deviceStore.isWebPSupported;
  }

  @computed get isMobileDevice(): boolean {
    if (!this.deviceStore) {
      return false;
    }

    return this.deviceStore.isMobileDevice;
  }

  @computed get themeStyle(): ThemeDriver {
    if (this.isMobileDevice) {
      return this.darkTheme;
    }
    return this.lightTheme;
  }
}
