import { observer } from "mobx-react-lite";

import { useTranslation } from "../../../shared/translate/NextI18next";
import { DangerouslyHTMLRenderer } from "../../DangerouslyHTMLRenderer/DangerouslyHTMLRenderer";
import { DownloadAppButton } from "../../DownloadAppButtons/DownloadAppButton";
import { sc } from "./SectionThree.styled";

export interface SectionThreeDirver {
  shouldRenderAppStore: boolean;
  shouldRenderGooglePlay: boolean;
}
interface IProps {
  driver: SectionThreeDirver;
}
export const SectionThree = observer((props: IProps) => {
  const { shouldRenderAppStore, shouldRenderGooglePlay } = props.driver;
  const { t } = useTranslation("LandingPage");

  return (
    <sc.Section className="visual-reg">
      <sc.SectionContent>
        <sc.Title>
          <DangerouslyHTMLRenderer>
            {t("landing-page.section-three.title")}
          </DangerouslyHTMLRenderer>
        </sc.Title>
        <sc.ImageGroup>
          <sc.StepImage src={require("./assets/step-1.png")} alt="step 1" />
          <sc.StepImage src={require("./assets/step-2.png")} alt="step 2" />
          <sc.StepImage src={require("./assets/step-3.png")} alt="step 3" />
          <sc.StepImage src={require("./assets/step-4.png")} alt="step 4" />
          <sc.PhoneImage src={require("./assets/phone.png")} alt="phone" />
        </sc.ImageGroup>
        <sc.DowloadOptionsContainer>
          {shouldRenderAppStore && (
            <DownloadAppButton
              store="app-store"
              width={152}
              height={47}
              storeUrl="https://apps.apple.com/app/uon-earth/id6470965614"
            />
          )}
          {shouldRenderGooglePlay && (
            <DownloadAppButton
              store="google-play"
              width={152}
              height={47}
              storeUrl="https://play.google.com/store/apps/details?id=uon.earth"
            />
          )}
        </sc.DowloadOptionsContainer>
      </sc.SectionContent>
    </sc.Section>
  );
});
