import { intervalToDuration } from "date-fns";

import { isCurrentYear } from "./isCurrentYear";

interface Result {
  day: string;
  month: string | number;
  year: number;
}

export const formatMonth = (date: Date | string): Result => {
  const dateObj = new Date(date);
  let month: string | number;

  switch (dateObj.getMonth() + 1) {
    case 1: {
      month = "January";
      break;
    }
    case 2: {
      month = "February";
      break;
    }
    case 3: {
      month = "March";
      break;
    }
    case 4: {
      month = "April";
      break;
    }
    case 5: {
      month = "May";
      break;
    }
    case 6: {
      month = "June";
      break;
    }
    case 7: {
      month = "July";
      break;
    }
    case 8: {
      month = "August";
      break;
    }
    case 9: {
      month = "September";
      break;
    }
    case 10: {
      month = "October";
      break;
    }
    case 11: {
      month = "November";
      break;
    }
    case 12: {
      month = "December";
      break;
    }
    default: {
      month = "January";
      break;
    }
  }

  const day = dateObj.getDate();

  return {
    day: day >= 10 ? `${day}` : `0${day}`,
    month,
    year: dateObj.getFullYear(),
  };
};

export const formatDateForNotification = (
  fromDate: Date,
  toDate: string,
): string => {
  const distance = fromDate.getTime() - new Date(toDate).getTime();

  if (distance >= 1000 * 60 * 60 * 24) {
    const parseDate = formatMonth(toDate);
    return `${parseDate.month} ${parseDate.day} ${parseDate.year}`;
  }

  let interval = Math.floor(distance / (3600 * 1000));
  if (interval >= 1) {
    return `${interval} hours ago`;
  }

  interval = Math.floor(distance / (60 * 1000));
  if (interval >= 1) {
    return `${interval} ${interval <= 1 ? "minute" : "minutes"} ago`;
  }

  interval = Math.floor(distance / 1000);
  return `${interval <= 1 ? "just now" : `${interval} seconds ago`}`;
};

export const formatDateForLeaderBoard = (
  toDate: string,
  isGoodCompany: boolean,
): string => {
  const dateString = toDate
    .replaceAll("-", "/")
    .replace("T", " ")
    .replace(/\..*|\+.*/, "");

  const parseDate = formatMonth(dateString);

  if (isCurrentYear(new Date(dateString))) {
    return isGoodCompany
      ? `${new Date(dateString).getDate()} ${parseDate.month}`
      : `${parseDate.month} ${new Date(dateString).getDate()}`;
  }

  return isGoodCompany
    ? `${new Date(dateString).getDate()} ${parseDate.month} ${parseDate.year}`
    : `${parseDate.month} ${new Date(dateString).getDate()} ${parseDate.year}`;
};

export const formatHourMinute = (date: Date | string): string => {
  const minutes = new Date(date).getMinutes();
  return `${new Date(date).getHours()}:${
    minutes < 10 ? `0${minutes}` : minutes
  }`;
};

export const formatDayMonthYear = (date: Date | string): string => {
  const parseDate = formatMonth(date);
  return `${parseDate.day} ${parseDate.month} ${parseDate.year}`;
};

export const formatMonthDayYearHourMinute = (date: Date | string): string => {
  const { day, month, year } = formatMonth(date);
  return `${month} ${day}, ${year}, ${formatHourMinute(date)}`;
};

export const millisecondsToHoursMinutes = (milliseconds: number): string => {
  const { hours, minutes, seconds } = intervalToDuration({
    start: 0,
    end: milliseconds,
  });

  if (hours === 0 && minutes === 0) {
    return `${seconds}s`;
  }

  return `${hours ? `${hours}h ` : ""}${minutes}min`;
};

export const formatDateForUonCard = (date: Date | string): string => {
  const dateObj = new Date(date);
  const day: number = dateObj.getDate();
  const month: number = dateObj.getMonth() + 1;
  const year: number = dateObj.getFullYear();
  const hours: number = dateObj.getUTCHours();
  const minutes: number = dateObj.getMinutes();
  const seconds: number = dateObj.getSeconds();
  return `${day < 10 ? "0" + day : day}/${
    month < 10 ? "0" + month : month
  }/${year} ${hours < 10 ? "0" + hours : hours}:${
    minutes < 10 ? "0" + minutes : minutes
  }:${seconds < 10 ? "0" + seconds : seconds} UTC`;
};
