/* eslint-disable max-classes-per-file */
/* eslint-disable class-methods-use-this */
import { slugify } from "transliteration";

import { StrategyFirstLastName } from "./StrategyFirstLastName";
import { StrategyFirstLastNameAbbreviation } from "./StrategyFirstLastNameAbbreviation";
import { StrategyFullName } from "./StrategyFullName";
import { StrategyFullNameAbbreviation } from "./StrategyFullNameAbbreviation";
import { StrategyMiddleNameAbbreviation } from "./StrategyMiddleNameAbbreviation";
import { StrategyPostfixRandomAlphanumeric } from "./StrategyPostfixRandomAlphanumeric";

export interface VanityNameGeneratorStrategy {
  generate(): { vanityName: string; next: boolean };
}

export type RandomAlphanumericFunction = () => string;

export const VANITY_NAME_MAX_NUMBER_LENGTH = 10;
export const VANITY_NAME_MIN_LENGTH = 3;
export const VANITY_NAME_PATTERN = new RegExp(
  "^[a-zA-Z0-9]([a-zA-Z0-9-]+[a-zA-Z0-9]|[a-zA-Z0-9])?$",
);
const MAX_ATTEMPTS = 52;

export class VanityNameGenerator {
  private nameParts: string[];

  private strategies: VanityNameGeneratorStrategy[];

  private activeStrategyIndex = 0;

  private generatedVanityNames: string[] = [];

  constructor(
    fullName: string,
    randomAlphanumeric: RandomAlphanumericFunction,
    private excludedVanityNames?: string[],
  ) {
    if (!fullName) {
      throw new Error("Expect fullName in vanity name generator");
    }

    this.nameParts = slugify(fullName).split("-");
    this.strategies = [
      new StrategyFirstLastName(this.nameParts),
      new StrategyFullName(this.nameParts),
      new StrategyFullNameAbbreviation(this.nameParts),
      new StrategyMiddleNameAbbreviation(this.nameParts),
      new StrategyFirstLastNameAbbreviation(this.nameParts),
      new StrategyPostfixRandomAlphanumeric(this.nameParts, randomAlphanumeric),
    ];
  }

  private nextVanityName(): string {
    const strategy = this.strategies[this.activeStrategyIndex];
    const { vanityName, next } = strategy.generate();

    if (next) {
      this.activeStrategyIndex += 1;
    }

    return vanityName;
  }

  private async isOk(vanityName: string): Promise<boolean> {
    if (this.generatedVanityNames.includes(vanityName)) {
      return false;
    }
    this.generatedVanityNames.push(vanityName);

    if (vanityName.length < VANITY_NAME_MIN_LENGTH) {
      return false;
    }

    if (!VANITY_NAME_PATTERN.test(vanityName)) {
      return false;
    }

    if (
      this.excludedVanityNames &&
      this.excludedVanityNames.includes(vanityName)
    ) {
      return false;
    }

    return true;
  }

  async createVanityName(): Promise<string> {
    let attemptCount = 0;
    let vanityName = "";

    do {
      vanityName = this.nextVanityName();
      attemptCount += 1;

      if (attemptCount > MAX_ATTEMPTS) {
        throw new Error("Unable to generate a valid vanity name");
      }
      // eslint-disable-next-line no-await-in-loop
    } while (!(await this.isOk(vanityName)));

    return vanityName;
  }
}
