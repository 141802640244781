import { action, computed, makeObservable, observable } from "mobx";

import { host } from "../shared/env";

export interface MetaTag {
  [key: string]: string;
}

export type MetaData = {
  metaTags: MetaTag;
};

export class MetaStore {
  @observable metaTags: MetaTag = {
    "og:image": `${host}/ThumbnailSmall.png`,
    "fb:app_id": "356721174733993",
    "og:type": "website",
    "og:site_name": "EarthToday",
    "og:title": "EarthToday",
    "og:url": "https://earthtoday.com/",
    "og:description": "What’s happening on EarthToday",
  };

  constructor() {
    makeObservable(this);
  }

  public dehydrate(): MetaData {
    return {
      metaTags: this.metaTags,
    };
  }

  @action.bound public hydrate = (data: MetaData): void => {
    const image = data.metaTags["og:image"];
    if (image && image.includes("images/earthtoday-preview-image.png")) {
      data.metaTags["og:image"] = `${host}/ThumbnailSmall.png`; // eslint-disable-line no-param-reassign
    }

    this.metaTags = data.metaTags;
  };

  @action.bound updateMetaTags = (metaTags: MetaTag): void => {
    if (Object.keys(metaTags).length === 0) {
      return;
    }

    this.metaTags = metaTags;
  };

  @computed get metaTagsArray(): MetaTag[] {
    if (!this.metaTags) {
      return [];
    }

    return Object.keys(this.metaTags).map((key) => {
      return {
        key,
        content: this.metaTags ? this.metaTags[key] : "",
      };
    });
  }
  @computed get metaTagsArrayWhatsApp(): MetaTag[] {
    if (!this.metaTags) {
      return [];
    }

    return Object.keys(this.metaTags).map((key) => {
      return {
        key,
        content: this.metaTags ? this.metaTags[key] : "",
      };
    });
  }

  @computed get metaTagsTwitterArray(): MetaTag[] {
    if (!this.metaTags) {
      return [];
    }

    const metaTagsTwitter = Object.keys(this.metaTags).map((key) => {
      return {
        key: key.replace("og", "twitter"),
        content: this.metaTags ? this.metaTags[key] : "",
      };
    });
    metaTagsTwitter.push({
      key: "twitter:card",
      content: "summary_large_image",
    }); // this makes preview image on telegram bigger

    return metaTagsTwitter;
  }

  @computed get metaGoogleArray(): MetaTag[] {
    if (!this.metaTags) {
      return [];
    }

    return Object.keys(this.metaTags).map((key) => {
      return {
        key: key.replace("og:", ""),
        content: this.metaTags ? this.metaTags[key] : "",
      };
    });
  }
  @computed get metaPinterestArray(): MetaTag[] {
    if (!this.metaTags) {
      return [];
    }

    const result: MetaTag[] = [];

    for (const key of Object.keys(this.metaTags)) {
      if (key === "og:type") {
        result.push({
          key,
          content: "article",
        });
      }
    }

    return result;
  }
}
