import { observer } from "mobx-react-lite";
import React, { ReactNode } from "react";

import { CardSize } from "../../shared/models/Card";
import sc from "./CardItemLayout.styled";

export interface LayoutDriver {
  readonly cardSize: CardSize;
}

interface IProps {
  layoutDriver: LayoutDriver;
  children: ReactNode;
  options?: {
    noBoxShadow: boolean;
  };
}

export default observer(function (props: IProps) {
  const { layoutDriver, options } = props;
  const spanNumber =
    layoutDriver.cardSize === CardSize.SINGLE
      ? 1
      : layoutDriver.cardSize === CardSize.DOUBLE
      ? 2
      : 3;

  return (
    <sc.Container
      data-cy="CardItemLayout-Container"
      className={`card visual-reg ${options?.noBoxShadow ? "" : "shadow"}`}
      spanNumber={spanNumber}
    >
      {props.children}
    </sc.Container>
  );
});
