import { action, makeObservable } from "mobx";

import { RootStore } from "../../stores/rootStore";

export class LayoutAppPresenter {
  constructor(private rootStore: RootStore) {
    makeObservable(this);
  }

  @action.bound onMount(
    isMobileDevice: boolean,
    pageName: string,
    asPath: string,
  ) {
    if (
      isMobileDevice &&
      this.rootStore.mobileLandingPagePresenter.shouldDisplayMobileLandingPage
    ) {
      return;
    }

    this.rootStore.theNavbarStore.onUserSessionStateUpdated(
      pageName,
      this.rootStore.userSessionStore.didInit,
      asPath,
    );
  }
}
