import styled, { keyframes } from "styled-components";

import { smallScreenUpperLimit } from "../../shared/breakpoints";
import {
  etBrightLightBlue,
  etHeatherBlue,
  etLicoriceBlue,
  etWhite,
} from "../../shared/colors";
import zIndex from "../../shared/zIndex";

namespace sc {
  const EaseIn = keyframes`
		0% {
			top: 100%;
			opacity: 0;
			transform: scale(0.8);
		}

		100% {
			bottom: 0;
			opacity: 1;
			transform: scale(1);
		}
	`;
  const EaseOut = keyframes`
		0% {
			bottom: 0;
			opacity: 1;
			transform: scale(1);
		}
		100% {
			top: 100%;
      opacity: 0;
			transform: scale(0.8);
		}
	`;

  export const Container = styled.div`
    padding: 1rem;
    position: fixed;
    bottom: -100%;
    right: 0;
    z-index: ${zIndex.TOAST_MESSAGE};
    display: none;
    &.active {
      animation: ${EaseIn} ease-in 0.4s;
      bottom: 0;
      display: block;
      @media (max-width: ${smallScreenUpperLimit}) {
        width: 100%;
        bottom: 50px;
      }
    }

    &.unactive {
      animation: ${EaseOut} ease-in 0.4s;
      display: block;
    }
  `;

  export const Wrapper = styled.div`
    background: ${etWhite};
    border-radius: 8px;
    padding: 12px;
    padding-bottom: 10px;
    box-shadow: 0 16px 18px 0 rgba(42, 54, 66, 0.2);
    width: 328px;
    z-index: ${zIndex.TOAST_MESSAGE_UNDERLAY};
    position: relative;
    @media (max-width: ${smallScreenUpperLimit}) {
      width: 100%;
    }
  `;

  export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 11px;
  `;

  export const Title = styled.div`
    flex: 1;

    height: 16px;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${etLicoriceBlue};
  `;

  export const Status = styled.div<{ fill: string }>`
    display: flex;
    align-items: center;
    justify-content: center;

    path {
      fill: ${(props) => props.fill};
    }
  `;

  export const Message = styled.div`
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: ${etLicoriceBlue};
  `;

  export const Line = styled.hr`
    margin: 19px 0 0 0;
    display: block;
    height: 1px;
    border: 0;
    border-bottom: solid 1px ${etHeatherBlue};
  `;

  export const Option = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 -0.5rem;
  `;

  export const Action = styled.div`
    margin-top: 9px;
    cursor: pointer;

    height: 14px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${etBrightLightBlue};

    text-align: center;
    width: 100%;
  `;
}

export default sc;
