import { observer } from "mobx-react-lite";
import React from "react";

import { ETReactProps } from "../../../typings/types";
import sc from "./Button.styled";
import { ButtonDriver } from "./PrimaryButton";

interface IProps extends ETReactProps {
  driver: ButtonDriver;
}

export const OutlineLightButton = observer((props: IProps) => {
  const { driver, children } = props;

  return (
    <sc.OutlineLightButton
      data-cy={driver.dataCy}
      data-track-id={driver.dataTrackId}
      driver={driver}
      disabled={driver.disabled}
      type={driver.action}
      onClick={driver.onClick}
    >
      {children}
    </sc.OutlineLightButton>
  );
});
