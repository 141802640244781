import {
  etBlue,
  etGreen,
  etOrange,
  etPink,
  etPurpleUonCard,
  etRed,
  etYellow,
  sdgClimate,
  sdgCommunities,
  sdgConsumption,
  sdgEducation,
  sdgEnergy,
  sdgEquality,
  sdgGrowth,
  sdgHealth,
  sdgHunger,
  sdgInequality,
  sdgInnovation,
  sdgLand,
  sdgPartnerships,
  sdgPeace,
  sdgPoverty,
  sdgSanitation,
  sdgWater,
} from "../colors";

export const getLogoColor = (s: string) => {
  switch (s) {
    case "people": {
      return etRed;
    }
    case "creation": {
      return etOrange;
    }
    case "world": {
      return etYellow;
    }
    case "nature": {
      return etGreen;
    }
    case "keys": {
      return etBlue;
    }
    case "spirit": {
      return etPurpleUonCard;
    }

    case "education": {
      return sdgEducation;
    }
    case "climate": {
      return sdgClimate;
    }
    case "communities": {
      return sdgCommunities;
    }
    case "consumption": {
      return sdgConsumption;
    }
    case "energy": {
      return sdgEnergy;
    }
    case "equality": {
      return sdgEquality;
    }
    case "growth": {
      return sdgGrowth;
    }
    case "health": {
      return sdgHealth;
    }
    case "hunger": {
      return sdgHunger;
    }
    case "inequality": {
      return sdgInequality;
    }
    case "innovation": {
      return sdgInnovation;
    }
    case "land": {
      return sdgLand;
    }
    case "partnerships": {
      return sdgPartnerships;
    }
    case "peace": {
      return sdgPeace;
    }
    case "poverty": {
      return sdgPoverty;
    }
    case "sanitation": {
      return sdgSanitation;
    }
    case "water": {
      return sdgWater;
    }
    case "unity": {
      return etPink;
    }
    default: {
      return "";
    }
  }
};
