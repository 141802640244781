import styled, { keyframes } from "styled-components";

namespace theLoaderSc {
  export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 5rem;
    height: 100%;
    width: 100%;
  `;

  export const loaderRotate = keyframes`
    0% {
      transform: scale(0.35);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.35);
    }
  `;

  export const Loader = styled.div<{
    size: string;
    forcedCenterMiddle: boolean;
  }>`
    content: "";
    height: ${(props) => props.size};
    width: ${(props) => props.size};
    background-image: url(${require("../../assets/svgs/et-com-logo.svg")});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    animation: ${loaderRotate} 3s linear infinite;

    ${(props) =>
      props.forcedCenterMiddle
        ? `
            position: fixed;
            top: 20%;
            left: 50%;
            transform: translateX(-50%);
          `
        : ` position: absolute;`}
  `;
}

export { theLoaderSc };
