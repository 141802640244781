import {
  PROFILE_AVATAR_HEIGHT_DEFAULT,
  PROFILE_AVATAR_WIDTH_DEFAULT,
} from "../../components/TheNavbarControllers/TheNavbarControllers.styled";
import { CustomConsumer, IConsumer } from "../models/Consumer";
import { buildResizedAwsImageRequest } from "./buildAwsImageRelated";

export function buildNewConsumers(consumers: IConsumer[]): CustomConsumer[] {
  return consumers.map((consumer: IConsumer) => {
    if (!consumer.photo) return consumer;
    if (!consumer.photo.awsBucket || !consumer.photo.awsKey) {
      return { ...consumer, imageUrl: consumer.photo.url };
    }

    return {
      ...consumer,
      imageUrl: buildResizedAwsImageRequest(consumer.photo, {
        width: PROFILE_AVATAR_WIDTH_DEFAULT,
        height: PROFILE_AVATAR_HEIGHT_DEFAULT,
      }),
    };
  });
}
