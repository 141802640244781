/* eslint-disable unicorn/filename-case */
import {
  OneFlowCreditedEntity,
  OneFlowInitialState,
  OneFlowRedirectionConfig,
  OneFlowRedirectMode,
} from "../../components/ModalOneFlow/ModalOneFlow";
import { UonSize, uonSizeOptions } from "../../stores/DonateStore/DonateStore";
import {
  CreateAccountType,
  OneFlowScreen,
  OneFlowType,
} from "../../stores/ModalOneFlowPresenter/ModalOneFlowPresenter";
import { OneFlowActAs } from "../models/OneFlow";

export type IDataURLSpecific = {
  urlPath: string;
  oneFlowType: OneFlowType;
  actAs?: OneFlowActAs;
  gifting?: boolean;
  giftsCount?: number;
  protectSize?: number;
  createAccountType?: CreateAccountType;
  subscribeIntervalValue?: number;
  subscribeIntervalUnit?: number;
  redirectPath?: string;
  redirectMode: OneFlowRedirectMode;
};

export const initDataFromURLSpecificOneFlow = (
  data: IDataURLSpecific,
): OneFlowInitialState => {
  const { oneFlowType, actAs, gifting, giftsCount, protectSize } = data;

  const isSkipWhoAreYouScreen =
    (actAs && [OneFlowActAs.GROUP, OneFlowActAs.USER].includes(actAs)) || false;

  const createAccountType =
    actAs === OneFlowActAs.GROUP
      ? CreateAccountType.ORGANIZATION
      : CreateAccountType.INDIVIDUAL;
  const sizeOption = protectSize && protectSize >= 2000 ? 2000 : protectSize;
  const isCustomSize = sizeOption && !uonSizeOptions.includes(sizeOption);
  const size: UonSize = isCustomSize ? "custom" : sizeOption || 1;

  const creditedEntity: OneFlowCreditedEntity = (() => {
    const pathFragments = data.urlPath
      .split("/")
      .filter((fragment) => !!fragment.trim());

    if (pathFragments.length === 2) {
      return {
        type: "RESERVE",
        vanityName: pathFragments[0],
        deckLinkName: pathFragments[1],
      };
    }

    if (pathFragments.length === 1) {
      return {
        type: "PROFILE",
        vanityName: pathFragments[0],
      };
    }

    return {
      type: "GLOBAL",
    };
  })();

  const redirectPath: string = data.redirectPath ?? data.urlPath;

  const redirectionConfig: OneFlowRedirectionConfig | undefined =
    data.redirectPath || data.redirectMode
      ? {
          creditedEntity,
          redirectPath,
          redirectMode: data.redirectMode,
        }
      : undefined;

  if (oneFlowType === OneFlowType.PROTECT) {
    return {
      currentScreen: OneFlowScreen.CHOOSE_M2,
      oneFlowType: OneFlowType.PROTECT,
      createAccountType,
      isSkipWhoAreYouScreen,
      size,
      sizeOption: size === "custom" ? 1 : size,
      preSelectSize: size,
      customSize: isCustomSize ? sizeOption.toString() : undefined,
      actAs,

      redirectionConfig,
    };
  }

  return {
    currentScreen: OneFlowScreen.START,
    giftsCount,
    hasGift: gifting,
    valueGiftInput: giftsCount ? giftsCount.toString() : "",
    createAccountType,
    isSkipWhoAreYouScreen,
    isConfirmSubscribe: true,
    oneFlowType: OneFlowType.SUBSCRIBE,
    actAs,

    redirectionConfig,
  };
};
