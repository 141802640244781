import { IFollowingRequest } from "../../stores/FollowingStore";
import { TokenInterceptorStore } from "../../stores/TokenInterceptorStore";
import { getAPIBaseUrl } from "../env";
import { isBrowser } from "../helpers/isBrowser";
import { Card } from "../models/Card";

export interface IFollowingApi {
  fetchFollowingDecks(): Promise<Card[]>;
  toggleFollowingDecks(data: IFollowingRequest): Promise<{
    count: number;
    following: boolean;
  }>;
}

export class FollowingApi implements IFollowingApi {
  constructor(private tokenInterceptorStore: TokenInterceptorStore) {}

  fetchFollowingDecks = async (): Promise<Card[]> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/feed/me?metaTags=${!isBrowser()}`,
    });

    return res ? res.data.cards : [];
  };

  toggleFollowingDecks = async ({
    following,
    deckId,
    fromProfileId,
  }: IFollowingRequest): Promise<{
    count: number;
    following: boolean;
  }> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/decks/${deckId}/follows`,
      data: { following, fromProfileId },
      method: "PUT",
    });

    return res.data;
  };
}
