import { observer } from "mobx-react-lite";

import { useTranslation } from "../../../shared/translate/NextI18next";
import { LazyImage } from "../../LazyImage/LazyImage";
import { sc } from "./SectionSix.styled";
export const SectionSix = observer(() => {
  const { t } = useTranslation("LandingPage");

  return (
    <sc.Section className="visual-reg">
      <sc.Globe>
        <LazyImage
          src={require("./assets/globe.png")}
          width="100%"
          maxWidth="100%"
          height="100%"
          maxHeight="100%"
          alt="nature needs half"
        />
      </sc.Globe>
      <sc.SectionContent>
        <sc.Title>{t("landing-page.section-six.title")}</sc.Title>
        <sc.Subtitle>{t("landing-page.section-six.sub-title")}</sc.Subtitle>
        <sc.Description>
          {t("landing-page.section-six.description")}
        </sc.Description>
      </sc.SectionContent>
    </sc.Section>
  );
});
