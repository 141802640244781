/* eslint-disable import/no-default-export */
import styled from "styled-components";

import {
  largeScreenUpperLimit,
  mediumScreenUpperLimit,
} from "../../shared/breakpoints";
import { etGrayTuna, etWhite } from "../../shared/colors";
import bgBottom from "./img/bg-bottom.svg";
import bgTop from "./img/bg-top.svg";
import m2Counter from "./img/m2-counter.svg";

namespace sc {
  export const Container = styled.div<{
    col3: string;
    col2: string;
    col1: string;
    enableOneFlow: boolean;
    isNewProtectPage: boolean;
  }>`
    position: relative;
    border-width: 8px;
    border-style: solid;
    border-color: ${etWhite};
    width: 75px;
    height: 75px;

    margin-left: -8px;

    :nth-last-child(3n + 2) {
      margin-right: 16px;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;

    .flap.no-animation {
      [data-kind="digit"] > div {
        animation-duration: 0ms !important;
      }
    }

    .flap {
      height: 54px !important;
      width: 54px !important;
      padding: 0 !important;

      [data-kind="digit"] {
        width: 100%;
        height: 100%;

        font-size: 56px !important;
        line-height: 56px !important;
        font-family: Roboto !important;
        font-weight: bold !important;
        font-stretch: condensed !important;

        background: transparent;

        > div {
          border-radius: 5px;
        }

        > div:nth-child(2),
        > div:nth-child(4) {
          margin-top: 2px !important;
          margin-bottom: 2px !important;
        }

        > div:nth-child(3) {
          margin-top: 2px !important;
          margin-bottom: 2px !important;
        }
      }

      [data-kind="digit"] > div {
        background-image: url(${bgTop}) !important;
        background-repeat: no-repeat !important;
        background-size: 100% 57% !important;
        background-position: top !important;
      }

      [data-kind="digit"] > div + div {
        background-image: url(${bgBottom}) !important;
        background-repeat: no-repeat !important;
        background-size: 100% 57% !important;
        background-position: bottom !important;
      }
    }

    @media (max-width: ${(props) => props.col3}) {
      width: ${(props) =>
        props.enableOneFlow && props.isNewProtectPage ? "75px" : "61px"};
      height: ${(props) =>
        props.enableOneFlow && props.isNewProtectPage ? "75px" : "61px"};
      border-width: ${(props) =>
        props.enableOneFlow && props.isNewProtectPage ? "5px" : "4px"};
      margin-left: -4px;

      :nth-last-child(3n + 2) {
        margin-right: ${(props) =>
          props.enableOneFlow && props.isNewProtectPage ? "13px" : "8px"};
      }

      .flap {
        height: ${(props) =>
          props.enableOneFlow && props.isNewProtectPage
            ? "63px"
            : "50px"} !important;
        width: ${(props) =>
          props.enableOneFlow && props.isNewProtectPage
            ? "63px"
            : "50px"} !important;

        [data-kind="digit"] {
          font-size: ${(props) =>
            props.enableOneFlow && props.isNewProtectPage
              ? "54px"
              : "45px"} !important;
          line-height: ${(props) =>
            props.enableOneFlow && props.isNewProtectPage
              ? "66px"
              : "50px"} !important;

          > div:nth-child(2),
          > div:nth-child(4) {
            margin-top: 1px !important;
          }

          > div:nth-child(3) {
            margin-top: 1px !important;
          }
        }
      }

      @media (max-width: ${largeScreenUpperLimit}) {
        ${(props) =>
          props.enableOneFlow &&
          props.isNewProtectPage &&
          `
            width: 61px;
            height: 61px;
            border-width: 4px;

            :nth-last-child(3n + 2) {
              margin-right: 11px;
            }
            .flap {
              height: 50px !important;
              width: 50px !important;
            
              [data-kind="digit"] {
                font-size: 45px !important;
                line-height: 50px !important;
              }
            }
      `}
      }

      @media (max-width: ${(props) => props.col2}) {
        width: 50px;
        height: 50px;
        border-width: 3px;
        margin-left: -3px;

        :nth-last-child(3n + 2) {
          margin-right: ${(props) => (props.enableOneFlow ? "8px" : "6px")};
        }

        .flap {
          height: 40px !important;
          width: 40px !important;

          [data-kind="digit"] {
            font-size: 37px !important;
            line-height: 41px !important;
          }
        }
      }

      @media (max-width: ${(props) => props.col1}) {
        width: 28px;
        height: 28px;
        border-width: 2px;
        margin-left: -2px;

        :nth-last-child(3n + 2) {
          margin-right: 4px;
        }

        .flap {
          height: 24px !important;
          width: 24px !important;

          [data-kind="digit"] {
            font-size: 21px !important;
            line-height: 25px !important;
          }
        }
      }
    }
  `;

  export const Wrapper = styled.div<{ noAnimationCounter: boolean }>`
    display: flex;
    justify-content: flex-end;

    flex-direction: column;

    animation: ${(props) =>
      props.noAnimationCounter ? "none" : "move linear 1s 1"};

    @keyframes move {
      from {
        transform: translateY(50%);
      }
      to {
        transform: translateY(0%);
      }
    }
  `;

  export const Count = styled.span<{
    fontSizeLargeScreen: string;
    fontSizeTablet: string;
    fontSizeMobile: string;
    lineHeight: string;
    lineHeightTablet: string;
    lineHeightMobile: string;
  }>`
    color: ${etWhite};
    font-family: "Roboto Condensed";
    font-style: normal;
    font-size: ${(props) => props.fontSizeLargeScreen};
    font-weight: bold;
    line-height: ${(props) => props.lineHeight};

    text-align: center;

    background: ${etGrayTuna};
    background: -webkit-linear-gradient(
      to top,
      ${etGrayTuna} 0%,
      ${etWhite} 43%,
      ${etWhite} 60%,
      ${etGrayTuna} 100%
    );
    background: -moz-linear-gradient(
      to top,
      ${etGrayTuna} 0%,
      ${etWhite} 43%,
      ${etWhite} 60%,
      ${etGrayTuna} 100%
    );
    background: linear-gradient(
      to top,
      ${etGrayTuna} 0%,
      ${etWhite} 43%,
      ${etWhite} 60%,
      ${etGrayTuna} 100%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: ${largeScreenUpperLimit}) {
      font-size: ${(props) => props.fontSizeTablet};
      line-height: ${(props) => props.lineHeightTablet};
    }
    @media (max-width: ${mediumScreenUpperLimit}) {
      font-size: ${(props) => props.fontSizeMobile};
      line-height: ${(props) => props.lineHeightMobile};
    }
  `;

  export const Chain = styled.div<{
    col3: string;
    col2: string;
    col1: string;
    enableOneFlow: boolean;
    isNewProtectPage: boolean;
  }>`
    position: absolute;
    z-index: ${(props) =>
      props.enableOneFlow && props.isNewProtectPage ? 0 : 10};
    width: 3px;
    max-width: 3px;
    height: 9px;
    max-height: 9px;

    top: 25px;
    left: 47.5px;

    border-radius: 100%;

    background: radial-gradient(
      ellipse 100% 100% at 50% 0%,
      rgb(71, 71, 71) 0%,
      rgb(24, 24, 24) 100%
    );

    &:before {
      content: "";
      position: absolute;
      z-index: 10;
      top: 0px;
      right: 39.5px;

      width: 3px;
      max-width: 3px;
      height: 9px;
      max-height: 9px;

      border-radius: 100%;

      background: radial-gradient(
        ellipse 100% 100% at 50% 0%,
        rgb(71, 71, 71) 0%,
        rgb(24, 24, 24) 100%
      );

      @media (max-width: ${(props) => props.col3}) {
        width: 2px;
        max-width: 2px;
        height: 7px;
        max-height: 7px;

        right: 36px;
      }

      @media (max-width: ${(props) => props.col2}) {
        width: 2px;
        max-width: 2px;
        height: 6px;
        max-height: 6px;

        right: 30px;
      }

      @media (max-width: ${(props) => props.col1}) {
        width: 1px;
        max-width: 1px;
        height: 3px;
        max-height: 3px;

        right: 18px;
      }
    }

    @media (max-width: ${(props) => props.col3}) {
      width: 2px;
      max-width: 2px;
      height: 7px;
      max-height: 7px;

      top: 23px;
      left: 43.5px;
    }

    @media (max-width: ${(props) => props.col2}) {
      width: 2px;
      max-width: 2px;
      height: 6px;
      max-height: 6px;

      top: 19px;
      left: 36px;
    }

    @media (max-width: ${(props) => props.col1}) {
      width: 1px;
      max-width: 1px;
      height: 3px;
      max-height: 3px;

      top: 11px;
      left: 20.5px;
    }
  `;

  export const M2 = styled.div<{
    col3: string;
    col2: string;
    col1: string;
    enableOneFlow: boolean;
    isNewProtectPage: boolean;
  }>`
    height: 75px;
    max-height: 75px;
    width: 75px;
    max-width: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -8px;

    background-image: url(${m2Counter});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media (max-width: ${(props) => props.col3}) {
      max-height: ${(props) =>
        props.enableOneFlow && props.isNewProtectPage ? "75px" : "61px"};
      height: ${(props) =>
        props.enableOneFlow && props.isNewProtectPage ? "75px" : "61px"};
      width: ${(props) =>
        props.enableOneFlow && props.isNewProtectPage ? "75px" : "61px"};
      max-width: ${(props) =>
        props.enableOneFlow && props.isNewProtectPage ? "75px" : "61px"};
      margin-left: ${(props) =>
        props.enableOneFlow && props.isNewProtectPage ? "-6px" : "-4px"};

      @media (max-width: ${largeScreenUpperLimit}) {
        height: 61px;
        width: 61px;
      }
    }

    @media (max-width: ${(props) => props.col2}) {
      height: 50px;
      max-height: 50px;
      width: 50px;
      max-width: 50px;
      margin-left: -3px;
    }

    @media (max-width: ${(props) => props.col1}) {
      height: 28px;
      max-height: 28px;
      width: 28px;
      max-width: 28px;
      margin-left: -2px;
    }
  `;
}

export default sc;
