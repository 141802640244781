/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import "mapbox-gl/dist/mapbox-gl.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../src/assets/css/font.css";

import { Request } from "express";
import { observer } from "mobx-react-lite";
import App, { AppContext, AppProps, NextWebVitalsMetric } from "next/app";
import { getDisplayName } from "next/dist/shared/lib/utils";
import Router, { useRouter } from "next/router";
import { appWithTranslation, i18n } from "next-i18next";
import { ReactNode, useEffect, useMemo } from "react";
import { ThemeProvider } from "styled-components";

import i18nConfigs from "../next-i18next.config";
import { DynamicLazyModalLoader } from "../src/components/DynamicModalLoader/DynamicLazyModalLoader";
import { LayoutApp } from "../src/components/LayoutApp/LayoutApp";
import { MobileLandingPage } from "../src/components/MobileLandingPage/MobileLandingPage";
import { BREAK_POINT_ACTIVE_DARK_MODE_VALUE } from "../src/shared/breakpoints";
import { googleAnalyticsContainer, host } from "../src/shared/env";
import activityDetector from "../src/shared/helpers/activityDetector";
import { getDeeplinkRedirection } from "../src/shared/helpers/getDeeplinkRedirection";
import { handleUnCommitImage } from "../src/shared/helpers/handleUncommitImage";
import { isBrowser } from "../src/shared/helpers/isBrowser";
import { isMobile } from "../src/shared/helpers/isMobile";
import { isMobileWidth } from "../src/shared/helpers/isMobileWidth";
import { isNodeEnvProduction } from "../src/shared/helpers/isNodeEnvProduction";
import { safeAsync } from "../src/shared/helpers/safeAsync";
import {
  IClientRenderProps,
  IRedirectProps,
  IServerRenderProps,
} from "../src/shared/helpers/serverRender";
import useWindowSize from "../src/shared/helpers/useWindowSize";
import {
  appCache,
  browserCookieManager,
  logger,
} from "../src/shared/requireCrossEnv";
import {
  setPageName,
  snowplowHandleGlobalMousedown,
} from "../src/shared/snowplow";
import { initPhrase } from "../src/shared/translate/initPhrase";
import { parseOneFlowParams } from "../src/stores/handleDeepLinkClicked";
import { Provider, RootStore } from "../src/stores/rootStore";
import { rootStoreFromNextPageContext } from "../src/stores/rootStoreFromNextPageContext";

require("../src/shared/customize-bootstrap.css");

function ETApp(
  appProps: AppProps<
    IServerRenderProps | IClientRenderProps | IRedirectProps
  > & {
    shell?: boolean;
    host?: string;
    locale: string;
    isMobileDeviceUserAgent: boolean;
  },
) {
  const _router = useRouter();

  useEffect(() => {
    if (!isNodeEnvProduction()) return;

    const handleRouteChange = (url: URL) => {
      window.gtag("config", `${googleAnalyticsContainer}`, {
        page_path: url,
      });
    };
    _router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      _router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [_router.events]);

  const {
    Component,
    router,
    pageProps: props,
    locale,
    shell,
    host: serverHost,
    isMobileDeviceUserAgent,
  } = appProps;

  const { width: browserWidth } = useWindowSize();
  const isMobileDevice =
    isBrowser() && browserWidth > 0
      ? browserWidth <= BREAK_POINT_ACTIVE_DARK_MODE_VALUE
      : false;
  const rootStore = (() => {
    if (typeof window !== "undefined") {
      if (!window.__rootStore) {
        const featureFlagsBase64: string =
          _router.query.featureFlagsBase64?.toString() || "";
        window.__rootStore = new RootStore(
          browserCookieManager,
          appCache,
          {
            isWebPSupported: false, // default to true when ?ssr=false
            isMobileDevice,
            isMobileWidth: false,
          },
          () => window.location.href,
          {
            mockApiBaseUrl: "",
            mockOptions: "",
          },
          featureFlagsBase64,
          logger,
        );
        window.__next = {
          router,
        };
      }

      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { __rootStore } = window;
      __rootStore.deviceStore.isMobileDevice = isMobileDevice;
      return __rootStore;
    }

    // server
    const featureFlagsBase64: string =
      router.query.featureFlagsBase64?.toString() || "";
    return new RootStore(
      browserCookieManager,
      appCache,
      {
        isWebPSupported: true,
        isMobileDevice: isMobileDeviceUserAgent,
        isMobileWidth: false,
      },
      () => `${host}${useRouter().pathname}`,
      {
        mockApiBaseUrl: "",
        mockOptions: "",
      },
      featureFlagsBase64,
      logger,
    );
  })();

  useMemo(() => {
    if (
      isBrowser() &&
      navigator.cookieEnabled &&
      navigator.serviceWorker &&
      navigator.serviceWorker.getRegistrations
    ) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (const registration of registrations) {
          registration.unregister();
        }
      });
    }

    if (props.isServerRender) {
      const { rootData } = props;
      if (isBrowser() && browserWidth > 0) {
        rootData.deviceData.isMobileWidth =
          browserWidth <= BREAK_POINT_ACTIVE_DARK_MODE_VALUE;
      }
      rootStore.hydrate(rootData);
    }
  }, []);

  useMemo(() => {
    (async () => {
      if (!rootStore.featureFlaggingStore.flags.enableLanguageSwitcher) {
        return;
      }
      if (locale === i18n?.language) {
        return;
      }
      await rootStore.localeStore.updateLocale(locale);
    })();
  }, []);

  useEffect(() => {
    if (props.isServerRender === null && "redirect" in props && isBrowser()) {
      Router.replace(props.redirect.destination);
      return;
    }

    if (
      props.isServerRender === false &&
      router.asPath.split("?").length >= 3
    ) {
      const url = decodeURIComponent(router.asPath)
        .replaceAll("?", "&")
        .replace("&", "?");
      Router.replace(url);
    }
  }, [router.asPath]);

  useEffect(() => {
    const { query } = router;

    const isFromMobileApp =
      query.platformType?.toString().toLowerCase() === "mobile_app" &&
      isMobile();

    if (isFromMobileApp) {
      window.location.replace("uonearth:/" + router.asPath);
    }

    const [asPath, search] = router.asPath.split("?");
    const params = new URLSearchParams(search);

    const { oneFlowInitialState } = parseOneFlowParams(asPath, params);
    if (!oneFlowInitialState) {
      return;
    }

    rootStore.modalStore.openLazyModal({
      name: "oneFlow",
      component: (
        <DynamicLazyModalLoader
          loadComponent={() =>
            import("../src/components/ModalOneFlow/ModalOneFlow")
          }
          initialState={oneFlowInitialState}
        />
      ),
    });
  }, [router.query.oneFlowType, router.query.actAs]);

  useEffect(() => {
    // init phrase
    initPhrase();

    (async () => {
      await handleUnCommitImage.execute(
        rootStore.tokenInterceptorStore,
        logger,
      );
    })();

    const routeChangeStart = (url) => {
      if (url === router.asPath) {
        return;
      }
      rootStore.userSessionStore.setUserCloseOverlay();
      rootStore.overlayAboutStore.closeOverlay();
      rootStore.tokenInterceptorStore.cancel();
    };

    const handleBeforeHistoryChange = (url: string) => {
      const [nextUrl] = url?.split("?") || [];

      if (nextUrl !== router.asPath) {
        const previousRoute = router.asPath;
        rootStore.previousRoute = previousRoute;
      }
    };

    Router.events.on("beforeHistoryChange", handleBeforeHistoryChange);
    Router.events.on("routeChangeStart", routeChangeStart);

    return () => {
      Router.events.off("routeChangeStart", routeChangeStart);
      Router.events.off("beforeHistoryChange", handleBeforeHistoryChange);
    };
  }, []);

  useEffect(() => {
    rootStore.deviceStore.updateIsMobileWidth(isMobileWidth());
    rootStore.deviceStore.updateBrowserWidth(browserWidth);
  }, [browserWidth]);

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", snowplowHandleGlobalMousedown);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", snowplowHandleGlobalMousedown);
    };
  }, []);

  const pageName = getDisplayName(Component);

  useEffect(() => {
    if (!isBrowser()) {
      return;
    }

    // set state to idle after 3s of noninteractive
    const focusDetector = activityDetector({ timeToIdle: 3000 });
    focusDetector.on("idle", () => {
      rootStore.userSessionStore.onUserIdleDetected();
    });
    focusDetector.on("active", () => {
      rootStore.userSessionStore.onUserActiveDetected();
    });

    return () => focusDetector.stop();
  }, []);

  useEffect(() => {
    if (
      !rootStore.userSessionStore.didInit ||
      rootStore.userSessionStore.user?.id
    ) {
      return;
    }

    // renderGooglePrompt();
  }, [rootStore.userSessionStore.didInit]);

  const domain: string = isBrowser()
    ? window.location.host
    : serverHost || host;
  const { mobileLandingPagePresenter } = rootStore;

  if (isBrowser()) {
    setPageName(pageName);

    mobileLandingPagePresenter.setPageInfo(domain, router.pathname);
  }

  const renderPage = (): ReactNode => {
    if (
      !isBrowser() &&
      isMobileDeviceUserAgent &&
      rootStore.featureFlaggingStore.flags.enableLandingPageMobileWeb
    ) {
      return null; // prevent flashing issue
    }

    if (
      isMobileDevice &&
      mobileLandingPagePresenter.shouldDisplayMobileLandingPage
    ) {
      return (
        <LayoutApp
          router={router}
          rootStore={rootStore}
          pageName={pageName}
          domain={domain}
          isMobileDevice={isMobileDevice}
        >
          <MobileLandingPage driver={mobileLandingPagePresenter} />
        </LayoutApp>
      );
    }

    const Page = shell ? (
      <Component isServerRender={false} />
    ) : (
      <Component {...props} />
    );
    return Component.getLayout ? (
      Component.getLayout(Page)
    ) : (
      <LayoutApp
        router={router}
        rootStore={rootStore}
        pageName={pageName}
        domain={domain}
        isMobileDevice={isMobileDevice}
      >
        {Page}
      </LayoutApp>
    );
  };

  return (
    <ThemeProvider
      theme={{
        isMobileDevice: rootStore.deviceStore.isMobileDevice,
        themeStyle: rootStore.themeStore.themeStyle,
        isWebpSupported: rootStore.deviceStore.isWebPSupported,
      }}
    >
      <Provider router={router} rootStore={rootStore}>
        {renderPage()}
      </Provider>
    </ThemeProvider>
  );
}

ETApp.getInitialProps = async (appContext: AppContext) => {
  const redirection = getDeeplinkRedirection({
    pathname: appContext.router.pathname,
    asPath: appContext.router.asPath,
    domain: (appContext.ctx.req as unknown as Request)?.get("host") || host,
  });

  if (redirection) {
    appContext.ctx.res?.writeHead(301, {
      Location: redirection.pageProps.redirect.destination,
    });
    appContext.ctx.res?.end();

    return redirection;
  }

  const { ctx } = appContext;
  if (!ctx.req || ctx.query.ssr?.toString() === "false") {
    return {
      pageProps: {
        isServerRender: false,
      },
      locale: ctx.query.locale,
    };
  }

  const userAgent = ctx.req.headers["user-agent"] || "";

  const isMobileDeviceUserAgent = Boolean(
    /android|blackberry|iphone|ipad|ipod|opera mini|iemobile|wpdesktop/i.test(
      userAgent,
    ),
  );
  const reqPath = ctx.req.url || "";

  if (reqPath.startsWith("/claim")) {
    const path = reqPath.replace("claim", "collect");

    logger.info("redirectLink", path);

    return {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      pageProps: {
        isServerRender: null,
        redirect: {
          permanent: true,
          destination: path,
        },
      },
    };
  }

  if (reqPath.split("?").length >= 3) {
    const path = reqPath.replaceAll(/\?+/g, "&").replace("&", "?");

    logger.info("redirectLink", path);

    return {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      pageProps: {
        isServerRender: null,
        redirect: {
          permanent: true,
          destination: path,
        },
      },
    };
  }

  // ----- Ensure Site is Crawlable -----
  // const accept = ctx.req.headers.accept || "";
  // if (
  //   !accept.includes("text/html") &&
  //   !accept.includes("application/xhtml+xml") &&
  //   !accept.includes("application/xml")
  // ) {
  //   ctx.res?.end();
  //   return {
  //     pageProps: {
  //       isServerRender: false,
  //     },
  //   };
  // }

  const rootStore = await safeAsync(rootStoreFromNextPageContext(ctx));

  // if (rootStore) rootStore.deviceStore.isMobileDevice = true;

  // eslint-disable-next-line no-param-reassign

  (appContext.ctx as any).rootStore = rootStore;
  (appContext.ctx as any).translationStore = (ctx.req as any).translationStore;
  const appProps = await App.getInitialProps(appContext);

  // calls page's `getInitialProps` and fills `appProps.pageProps`
  return {
    ...appProps,
    shell: ctx.query.shell !== undefined,
    locale: ctx.query.locale,
    host: ctx.req?.headers?.host,
    isMobileDeviceUserAgent,
  };
};

export function reportWebVitals(metric: NextWebVitalsMetric) {
  if (!isNodeEnvProduction()) {
    return;
  }

  window.gtag("event", metric.name, {
    event_category:
      metric.label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
    value: Math.round(
      metric.name === "CLS" ? metric.value * 1000 : metric.value,
    ), // values must be integers
    event_label: metric.id, // id unique to current page load
    non_interaction: true, // avoids affecting bounce rate.
  });
}

export default appWithTranslation(observer(ETApp), i18nConfigs);
