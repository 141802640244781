import { observer } from "mobx-react-lite";

import { Currency } from "../../stores/DonateStore/DonateStore";
import { InfoBubbleLayout } from "./InfoBubbleLayout";
import { InfoBubblePresenter } from "./InfoBubblePresenter";

export interface InfoBubbleDriver {
  currency: Currency;
}

interface IProps {
  driver: InfoBubbleDriver;
}

export const InfoBubble = observer((props: IProps) => {
  const presenter = new InfoBubblePresenter(props.driver.currency);

  return <InfoBubbleLayout driver={presenter} />;
});
