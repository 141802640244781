// ts-gql-integrity:65c187def53a38937b40884b3992ce3f
/*
ts-gql-meta-begin
{
  "hash": "91616499c0cf5bc5e8282f6cfd6f817a"
}
ts-gql-meta-end
*/

import * as SchemaTypes from "./@schema";
import { TypedDocumentNode } from "@ts-gql/tag";

type npoCountUpdatedSubscriptionVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID'];
}>;


type npoCountUpdatedSubscription = { readonly __typename: 'Subscription', readonly npoCountUpdated: { readonly __typename: 'UonCountOutput', readonly count: number } | null };


      
export type type = TypedDocumentNode<{
  type: "subscription";
  result: npoCountUpdatedSubscription;
  variables: npoCountUpdatedSubscriptionVariables;
  documents: SchemaTypes.TSGQLDocuments;
  fragments: SchemaTypes.TSGQLRequiredFragments<"none">
}>

declare module "./@schema" {
  interface TSGQLDocuments {
    npoCountUpdated: type;
  }
}

export const document = JSON.parse("{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"subscription\",\"name\":{\"kind\":\"Name\",\"value\":\"npoCountUpdated\"},\"variableDefinitions\":[{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"ID\"}}},\"directives\":[]}],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"npoCountUpdated\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"}}}],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"count\"},\"arguments\":[],\"directives\":[]}]}}]}}]}")
