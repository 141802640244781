import { FlapDisplay } from "earthtoday-react-split-flap-effect";
import { observer } from "mobx-react-lite";

import sc from "./HomeHeaderCardCountAnimation.styled";
interface IProps {
  lastNumber: number;
  countNumbers: number[];
  noAnimationCounter?: boolean;
  dataCy?: string;
  borderColor?: string;
  fixedSize?: boolean;
  flags: {
    counterFlapEffectDuration: number;
  };
}

export const HomeHeaderCardCountAnimation = observer((props: IProps) => {
  return (
    <sc.Container
      data-cy={props.dataCy}
      borderColor={props.borderColor}
      fixedSize={props.fixedSize}
    >
      <FlapDisplay
        className={`flap ${props.noAnimationCounter && "no-animation"}`}
        length={1}
        timing={props.flags.counterFlapEffectDuration}
        value={`${props.lastNumber}`}
      />
      <sc.Chain />
    </sc.Container>
  );
});
