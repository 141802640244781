import { observer } from "mobx-react-lite";
import Link from "next/link";
import React from "react";

import {
  etGreen,
  etOrange,
  etRed,
  etWhite,
  etWhiteDark,
} from "../../shared/colors";
import { useTranslation } from "../../shared/translate/NextI18next";
import { DangerouslyHTMLRenderer } from "../DangerouslyHTMLRenderer/DangerouslyHTMLRenderer";
import { IconSvg } from "../IconSvg/IconSvg";
import sc from "./TheMessage.styled";
import {
  IMessage,
  ToastMessageAction,
  ToastMessageActionHref,
  ToastMessageStatus,
  TypeIsShowMessage,
} from "./TheMessageStore";

const ToastMessageStatusIcon = {
  [ToastMessageStatus.DANGER]: "status-error",
  [ToastMessageStatus.WARNING]: "status-warning",
  [ToastMessageStatus.INFO]: "info",
  [ToastMessageStatus.SUCCESS]: "status-success",
};
const ToastMessageStatusIconColor = {
  [ToastMessageStatus.DANGER]: etRed,
  [ToastMessageStatus.WARNING]: etOrange,
  [ToastMessageStatus.INFO]: etWhiteDark,
  [ToastMessageStatus.SUCCESS]: etGreen,
};

interface IProps {
  onClose(): void;
  isActive: TypeIsShowMessage;
  message: IMessage;
}

function isHrefAction(
  action: ToastMessageAction,
): action is ToastMessageActionHref {
  return (action as ToastMessageActionHref).href !== undefined;
}

export const TheMessageLayout = observer(function (props: IProps) {
  const { isActive, onClose, message } = props;

  const { t } = useTranslation("ToastMessage");

  let actions: ToastMessageAction[] = [];
  if (message.typeMessage === "Close" || message.typeMessage === "Error") {
    actions.push({
      key: "close",
      name: "toast-message.general.action-close",
      action: onClose,
    });
  } else {
    actions = message.actions;
  }

  const status = ((): ToastMessageStatus => {
    if (message.typeMessage === "Close") {
      return ToastMessageStatus.SUCCESS;
    }
    if (message.typeMessage === "Error") {
      return ToastMessageStatus.DANGER;
    }

    return message.status;
  })();

  const iconClass = ToastMessageStatusIcon[status];
  const iconColor = ToastMessageStatusIconColor[status];

  return (
    <sc.Container
      className={
        (isActive === true ? "active" : isActive === false ? "unactive" : "") +
        " visual-reg"
      }
    >
      <sc.Wrapper>
        <sc.Header>
          <sc.Title data-cy="ToastMessage-title">{t(message.title)}</sc.Title>
          <sc.Status
            data-cy={"ToastMessage-status"}
            data-status={iconClass}
            fill={iconColor}
          >
            <IconSvg
              dataCy="ToastMessage-closeBtn"
              width={"18px"}
              height={"18px"}
              padding="0"
              icon={iconClass}
              pointer={true}
              color={etWhite}
              onClick={onClose}
            />
          </sc.Status>
        </sc.Header>
        <sc.Message data-cy="ToastMessage-content">
          <DangerouslyHTMLRenderer>
            {t(message.content, { ...message.vars })}
          </DangerouslyHTMLRenderer>
        </sc.Message>
        <sc.Line />
        {actions.map((action) =>
          isHrefAction(action) ? (
            <sc.Action key={action.href}>
              <Link
                key={action.key}
                href={action.href}
                as={action.as}
                passHref
                data-cy={"ToastMessageAction-" + action.key}
              >
                <DangerouslyHTMLRenderer>
                  {t(action.name)}
                </DangerouslyHTMLRenderer>
              </Link>
            </sc.Action>
          ) : (
            <sc.Action
              key={action.key}
              data-cy={"ToastMessageAction-" + action.key}
              onClick={() => action.action()}
            >
              <DangerouslyHTMLRenderer>
                {t(action.name)}
              </DangerouslyHTMLRenderer>
            </sc.Action>
          ),
        )}
      </sc.Wrapper>
    </sc.Container>
  );
});
