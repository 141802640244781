/* eslint-disable no-await-in-loop */
import { RequestType } from "@earthtoday/contract";
import { CookieSerializeOptions } from "cookie";
import jwtDecode from "jwt-decode";
import getConfig from "next/config";
import { destroyCookie, parseCookies, setCookie } from "nookies";

import {
  ACCESS_TOKEN_KEY,
  COOKIE_CONSENT,
  REFRESH_TOKEN_KEY,
} from "../shared/constants";
import { ETLocalStorage, ETSessionStorage } from "../shared/helpers/EtStorages";
import { isBrowser } from "../shared/helpers/isBrowser";
import { NoRefreshTokenError } from "../shared/helpers/NoRefreshTokenError";
import { isAxiosError } from "../shared/helpers/translateApiError";
import { wait } from "../shared/helpers/wait";
import { Logger } from "../shared/models/Logger";
import { CookieConsent } from "../shared/models/User";
import { RootStore } from "./rootStore";

const DEFAULT_MAX_RETRIES = 3;

const TOKEN_LOADING_TIMEOUT = 10000; // 10s

export interface ICookie {
  get(key: string): string;
  set(key: string, value: string, option: CookieSerializeOptions): void;
  remove(key: string, options: CookieSerializeOptions): void;
}

export interface ITokenStore {
  setTokensFromResponse(response: { headers: Record<string, string> }): string;
  getRefreshToken(): string;
  setAccessToken(token: string): void;
  setRefreshToken(token: string): void;
  getMemberUserId(): string;
}

export class TokenStore implements ITokenStore {
  private logger: Logger;
  private tokenLoading = false;

  private cookieService: ICookie;

  constructor(private rootStore: RootStore, cookieManager: ICookie) {
    this.logger = rootStore.logger.child({ container: this.constructor.name });

    if (!cookieManager) {
      this.cookieService = {
        get(key: string): string {
          const cookies = parseCookies();
          return cookies[key];
        },
        set(key: string, value: string, options: CookieSerializeOptions) {
          setCookie(null, key, value, options);
        },
        remove(key: string) {
          destroyCookie(null, key);
        },
      };
      return;
    }

    this.cookieService = cookieManager;
  }

  tokenLoadCallbacks: {
    resolve: (token: string) => void;
    reject: (err: Error) => void;
  }[] = [];

  static isExpired(token: string): boolean {
    try {
      const payload = jwtDecode<{ exp: number }>(token);

      return Date.now() > payload.exp * 1000;
    } catch (error) {
      console.warn(
        `[TokenService] isExpired catches an error (token: ${token})`,
        error,
      );
      return true;
    }
  }

  static userId(token: string): string {
    try {
      const payload = jwtDecode<{ id: string }>(token);
      return payload.id;
    } catch {
      return "";
    }
  }

  static memberUserId(token: string): string {
    try {
      const payload = jwtDecode<{ memberUserId: string }>(token);
      return payload.memberUserId;
    } catch {
      return "";
    }
  }

  public isAnonymous(): boolean {
    const token = this.getAccessToken();
    return !token;
  }

  public getAccessToken(): string {
    if (isBrowser()) {
      return ETLocalStorage.getItem(ACCESS_TOKEN_KEY) || "";
    }
    return "";
  }

  private setCookie(name: string, value: string) {
    this.cookieService.set(name, value, {
      path: "/",
      expires: new Date("2038-01-19 04:14:07"), // Maximum value: 2147483647 - prevent clear cookie after close browser
      maxAge: 60 * 60 * 24 * 365 * 10,
    });
  }

  public getUserId(): string {
    const token = this.cookieService.get(ACCESS_TOKEN_KEY);
    return token ? TokenStore.userId(token) : "";
  }

  public getMemberUserId(): string {
    const token = this.getAccessToken();
    return token ? TokenStore.memberUserId(token) : "";
  }

  private removeCookie(name: string) {
    this.cookieService.remove(name, { path: "/" });
  }

  public getCookieConsent(): string {
    const cookieConsent = this.cookieService.get(COOKIE_CONSENT);

    return cookieConsent;
  }

  public setCookieConsent(): void {
    if (isBrowser()) {
      this.setCookie(COOKIE_CONSENT, CookieConsent.SOCIAL_MEDIA);
    }
  }

  public setAccessToken(token: string): void {
    if (isBrowser()) {
      ETLocalStorage.setItem(ACCESS_TOKEN_KEY, token);
      this.syncTokenPayloadCookie();
    }
  }

  public removeAccessToken(): void {
    if (isBrowser()) {
      ETLocalStorage.removeItem(ACCESS_TOKEN_KEY);
      this.syncTokenPayloadCookie();
    }
  }

  private setTokenPayloadCookie(value: string) {
    const domain = new URL(getConfig().publicRuntimeConfig.REACT_APP_HOST)
      .hostname;

    this.cookieService.set("tokenpayl", value, {
      domain,
      path: "/",
      expires: new Date("2038-01-19 04:14:07"), // Maximum value: 2147483647 - prevent clear cookie after close browser
      maxAge: 60 * 60 * 24 * 365 * 10,
    });
  }

  public syncTokenPayloadCookie(): void {
    if (!isBrowser()) {
      return;
    }

    const token = ETLocalStorage.getItem(ACCESS_TOKEN_KEY);
    if (!token) {
      this.removeCookie("tokenpayl");
      return;
    }

    const tokenPayload = token.split(".")[1];
    if (!tokenPayload) {
      this.removeCookie("tokenpayl");
      return;
    }

    this.setTokenPayloadCookie(tokenPayload);
  }

  public getRefreshToken(): string {
    if (isBrowser()) {
      return ETLocalStorage.getItem(REFRESH_TOKEN_KEY) || "";
    }
    return "";
  }

  public setRefreshToken(token: string): void {
    if (isBrowser()) {
      ETLocalStorage.setItem(REFRESH_TOKEN_KEY, token);
    }
  }

  public removeRefreshToken(): void {
    if (isBrowser()) {
      ETLocalStorage.removeItem(REFRESH_TOKEN_KEY);
    }
  }

  public setTokensFromResponse(response: {
    headers: Record<string, string>;
  }): string {
    const accessToken =
      response.headers.authorization || response.headers.Authorization;

    if (accessToken) {
      this.setAccessToken(accessToken);
    }
    const refreshToken =
      response.headers.refreshtoken || response.headers.RefreshToken;
    if (refreshToken) {
      this.setRefreshToken(refreshToken);
    }

    return accessToken;
  }

  public async refreshToken(logger: Logger): Promise<string> {
    if (this.tokenLoading) {
      let timeoutId;
      const token = await Promise.race([
        new Promise<string>((resolve, reject) => {
          clearTimeout(timeoutId!);
          this.tokenLoadCallbacks.push({ resolve, reject });
        }),
        new Promise<string>((_, reject) => {
          timeoutId = setTimeout(function () {
            clearTimeout(timeoutId!);
            reject(new Error("refresh TOKEN_LOADING_TIMEOUT timeout"));
          }, TOKEN_LOADING_TIMEOUT);
        }),
      ]);

      return token;
    }

    const refreshToken = this.getRefreshToken();

    if (!refreshToken) {
      throw new NoRefreshTokenError();
    }

    let retryCount = 0;

    while (retryCount < DEFAULT_MAX_RETRIES) {
      try {
        this.tokenLoading = true;
        logger.info("refreshing token");

        const headers: Record<string, string> = {};

        if (isBrowser()) {
          const mockOptions = ETSessionStorage.getItem("mockOptions");
          if (mockOptions) {
            headers.mockoptions = mockOptions;
          }
        }

        const response =
          await this.rootStore.tokenInterceptorStore.tsRestClient.user.refreshToken(
            {
              body: {
                data: {
                  refreshToken,
                  requestType: RequestType.WEB_APP,
                },
              },
            },
          );

        const token =
          response.headers.get("authorization") ||
          response.headers.get("Authorization") ||
          "";

        for (const { resolve } of this.tokenLoadCallbacks) resolve(token);

        return token;
      } catch (error) {
        let body: object | undefined;
        if (isAxiosError(error)) {
          body = error.response?.data as {};

          if (error.response?.status === 400) {
            // fail fast. discard user session in this case
            logger.error({ err: error, body }, "refresh token error 400");

            // "debugMessage": "Query; CQL [SELECT * FROM anonymoususer WHERE id = ? ALLOW FILTERING]; Cassandra timeout during read query at consistency LOCAL_QUORUM (2 responses were required but only 0 replica responded); nested exception is com.datastax.driver.core.exceptions.ReadTimeoutException: Cassandra timeout during read query at consistency LOCAL_QUORUM (2 responses were required but only 0 replica responded)"
            this.removeRefreshToken();
            this.removeAccessToken();

            for (const { reject } of this.tokenLoadCallbacks) reject(error);

            throw error;
          }
        }

        retryCount += 1;
        if (retryCount >= DEFAULT_MAX_RETRIES) {
          this.removeRefreshToken();
          this.removeAccessToken();

          for (const { reject } of this.tokenLoadCallbacks)
            reject(error as Error);
          this.tokenLoadCallbacks = [];
          this.tokenLoading = false;

          logger.error({ err: error, body }, "refresh token error");
          throw error;
        }
        logger.info(`refresh token retry count: ${retryCount}`);
        await wait(1500);
      } finally {
        for (const { resolve } of this.tokenLoadCallbacks) resolve("");
        this.tokenLoadCallbacks = [];
        this.tokenLoading = false;
      }
    }

    for (const { reject } of this.tokenLoadCallbacks)
      reject(new Error("refresh timeout"));
    this.tokenLoadCallbacks = [];
    this.tokenLoading = false;

    return "";
  }
}
