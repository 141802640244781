export interface ImageData {
  id: string;
  url: string;
  primaryColor?: string;
  originalWidth?: number;
  originalHeight?: number;
  alt?: string;
  awsKey?: string;
  awsBucket?: string;
  isTransparent: boolean | null;
  extract?: {
    left: number;
    top: number;
    width: number;
    height: number;
  };
}

export enum ImagePresignUploadStatus {
  uploading = "uploading",
  uploaded = "uploaded",
  committing = "committing",
  committed = "committed",
}

export type IImageCustom = Pick<
  ImageData,
  "id" | "awsBucket" | "awsKey" | "isTransparent"
> & {
  url: string;
  primaryColor: string;
  originalWidth: number;
  originalHeight: number;
};
