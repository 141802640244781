import {
  NotificationResponseDto,
  NotificationStatusQuery,
  PromotionsResponseDto,
  QrCodeCreateResponseDto,
  SearchResponseDto,
  UserResponseDto,
} from "@earthtoday/contract";
import axios from "axios";
import axiosCancel from "axios-cancel";

import { IUserSessionStore } from "../../components/ModalLogin/UserSessionStore";
import { TokenInterceptorStore } from "../../stores/TokenInterceptorStore";
import { getAPIBaseUrl } from "../env";
import { getGraphqlClient } from "../graphqlClient";
import { isBrowser } from "../helpers/isBrowser";
import {
  ICardNotification,
  userCardCreatedNotificationsSubscriptionQuery,
  userNotificationQuery,
} from "./navbarApiQueries";
import { qrCodeActivatedQuery } from "./qrCodeQueries";
import { UnsubscribeFn } from "./UnsubscribeFn";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
axiosCancel(axios, {
  debug: false,
});

export interface INavbarApi {
  fetchSearchResult(q: string): Promise<SearchResponseDto[]>;
  fetchNotifications(
    status?: NotificationStatusQuery,
  ): Promise<NotificationResponseDto[]>;
  markNotificationRead(
    notificationItem: NotificationResponseDto,
  ): Promise<void>;
  subscribeUserNotification(
    accessToken: string,
    userId: string,
    subscribeFn: (
      error: Error | null,
      notificationItems: NotificationResponseDto[] | null,
    ) => void,
  ): UnsubscribeFn;
  subscribeUserCardActionNotification(
    accessToken: string,
    userId: string,
    subscribeFn: (
      error: Error | null,
      cardNotification: ICardNotification | null,
    ) => void,
  ): UnsubscribeFn;
  replyGroupInvitation(
    b: boolean,
    notification: NotificationResponseDto,
  ): Promise<void>;
  fetchPromotions: (
    userId: string,
    metaCountOnly: boolean,
  ) => Promise<PromotionsResponseDto>;
  createQrCode(): Promise<QrCodeCreateResponseDto>;
  subscribeQrCode: (
    uuid: string,
    subscribeFn: (
      error: Error | null,
      qrCodeActivated: {
        accessToken: string;
        refreshToken: string;
        user: UserResponseDto;
        status: "SUCCESS" | "EXPIRED";
      } | null,
    ) => void,
  ) => UnsubscribeFn;
}
export class NavbarApi implements INavbarApi {
  constructor(
    private tokenInterceptorStore: TokenInterceptorStore,
    private userSessionStore: IUserSessionStore,
  ) {}

  fetchSearchResult = async (q: string): Promise<SearchResponseDto[]> => {
    try {
      const requestId = "fetchSearchResult";
      const config: any = {
        url: `${getAPIBaseUrl()}/search?query=${q}&type=ALL`,
        requestId,
      };

      return await this.tokenInterceptorStore
        .call(config)
        .then((res) => {
          return res.data;
        })
        .catch((error: any) => {
          if (axios.isCancel(error)) {
            console.log("request 1 cancelled");
          }
          return [];
        });
    } catch {
      return [];
    }
  };

  fetchNotifications = async (
    status: NotificationStatusQuery = NotificationStatusQuery.UNREAD,
  ): Promise<NotificationResponseDto[]> => {
    const response =
      await this.tokenInterceptorStore.tsRestClient.user.getUserNotifications({
        query: {
          status,
        },
      });

    if (response.status === 200) {
      return response.body;
    }

    console.error({ error: response }, "fetchNotifications", response);
    return [];
  };

  markNotificationRead = async (
    notificationItem: NotificationResponseDto,
  ): Promise<void> => {
    const { event, id, latestTime, type, initialTime } = notificationItem;
    await this.tokenInterceptorStore.call({
      method: "PUT",
      url: `${getAPIBaseUrl()}/users/me/notifications/read`,
      data: {
        event,
        id,
        latestTime,
        type,
        initialTime,
      },
    });
  };

  subscribeUserNotification = (
    accessToken: string,
    userId: string,
    subscribeFn: (
      error: Error | null,
      notificationItems: NotificationResponseDto[] | null,
    ) => void,
  ): UnsubscribeFn => {
    if (!isBrowser()) {
      return () => {};
    }

    const observable = getGraphqlClient(accessToken)
      .subscribe<{ userNotificationItemsUpdated: NotificationResponseDto[] }>({
        query: userNotificationQuery() as any,
        variables: {
          userId,
        },
        context: {
          tokenInterceptorStore: this.tokenInterceptorStore,
        },
      })
      .subscribe((result) => {
        if (Array.isArray(result.errors) && result.errors.length > 0) {
          subscribeFn(new Error(result.errors.toString()), null);
          return;
        }
        subscribeFn(null, result.data?.userNotificationItemsUpdated || null);
      });

    return () => observable.unsubscribe();
  };

  subscribeUserCardActionNotification = (
    accessToken: string,
    userId: string,
    subscribeFn: (
      error: Error | null,
      cardNotification: ICardNotification | null,
    ) => void,
  ): UnsubscribeFn => {
    if (!isBrowser()) {
      return () => {};
    }

    const observable = getGraphqlClient(accessToken)
      .subscribe<{ userCardCreatedNotification: ICardNotification }>({
        query: userCardCreatedNotificationsSubscriptionQuery() as any,
        variables: {
          userId,
        },
        context: {
          tokenInterceptorStore: this.tokenInterceptorStore,
        },
      })
      .subscribe((result) => {
        if (Array.isArray(result.errors) && result.errors.length > 0) {
          subscribeFn(new Error(result.errors.toString()), null);
          return;
        }
        subscribeFn(null, result.data?.userCardCreatedNotification || null);
      });

    return () => observable.unsubscribe();
  };

  replyGroupInvitation = async (
    b: boolean,
    { group: groupVanityName }: NotificationResponseDto,
  ): Promise<void> => {
    const userId = this.userSessionStore.user?.id;

    const res = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/groups/users/${userId}/memberships/${groupVanityName}/replies`,
      data: { accepted: b },
    });
    return res.data;
  };
  fetchPromotions = async (
    userId: string,
    metaCountOnly: boolean,
  ): Promise<PromotionsResponseDto> => {
    const response =
      await this.tokenInterceptorStore.tsRestClient.promotion.getPromotions({
        params: {
          idOrVanityName: userId,
        },
        query: {
          metaCountOnly: metaCountOnly.toString(),
        },
      });

    if (response.status == 200) {
      return response.body;
    }

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw response;
  };

  createQrCode = async (): Promise<QrCodeCreateResponseDto> => {
    const response =
      await this.tokenInterceptorStore.tsRestClient.qrCode.createQRCode({
        body: null,
      });

    if (response.status === 201) {
      return response.body;
    }

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw response;
  };

  subscribeQrCode = (
    uuid: string,
    subscribeFn: (
      error: Error | null,
      qrCodeActivated: {
        accessToken: string;
        refreshToken: string;
        user: UserResponseDto;
        status: "SUCCESS" | "EXPIRED";
      } | null,
    ) => void,
  ): UnsubscribeFn => {
    if (!isBrowser()) {
      return () => {};
    }

    const observable = getGraphqlClient()
      .subscribe<{
        qrCodeActivated: {
          accessToken: string;
          refreshToken: string;
          user: UserResponseDto;
          status: "SUCCESS" | "EXPIRED";
        } | null;
      }>({
        query: qrCodeActivatedQuery() as any,
        variables: {
          uuid,
        },
        context: {
          tokenInterceptorStore: this.tokenInterceptorStore,
        },
      })
      .subscribe((result) => {
        if (Array.isArray(result.errors) && result.errors.length > 0) {
          subscribeFn(new Error(result.errors.toString()), null);
          return;
        }
        subscribeFn(null, result.data?.qrCodeActivated || null);
      });

    return () => observable.unsubscribe();
  };
}
