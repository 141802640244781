import { PaymentMethodResponse } from "../apis/PaymentApi";
import { PaymentMethod } from "../models/Payment";
import { alternativePaymentMethods } from "./alternativePaymentMethods";
import { paymentMethodsMapper } from "./paymentMethodsMapper";

export function resolvePaymentMethods(
  paymentMethodsResponse: PaymentMethodResponse[],
): PaymentMethod[] {
  return paymentMethodsMapper(
    alternativePaymentMethods(paymentMethodsResponse),
  );
}
