import { ImageSource } from "@earthtoday/contract";

import { OneFlowType } from "../../stores/ModalOneFlowPresenter/ModalOneFlowPresenter";
import { PaymentProvider } from "../apis/PaymentApi";
import { ClaimResponse } from "./Claim";
import { ReserveImageUrls } from "./Uon";

export interface PaymentStripe {
  id: string;
  provider: PaymentProvider.STRIPE;
  createdAt: string;
  amount: number;
  providedStatus: string;
  status: string;
  key: string;
  userEarthId: number | string;
  numberOfUons: number;
  internalId: string;

  processing?: boolean;
  checkoutUrl?: string;
  response?: any;
  error?: any;
}

export interface PaymentMollie {
  id: string;
  internalId: string;
  provider: PaymentProvider.MOLLIE;
  createdAt: string;
  amount: number;
  description: string;
  method: string;
  providedStatus: string;
  status: string;
  expiresAt: string;
  redirectUrl: string;
  checkoutUrl: string;
  userEarthId: number | string;
  numberOfUons: number;
}

export type Payment = PaymentStripe | PaymentMollie;

export enum PaymentMethod {
  IDeal = "idealBank",
  Card = "card",
}
export interface PaymentResponseMollie {
  provider: PaymentProvider.MOLLIE;
  id: string;
  status: string;
  statusCode: number;
  received: number;
  created: number;
  amountPaid: number;
  numberOfUon: number;
  transaction: {
    id: string;
    createdAt: number;
    npoDetails: {
      coverImage: string;
      earthId: number;
      image: string;
      name: string;
      path: string[];
      vanityName: string;
    };
    donatedReserve: {
      count: number;
      deckDetails: {
        earthId: string;
        path: string[];
      };
      deckEarthId: string;
      id: number;
      reserveImageUrls?: ReserveImageUrls;
      imageUrl: string;
      npoDetails: {
        coverImage: string;
        earthId: number;
        image: string;
        name: string;
        path: string[];
        vanityName: string;
      };
      point: {
        lat: number;
        lng: number;
      };
    };
    imageSource: ImageSource;
  };
}

export interface PaymentResponseStripe {
  id: string;
  provider: string;
  statusCode: number;
  status: string; // from BE
  received: string;
  created: number;
  amountPaid: number; // in cents
  numberOfUon: number;
  transactionId?: string;
  transaction?: {
    id: string;
    reserveId: string;
    createdAt: Date;
    donatedReserve: {
      count: number;
      deckDetails: {
        earthId: string;
        path: string[];
      };
      deckEarthId: string;
      id: number;
      reserveImageUrls?: ReserveImageUrls;
      imageUrl: string;
      npoDetails: {
        coverImage: string;
        earthId: number;
        image: string;
        name: string;
        path: string[];
        vanityName: string;
      };
      point: {
        lat: number;
        lng: number;
      };
    };
  };
}

export enum OneFlowSessionSource {
  SIGNED_IN = "SIGNED_IN",
  SIGNUP_EMAIL = "SIGNUP_EMAIL",
  SIGNUP_SOCIAL = "SIGNUP_SOCIAL",
}

export type PaymentResponseFinalizeOneFlow = {
  type: OneFlowType;
  paymentResponse: PaymentResponse;
  redeemResponse?: ClaimResponse;
  sessionSource: OneFlowSessionSource;
  errorResponse?: {
    error: "payment failed";
  };
  oneFlowId: string;
  shareId: string | null;
};

export type PaymentResponse = PaymentResponseMollie | PaymentResponseStripe;

export enum ErrorCodeStripeCard {
  GENERIC_DECLINE = "generic_decline",
  INSUFFICIENT_FUNDS = "insufficient_funds",
  EXPIRED_CARD = "expired_card",
  INCORRECT_CVC = "incorrect_cvc",
  INCORRECT_NUMBER = "incorrect_number",
  PROCESSING_ERROR = "processing_error",
}
