import { observer } from "mobx-react-lite";

import { CampaignDefaultStatus } from "../../stores/CampaignDefaultModel";
import {
  getBreakPointsFlapEffect,
  UonCountNumberLocation,
} from "./StylesUonCountNumberAnimation";
import sc from "./UonCountNumberAnimation.styled";

export const M2Icon = observer(function M2Icon(props: {
  location: UonCountNumberLocation;
  state?: CampaignDefaultStatus;
  enableOneFlow?: boolean;
}) {
  const { col3, col2, col1 } = getBreakPointsFlapEffect(
    props.location,
    props.state,
  );
  return (
    <sc.M2
      data-cy="M2Icon-M2"
      col3={col3}
      col2={col2}
      col1={col1}
      enableOneFlow={props.enableOneFlow || false}
      isNewProtectPage={
        props.location === UonCountNumberLocation.NewProtectPage
      }
    />
  );
});
