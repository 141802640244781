/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-underscore-dangle */
import { gql } from "@ts-gql/tag";

export enum CardNotificationAction {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
}

export enum CardNotificationStatus {
  DRAFTING = "DRAFTING",
  PUBLISHED = "PUBLISHED",
  MODERATION = "MODERATION",
  ARCHIVED = "ARCHIVED",
}

export type ICardNotification = {
  cardId: string;
  path: string[];
  action: CardNotificationAction;
  status: CardNotificationStatus;
  deckName: string;
};

export function userNotificationQuery() {
  return gql`
    subscription userNotificationItems($userId: ID!) {
      userNotificationItemsUpdated(userId: $userId) {
        id
        type
        event
        path
        visited
        initialTime
        latestTime
        description
        secondaryTarget
        secondaryTargetId
        count
        group
        imageId
        imageUrl
        initiators
        userId
        targetId
      }
    }
  ` as import("../../../__generated__/ts-gql/userNotificationItems").type;
}

export function userCardCreatedNotificationsSubscriptionQuery() {
  return gql`
    subscription userCardCreatedNotification($userId: ID!) {
      userCardActionNotification(userId: $userId) {
        cardId
        path
        status
      }
    }
  ` as import("../../../__generated__/ts-gql/userCardCreatedNotification").type;
}
