import { observer } from "mobx-react-lite";
import Head from "next/head";
import { Router } from "next/router";
import { PropsWithChildren, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import snippet from "../../../newrelic.snippet.txt";
import { PaymentProvider } from "../../shared/apis/PaymentApi";
import { SESSION_ID_ON_RELOAD } from "../../shared/constants";
import { isProduction } from "../../shared/env";
import { ETLocalStorage } from "../../shared/helpers/EtStorages";
import { useTranslation } from "../../shared/translate/NextI18next";
import { PaymentContext } from "../../stores/DonateStore/DonateStore";
import {
  OneFlowScreen,
  OneFlowType,
} from "../../stores/ModalOneFlowPresenter/ModalOneFlowPresenter";
import { RootStore } from "../../stores/rootStore";
import { DynamicLazyModalLoader } from "../DynamicModalLoader/DynamicLazyModalLoader";
import { MetaTagsStateful } from "../MetaTagsStateful/MetaTagsStateful";
import { OneFlowRedirectMode } from "../ModalOneFlow/ModalOneFlow";
import TheMessage from "../TheMessage/TheMessage";
import { LayoutAppPresenter } from "./LayoutAppPresenter";

export const LayoutApp = observer(
  (
    props: PropsWithChildren & {
      router: Router;
      rootStore: RootStore;
      pageName: string;
      domain: string;
      isMobileDevice: boolean;
    },
  ) => {
    const { t } = useTranslation("Meta");

    const { router, rootStore } = props;
    const [presenter] = useState(() => new LayoutAppPresenter(rootStore));

    useEffect(() => {
      (async () => {
        const { query, asPath, pathname } = router;
        const screen: string = query.screen as string;
        const type: string = query.type as string;

        if (pathname !== "/counter") {
          await rootStore.initSessionState();
        }

        if (
          query.context === "payments" &&
          query.provider === PaymentProvider.MOLLIE &&
          query.id &&
          !asPath.startsWith("/mobile")
        ) {
          const oneFlowId = query.oneFlowId as string;
          const finalizeData: PaymentContext = {
            context: "payments",
            provider: PaymentProvider.MOLLIE,
            id: query.id.toString(),
            token: query.token?.toString() || "",
            sourceId: query.sourceId?.toString() || "",
            sourceType: query.sourceType?.toString() || "",
            numberOfUon: Number.parseInt(
              query.numberOfUon?.toString() || "0",
              10,
            ),
          };
          // eslint-disable-next-line no-unused-expressions
          await rootStore.donateStore.fetchMolliePaymentStatus(
            finalizeData,
            oneFlowId,
            (type?.toUpperCase() as OneFlowType) || OneFlowType.SUBSCRIBE,
            query.redirectPath?.toString(),
            query.redirectMode?.toString() as OneFlowRedirectMode,
          );
        }

        if ((query.context as string | undefined)?.toLowerCase() !== "oneflow")
          return;

        if (screen.toLowerCase() === OneFlowScreen.START.toLowerCase()) {
          rootStore.modalStore.openLazyModal({
            name: "oneFlow",
            component: (
              <DynamicLazyModalLoader
                loadComponent={() => import("../ModalOneFlow/ModalOneFlow")}
                initialState={{
                  currentScreen: OneFlowScreen.START,
                  oneFlowType:
                    (type?.toUpperCase() as OneFlowType) ||
                    OneFlowType.SUBSCRIBE,
                }}
              />
            ),
          });
        }

        const subscriptionId: string = query.subscriptionId as string;
        if (
          screen.toLowerCase() ===
            OneFlowScreen.GIFT_SUBSCRIPTION_SUCCESS.toLowerCase() &&
          subscriptionId &&
          !rootStore.userSessionStore.user
        ) {
          rootStore.theNavbarStore.onOpenLoginModal();
          return;
        }
      })();
    }, [router.query.context, router.query.provider, router.query.id]);

    useEffect(() => {
      const newBrowsingSessionId = `${new Date().toISOString()}-${uuidv4()}`;
      ETLocalStorage.setItem(SESSION_ID_ON_RELOAD, newBrowsingSessionId);
    }, [rootStore.userSessionStore.didInit]);

    useEffect(() => {
      presenter.onMount(props.isMobileDevice, props.pageName, router.asPath);
    }, [rootStore.userSessionStore.didInit, router.asPath]);

    return (
      <>
        <Head>
          <title>{t("meta.uon-earth-title")}</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"
          />
          <meta name="description" content={t("meta.content")} />
          {snippet !== "" && isProduction ? (
            <script
              type="text/javascript"
              dangerouslySetInnerHTML={{ __html: snippet }}
            />
          ) : (
            <></>
          )}
          <link rel="icon" type="image/png" href="/icon/uon-earth.png" />
          <link
            rel="icon"
            type="image/png"
            sizes="128x128"
            href="/icon/uon-earth-128x128.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="144x144"
            href="/icon/uon-earth-144x144.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="152x152"
            href="/icon/uon-earth-152x152.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="192x192"
            href="/icon/uon-earth-192x192.png"
          />
          <link
            rel="apple-touch-startup-image"
            media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)"
            href="/launch/launch-max.png"
          />
          <link
            rel="apple-touch-startup-image"
            media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)"
            href="/launch/launch-xr.png"
          />
          <link
            rel="apple-touch-startup-image"
            media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)"
            href="/launch/launch-x.png"
          />
          <link
            rel="apple-touch-startup-image"
            media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3)"
            href="/launch/launch-plus.png"
          />
          <link
            rel="apple-touch-startup-image"
            media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)"
            href="/launch/launch-8.png"
          />
          <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
          <meta
            name="msapplication-TileImage"
            content="/icon/uon-earth-144x144.png"
          />
        </Head>
        <MetaTagsStateful />
        <TheMessage />

        {props.children}

        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `if ('serviceWorker' in navigator) {
            window.addEventListener('load', function () {
              navigator.serviceWorker.getRegistrations().then(function(registrations) {
                for(let registration of registrations) {
                    registration.unregister();
                } 
            });
            })
          }`,
          }}
        />
      </>
    );
  },
);
