import NumAbbr from "number-abbreviate";

const numAbbr = new NumAbbr();

export function formatToThousand(n: number, separate = ",") {
  const s = n.toString();
  const len = s.length;
  let ret = "";
  for (let i = 1; i <= len; i++) {
    ret = s[len - i] + ret;
    if (i % 3 === 0 && i < len) {
      ret = separate + ret;
    }
  }
  return ret;
}

export function floorDecimalAdjust(num: number, Exp: number) {
  if (Exp === undefined || +Exp === 0) {
    return Math.floor(num);
  }
  let value: number | Array<string> = +num;
  const exp = +Exp;

  if (Number.isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
    return Number.NaN;
  }

  value = value.toString().split("e");
  value = Math.floor(+`${value[0]}e${value[1] ? +value[1] - exp : -exp}`);

  value = value.toString().split("e");
  return +`${value[0]}e${value[1] ? +value[1] + exp : exp}`;
}

export const formatProtected = (num: number): string => {
  let res = num;
  if (num > 999999999999) {
    res = floorDecimalAdjust(res / 1000000000000, -1);
    return numAbbr.abbreviate(res * 1000000000000, 1).toUpperCase();
  }
  if (num > 99999999999) {
    res = floorDecimalAdjust(res / 10000000000, -1);
    return numAbbr.abbreviate(res * 10000000000, 0).toUpperCase();
  }
  if (num > 999999999) {
    res = floorDecimalAdjust(res / 1000000000, -1);
    return numAbbr.abbreviate(res * 1000000000, 1).toUpperCase();
  }
  if (num > 99999999) {
    res = floorDecimalAdjust(res / 10000000, -1);
    return numAbbr.abbreviate(res * 10000000, 0).toUpperCase();
  }
  if (num > 999999) {
    res = floorDecimalAdjust(res / 1000000, -1);
    return numAbbr.abbreviate(res * 1000000, 1).toUpperCase();
  }
  if (num > 99999) {
    res = floorDecimalAdjust(res / 10000, -1);
    return numAbbr.abbreviate(res * 10000, 0).toUpperCase();
  }
  if (num > 9999) {
    res = floorDecimalAdjust(res / 1000, -1);
    return numAbbr.abbreviate(res * 1000, 1).toUpperCase();
  }

  return formatToThousand(res, ".").toUpperCase();
};
