import { action, makeObservable, observable } from "mobx";

import { isBrowser } from "../../shared/helpers/isBrowser";

export interface IWindowStore {
  innerWidth: number;
  innerHeight: number;
  setWindowSize(size: { width: number; height: number }): void;
}

export class WindowStore {
  @observable innerWidth: number = isBrowser() ? window.innerWidth : 0;
  @observable innerHeight: number = isBrowser() ? window.innerHeight : 0;

  constructor() {
    makeObservable(this);
  }

  @action.bound setWindowSize(size: { width: number; height: number }) {
    this.innerWidth = size.width;
    this.innerHeight = size.height;
  }
}
