/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable unicorn/consistent-function-scoping */

import { PhotoState } from "@earthtoday/contract";
import { action, computed, flow, makeObservable } from "mobx";
import { CancellablePromise } from "mobx/dist/internal";
import { toFlowGeneratorFunction } from "to-flow-generator-function";

import { IModalDeleteCardStore } from "../components/ModalDeleteCard/ModalDeleteCardStore";
import { IUserSessionStore } from "../components/ModalLogin/UserSessionStore";
import { ITheMessageStore } from "../components/TheMessage/TheMessageStore";
import { DeckEditData, IDeckDetailApi } from "../shared/apis/DeckDetailApi";
import { translateAPIError } from "../shared/helpers/translateApiError";
import {
  CardArticleLayoutType,
  CardRegular,
  CardSize,
  CardState,
  CardType,
  MasterLinkType,
  RepostContentData,
} from "../shared/models/Card";
import { Deck } from "../shared/models/Deck";
import { IUserCounts, UserType } from "../shared/models/User";
import { AutoplayStatus } from "./AutoplayDeckStore";
import { CardActionModel } from "./CardActionModel/CardActionModel";
import { CardBaseModel } from "./CardBaseModel";
import { CardDeckHeaderModel } from "./CardDeckHeaderModel";
import { CardDeckRepostModel } from "./CardDeckRepostModel";
import { CardInfoModel } from "./CardInfoModel/CardInfoModel";
import { CardProfilePageModel } from "./CardProfilePageModel";
import { CardPromotionRepostModel } from "./CardPromotionModel/CardPromotionRepostModel";
import { CardRegularModel } from "./CardRegularModel";
import { ICreateCardType } from "./CreateCardStore/CreateCardStore";
import {
  ChannelPage,
  DeckDetailCardItem,
} from "./DeckDetailStore/DeckDetailStore";
import { IDeviceStore } from "./DeviceStore";
import { FeatureFlaggingData } from "./FeatureFlaggingStore";
import { IFollowingRequest } from "./FollowingStore";
import { IModalStore } from "./ModalStore";
import { CardDraftEditData } from "./ProfilePagePresenter";
import { ITokenStore } from "./TokenStore";
import { UserModel } from "./UserModel";

export function isCardProfileRepostModel(
  item:
    | CardDeckRepostModel
    | CardRegularModel
    | CardProfilePageModel
    | CardProfileRepostModel
    | CardActionModel
    | CardInfoModel
    | CardPromotionRepostModel,
): item is CardProfileRepostModel {
  return item.contentType === CardType.PROFILE_REPOST;
}
export class CardProfileRepostModel extends CardBaseModel {
  type = "profile_repost";
  constructor(
    card: CardRegular,
    protected modalStore: IModalStore,
    public theMessageStore: ITheMessageStore,
    public modalDeleteCardStore: IModalDeleteCardStore,
    public userSessionStore: IUserSessionStore,
    public tokenStore: ITokenStore,
    public pageStore:
      | ({
          cards: Array<DeckDetailCardItem>;
          updateStarCard(id: string, starred: boolean): void;
          onDeleteCard(
            cardId: string,
            promotionId?: string,
            isAutoplayedCard?: boolean,
          ): void;
          toggleFollowing(data: IFollowingRequest): void;
          onCardSizeChanged(cardId: string, size: CardSize): void;
          onChangeCardLayout(
            id: string,
            layoutType: CardArticleLayoutType,
          ): void;
          updateFeatureCard(cardId: string, featured: boolean): void;
          submitAutoplay(
            sourceDeckId: string,
            targetDeckId: string,
            numberOfCards: number,
            status: AutoplayStatus,
          ): void;
          deckDetail: CardDeckHeaderModel | null;
          isCurrentUser: boolean;
        } & {
          onEditCard?: (
            cardId: string,
            data: CardDraftEditData,
          ) => CancellablePromise<boolean>;
          isLoggedinUser?: boolean;
          updateOrderOfCards?: (cardIds: string[]) => void;
        })
      | null,
    public featureFlagging: FeatureFlaggingData,
    public deviceStore: IDeviceStore,
    private deckDetailApi: IDeckDetailApi,
  ) {
    super(
      card,
      userSessionStore,
      featureFlagging,
      deviceStore,
      pageStore,
      modalStore,
    );
    makeObservable(this);
  }

  @action.bound
  onStarCard(): void {}

  @action.bound onResizeCardDouble = flow(function* onResizeCardDouble(
    this: CardDeckRepostModel,
  ) {});

  @action.bound onResizeCardSingle = flow(function* onResizeCardSingle(
    this: CardDeckRepostModel,
  ) {});

  @action.bound onChangeCardLayoutEditorial = flow(
    function* onChangeCardLayoutEditorial(this: CardDeckRepostModel) {},
  );

  @action.bound onChangeCardLayoutPhoto = flow(
    function* onChangeCardLayoutPhoto(this: CardDeckRepostModel) {},
  );

  @action.bound onChangeCardLayoutText = flow(function* onChangeCardLayoutText(
    this: CardDeckRepostModel,
  ) {});

  @action.bound updateCardSize(): void {}

  @action.bound updateChageLayout(): void {}

  @action.bound onPinFeaturedCard = flow(function* onPinFeaturedCard(
    this: CardDeckRepostModel,
  ) {});

  @action.bound onOpenModalCardEdit(): void {
    if (!this.card) return;
    if (!this.cardContent) return;
    const principles = this.card.categoryPrinciples || [];

    this.modalStore.openModal({
      name: "cardCreate",
      driver: {
        cardWhy: this.card.comment || "",
        categoryName: principles.length > 0 ? principles[0] : "",
        deckId: this.card.deck?.id || null,
        url: this.cardContent?.content?.url || "",
        cardId: this.card.id || "",
        actionType:
          this.card.content?.type === MasterLinkType.LINK_REPOST
            ? CardType.LINK_REPOST
            : "draft",
        stateCard:
          this.card.state === CardState.DRAFTING ? CardState.DRAFTING : "",
        cardLayout: this.layoutType || CardArticleLayoutType.EDITORIAL,
        cardType: ICreateCardType.PROFILE_REPOST,
        profileRepost:
          this.card.contentType === CardType.PROFILE_REPOST
            ? this.card.profileRepost
            : undefined,
      },
    });
  }

  @action.bound cardRepostIdUpdate(): void {}

  @action.bound updateCardDelete(): void {
    this.modalDeleteCardStore.updateCardDelete(this);
  }

  @action.bound onDeleteCard(): void {
    this.pageStore?.onDeleteCard(
      this.card.id,
      undefined,
      this.isAutoplayedCard,
    );
  }

  @action.bound updateStarCard(starred: boolean): void {
    this.metadata.starred = starred;
  }

  @action.bound onEditCard(): void {
    if (!this.card) return;
    if (!this.pageStore?.onEditCard) return;

    const principles = this.card.categoryPrinciples || [];

    this.pageStore.onEditCard(this.card.id || "", {
      principleName: principles[0],
      comment: this.card.comment || "",
      content: {
        type: this.card.content?.type || CardType.PROFILE_REPOST.toLowerCase(),
        url: this.cardContent?.content?.url || "",
      },
      deck: this.card.deck?.id,
      state: "published",
    });
  }

  @computed get fullName(): string {
    return this.profileRepostModel.fullName;
  }

  @computed get deckName(): string {
    return this.isRepostCardByEditorInDeckCategory
      ? (this.card?.content as RepostContentData)?.original?.deck?.name || ""
      : this.card.deck?.name || "";
  }

  @computed get deckPathString(): string {
    if (!this.isRepostCardByEditorInDeckCategory) {
      return this.card.deck?.path?.join("/") || "";
    }
    return (
      (this.card?.content as RepostContentData)?.original?.deck?.path?.join(
        "/",
      ) || ""
    );
  }

  @computed get isLoggedinUser(): boolean {
    return (
      this.card.curator.vanityName === this.userSessionStore.user?.vanityName ||
      this.pageStore?.deckDetail?.vanityName ===
        this.userSessionStore.user?.vanityName
    );
  }

  @computed get isCardSingleOptionActive(): boolean {
    return !this.card.featured && this.card.size === CardSize.SINGLE;
  }

  @computed get isCardDoubleOptionActive(): boolean {
    return !this.card.featured && this.card.size === CardSize.DOUBLE;
  }

  @computed get isCardDrafting(): boolean {
    return this.card.state === CardState.DRAFTING;
  }

  @computed get profileRepostModel(): UserModel {
    if (this.card.contentType !== CardType.PROFILE_REPOST) {
      throw new Error("Expect content type is PROFILE_REPOST");
    }

    return new UserModel(
      this.card.profileRepost,
      this.userSessionStore,
      this.tokenStore,
    );
  }

  @computed get uonCount(): number {
    return this.profileRepostModel.uonCount;
  }

  @computed get id(): string {
    return this.card.id;
  }

  @computed get userSubTitle(): string {
    return this.profileRepostModel.userSubTitle;
  }

  @computed get bio(): string {
    return this.profileRepostModel.bio;
  }

  @computed get image(): string {
    return this.profileRepostModel.image;
  }

  @computed get joined(): Date {
    return this.profileRepostModel.joined;
  }

  @computed get userCounts(): IUserCounts {
    return this.profileRepostModel.userCounts;
  }

  @computed get isPublished(): boolean | undefined {
    return this.profileRepostModel.isPublished;
  }

  @computed get curatorName(): string {
    return "";
  }

  @computed get vanityName(): string {
    if (this.card.contentType !== CardType.PROFILE_REPOST) {
      return "";
    }
    return this.card.profileRepost.vanityName;
  }

  @computed get isCardHeaderOptionActive(): boolean {
    return this.card.featured || false;
  }

  @computed get newestId(): string {
    return this.card.id || "";
  }

  @computed get isFeaturedDeck(): boolean {
    return this.card.featured || false;
  }

  @computed get comment(): string {
    return this.card.comment || "";
  }

  @computed get deckPath(): string[] {
    return this.card.deck?.path || [];
  }

  @computed get pathSharing(): string {
    return `${this.deckPath.join("/")}/${this.card.id}`;
  }

  @computed get deck(): Deck | null {
    return this.card.deck || null;
  }

  @computed get emailAddress(): string {
    return this.profileRepostModel.emailAddress;
  }

  @computed get website(): string | undefined {
    return this.profileRepostModel.website;
  }
  @computed get twitterUsername(): string | undefined {
    return this.profileRepostModel.twitterUsername;
  }
  @computed get facebookUsername(): string | undefined {
    return this.profileRepostModel.facebookUsername;
  }
  @computed get instagramUsername(): string | undefined {
    return this.profileRepostModel.instagramUsername;
  }
  @computed get linkedInUsername(): string | undefined {
    return this.profileRepostModel.linkedInUsername;
  }
  @computed get isEmailPublic(): boolean {
    return this.profileRepostModel.isEmailPublic || false;
  }

  @computed get forceUseTagLine(): boolean {
    return this.profileRepostModel.forceUseTagLine || false;
  }

  @computed get userType(): UserType {
    return this.profileRepostModel.details?.type || UserType.CONSUMER;
  }

  @computed get tagline() {
    if (this.profileTagOverride) {
      return this.profileTagOverride;
    }

    if (this.forceUseTagLine) {
      return this.profileRepostModel.tagline;
    }

    if (this.userSubTitle) {
      return "";
    }

    return this.profileRepostModel.tagline;
  }

  @computed get profileTagOverride() {
    return this.profileRepostModel.profileTagOverride || "";
  }

  @computed get allowSwitchToken(): boolean {
    return false;
  }

  @action.bound switchToken(redirect?: () => void) {
    // Todo
  }

  @computed get active(): boolean {
    return this.isContextMenuActive;
  }

  @computed get isAutoplayedCard(): boolean {
    return this.card.deck?.id !== this.pageStore?.deckDetail?.id;
  }
  @computed get isPersonalizeCard() {
    if (
      this.pageStore?.deckDetail &&
      this.pageStore?.deckDetail.path.length > 1
    ) {
      return (
        this.isAutoplayedCard &&
        Object.values(ChannelPage).includes(
          this.pageStore.deckDetail.path[1] as ChannelPage,
        )
      );
    }

    return false;
  }
  @computed get isOwnerView() {
    return (
      !!this.userSessionStore.user &&
      this.userSessionStore.user.id === this.profileRepostModel.id
    );
  }
  @computed get photoState(): PhotoState {
    return this.profileRepostModel.photoState;
  }

  @computed get shouldEnableImageUploadZone() {
    return false;
  }
  @computed get shouldRenderNewImageButton() {
    return false;
  }

  @action.bound onProfileImageZoneClicked() {}
  @action.bound onCardProfileMount() {}

  @computed get shouldRenderCardOrder(): boolean {
    if (this.isFeaturedDeck || !this.pageStore?.isLoggedinUser) {
      return false;
    }
    return this.featureFlagging.flags.enableSortCardInDeck;
  }
  @action.bound onCardOrderBlured = flow(function* onCardOrderBlured(
    this: CardProfileRepostModel,
  ) {
    if (
      this.sortNumberInputError ||
      !this.userSessionStore.user ||
      !this.pageStore?.isLoggedinUser
    ) {
      return;
    }

    try {
      const userVanityName = this.userSessionStore.user.vanityName;
      const deckId = this.card.deck?.id || "";
      const response = yield* toFlowGeneratorFunction(
        this.deckDetailApi.updateCardOrder,
      )(userVanityName, deckId, this.card.id, Number(this.cardOrderInput));
      const cardIds = response.data.map((item) => item.id.toString());
      if (this.pageStore && this.pageStore.updateOrderOfCards) {
        this.pageStore.updateOrderOfCards(cardIds);
      }
      this.closeContextMenu();
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    }
  });

  @action.bound onDeleteCardButtonClick(): void {
    this.closeContextMenu();
    this.updateCardDelete();
    this.openModal("cardDelete");
  }
}
