/* eslint-disable unicorn/consistent-function-scoping */
import { action, flow, makeObservable, observable } from "mobx";

import {
  validateUserPassword,
  ValidationCheck,
} from "../shared/helpers/commonValidations";
import hashPassword from "../shared/helpers/hashPassword";
import { translateAPIError } from "../shared/helpers/translateApiError";
import { RootStore } from "./rootStore";

export class RestorePasswordFormStore {
  private code: string = "";

  @observable password: string = "";
  @observable passwordBlurred: boolean = false;

  @observable passwordRepeat: string = "";
  @observable passwordRepeatBlurred: boolean = false;

  @observable submitting = false;
  @observable errorMessage: string | null = null;

  constructor(private rootStore: RootStore) {
    makeObservable(this);
  }

  get passwordFeedback(): ValidationCheck {
    const [valid, msg, { vars }] = validateUserPassword(this.password);

    if (!this.passwordBlurred) {
      return { valid, msg: "" };
    }

    return { valid, msg, vars };
  }

  get passwordRepeatFeedback(): ValidationCheck {
    // eslint-disable-next-line prefer-const
    let [valid, msg, { vars }] = validateUserPassword(this.passwordRepeat);

    if (valid && this.password !== this.passwordRepeat) {
      valid = false;
      msg = "Repeated password doesn't match";
    }

    if (!this.passwordRepeatBlurred) {
      return { valid, msg: "" };
    }

    return { valid, msg, vars };
  }

  get submitBtnEnabled(): boolean {
    if (!this.passwordFeedback.valid && !this.passwordRepeatFeedback.valid) {
      return false;
    }

    if (this.submitting) {
      return false;
    }

    if (
      this.password !== "" &&
      this.passwordRepeat !== "" &&
      this.password === this.passwordRepeat
    ) {
      return true;
    }

    return false;
  }

  @action.bound setCode(v: string): void {
    this.code = v;
  }

  @action.bound updatePassword(v: string): void {
    this.password = v;
  }

  @action.bound updatePasswordBlurred(b: boolean): void {
    this.passwordBlurred = b;
  }

  @action.bound updatePasswordRepeat(v: string): void {
    this.passwordRepeat = v;
  }

  @action.bound updatePasswordRepeatBlurred(b: boolean): void {
    this.passwordRepeatBlurred = b;
  }

  @action.bound submitForm = flow(function* submitForm(
    this: RestorePasswordFormStore,
  ) {
    this.errorMessage = null;
    this.submitting = true;
    try {
      const { emailAddress } =
        yield this.rootStore.userSessionApi.verifyRestorePasswordToken(
          this.code,
        );
      const newPasswordHash = hashPassword(
        this.password,
        emailAddress.toLowerCase(),
      );

      const data = {
        newPasswordHash,
        code: this.code,
      };
      yield this.rootStore.userSessionApi.resetPassword(data);
      this.rootStore.modalStore.openModal("passRestored");

      this.password = "";
      this.passwordBlurred = false;
      this.passwordRepeat = "";
      this.passwordRepeatBlurred = false;
    } catch (error) {
      this.errorMessage = translateAPIError(error);
    } finally {
      this.submitting = false;
    }
  });
}
