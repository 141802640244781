import { StrategyFirstLastName } from "./StrategyFirstLastName";
import { VanityNameGeneratorStrategy } from "./VanityNameGenerator";

export class StrategyMiddleNameAbbreviation
  implements VanityNameGeneratorStrategy
{
  private abbreviateMiddleNameIndex: "ALL" | number = "ALL";

  constructor(private nameParts: string[]) {}

  private get hasMiddleName() {
    return this.nameParts.length > 2;
  }

  generate(): { vanityName: string; next: boolean } {
    if (!this.hasMiddleName) {
      return new StrategyFirstLastName(this.nameParts).generate();
    }

    const middleNameParts = this.nameParts.slice(1, -1);
    if (this.abbreviateMiddleNameIndex === "ALL") {
      const abbrebrivations = middleNameParts.map((part) => part.slice(0, 1));

      const vanityName =
        this.nameParts[0] +
        abbrebrivations.join("") +
        this.nameParts[this.nameParts.length - 1];

      if (middleNameParts.length === 1) {
        return { vanityName, next: true };
      }

      this.abbreviateMiddleNameIndex = 0;
      return { vanityName, next: false };
    }

    // this.abbreviateMiddleNameIndex is index number
    const middleName = this.nameParts[1 + this.abbreviateMiddleNameIndex];
    const abbrebrivation = middleName.slice(0, 1);

    const vanityName =
      this.nameParts[0] +
      abbrebrivation +
      this.nameParts[this.nameParts.length - 1];
    if (this.abbreviateMiddleNameIndex >= middleNameParts.length - 1) {
      return { vanityName, next: true };
    }

    this.abbreviateMiddleNameIndex += 1;
    return { vanityName, next: false };
  }
}
