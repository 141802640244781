import { branchIOHost, deepLinkHost, isProduction } from "../env";

export function getDeeplinkRedirection({
  pathname,
  asPath,
  domain,
}: {
  pathname: string;
  asPath: string;
  domain: string;
}) {
  if (!isProduction) {
    return null;
  }

  const sanitizeDomain = domain.replace(/^https?:\/\//, "");
  const deepLinkDomain = deepLinkHost.replace(/^https?:\/\//, "");

  const destination =
    branchIOHost + "/i?deeplink_path=" + encodeURIComponent(asPath);

  if (asPath.includes("?")) {
    const params = new URLSearchParams(asPath.split("?")[1]);
    const oneFlowType = params.has("oneFlowType")
      ? params.get("oneFlowType")
      : null;

    const utmMedium = params.has("utm_medium")
      ? params.get("utm_medium")
      : null;

    if (oneFlowType || utmMedium === "email") {
      return {
        pageProps: {
          isServerRender: null,
          redirect: {
            permanent: true,
            destination,
          },
        },
      };
    }
  }

  const whitelistedPathnames = [
    {
      pathname: "/[vanityName]/[deckName]/[cardId]",
      domain: deepLinkDomain,
    },
    { pathname: "/[vanityName]/[deckName]", domain: deepLinkDomain },
    { pathname: "/[vanityName]", domain: deepLinkDomain },
    { pathname: "/qr/[poolName]" },
    { pathname: "/collect" },
    { pathname: "/collect/[brandName]" },
    { pathname: "/share/[giftType]/[uniqueId]" },
    { pathname: "/m2/[transactionId]" },
  ];

  for (const whitelistedPathname of whitelistedPathnames) {
    if (pathname === whitelistedPathname.pathname) {
      if (
        whitelistedPathname.domain &&
        whitelistedPathname.domain !== sanitizeDomain
      ) {
        return null;
      }

      return {
        pageProps: {
          isServerRender: null,
          redirect: {
            permanent: true,
            destination,
          },
        },
      };
    }
  }

  return null;
}
