export const truncateWithEllipsis = (
  text: string,
  limit: number,
  ellipsis: string = "...",
): string => {
  if (text.length <= limit) {
    return text;
  }

  /* ellipsis is include in the limit */
  return text.slice(0, Math.max(0, limit - ellipsis.length)) + ellipsis;
};
