import { observer } from "mobx-react-lite";
import Head from "next/head";
import React from "react";

import { useRootStore } from "../../stores/rootStore";

export const MetaTagsStateful = observer(() => {
  const rootStore = useRootStore();
  const { metaStore: store } = rootStore;
  return (
    <Head>
      {store.metaTagsArray.map((meta) => (
        <meta name={meta.key} content={meta.content} key={meta.key} />
      ))}
      {store.metaTagsArrayWhatsApp.map((meta) => (
        <meta
          property={meta.key}
          content={meta.content}
          key={`property-${meta.key}`}
        />
      ))}
      <meta property="og:image:width" content="720" />
      <meta property="og:image:height" content="480" />
      <meta property="og:rich_attachment" content="true" />
      {store.metaTagsTwitterArray.map((meta) => (
        <meta name={meta.key} content={meta.content} key={meta.key} />
      ))}
      {store.metaGoogleArray.map((meta) => (
        <meta itemProp={meta.key} content={meta.content} key={meta.key} />
      ))}
      {store.metaPinterestArray.map((meta) => (
        <meta property={meta.key} content={meta.content} key={meta.key} />
      ))}
    </Head>
  );
});
