import { observer } from "mobx-react-lite";

import { etGrayBold } from "../../shared/colors";
import sc from "./ButtonSocial.styled";

interface Driver {
  readonly text: string;
  readonly disabled?: boolean;
  readonly dataCy: string;
  readonly className?: string;
  readonly color?: string;
  readonly dataTrackId?: string;
  onClick(): void;
}

interface IProps {
  driver: Driver;
}

export const ButtonSocialGoogle = observer((props: IProps) => {
  const { driver } = props;
  const { disabled, dataCy, color = etGrayBold, dataTrackId: trackId } = driver;
  return (
    <sc.ButtonSocialGoogle
      className={`visual-reg btn-google ${driver.className || ""}`}
      data-cy={dataCy}
      data-track-id={trackId}
      onClick={driver.onClick}
      disabled={disabled}
      color={color}
    >
      <sc.GoogleIcon />
      {driver.text}
    </sc.ButtonSocialGoogle>
  );
});
