import React, { useEffect } from "react";
import { Element, Link } from "react-scroll";

import { etBlue } from "../../shared/colors";
import { getCurrencySymbol } from "../../shared/helpers/getCurrencySymbol";
import { useTranslation } from "../../shared/translate/NextI18next";
import { Currency } from "../../stores/DonateStore/DonateStore";
import { SectionInfo } from "../../stores/PoliciesPresenter";
import { DangerouslyHTMLRenderer } from "../DangerouslyHTMLRenderer/DangerouslyHTMLRenderer";
import sc from "./PoliciesItem.styled";

interface IProps {
  onRegister(section: SectionInfo): void;
  currency: Currency;
}

export const PoliciesItemTermsOfService = (props: IProps) => {
  const { t } = useTranslation("Policies");

  useEffect(() => {
    props.onRegister({
      key: "terms-and-conditions",
      label: "Terms and Conditions",
    });
  }, [props]);

  return (
    <Element name={"terms-and-conditions"}>
      <sc.TextHeader>{t("policy.terms-of-service.label")}</sc.TextHeader>
      <sc.TextModified>
        <DangerouslyHTMLRenderer>
          {t("policy.general.text-modified")}
        </DangerouslyHTMLRenderer>
      </sc.TextModified>

      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.introduction-title")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>
      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.introduction.text-header")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.introduction.text-content-1")}
        </DangerouslyHTMLRenderer>{" "}
        <a href="mailto:support@earthtoday.com">support@earthtoday.com</a>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.introduction.text-content-2")}
        </DangerouslyHTMLRenderer>{" "}
        <a target="_blank" rel="noreferrer" href="https://earthtoday.com/">
          earthtoday.com
        </a>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.introduction.text-content-2-fr-2")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.introduction.text-content-3")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.introduction.text-content-4")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.introduction.text-content-5")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.introduction.text-content-6")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.service.text-header")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.service.text-content-1")}
        </DangerouslyHTMLRenderer>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>{" "}
          <DangerouslyHTMLRenderer>
            {t("policy.terms-of-service.service.indent-1")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.terms-of-service.service.indent-2")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.terms-of-service.service.indent-3")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.service.text-content-2")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.service.text-content-3")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.account-and-privacy-title")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>
      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.account-and-privacy.text-header")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.account-and-privacy.text-content-1")}
        </DangerouslyHTMLRenderer>{" "}
        <sc.TextPointer>
          <Link
            className="active"
            activeClass="active"
            color={etBlue}
            to="privacy"
            spy
            smooth
            duration={500}
          >
            <DangerouslyHTMLRenderer>
              {t("policy.general.privacy-policy")}
            </DangerouslyHTMLRenderer>
          </Link>
        </sc.TextPointer>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.account-and-privacy.text-content-2")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.account-and-privacy.text-content-3")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.account-and-privacy.text-content-4")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.account-and-privacy.text-content-5")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.account-and-privacy.text-content-6")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.account-and-privacy.text-content-7")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.account-and-privacy.text-content-8")}
        </DangerouslyHTMLRenderer>{" "}
        <a href="mailto:support@earthtoday.com ">partnerships@earthtoday.com</a>
        .
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.account-and-privacy.text-content-9")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.account-and-privacy.text-content-10")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.usage-restriction.text-header")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.usage-restriction.text-content-1")}
        </DangerouslyHTMLRenderer>
        <sc.Indent>
          <sc.IndentInline>■ </sc.IndentInline>{" "}
          <DangerouslyHTMLRenderer>
            {t("policy.terms-of-service.usage-restriction.indent-1")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■ </sc.IndentInline>{" "}
          <DangerouslyHTMLRenderer>
            {t("policy.terms-of-service.usage-restriction.indent-2")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■ </sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.terms-of-service.usage-restriction.indent-3")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■ </sc.IndentInline>{" "}
          <DangerouslyHTMLRenderer>
            {t("policy.terms-of-service.usage-restriction.indent-4")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■ </sc.IndentInline>{" "}
          <DangerouslyHTMLRenderer>
            {t("policy.terms-of-service.usage-restriction.indent-5")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■ </sc.IndentInline>{" "}
          <DangerouslyHTMLRenderer>
            {t("policy.terms-of-service.usage-restriction.indent-6")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■ </sc.IndentInline>{" "}
          <DangerouslyHTMLRenderer>
            {t("policy.terms-of-service.usage-restriction.indent-7")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■ </sc.IndentInline>{" "}
          <DangerouslyHTMLRenderer>
            {t("policy.terms-of-service.usage-restriction.indent-8")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.usage-restriction.text-content-2")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.content-curation-title")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>
      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.content-curation.text-header")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.content-curation.text-content-1")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.content-curation.text-content-2")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.content-curation.text-content-3")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.content-curation.text-content-4")}
        </DangerouslyHTMLRenderer>{" "}
        <sc.TextPointer>
          <Link
            className="active"
            activeClass="active"
            color={etBlue}
            to="notice-take-down"
            spy
            smooth
            duration={500}
          >
            <DangerouslyHTMLRenderer>
              {t("policy.general.notice-takedown-policy")}
            </DangerouslyHTMLRenderer>
          </Link>
        </sc.TextPointer>
      </sc.TextContent>

      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.your-content.text-header")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.your-content.text-content-1")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.your-content.text-content-2")}
        </DangerouslyHTMLRenderer>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>{" "}
          <DangerouslyHTMLRenderer>
            {t("policy.terms-of-service.your-content.indent-1")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>{" "}
          <DangerouslyHTMLRenderer>
            {t("policy.terms-of-service.your-content.indent-2")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>{" "}
          <DangerouslyHTMLRenderer>
            {t("policy.terms-of-service.your-content.indent-3")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>{" "}
          <DangerouslyHTMLRenderer>
            {t("policy.terms-of-service.your-content.indent-4")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>{" "}
          <DangerouslyHTMLRenderer>
            {t("policy.terms-of-service.your-content.indent-5")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>{" "}
          <DangerouslyHTMLRenderer>
            {t("policy.terms-of-service.your-content.indent-6")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>{" "}
          <DangerouslyHTMLRenderer>
            {t("policy.terms-of-service.your-content.indent-7")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>{" "}
          <DangerouslyHTMLRenderer>
            {t("policy.terms-of-service.your-content.indent-8")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>{" "}
          <DangerouslyHTMLRenderer>
            {t("policy.terms-of-service.your-content.indent-9")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>{" "}
          <DangerouslyHTMLRenderer>
            {t("policy.terms-of-service.your-content.indent-10")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.your-content.text-content-3")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.your-content.text-content-4")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.your-content.text-content-5")}
        </DangerouslyHTMLRenderer>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>{" "}
          <DangerouslyHTMLRenderer>
            {t("policy.terms-of-service.your-content.text-content-5-indent-1")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>{" "}
          <DangerouslyHTMLRenderer>
            {t("policy.terms-of-service.your-content.text-content-5-indent-2")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
      </sc.TextContent>

      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.trademarks-copyrights.text-header")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.trademarks-copyrights.text-content-1")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.trademarks-copyrights.text-content-2")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.trademarks-copyrights.text-content-3")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t(
            "policy.terms-of-service.trademarks-copyrights.text-content-4-fr-1",
          )}
        </DangerouslyHTMLRenderer>{" "}
        <sc.TextPointer>
          <Link
            className="active"
            activeClass="active"
            color={etBlue}
            to="notice-take-down"
            spy
            smooth
            duration={500}
          >
            <DangerouslyHTMLRenderer>
              {t("policy.general.notice-takedown-policy")}
            </DangerouslyHTMLRenderer>
          </Link>
        </sc.TextPointer>
        <DangerouslyHTMLRenderer>
          {t(
            "policy.terms-of-service.trademarks-copyrights.text-content-4-fr-2",
          )}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.trademarks-copyrights.text-content-5")}
        </DangerouslyHTMLRenderer>{" "}
      </sc.TextContent>

      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.financial-title")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>
      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.financial-responsibility.text-header")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.financial-responsibility.text-content-1")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.financial-responsibility.text-content-2")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.financial-responsibility.text-content-3")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.financial-responsibility.text-content-4")}
        </DangerouslyHTMLRenderer>{" "}
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.financial-responsibility.text-content-5")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.financial-responsibility.text-content-6")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.protect-m2.text-header")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>

      <sc.TextContent>
        {t("policy.terms-of-service.protect-m2.text-content-1", {
          currency: getCurrencySymbol(props.currency),
        })}
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.protect-m2.text-content-2")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.protect-m2.text-content-3")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.protect-m2.text-content-4")}
        </DangerouslyHTMLRenderer>{" "}
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.protect-m2.text-content-5-fr-1")}
        </DangerouslyHTMLRenderer>{" "}
        <a href="mailto:uon-revoke@earthtoday.com">uon-revoke@earthtoday.com</a>{" "}
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.protect-m2.text-content-5-fr-2")}
        </DangerouslyHTMLRenderer>{" "}
      </sc.TextContent>

      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.other-title")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>
      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.warranties.text-header")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.warranties.text-content-1")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.warranties.text-content-2")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.limit-liability.text-header")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.limit-liability.text-content-1")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.limit-liability.text-content-2", {
            currency: props.currency === Currency.USD ? "$" : "€",
          })}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.limit-liability.text-content-3")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.limit-liability.text-content-4")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.miscellaneous.text-header")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.miscellaneous.text-content-1")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.miscellaneous.text-content-2")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.miscellaneous.text-content-3")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.miscellaneous.text-content-4")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.terms-of-service.miscellaneous.text-content-5")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
    </Element>
  );
};
