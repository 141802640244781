import { TokenInterceptorStore } from "../../stores/TokenInterceptorStore";
import { getAPIBaseUrl } from "../env";
import { Deck } from "../models/Deck";

export default class {
  constructor(private tokenInterceptorStore: TokenInterceptorStore) {}

  fetchDeck = async (deckId: string): Promise<Deck> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/decks/${deckId}`,
    });

    return res.data;
  };

  fetchDeckSafe = async (deckId: string): Promise<Deck | null> => {
    return this.fetchDeck(deckId);
  };
}
