/* eslint-disable class-methods-use-this */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { action, computed, flow, makeObservable } from "mobx";
import { toFlowGeneratorFunction } from "to-flow-generator-function";

import { IModalDeleteCardStore } from "../components/ModalDeleteCard/ModalDeleteCardStore";
import { IUserSessionStore } from "../components/ModalLogin/UserSessionStore";
import { ITheMessageStore } from "../components/TheMessage/TheMessageStore";
import { DeckEditData, IDeckDetailApi } from "../shared/apis/DeckDetailApi";
import { showMessageEmailNotVerified } from "../shared/helpers/showMessageEmailNotVerified";
import { translateAPIError } from "../shared/helpers/translateApiError";
import {
  CardArticleLayoutType,
  CardRegular,
  CardSize,
  CardState,
  CardType,
  MasterLinkType,
  RepostContentData,
} from "../shared/models/Card";
import { Deck } from "../shared/models/Deck";
import { CardBaseModel } from "./CardBaseModel";
import { CardDeckHeaderModel } from "./CardDeckHeaderModel";
import {
  ChannelPage,
  DeckDetailCardItem,
} from "./DeckDetailStore/DeckDetailStore";
import { IDeviceStore } from "./DeviceStore";
import { Currency } from "./DonateStore/DonateStore";
import { FeatureFlaggingData } from "./FeatureFlaggingStore";
import { IFollowingRequest } from "./FollowingStore";
import { IModalStore } from "./ModalStore";

type PageStore = {
  cards: Array<DeckDetailCardItem>;
  updateStarCard(id: string, starred: boolean): void;
  onDeleteCard(
    cardId: string,
    promotionId?: string,
    isAutoplayedCard?: boolean,
  ): void;
  toggleFollowing(data: IFollowingRequest): void;
  onCardSizeChanged(cardId: string, size: CardSize): void;
  onChangeCardLayout(id: string, layoutType: CardArticleLayoutType): void;
  updateFeatureCard(cardId: string, featured: boolean): void;
  deckDetail: CardDeckHeaderModel | null;
  currency: Currency;
  isCurrentUser: boolean;
} & {
  isLoggedinUser?: boolean;
  updateOrderOfCards?: (cardIds: string[]) => void;
  onOpenLoginModal?: () => void;
};

export class CardRegularModel extends CardBaseModel {
  constructor(
    card: CardRegular,
    protected modalStore: IModalStore,
    public theMessageStore: ITheMessageStore,
    public userSessionStore: IUserSessionStore,
    public modalDeleteCardStore: IModalDeleteCardStore,
    public pageStore: PageStore,
    public featureFlagging: FeatureFlaggingData,
    public deviceStore: IDeviceStore,
    private deckDetailApi: IDeckDetailApi,
    renderKey?: string,
  ) {
    super(
      card,
      userSessionStore,
      featureFlagging,
      deviceStore,
      pageStore,
      modalStore,
      renderKey,
    );
    makeObservable(this);
  }
  @action.bound
  onStarCard(): void {
    if (!this.isUserLoggedin) {
      if (
        this.featureFlagging.flags.enableLoginWithQRCode &&
        this.pageStore.onOpenLoginModal
      ) {
        this.pageStore.onOpenLoginModal();
      } else {
        this.modalStore.openModal("loginForm");
      }
      return;
    }

    if (!this.userSessionStore.user?.emailAddressVerified) {
      showMessageEmailNotVerified(this.theMessageStore, this.userSessionStore);
      return;
    }

    if (this.card && this.card.id) {
      this.pageStore.updateStarCard(this.card.id, !this.metadata.starred);
    }
  }

  @action.bound onResizeCardDouble = flow(function* onResizeCardDouble(
    this: CardRegularModel,
  ) {
    this.closeContextMenu();
    const cardId = this.card.cardAutoplayId || this.card.id;
    yield* toFlowGeneratorFunction(this.pageStore.onCardSizeChanged)(
      cardId,
      CardSize.DOUBLE,
    );
    if (this.card.featured) {
      this.onPinFeaturedCard();
    }
  });

  @action.bound onResizeCardSingle = flow(function* onResizeCardSingle(
    this: CardRegularModel,
  ) {
    this.closeContextMenu();
    const cardId = this.card.cardAutoplayId || this.card.id;
    yield* toFlowGeneratorFunction(this.pageStore.onCardSizeChanged)(
      cardId,
      CardSize.SINGLE,
    );
    if (this.card.featured) {
      this.onPinFeaturedCard();
    }
  });

  @action.bound onChangeCardLayoutEditorial = flow(
    function* onChangeCardLayoutEditorial(this: CardRegularModel) {
      this.closeContextMenu();
      if (!this.adminOrOwner) return;
      const cardId = this.card.cardAutoplayId || this.card.id;
      yield this.pageStore.onChangeCardLayout(
        cardId,
        CardArticleLayoutType.EDITORIAL,
      );
    },
  );

  @action.bound onChangeCardLayoutPhoto = flow(
    function* onChangeCardLayoutPhoto(this: CardRegularModel) {
      this.closeContextMenu();
      if (!this.adminOrOwner) return;
      const cardId = this.card.cardAutoplayId || this.card.id;
      yield this.pageStore.onChangeCardLayout(
        cardId,
        CardArticleLayoutType.PHOTO,
      );
    },
  );

  @action.bound onChangeCardLayoutText = flow(function* onChangeCardLayoutText(
    this: CardRegularModel,
  ) {
    this.closeContextMenu();
    if (!this.adminOrOwner) return;
    const cardId = this.card.cardAutoplayId || this.card.id;
    yield this.pageStore.onChangeCardLayout(cardId, CardArticleLayoutType.TEXT);
  });

  @action.bound updateCardSize(size: CardSize): void {
    Object.assign(this.card, { size });
  }

  @action.bound updateChageLayout(
    cardArticleLayoutType: CardArticleLayoutType,
  ): void {
    Object.assign(this.card, { layoutType: cardArticleLayoutType });
  }

  @action.bound onPinFeaturedCard = flow(function* onPinFeaturedCard(
    this: CardRegularModel,
  ) {
    this.closeContextMenu();
    if (!this.card.deck) return;
    yield* toFlowGeneratorFunction(this.pageStore.updateFeatureCard)(
      this.card.id,
      !this.card.featured,
    );
  });

  @action.bound onOpenModalCardEdit(): void {
    if (!this.card) return;
    if (!this.cardContent) return;
    const principles = this.card.categoryPrinciples || [];

    this.modalStore.openModal({
      name: "cardCreate",
      driver: {
        cardWhy: this.card.comment || "",
        categoryName: principles.length > 0 ? principles[0] : "",
        deckId: this.card.deck?.id || null,
        url: this.cardContent.content.url,
        cardId: this.card.id || "",
        actionType:
          this.card.content.type === MasterLinkType.LINK_REPOST
            ? CardType.LINK_REPOST
            : "draft",
        stateCard:
          this.card.state === CardState.DRAFTING ? CardState.DRAFTING : "",
        cardLayout: this.layoutType || CardArticleLayoutType.EDITORIAL,
      },
    });
  }

  @action.bound cardRepostIdUpdate(): void {
    if (!this.isUserLoggedin) {
      if (
        this.featureFlagging.flags.enableLoginWithQRCode &&
        this.pageStore.onOpenLoginModal
      ) {
        this.pageStore.onOpenLoginModal();
      } else {
        this.modalStore.openModal("loginForm");
      }
      return;
    }

    if (!this.card || !this.card.id || !this.userSessionStore.isCurator) return;

    if (!this.userSessionStore.user?.emailAddressVerified) {
      showMessageEmailNotVerified(this.theMessageStore, this.userSessionStore);
      return;
    }

    this.modalStore.openModal({
      name: "cardCreate",
      driver: {
        cardRepostId: this.card.id,
        cardLayout: this.layoutType || CardArticleLayoutType.EDITORIAL,
      },
    });
  }

  @action.bound updateCardDelete(): void {
    this.modalDeleteCardStore.updateCardDelete(this);
  }

  @action.bound onDeleteCard(): void {
    this.pageStore.onDeleteCard(this.card.id, undefined, this.isAutoplayedCard);
  }

  @action.bound updateStarCard(starred: boolean): void {
    this.metadata.starred = starred;
  }

  @action.bound onEditCard(): void {
    if (!this.card) return;
    if (!this.cardContent) return;
    const principles = this.card.categoryPrinciples || [];

    this.modalStore.openModal({
      name: "cardCreate",
      driver: {
        cardWhy: this.card.comment || "",
        categoryName: principles.length > 0 ? principles[0] : "",
        deckId: this.card.deck?.id || null,
        url: this.cardContent.content.url,
        cardId: this.card.id || "",
        actionType:
          this.card.content.type === MasterLinkType.LINK_REPOST
            ? CardType.LINK_REPOST
            : "draft",
        stateCard:
          this.card.state === CardState.DRAFTING ? CardState.DRAFTING : "",
        cardLayout: this.layoutType || CardArticleLayoutType.EDITORIAL,
      },
    });
  }

  @computed get curatorName(): string {
    return (
      (this.isRepostCardByEditorInDeckCategory
        ? (this.card?.content as RepostContentData)?.original?.curator?.name
        : this.card.curator?.name) || ""
    );
  }

  @computed get vanityName(): string {
    return (
      (this.isRepostCardByEditorInDeckCategory
        ? (this.card?.content as RepostContentData)?.original?.curator
            ?.vanityName
        : this.card.curator?.vanityName) || ""
    );
  }

  @computed get deckName(): string {
    return (
      (this.isRepostCardByEditorInDeckCategory
        ? (this.card?.content as RepostContentData)?.original?.deck?.name
        : this.card.deck?.name) || ""
    );
  }

  @computed get deckPathString(): string {
    return (
      (this.isRepostCardByEditorInDeckCategory
        ? (this.card?.content as RepostContentData)?.original?.deck?.path?.join(
            "/",
          )
        : this.card.deck?.path?.join("/")) || ""
    );
  }

  @computed get currency(): Currency {
    return this.pageStore.currency;
  }
  @computed get isLoggedinUser(): boolean {
    return this.card.curator.currentUser;
  }

  @computed get isCardSingleOptionActive(): boolean {
    return !this.card.featured && this.card.size === CardSize.SINGLE;
  }

  @computed get isCardDoubleOptionActive(): boolean {
    return !this.card.featured && this.card.size === CardSize.DOUBLE;
  }

  @computed get isCardHeaderOptionActive(): boolean {
    return this.card.featured || false;
  }

  @computed get newestId(): string {
    return this.card.id || "";
  }

  @computed get isFeaturedDeck(): boolean {
    if (!this.isPersonalizeCard) {
      return !!this.card.featured;
    }
    return false;
  }

  @computed get comment(): string {
    return (
      (this.isRepostCardByEditorInDeckCategory
        ? (this.card?.content as RepostContentData)?.original?.comment
        : this.card.comment) || ""
    );
  }

  @computed get deckPath(): string[] {
    return this.card.deck?.path || [];
  }

  @computed get pathSharing(): string {
    return `${this.deckPath.join("/")}/${this.card.id}`;
  }

  @computed get deck(): Deck | null {
    return this.card.deck || null;
  }

  @computed get isCardDrafting(): boolean {
    return this.card.state === CardState.DRAFTING;
  }

  @computed get id(): string {
    return this.card.id;
  }

  @computed get isAutoplayedCard(): boolean {
    return this.card.deck?.id !== this.pageStore.deckDetail?.id;
  }

  @computed get isPersonalizeCard() {
    if (
      this.pageStore.deckDetail &&
      this.pageStore.deckDetail.path.length > 1
    ) {
      return (
        this.isAutoplayedCard &&
        Object.values(ChannelPage).includes(
          this.pageStore.deckDetail.path[1] as ChannelPage,
        )
      );
    }

    return false;
  }

  @computed get shouldRenderCardOrder(): boolean {
    if (this.isFeaturedDeck || !this.pageStore.isLoggedinUser) {
      return false;
    }
    return this.featureFlagging.flags.enableSortCardInDeck;
  }
  @action.bound onCardOrderBlured = flow(function* onCardOrderBlured(
    this: CardRegularModel,
  ) {
    if (
      this.sortNumberInputError ||
      !this.userSessionStore.user ||
      !this.pageStore.isLoggedinUser
    ) {
      return;
    }

    try {
      const userVanityName = this.userSessionStore.user.vanityName;
      const deckId = this.card.deck?.id || "";
      const response = yield* toFlowGeneratorFunction(
        this.deckDetailApi.updateCardOrder,
      )(userVanityName, deckId, this.card.id, Number(this.cardOrderInput));
      const cardIds = response.data.map((item) => item.id.toString());

      if (this.pageStore && this.pageStore.updateOrderOfCards) {
        this.pageStore.updateOrderOfCards(cardIds);
      }
      this.closeContextMenu();
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    }
  });
}
