/* eslint-disable unicorn/filename-case */
import { CounterSource } from "../../stores/CounterStore/CounterStore";
import { TokenInterceptorStore } from "../../stores/TokenInterceptorStore";
import { getAPIBaseUrl } from "../env";
import { getGraphqlClient } from "../graphqlClient";
import { isBrowser } from "../helpers/isBrowser";
import { IConsumer } from "../models/Consumer";
import {
  globalLeaderboardUpdatedSubscription,
  uonCountUpdatedSubscription,
} from "./protectPageQueries";
import { UnsubscribeFn } from "./UnsubscribeFn";

export interface IProtectPageApi {
  fetchLeaderboardTop(): Promise<IConsumer[]>;
  fetchLeaderboardRecent(): Promise<IConsumer[]>;
  fetchUonCount(source?: CounterSource): Promise<number>;
  fetchMultipleUonCount(sources?: CounterSource[]): Promise<number>;
  fetchLeaderboardGoodCompanyTop(offset: number): Promise<IConsumer[]>;
  fetchLeaderboardGoodCompanyRecent(offset: number): Promise<IConsumer[]>;
  subscribeUonCount(
    subscribeFn: (error: Error | null, uonCount: number) => void,
  ): UnsubscribeFn;
  subscribeGlobalLeaderboard(
    subscribeFn: (
      error: Error | null,
      globalLeaderboardUpdated: IConsumer[] | null,
    ) => void,
  ): UnsubscribeFn;
  fetchDeck(vanityName: string, deckName: string): Promise<number>;
}

export class ProtectPageAPI implements IProtectPageApi {
  // eslint-disable-next-line no-useless-constructor
  constructor(private readonly tokenInterceptorStore: TokenInterceptorStore) {}

  fetchLeaderboardTop = async (): Promise<IConsumer[]> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/uon/leaderboard?type=TOP&count=50`,
    });
    return res.data;
  };

  fetchLeaderboardRecent = async (): Promise<IConsumer[]> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/uon/leaderboard?type=RECENT&count=50`,
    });
    return res.data;
  };

  fetchLeaderboardGoodCompanyTop = async (
    offset: number,
  ): Promise<IConsumer[]> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/goodcompanies/leaderboard?type=TOP&offset=${offset}`,
    });
    return res.data;
  };

  fetchLeaderboardGoodCompanyRecent = async (
    offset: number,
  ): Promise<IConsumer[]> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/goodcompanies/leaderboard?type=RECENT&offset=${offset}`,
    });
    return res.data;
  };

  fetchUonCount = async (source?: CounterSource): Promise<number> => {
    // get global count
    if (!source) {
      const res = await this.tokenInterceptorStore.call({
        url: `${getAPIBaseUrl()}/uon/counts`,
      });

      return res.data.uonPreserved;
    }

    const { sourceType, idOrVanityName } = source;
    const queryString = new URLSearchParams({ sourceType, idOrVanityName });
    const url = `${getAPIBaseUrl()}/uon/counts?${queryString}`;

    const res = await this.tokenInterceptorStore.call({
      url,
    });

    return res.data.uonPreserved;
    /*
    NOTE: doesn't work, fix api later
    const result = await getGraphqlClient().query<UonCountOutput>({
      query: uonCountQuery as any,
      context: {
        tokenInterceptorStore: this.tokenInterceptorStore,
      },
    });

    if (result.error) {
      throw result.error;
    }
    if (result.errors && result.errors.length > 0) {
      throw new Error(`gql error: ${JSON.stringify(result.errors)}`);
    }

    return result.data.count;
    */
  };

  fetchMultipleUonCount = async (sources: CounterSource[]): Promise<number> => {
    const multipleCounterSourceMapper = {
      global: "true",
      campaign: "campaigns",
      charity: "charities",
      npo: "npos",
      brand: "brands",
    };
    const queryString = new URLSearchParams();
    for (const source of sources) {
      queryString.append(
        multipleCounterSourceMapper[source.sourceType],
        source.idOrVanityName,
      );
    }

    const url = `${getAPIBaseUrl()}/uon/multipleCounts?${queryString}`;
    const res = await this.tokenInterceptorStore.call({
      url,
    });

    return res.data.uonPreserved;
  };

  fetchDeck = async (vanityName: string, deckName: string): Promise<number> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/decks/${vanityName}/${deckName}?metaTags=false`,
    });
    return res.data;
  };

  subscribeUonCount = (
    subscribeFn: (error: Error | null, uonCount: number) => void,
  ): UnsubscribeFn => {
    if (!isBrowser()) {
      return () => {};
    }

    const observable = getGraphqlClient()
      .subscribe<{ uonCountUpdated: { count: number } }>({
        query: uonCountUpdatedSubscription() as any,
        context: {
          tokenInterceptorStore: this.tokenInterceptorStore,
        },
      })
      .subscribe((result) => {
        if (Array.isArray(result.errors) && result.errors.length > 0) {
          subscribeFn(new Error(result.errors.toString()), -1);
          return;
        }
        subscribeFn(null, result.data?.uonCountUpdated.count || 0);
      });

    return () => observable.unsubscribe();
  };

  subscribeGlobalLeaderboard = (
    subscribeFn: (
      error: Error | null,
      globalLeaderboardUpdated: IConsumer[] | null,
    ) => void,
  ): UnsubscribeFn => {
    if (!isBrowser()) {
      return () => {};
    }

    const observable = getGraphqlClient()
      .subscribe<{ globalLeaderboardUpdated: IConsumer[] | null }>({
        query: globalLeaderboardUpdatedSubscription() as any,
        context: {
          tokenInterceptorStore: this.tokenInterceptorStore,
        },
      })
      .subscribe((result) => {
        if (Array.isArray(result.errors) && result.errors.length > 0) {
          subscribeFn(new Error(result.errors.toString()), null);
          return;
        }
        subscribeFn(null, result.data?.globalLeaderboardUpdated || null);
      });

    return () => observable.unsubscribe();
  };
}
