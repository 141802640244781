import { gql } from "@ts-gql/tag";

export function userMetadataQuery() {
  return gql`
    query userMetadataQuery($cardIds: [ID]!) {
      userMetadata(cardIds: $cardIds) {
        cards {
          id
          starred
        }
      }
    }
  ` as import("../../../__generated__/ts-gql/userMetadataQuery").type;
}
