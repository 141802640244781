/* eslint-disable class-methods-use-this */
/* eslint-disable unicorn/consistent-function-scoping */
import { PaymentRegion } from "@earthtoday/contract";
import {
  action,
  computed,
  flow,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { toFlowGeneratorFunction } from "to-flow-generator-function";

import { LandingPageDriver } from "../../components/LayoutLandingPage/LandingPage";
import { TheMessageStore } from "../../components/TheMessage/TheMessageStore";
import { HomeApi } from "../../shared/apis/HomeApi";
import { UnsubscribeFn } from "../../shared/apis/UnsubscribeFn";
import { translateAPIError } from "../../shared/helpers/translateApiError";
import { Currency } from "../DonateStore/DonateStore";
import { FeatureFlaggingData } from "../FeatureFlaggingStore";

export class LandingPageStore implements LandingPageDriver {
  constructor(
    private homeApi: HomeApi,
    public theMessageStore: TheMessageStore,
    private featureFlaggingStore: FeatureFlaggingData,
  ) {
    makeObservable(this);
  }

  @observable paymentRegion: PaymentRegion = PaymentRegion.EU;
  @observable protectedCount: number | null = null;
  @action.bound onMount = flow(function* onMount(
    this: LandingPageStore,
    msgType?: string,
  ) {
    try {
      const protectedCount = yield* toFlowGeneratorFunction(
        this.homeApi.fetchProtectedCount,
      )();

      this.protectedCount = protectedCount;
      this.userAgent = this.getUserAgent();

      const onPageLoadResponse = yield* toFlowGeneratorFunction(
        this.homeApi.detectUserRegion,
      )();
      this.paymentRegion = onPageLoadResponse.paymentRegion;

      if (msgType === "magic_link_expired") {
        this.theMessageStore.showMessage({
          typeMessage: "Error",
          title: "toast-message.general.error",
          content: "toast-message.magiclink.expired",
        });
        return;
      }

      if (msgType === "magic_link_verified") {
        this.theMessageStore.showMessage({
          typeMessage: "Close",
          title: "toast-message.general.success",
          content: "toast-message.magiclink.verified",
        });
        return;
      }

      if (msgType === "magic_link_invalid") {
        this.theMessageStore.showMessage({
          typeMessage: "Error",
          title: "toast-message.general.error",
          content: "toast-message.magiclink.invalid",
        });
        return;
      }
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    }
  });

  @computed get currency(): Currency {
    if (this.featureFlaggingStore.flags.enableMultiCurrency) {
      return this.paymentRegion === PaymentRegion.EU
        ? Currency.EUR
        : Currency.USD;
    }

    return Currency.EUR;
  }

  @observable userAgent: string | null = null;
  @computed get isMobile(): boolean {
    if (!this.userAgent) {
      return false;
    }

    const toMatch = [
      /android/i,
      /webos/i,
      /iphone/i,
      /ipad/i,
      /ipod/i,
      /blackberry/i,
      /windows phone/i,
    ];
    const userAgent = this.userAgent;

    return toMatch.some((toMatchItem) => {
      return userAgent.match(toMatchItem);
    });
  }
  @computed get shouldRenderAppStore(): boolean {
    if (!this.featureFlaggingStore.flags.uonEarthLandingPageDownloadButtons) {
      return false;
    }
    if (!this.userAgent) {
      return true;
    }

    if (this.isMobile) {
      return /iPad|iPhone|iPod/.test(this.userAgent);
    }

    return true;
  }

  @computed get shouldRenderGooglePlay(): boolean {
    if (!this.featureFlaggingStore.flags.uonEarthLandingPageDownloadButtons) {
      return false;
    }
    if (!this.userAgent) {
      return true;
    }

    if (this.isMobile) {
      return /android|Android|webos|blackberry|windows phone/.test(
        this.userAgent,
      );
    }

    return true;
  }

  unsubscribeFns: UnsubscribeFn[] = [];
  @action.bound subscribeCounter = (): void => {
    this.unsubscribeFns.push(
      this.homeApi.subscribeUonCount((error, count) => {
        if (error) {
          this.theMessageStore.showMessage({
            typeMessage: "Error",
            title: "Error",
            content: translateAPIError(error),
          });
          return;
        }

        runInAction(() => {
          this.protectedCount = count;
        });
      }),
    );
  };
  @action.bound unsubscribeCounter = (): void => {
    for (const unsubscribe of this.unsubscribeFns) unsubscribe();
  };

  getUserAgent(): string | null {
    if (!navigator) {
      return null;
    }
    return navigator.userAgent;
  }

  @observable shouldRenderVideoPlayer = false;
  @action.bound onPlayVideoButtonClicked() {
    this.shouldRenderVideoPlayer = true;
  }
  @action.bound onOverlayClicked() {
    this.shouldRenderVideoPlayer = false;
  }
}
