import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { sc } from "./LazyImage.styled";

export interface ILazyImage {
  src: string;
  alt: string;
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  display?: string;
  dataCy?: string;
  position?:
    | "static"
    | "absolute"
    | "fixed"
    | "relative"
    | "sticky"
    | "initial"
    | "inherit";
  top?: string;
  left?: string;
  zIndex?: string;
}

export const LazyImage = (props: ILazyImage) => {
  const attributes = {
    alt: props.alt,
    src: props.src,
    width: props.width || "30px",
    height: props.height || "30px",
    maxWidth: props.maxWidth || "30px",
    maxHeight: props.maxHeight || "30px",
    display: props.display || "block",
    dataCy: props.dataCy || "",
    position: props.position || "static",
    top: props.top || "auto",
    left: props.left || "auto",
    zIndex: props.zIndex || "auto",
  };

  return (
    <sc.WrapperLazyImage
      width={attributes.width}
      height={attributes.height}
      maxWidth={attributes.width}
      maxHeight={attributes.height}
      display={attributes.display}
      position={attributes.position}
      top={attributes.top}
      left={attributes.left}
      zIndex={attributes.zIndex}
    >
      <LazyLoadImage
        alt={attributes.alt}
        src={attributes.src}
        data-cy={attributes.dataCy}
      />
    </sc.WrapperLazyImage>
  );
};
