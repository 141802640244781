import { observer } from "mobx-react-lite";

import { useTranslation } from "../../../shared/translate/NextI18next";
import { DangerouslyHTMLRenderer } from "../../DangerouslyHTMLRenderer/DangerouslyHTMLRenderer";
import { sc } from "./SectionFour.styled";
export const SectionFour = observer(() => {
  const { t } = useTranslation("LandingPage");

  return (
    <sc.Section className="visual-reg">
      <sc.SectionContent>
        <sc.M2 />
        <sc.Title>
          <DangerouslyHTMLRenderer>
            {t("landing-page.section-four.title")}
          </DangerouslyHTMLRenderer>
        </sc.Title>
        <sc.Subtitle>{t("landing-page.section-four.sub-title")}</sc.Subtitle>
        <sc.NpoLogos src={require("./assets/npo-logos.png")} alt="Npo Logos" />
      </sc.SectionContent>
    </sc.Section>
  );
});
