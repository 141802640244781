import { ImageResponseDto } from "@earthtoday/contract";

import { CardRegular, ICardFeatures } from "./Card";
import { Deck } from "./Deck";
export enum CookieConsent {
  NONE = "none",
  BASIC = "basic",
  SOCIAL_MEDIA = "social_media",
}

export enum UserFeatureSource {
  CARD = "card",
  CAMPAIGN = "campaign",
}

export type GroupInfo = {
  id: string;
  name: string;
  isVerified: boolean | null;
  profileType: GroupProfileType | null;
  state: string;
  createdBy: string;
  isVerificationPending?: boolean;
  roleRequest?: GroupProfileType;
};

export type IUserCountsCustom = IUserCounts & {
  groupCount?: number;
  groupMemberCount?: number;
  promoteCards?: number;
};

export enum GroupMemberRole {
  OWNER = "owner",
  ADMIN = "admin",
  CURATOR = "curator",
  LEGAL_REP = "legal_rep",
}

export enum GroupProfileType {
  UNCLASSIFIED_ORG = "unclassified_org",
  BRAND = "brand",
  NPO = "npo",
  CHARITY = "charity",
  COMMUNITY = "community",
  PARTNER = "partner",
}

export enum ProfileTab {
  DECKS = "decks",
  FOLLOWING = "following",
  CARDS = "cards",
  ARCHIVE = "archive",
  GROUPS = "groups",
  PROTECTED = "your-square-meters",
  MEMBERS = "members",
  GALLERY = "gallery",
  PROFILE = "profile",
  WALLET = "wallet",
}
export const GroupEditorRoles = [GroupMemberRole.OWNER, GroupMemberRole.ADMIN];

export const GroupCuratorRoles = [
  GroupMemberRole.OWNER,
  GroupMemberRole.ADMIN,
  GroupMemberRole.CURATOR,
];

export interface User {
  id: string;
  vanityName: string;
  firstName: string;
  lastName: string;
  fullName: string;
  darkMode: boolean;
  bio?: string;
  tagline?: string;
  profileTagOverride?: string;
  provider: string;
  providerId: string;
  emailAddress: string;
  emailAddressVerified: boolean;
  administrator: boolean;
  joined: Date;
  image: ImageResponseDto;
  cover?: ImageResponseDto;
  details?: UserDetails;
  termsAccepted: boolean;
  termsExpired: boolean;
  cookieConsent: CookieConsent;
  userCounts: IUserCounts;
  website?: string;
  twitterUsername?: string;
  facebookUsername?: string;
  instagramUsername?: string;
  linkedInUsername?: string;
  photoState: "SOCIAL" | "INITIALS" | "CUSTOM";
  presentation?: Presentation;
  curator: boolean;
  featuredCard?: CardRegular;

  featureSource?: UserFeatureSource;
  isPublished?: boolean;
  metaTags?: { [tagName: string]: string };

  isEmailPublic?: boolean;
  groupMemberAccepted?: boolean;
  groupMemberRole?: GroupMemberRole;
  group?: GroupInfo;
  isDisabledProtect?: boolean;
  isNewUser?: boolean;
  forceUseTagLine?: boolean;
  enableGiftLastUon: boolean;
}

export interface UserProfile {
  id: string;
  vanityName: string;
  firstName: string;
  lastName: string;
  fullName: string;
  darkMode: boolean;
  bio?: string;
  provider: string;
  providerId: string;
  emailAddress: string;
  emailAddressVerified: boolean;
  administrator: boolean;
  joined: Date;
  image: ImageResponseDto;
  cover?: ImageResponseDto;
  details?: UserDetails;
  termsAccepted: boolean;
  termsExpired: boolean;
  cookieConsent: string;
  userCounts: IUserCounts;
  website?: string;
  twitterUsername?: string;
  facebookUsername?: string;
  instagramUsername?: string;
  linkedInUsername?: string;
  photoState: "SOCIAL" | "INITIALS" | "CUSTOM";
  presentation?: Presentation;
  curator: boolean;
  featuredCard?: ICardFeatures;
  isPublished: boolean;
  metaTags?: { [tagName: string]: string };
}

export type IUserCounts = {
  curatedDecks: number;
  followingDecks: number;
  starredCards: number;
  draftingCards: number;
  publishedCards: number;
  notifications: number;
  remainingCuratedDecksCount?: number;
  remainingDraftingCardsCount?: number;
  uonCount: number; // number of donated "units of nature" (m^2) on uon.store platform
  campaignCards?: number;
  followerCounts?: number;
  receivedUonCount?: number;
};

export interface Presentation {
  headerAngle: string;
  headerColor: string;
}

export interface DecksAdditionalInfo {
  decks: Deck[];
}

export interface CardsAdditionalInfo {
  content: any;
}

export type AdditionalInfo = CardsAdditionalInfo | DecksAdditionalInfo;

export interface UserDetails {
  name?: string;
  type?: UserType;
  quote?: string;
  uonStoreId?: number;
}

export enum UserType {
  NPO = "NPO",
  BRAND = "BRAND",
  CONSUMER = "CONSUMER",
  CHARITY = "CHARITY",
  UNCLASSIFIED_ORG = "UNCLASSIFIED_ORG",
  GROUP_ADAPTER = "GROUP_ADAPTER",
  COMMUNITY = "COMMUNITY",
  PARTNER = "PARTNER",
}

export enum PhotoType {
  PROFILE = "profile",
  COVER = "cover",
}

export interface UserResponse {
  article?: {
    quote: string;
    cover: string;
    linkSource: string;
    type: string;
  };
  features?: {
    id: string;
    type: string;
    comment: string;
    title: string;
    deck: { name: string; path: string[] };
    source: string;
    metaData: { starsCount: number; votesSum: number };
    published: string;
    curator: { name: string; vanityName: string };
    category: string;
  };
  identity: {
    id: string;
    image: string;
    fullName: string;
    type: string;
    curatedDecks: number;
    publishedCards: number;
    bio: string;
    headerColor: string;
    facebookUsername?: string;
    website?: string;
    instagramUsername?: string;
    twitterUsername?: string;
    linkedInUsername?: string;
  };
}

export type IUserFacebook = {
  email?: string;
  name: string;
  id: string;
  first_name: string;
  last_name: string;
  picture: {
    data: {
      height: number;
      is_silhouette: boolean;
      url: string;
      width: number;
    };
  };
};

export type IMemberUserInfo = {
  fullName: string;
  photo: string;
};
