/* eslint-disable react/destructuring-assignment */
import appStore from "../../assets/img/download-on-app-store.png";
import appStoreWhite from "../../assets/img/download-on-app-store-white.png";
import googlePlay from "../../assets/img/download-on-google-play.png";
import googlePlayWhite from "../../assets/img/download-on-google-play-white.png";
import { sc } from "./DownloadAppButton.styled";
interface IProps {
  store: "app-store" | "google-play";
  width: number;
  height: number;
  storeUrl?: string;
  dataCy?: string;
  buttonStyle?: "black" | "white";
}

export const DownloadAppButton = (props: IProps) => {
  const {
    store,
    width,
    height,
    storeUrl = "",
    dataCy,
    buttonStyle = "black",
  } = props;
  let imageUrl: string;
  if (store === "app-store") {
    imageUrl = buttonStyle === "black" ? appStore : appStoreWhite;
  } else {
    imageUrl = buttonStyle === "black" ? googlePlay : googlePlayWhite;
  }
  const pointerEvents = storeUrl ? "auto" : "none";

  return (
    <sc.Container
      height={height}
      width={width}
      pointerEvents={pointerEvents}
      data-cy={dataCy || `DownloadAppButton-${store}`}
    >
      <sc.StoreLink imageUrl={imageUrl} href={storeUrl} target="_blank">
        &nbsp;
      </sc.StoreLink>
      {!storeUrl && <sc.ComingSoonBanner>Coming Soon</sc.ComingSoonBanner>}
    </sc.Container>
  );
};
