/* eslint-disable unicorn/prefer-array-some */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable unicorn/prefer-spread */
import { DeckDetailResponseDto } from "@earthtoday/contract";
import { AxiosError } from "axios";
import {
  action,
  computed,
  flow,
  IObservableArray,
  makeObservable,
  observable,
} from "mobx";
import Router from "next/router";
import { toFlowGeneratorFunction } from "to-flow-generator-function";

import { NewCategoryType } from "../../components/ModalCreateCard/ModalCreateCardCategories";
import { IUserSessionStore } from "../../components/ModalLogin/UserSessionStore";
import {
  ErrorMessage,
  ITheMessageStore,
  ToastMessageStatus,
} from "../../components/TheMessage/TheMessageStore";
import { ICreateCardApi } from "../../shared/apis/CreateCardApi";
import { IDeckDetailApi } from "../../shared/apis/DeckDetailApi";
import { IProfileApi } from "../../shared/apis/ProfileApi";
import {
  CARD_WHY_MAX_LENGTH,
  DECK_DESCRIPTION_MAX_LENGTH,
  DECK_NAME_REGEX,
} from "../../shared/constants";
import { ValidationCheck } from "../../shared/helpers/commonValidations";
import { deepObjectEqual } from "../../shared/helpers/deepObjectEqual";
import { isPdfUrl } from "../../shared/helpers/isPdfUrl";
import { isValidUrl } from "../../shared/helpers/isValidUrl";
import { parseVanityNameAndDeckLink } from "../../shared/helpers/parseVanityNameAndDeckLink";
import { parseVanityNameAndProfileLink } from "../../shared/helpers/parseVanityNameAndProfileLink";
import {
  ErrorAPIType,
  getAPIErrorMessage,
  getAPIErrorType,
  translateAPIError,
} from "../../shared/helpers/translateApiError";
import {
  CardArticleLayoutType,
  CardState,
  CardType,
} from "../../shared/models/Card";
import { Deck, DeckItemInfo } from "../../shared/models/Deck";
import { ProfileTab, User, UserType } from "../../shared/models/User";
import { logger } from "../../shared/requireCrossEnv";
import {
  snowplowCaptureCardCreatedEvent,
  snowplowCaptureDeckCreatedEvent,
} from "../../shared/snowplow";
import { AutoPlayDeckStore } from "../AutoplayDeckStore";
import CardDeckOverviewModel, {
  DeckSize,
  ProfileRepost,
} from "../CardDeckOverviewModel";
import CardDetailPresenter from "../CardDetailPresenter";
import DeckDetailStore from "../DeckDetailStore/DeckDetailStore";
import FeatureFlaggingStore from "../FeatureFlaggingStore";
import { IModalStore } from "../ModalStore";
import {
  CardDraftEditData,
  ProfilePagePresenter,
} from "../ProfilePagePresenter";
import { ITokenStore } from "../TokenStore";
import { transformCardUrl } from "../transformCardUrl";
import { UserModel } from "../UserModel";

export type VanityNameAndDeckLinkName = {
  isDeckUrl: boolean;
  vanityName: string;
  deckName: string;
};

export type VanityNameAndProfileLinkName = {
  isProfileUrl: boolean;
  vanityName: string;
};

export enum ICreateCardType {
  LINK = "link",
  DECK_REPOST = "deck_repost",
  PROFILE_REPOST = "profile_repost",
  PROMOTION_REPOST_BY_PROMOTION = "PROMOTION_REPOST_BY_PROMOTION",
}
export type CreateCardData = {
  decks: IObservableArray<DeckItemInfo>;
};

export type InitialStateEdit = {
  cardWhy: string;
  categoryName: string;
  deckId: string | null;
  url: string;
  cardId: string;
  actionType: CardType.LINK_REPOST | "draft";
  stateCard: "drafting" | "";
  cardLayout: CardArticleLayoutType;
  cardType?: ICreateCardType;
  deckRepost?: DeckDetailResponseDto;
  profileRepost?: User;
};

export type InitialStateRepost = {
  cardRepostId: string;
  updateCommentForSaveCard?(): void;
  cardLayout: CardArticleLayoutType;
};

export type InitialStateSaveDeck = {
  deckUrl: string;
};

export type InitialState =
  | InitialStateEdit
  | InitialStateRepost
  | InitialStateSaveDeck;

export function isInitialStateRepost(
  state: InitialState,
): state is InitialStateRepost {
  return (state as InitialStateRepost).cardRepostId !== undefined;
}
export function isInitialStateSaveDeck(
  state: InitialState,
): state is InitialStateSaveDeck {
  return (state as InitialStateSaveDeck).deckUrl !== undefined;
}
export function isInitialStateEdit(
  state: InitialState | null,
): state is InitialStateEdit {
  return (
    state !== null &&
    !isInitialStateRepost(state) &&
    !isInitialStateSaveDeck(state)
  );
}

export class CreateCardStore {
  private cardRepostId = "";

  @observable stateCard = "";

  @observable url = "";

  @observable urlTouched = false;

  @observable newDeckName = "";

  @observable newDeckNameTouched = false;

  @observable newDeckDescription = "";

  @observable newDeckDescriptionTouched = false;

  @observable isAddingNewDeck = false;

  @observable cardWhy = "";

  @observable cardWhyTouched = false;

  @observable selectedDeckId = "";

  @observable selectedCardCategoryName = "";

  @observable selectedDeckCategoryName = "";

  @observable cardDraftId = "";
  @observable isModalCreateDeckVisible = false;

  @observable decks: IObservableArray<DeckItemInfo> = observable<DeckItemInfo>(
    [],
  );

  @observable newPrincipleCategories: IObservableArray<NewCategoryType> =
    observable<NewCategoryType>([]);

  @observable createDeckErrorMessage: ErrorMessage = null;

  @observable createCardSubmitting = false;

  @observable createCardErrorMessage: ErrorMessage = null;

  @observable createdCardResponse: any = null; // Can Defined Type, related with many store

  @observable isSubmitting = false;

  @observable showDeckCategories = false;
  @observable showCardCategories = false;

  @observable isCardDetail = false;
  @observable showDeckNameInputDialog = false;
  @observable showDecks = false;
  @observable isFetchingData = false;

  @observable isNewDeck: boolean = false;
  @observable showCardCategoryDialog: boolean = false;
  @observable showCreateCardDialog: boolean = false;

  @observable initSelectedCardCategoryName: string = "";
  @observable deckModel: CardDeckOverviewModel | null = null;

  @observable isModalCreateCard: boolean = true;

  @observable profileRepost: ProfileRepost | null = null;
  @observable isUnpublishedUrl: boolean = false;
  @observable isInValidProfileUrl: boolean = false;
  @observable isDeckNotFound: boolean = false;

  constructor(
    readonly initialState: InitialState | null,
    private createCardAPI: ICreateCardApi,
    private modalStore: IModalStore,
    private theMessageStore: ITheMessageStore,
    private userSessionStore: IUserSessionStore,
    private featureFlagStore: FeatureFlaggingStore,
    private deckDetailApi: IDeckDetailApi,
    private autoplayDeckStore: AutoPlayDeckStore,
    private profileApi: IProfileApi,
    private tokenStore: ITokenStore,
    private profileStore?: ProfilePagePresenter,
    private deckDetailStore?: DeckDetailStore,
    private cardDetailStore?: CardDetailPresenter,
  ) {
    makeObservable(this);
    if (!initialState) {
      return;
    }
    if (isInitialStateRepost(initialState)) {
      this.cardRepostId = initialState.cardRepostId;
      return;
    }
    if (isInitialStateSaveDeck(initialState)) {
      this.url = initialState.deckUrl;
      return;
    }

    if (initialState.deckId) {
      this.selectDeck(initialState.deckId);
    }

    if (initialState.deckRepost) {
      this.deckModel = new CardDeckOverviewModel(
        this.modalStore,
        { onClose: () => {}, onDelete: () => {}, updateDeckDelete: () => {} },
        this.autoplayDeckStore,
        this.theMessageStore,
        this.userSessionStore,
        this,
        initialState.deckRepost.data,
        this.featureFlagStore,
        this.deckDetailApi,
        DeckSize.Modal,
      );
    }

    if (initialState.profileRepost) {
      this.profileRepost = new UserModel(
        initialState.profileRepost,
        this.userSessionStore,
        this.tokenStore,
      );
    }

    this.updateCardWhy(initialState.cardWhy);
    this.selectCardCategory(initialState.categoryName);
    this.updateStateCard(initialState.stateCard);
    this.initSelectedCardCategoryName = initialState.categoryName;

    this.updateUrl(initialState.url);
    if (initialState.actionType === CardType.LINK_REPOST) {
      this.cardRepostId = initialState.cardId;
    } else {
      this.updateCardDraftId(initialState.cardId);
    }
  }

  public dehydrate(): CreateCardData {
    return {
      decks: this.decks,
    };
  }

  @action.bound public hydrate(data: CreateCardData) {
    this.decks.replace(data.decks);
  }

  @computed get decksWithDisabled(): IObservableArray<
    DeckItemInfo & { disabled: boolean }
  > {
    return observable(
      this.decks.map((deck) => {
        const isSameDeck =
          !!this.deckModel && this.deckModel.deck.id === deck.id;
        return { ...deck, disabled: isSameDeck };
      }),
    );
  }

  @computed get modalTitle(): string {
    if (this.isEditCard && !this.deckModel) {
      return "modalcreatecard.title.edit-card";
    }

    if (this.stateCard === "drafting") {
      return "modalcreatecard.title.edit-draft-card";
    }

    if (this.isSaveMode) {
      return "modalcreatecard.title.save-card";
    }
    return "modalcreatecard.title.create-card";
  }

  @computed get isSaveMode(): boolean {
    return !!this.cardRepostId;
  }

  @computed get isEditDraftMode(): boolean {
    return !!this.cardDraftId;
  }

  @computed get isSaveOrEditDraftMode(): boolean {
    return this.isSaveMode || this.isEditDraftMode;
  }
  @computed get deckNameRule(): string {
    if (this.newDeckName === "") {
      return "#EnterNameOfDeck";
    }

    if (this.newDeckName !== "" && !this.newDeckName.includes("#")) {
      return `#${this.newDeckName}`;
    }

    return this.newDeckName;
  }

  @computed get isSameDeck(): boolean {
    return !!this.deckModel && this.deckModel.deck.id === this.selectedDeckId;
  }

  @computed get selectDeckPlaceholder(): string {
    if (this.isSameDeck) {
      return "modalcreatecard.placeholder.deck-save";
    }
    if (this.decks.length === 0 && !this.isFetchingData) {
      return "modalcreatecard.placeholder.deck-category";
    }
    return this.deckName || "modalcreatecard.placeholder.deck-save";
  }

  @computed get isUrlVisible(): boolean {
    if (this.isEditCard) {
      return true;
    }
    return !this.isSaveOrEditDraftMode;
  }

  @computed get selectedDeckPrinciple() {
    return this.newPrincipleCategories.find(
      (principle) => principle.name === this.selectedDeckCategoryName,
    );
  }

  @computed get selectedCardPrinciple() {
    const principle = this.newPrincipleCategories.find(
      (p) => p.name === this.selectedCardCategoryName,
    );
    return principle;
  }

  @computed get initSelectedCardPrinciple() {
    const principle = this.newPrincipleCategories.find(
      (p) => p.name === this.initSelectedCardCategoryName,
    );
    if (principle) return principle;
  }

  @computed get cardLayout(): CardArticleLayoutType {
    if (this.initialState && isInitialStateSaveDeck(this.initialState)) {
      return CardArticleLayoutType.EDITORIAL;
    }
    return this.initialState?.cardLayout || CardArticleLayoutType.EDITORIAL;
  }

  @action.bound updateStateCard(state: "drafting" | ""): void {
    this.stateCard = state;
  }

  @action.bound close(): void {
    this.modalStore.closeLazyModal();
    this.url = "";
    this.cardWhy = "";
    this.selectedCardCategoryName = "";
    this.selectedDeckId = "";
  }

  @action.bound updateDecks(decks: Deck[]): void {
    this.decks.replace(decks);
  }

  @action.bound updateUrl(url: string): void {
    this.url = url.trim();
  }

  @action.bound updateUrlTouched(touched: boolean): void {
    this.urlTouched = touched;
  }

  @action.bound updateNewDeckName(newDeckName: string): void {
    this.createDeckErrorMessage = null;
    this.newDeckName = newDeckName;
  }

  @action.bound updateNewDeckDescription(newDeckDescription: string): void {
    this.newDeckDescription = newDeckDescription;
  }

  @action.bound toggleAddingDeck(): void {
    if (!this.userSessionStore.isCurator) return;
    this.isAddingNewDeck = !this.isAddingNewDeck;
  }

  @action.bound updateCardWhy(cardWhy: string): void {
    this.cardWhy = cardWhy;
  }

  @action.bound updateCardDraftId(cardDraftId: string): void {
    this.cardDraftId = cardDraftId;
  }

  @action.bound fetchData = flow(function* (this: CreateCardStore) {
    try {
      this.isFetchingData = true;
      const newCategories =
        yield this.createCardAPI.fetchNewPrincipleCategories();
      this.newPrincipleCategories.replace(newCategories);

      const decks = yield this.createCardAPI.fetchDecks();
      this.decks.replace(decks);
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    } finally {
      this.isFetchingData = false;
    }
  });

  @action.bound selectDeck(deckId: string): void {
    this.selectedDeckId = deckId;
    if (this.selectedDeck && this.selectedDeck.categoryPrinciples.length > 0) {
      this.selectCardCategory(this.selectedDeck.categoryPrinciples[0]);
    } else {
      this.selectCardCategory("");
    }
    this.showDecks = false;
    this.isAddingNewDeck = false;
  }

  @action.bound selectCardCategory(categoryName: string): void {
    this.selectedCardCategoryName = categoryName;
  }

  @action.bound selectDeckCategory(categoryName: string): void {
    this.selectedDeckCategoryName = categoryName;
    this.selectedCardCategoryName = categoryName;
  }

  @action.bound updateNewDeckNameTouched(touched: boolean): void {
    this.newDeckNameTouched = touched;
  }

  @action.bound updateNewDeckDescriptionTouched(touched: boolean): void {
    this.newDeckDescriptionTouched = touched;
  }

  @action.bound updateCardWhyTouched(touched: boolean): void {
    this.cardWhyTouched = touched;
  }

  @action.bound createDeck = flow(function* (this: CreateCardStore) {
    this.isSubmitting = true;
    try {
      if (!this.selectedDeckPrinciple?.id) {
        logger.error("deck should have principle");
        return;
      }
      const { deckId, linkName } = yield this.createCardAPI.createDeck(
        this.newDeckName.replace("#", ""),
        this.newDeckDescription,
        this.selectedDeckPrinciple.id,
      );
      if (this.deckDetailStore) {
        this.deckDetailStore.userProfile?.updateCuratedDecks(1);
      }
      const minimalDeck: Deck = {
        id: deckId,
        name: this.newDeckName.replace("#", ""),
        path: [],
        description: this.newDeckDescription,
        curator: {
          id: "",
          name: "",
          vanityName: "",
          image: {
            isTransparent: null,
            id: "",
            url: "",
            awsKey: null,
            awsBucket: null,
          },
          currentUser: true,
        },
        created: "",
        cards: [],
        cardsCount: 0,
        following: {
          count: 0,
          following: false,
        },
        followersCount: 0,
        onFeed: false,
        previewImages: [],
        categoryPrinciples: [this.selectedDeckCategoryName],
      };
      this.decks.push(minimalDeck);
      this.selectedDeckId = deckId;

      /* tracking */
      snowplowCaptureDeckCreatedEvent({
        deckId,
        deckName: this.newDeckName,
        linkName,
        category: this.selectedDeckCategory?.name || "",
        description: this.newDeckDescription,
      });

      this.isNewDeck = true;
      this.isAddingNewDeck = false;
      this.newDeckName = "";
      this.newDeckDescription = "";
      this.newDeckNameTouched = false;
    } catch (error) {
      this.createDeckErrorMessage = translateAPIError(error);
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    } finally {
      this.isSubmitting = false;
    }
  });

  @computed get createCardType(): ICreateCardType {
    if (isInitialStateEdit(this.initialState) && this.initialState.cardType) {
      return this.initialState.cardType;
    }
    return this.vanityNameAndDeckLinkName.isDeckUrl && this.isExistingDeck
      ? ICreateCardType.DECK_REPOST
      : this.vanityNameAndProfileLinkName.isProfileUrl && this.profileRepost
      ? ICreateCardType.PROFILE_REPOST
      : ICreateCardType.LINK;
  }

  @action.bound saveAsDraft = flow(function* saveAsDraft(
    this: CreateCardStore,
  ) {
    this.isSubmitting = true;

    if (this.isAddingNewDeck) {
      yield this.createDeck();
      this.modalStore.closeLazyModal();
    }

    if (this.createDeckErrorMessage) {
      return;
    }

    if (this.isEditCard) {
      const data: CardDraftEditData = {
        ...this.cardDraftEditData,
        state: CardState.DRAFTING,
      };
      //revert card published to draft
      const editCardResponse = yield this.createCardAPI.editCard(
        this.cardDraftId || this.cardRepostId,
        data,
      );
      this.deckDetailStore?.updateAfterEditCard(
        this.cardDraftId || this.cardRepostId,
        editCardResponse,
      );

      this.profileStore?.updateAfterEditCard(
        this.cardDraftId || this.cardRepostId,
        editCardResponse,
      );

      this.cardDetailStore?.updateAfterEditCard(
        this.cardDraftId || this.cardRepostId,
        editCardResponse,
      );

      this.modalStore.closeLazyModal();
      if (!this.featureFlagStore.flags.enableWebsocketCardEvents) {
        this.theMessageStore.showMessage({
          typeMessage: "Close",
          title: "toast-message.general.success",
          content: "toast-message.edit-card.content",
        });
      }
      return;
    }
    if (this.cardDraftId && !this.cardRepostId) {
      const data: CardDraftEditData = {
        ...this.cardDraftEditData,
        state: CardState.DRAFTING,
      };
      yield this.profileStore?.onEditCard(this.cardDraftId, data);
      this.modalStore.closeLazyModal();
      this.isSubmitting = false;
      return;
    }
    if (this.stateCard === "drafting" && this.cardRepostId) {
      yield this.profileStore?.onEditRespotCard(
        this.cardRepostId,
        this.selectedCardCategory,
        this.selectedDeck as Deck,
        this.cardWhy,
        CardState.DRAFTING,
        this.selectedCardPrinciple?.id,
      );
      this.modalStore.closeLazyModal();
      this.isSubmitting = false;
      return;
    }

    try {
      if (this.cardRepostId && this.stateCard !== "drafting") {
        this.createdCardResponse = yield this.createCardAPI.createRepostCard(
          this.cardRepostId,
          this.cardWhy,
          this.cardLayout,
          this.selectedDeck?.id,
          true,
          this.selectedCardPrinciple?.name.toUpperCase(),
        );

        snowplowCaptureCardCreatedEvent({
          cardId: this.createdCardResponse.id,
          deckId: this.createdCardResponse.deck?.id || "",
          contentType: this.createdCardResponse.contentType,
          category: this.selectedCardCategory?.name || "",
          state: this.createdCardResponse.state as CardState,
          cardName:
            this.createdCardResponse.content.original?.content.title || "",
          cardWhy: this.createdCardResponse.comment,
        });
      } else {
        this.createdCardResponse = yield this.createCardAPI.createCard(
          this.url,
          this.cardWhy,
          this.createCardType,
          this.selectedDeck?.id,
          true,
          this.selectedCardPrinciple?.name.toUpperCase(),
        );

        snowplowCaptureCardCreatedEvent({
          cardId: this.createdCardResponse.id,
          deckId: this.createdCardResponse.deck?.id || "",
          contentType: this.createdCardResponse.contentType,
          category: this.selectedCardCategory?.name || "",
          state: this.createdCardResponse.state.toLowerCase() as CardState,
          cardWhy: this.createdCardResponse.comment,
          cardName:
            this.createdCardResponse.contentType.toLowerCase() ===
            ICreateCardType.DECK_REPOST
              ? this.createdCardResponse.deckRepost.name
              : this.createdCardResponse.contentType.toLowerCase() ===
                ICreateCardType.PROFILE_REPOST
              ? this.createdCardResponse.profileRepost.vanityName
              : this.createdCardResponse.content.title,
        });

        if (this.profileStore?.currentTab === "archive") {
          this.profileStore?.optimisticCreateDraftUpdate(
            this.createdCardResponse,
          );
        }
      }

      this.modalStore.closeLazyModal();

      if (!this.featureFlagStore.flags.enableWebsocketCardEvents) {
        this.theMessageStore.showMessage({
          typeMessage: "Action",
          title: "toast-message.save-card-as-draft.title",
          status: ToastMessageStatus.SUCCESS,
          content: "toast-message.save-card-as-draft.content",
          actions: [
            {
              key: "cards",
              name: "Go to Drafts",
              href: "/[vanityName]",
              as: `/${this.createdCardResponse.curator.vanityName}?tab=${ProfileTab.ARCHIVE}`,
            },
          ],
        });
      }

      if (!this.profileStore) return;
      if (this.profileStore.currentTab === "archive") {
        this.profileStore.addNewCard(this.createdCardResponse);
      }
    } catch (error) {
      this.modalStore.closeLazyModal();
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    } finally {
      this.isSubmitting = false;

      if (this.profileStore) {
        yield this.profileStore?.onFetchUserCounts({ clearCache: true });
      } else {
        this.userSessionStore.user?.updateDraftingCardCounts(1);
      }
    }
  });

  @computed get shouldEditCard(): boolean {
    if (isInitialStateEdit(this.initialState)) {
      const oldData = {
        selectedCardPrinciple: this.initSelectedCardPrinciple?.id,
        cardWhy: this.initialState.cardWhy,
        categoryName: this.initSelectedCardPrinciple?.id
          ? ""
          : this.initialState.categoryName.toUpperCase(),
        deckId: this.initialState.deckId,
      };
      const newData = {
        selectedCardPrinciple: this.selectedCardPrinciple?.id,
        cardWhy: this.cardDraftEditData.comment,
        categoryName: this.cardDraftEditData.principleName
          ? this.cardDraftEditData.principleName.toUpperCase()
          : "",
        deckId: this.isAddingNewDeck ? "" : this.cardDraftEditData.deck,
      };
      return !deepObjectEqual(oldData, newData);
    }
    return true;
  }

  @computed get isDisabledEditCard(): boolean {
    if (
      this.isUnpublishedUrl ||
      this.isInValidProfileUrl ||
      this.isSameDeck ||
      this.isUnpublishedGroupUrl
    ) {
      return true;
    }

    return (
      (!this.shouldEditCard && this.isEditCard) ||
      !this.canPublish ||
      this.isSubmitting
    );
  }

  @computed get isOwnedDeck(): boolean {
    if (this.deckModel && !this.url.includes(this.deckModel.vanityName)) {
      return false;
    }
    return (
      this.deckModel?.vanityName === this.userSessionStore.user?.vanityName
    );
  }

  @computed get vanityNameAndDeckLinkName(): VanityNameAndDeckLinkName {
    if (!this.urlValid) {
      return { isDeckUrl: false, vanityName: "", deckName: "" };
    }

    return parseVanityNameAndDeckLink(this.url);
  }

  @computed get isExistingDeck(): boolean {
    return (
      !!this.deckModel &&
      (!!this.deckModel.deck.curator.currentUser ||
        !!this.deckModel.deck.curator.isPublished)
    );
  }

  @action.bound fetchDeck = flow(function* (this: CreateCardStore) {
    try {
      if (
        isInitialStateEdit(this.initialState) &&
        this.initialState.deckRepost
      ) {
        return;
      }

      this.isInValidProfileUrl = false;
      this.isUnpublishedUrl = false;
      this.isDeckNotFound = false;

      const res = yield* toFlowGeneratorFunction(
        this.deckDetailApi.fetchDeckDetail,
      )(
        this.vanityNameAndDeckLinkName.vanityName,
        this.vanityNameAndDeckLinkName.deckName,
      );

      this.deckModel = new CardDeckOverviewModel(
        this.modalStore,
        { onClose: () => {}, onDelete: () => {}, updateDeckDelete: () => {} },
        this.autoplayDeckStore,
        this.theMessageStore,
        this.userSessionStore,
        this,
        res.data,
        this.featureFlagStore,
        this.deckDetailApi,
        DeckSize.Modal,
      );

      if (this.isSameDeck) {
        this.setShowDecks(true);
      }
    } catch (error) {
      this.deckModel = null;
      const errorType = getAPIErrorType(error as AxiosError);
      const errorMessage = getAPIErrorMessage(error as AxiosError);
      if (
        errorType === ErrorAPIType.DeckNotFound &&
        errorMessage === "Deck not found."
      )
        return (this.isDeckNotFound = true);
    }
  });

  @action.bound toggleFollowing() {}
  @action.bound editDeck() {}
  @action.bound editCard = flow(function* (this: CreateCardStore) {
    const data: CardDraftEditData = {
      ...this.cardDraftEditData,
      state: CardState.PUBLISHED,
    };
    const editCardResponse = yield this.createCardAPI.editCard(
      this.cardDraftId || this.cardRepostId,
      data,
    );
    this.deckDetailStore?.updateAfterEditCard(
      this.cardDraftId || this.cardRepostId,
      editCardResponse,
    );
    this.profileStore?.updateAfterEditCard(
      this.cardDraftId || this.cardRepostId,
      editCardResponse,
    );
    this.cardDetailStore?.updateAfterEditCard(
      this.cardDraftId || this.cardRepostId,
      editCardResponse,
    );

    this.modalStore.closeLazyModal();
    if (!this.featureFlagStore.flags.enableWebsocketCardEvents) {
      this.theMessageStore.showMessage({
        typeMessage: "Close",
        title: "toast-message.edit-card.title",
        content: "toast-message.edit-card.content",
      });
    }
  });

  @action.bound save = flow(function* (this: CreateCardStore) {
    this.isSubmitting = true;

    try {
      if (
        this.createCardType === ICreateCardType.DECK_REPOST &&
        this.isExistingDeck
      ) {
        if (this.initialState && !isInitialStateSaveDeck(this.initialState)) {
          this.createdCardResponse = yield this.profileStore?.onEditCard(
            this.cardDraftId,
            {
              ...this.cardDraftEditData,
              state: CardState.PUBLISHED,
            },
          );
          this.modalStore.closeLazyModal();

          return;
        }

        yield this.createNewCard();
        this.closeModalAndSuccessMessage();
        this.addNewCardToUI();
        return;
      }

      if (
        this.createCardType === ICreateCardType.PROFILE_REPOST &&
        this.profileRepost
      ) {
        if (this.initialState && !isInitialStateSaveDeck(this.initialState)) {
          this.createdCardResponse = yield this.profileStore?.onEditCard(
            this.cardDraftId,
            {
              ...this.cardDraftEditData,
              state: CardState.PUBLISHED,
            },
          );
          this.modalStore.closeLazyModal();

          return;
        }

        yield this.createNewCard();
        this.closeModalAndSuccessMessage();
        this.addNewCardToUI();
        return;
      }

      if (this.isAddingNewDeck) {
        yield this.createDeck();
        this.modalStore.closeLazyModal();
      }

      if (this.createDeckErrorMessage) {
        return;
      }

      if (this.isEditCard) {
        yield this.editCard();
        return;
      }

      if (this.cardDraftId) {
        this.createdCardResponse = yield this.profileStore?.onEditCard(
          this.cardDraftId,
          {
            ...this.cardDraftEditData,
            state: CardState.PUBLISHED,
          },
        );
        this.modalStore.closeLazyModal();

        return;
      }
      if (this.cardRepostId && this.stateCard === "drafting") {
        this.createdCardResponse = yield this.profileStore?.onEditCard(
          this.cardRepostId,
          {
            ...this.cardDraftEditData,
            state: CardState.PUBLISHED,
          },
        );

        this.modalStore.closeLazyModal();

        return;
      }

      if (this.cardRepostId) {
        this.createdCardResponse = yield this.createCardAPI.createRepostCard(
          this.cardRepostId,
          this.cardWhy,
          this.cardLayout,
          this.selectedDeck?.id,
          false,
          this.selectedCardPrinciple?.name.toUpperCase(),
        );

        snowplowCaptureCardCreatedEvent({
          cardId: this.createdCardResponse.id,
          deckId: this.createdCardResponse.deck.id,
          contentType: this.createdCardResponse.contentType,
          category: this.selectedCardCategory?.name || "",
          state: this.createdCardResponse.state as CardState,
          cardName:
            this.createdCardResponse.content.original?.content.title || "",
          cardWhy: this.createdCardResponse.comment,
        });

        if (
          this.initialState &&
          isInitialStateRepost(this.initialState) &&
          this.initialState.updateCommentForSaveCard
        ) {
          this.initialState.updateCommentForSaveCard();
        }
      } else {
        yield this.createNewCard();
      }
      this.closeModalAndSuccessMessage();
      this.addNewCardToUI();
    } catch (error) {
      this.modalStore.closeLazyModal();
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    } finally {
      yield this.profileStore?.onFetchUserCounts({ clearCache: true });
      this.isSubmitting = false;
      this.isNewDeck = false;
    }
  });

  @action.bound closeModalAndSuccessMessage = () => {
    this.modalStore.closeLazyModal();
    if (!this.featureFlagStore.flags.enableWebsocketCardEvents) {
      if (this.createdCardResponse.state === CardState.DRAFTING) {
        this.theMessageStore.showMessage({
          typeMessage: "Action",
          title: "toast-message.save-card-as-draft.title",
          status: ToastMessageStatus.SUCCESS,
          content: "toast-message.save-card-as-draft.content",
          actions: [
            {
              key: "cards",
              name: "Go to Drafts",
              href: "/[vanityName]",
              as: `/${this.createdCardResponse.curator.vanityName}?tab=${ProfileTab.ARCHIVE}`,
            },
          ],
        });
        return;
      }

      this.theMessageStore.showMessage({
        typeMessage: "Action",
        title: "toast-message.create-card-success.title",
        status: ToastMessageStatus.SUCCESS,
        content: `Your Card has been added to ${this.createdCardResponse.deck.name}`,
        actions: [
          {
            key: "cards",
            name: "View Card in Deck",
            href: "/[vanityName]/[deckName]",
            as: `/${this.createdCardResponse.deck.path.join("/")}`,
          },
        ],
      });
    }
  };

  @action.bound addNewCardToUI = () => {
    // only addNewCard if deck current on deck detail page
    if (
      this.deckDetailStore &&
      this.deckDetailStore.getDeckId() === this.selectedDeckId
    ) {
      this.deckDetailStore.addNewCard(this.createdCardResponse);
      return;
    }

    if (!this.profileStore) return;
    if (this.profileStore.currentTab === ProfileTab.CARDS) {
      this.profileStore.addNewCard(this.createdCardResponse);
    }
  };

  @action.bound createNewCard = flow(function* (this: CreateCardStore) {
    this.createdCardResponse = yield this.createCardAPI.createCard(
      transformCardUrl(this.url),
      this.cardWhy,
      this.createCardType,
      this.selectedDeck?.id,
      false,
      this.selectedCardPrinciple?.name.toUpperCase(),
    );

    snowplowCaptureCardCreatedEvent({
      cardId: this.createdCardResponse.id,
      deckId: this.createdCardResponse.deck.id,
      contentType: this.createdCardResponse.contentType,
      category: this.selectedCardCategory?.name || "",
      state: this.createdCardResponse.state as CardState,
      cardWhy: this.createdCardResponse.comment,
      cardName:
        this.createdCardResponse.contentType.toLowerCase() ===
        ICreateCardType.DECK_REPOST
          ? this.createdCardResponse.deckRepost.name
          : this.createdCardResponse.contentType.toLowerCase() ===
            ICreateCardType.PROFILE_REPOST
          ? this.createdCardResponse.profileRepost.vanityName
          : this.createdCardResponse.content.title,
    });

    if (!this.profileStore) return;

    if (
      (this.profileStore.currentTab === ProfileTab.DECKS ||
        this.profileStore.currentTab === ProfileTab.PROFILE) &&
      !!this.userSessionStore.user &&
      Router.asPath.slice(1) === this.userSessionStore.user.vanityName
    ) {
      this.profileStore.optimisticCardCounts(this.createdCardResponse.deck.id);
      yield this.isNewDeck &&
        this.profileStore.fetchDecks(this.userSessionStore.user.vanityName);
    }
  });

  @action updateShowDeckCategories = (b: boolean): void => {
    this.showDeckCategories = b;
  };

  @action updateShowCardCategories = (b: boolean): void => {
    this.showCardCategories = b;
  };

  @action toggleShowCardCategoryDialog = (): void => {
    this.showCardCategoryDialog = !this.showCardCategoryDialog;
  };

  @action toggleShowCreateCardDialog = (): void => {
    this.showCreateCardDialog = !this.showCreateCardDialog;
  };
  @computed get selectedDeck(): DeckItemInfo | undefined {
    if (!this.selectedDeckId) {
      return;
    }

    return this.decks.find((deck) => deck.id === this.selectedDeckId);
  }

  @computed get categoryTypeLabel(): string {
    const { user } = this.userSessionStore;

    if (!user) {
      return "";
    }

    if (user.userType === UserType.CONSUMER) {
      return "Arcanas";
    }

    return "GlobalGoals";
  }

  @computed get selectedCardCategory(): NewCategoryType | null {
    if (!this.selectedCardCategoryName) {
      return null;
    }

    const principle = this.newPrincipleCategories.find(
      (p) =>
        p.name.toUpperCase() === this.selectedCardCategoryName.toUpperCase(),
    );

    if (!principle) {
      return null;
    }
    return principle;
  }

  @computed get selectedDeckCategory(): NewCategoryType | null {
    if (!this.selectedDeckCategoryName) {
      return null;
    }

    const cat = this.newPrincipleCategories.find(
      (c) => c.name === this.selectedDeckCategoryName,
    );
    if (!cat) {
      return null;
    }

    return cat;
  }

  @computed get deckName(): string {
    if (!this.selectedDeck) {
      return "";
    }
    return this.selectedDeck.name;
  }

  @computed get categoryNameCard(): string {
    if (!this.selectedCardCategory) {
      return "";
    }

    if ("description" in this.selectedCardCategory) {
      return this.selectedCardCategory.description;
    }

    return "";
  }

  @computed get isCategoriesDropdownDisabled(): boolean {
    return !this.selectedDeck && !this.selectedDeckCategory;
  }

  @computed get categoryNameDeck(): string {
    if (!this.selectedDeckCategory) {
      return "";
    }

    if ("description" in this.selectedDeckCategory) {
      return this.selectedDeckCategory.description;
    }

    return "";
  }

  @computed get selectedCategoryNameCard(): string {
    if (!this.selectedCardCategory) {
      return "";
    }

    return this.selectedCardCategory.name || "";
  }

  @computed get selectedCategoryNameDeck(): string {
    if (!this.selectedDeckCategory) {
      return "";
    }

    return this.selectedDeckCategory.name || "";
  }

  @computed get urlValid(): boolean {
    return isValidUrl(this.url);
  }

  @computed get isPdfUrl(): boolean {
    return isPdfUrl(this.url);
  }

  @computed get newDeckNameValid(): boolean {
    return DECK_NAME_REGEX.test(this.newDeckName);
  }

  @computed get newDeckDescriptionGetterLength(): boolean {
    return this.newDeckDescription.length > DECK_DESCRIPTION_MAX_LENGTH;
  }

  @computed get cardWhyGetterLength(): boolean {
    return this.cardWhy.length > CARD_WHY_MAX_LENGTH;
  }

  @computed get canSaveAsDraft(): boolean {
    if (
      this.isInValidProfileUrl ||
      this.isUnpublishedUrl ||
      this.isUnpublishedGroupUrl
    )
      return false;

    if (
      this.isAddingNewDeck &&
      (!this.newDeckName || !this.selectedDeckCategoryName)
    ) {
      return false;
    }
    return (
      (this.urlValid && !!this.url) ||
      !!this.cardRepostId ||
      !!this.deckModel ||
      !!this.profileRepost
    );
  }

  @computed get isDeckInfoValid(): boolean {
    if (this.isAddingNewDeck) {
      return (
        !!this.newDeckName &&
        this.newDeckNameValid &&
        !this.newDeckDescriptionGetterLength
      );
    }

    return !!this.selectedDeckId;
  }

  @computed get canPublish(): boolean {
    if (
      this.isAddingNewDeck &&
      (!this.newDeckName || !this.selectedDeckCategoryName)
    ) {
      return false;
    }
    if (this.createDeckErrorMessage) {
      return false;
    }
    if (this.isSaveOrEditDraftMode) {
      return !!this.selectedCardCategory && this.isDeckInfoValid;
    }

    const withoutDeckValid =
      this.urlValid &&
      !!this.selectedCardCategory &&
      !!this.url &&
      !this.cardWhyGetterLength;

    return withoutDeckValid && this.isDeckInfoValid;
  }

  @computed get isValidToSaveDeck(): boolean {
    return (
      !!this.newDeckName &&
      this.newDeckNameValid &&
      !this.newDeckDescriptionGetterLength &&
      !!this.selectedDeckCategoryName
    );
  }

  @computed get saveDeckCanPublish(): boolean {
    if (
      this.isAddingNewDeck &&
      (!this.newDeckName || !this.selectedDeckCategoryName)
    ) {
      return false;
    }
    if (this.createDeckErrorMessage) {
      return false;
    }
    if (this.isSaveOrEditDraftMode) {
      return this.isDeckInfoValid;
    }

    const withoutDeckValid =
      this.urlValid && !!this.url && !this.cardWhyGetterLength;

    return withoutDeckValid && this.isDeckInfoValid;
  }

  @computed get cardDraftEditData(): CardDraftEditData {
    return {
      principleId: this.selectedCardPrinciple?.id,
      principleName:
        !this.selectedCardPrinciple?.id && this.selectedCardCategoryName
          ? this.selectedCardCategoryName.toLowerCase()
          : undefined,
      deck: this.selectedDeckId || undefined,
      content: {
        type: this.createCardType,
        url: this.url,
      },
      comment: this.cardWhy,
    };
  }

  @computed get newDeckNameFeedback(): ValidationCheck {
    if (!this.isAddingNewDeck) {
      return {
        valid: true,
        msg: "",
      };
    }

    if (!this.newDeckNameTouched) {
      return {
        valid: true,
        msg: "",
      };
    }

    if (!this.newDeckNameValid) {
      return {
        valid: false,
        msg: "modalcreatecard.invalid.deck-name",
      };
    }

    return {
      valid: true,
      msg: "",
    };
  }

  @computed get newDeckDescriptionFeedback(): ValidationCheck {
    if (!this.newDeckDescriptionTouched) {
      return {
        valid: true,
        msg: "",
      };
    }

    if (this.newDeckDescriptionGetterLength) {
      return {
        valid: false,
        msg: "modalcreatecard.invalid.deck-description",
        vars: {
          length: DECK_DESCRIPTION_MAX_LENGTH,
        },
      };
    }

    return {
      valid: true,
      msg: "",
    };
  }

  @computed get cardWhyFeedback(): ValidationCheck {
    if (!this.cardWhyTouched) {
      return {
        valid: true,
        msg: "",
      };
    }

    if (this.cardWhyGetterLength) {
      return {
        valid: false,
        msg: "modalcreatecard.invalid.card-why",
        vars: {
          length: CARD_WHY_MAX_LENGTH,
        },
      };
    }

    return {
      valid: true,
      msg: "",
    };
  }

  @computed get showNewDeckNameHint(): boolean {
    if (!this.newDeckName) {
      return false;
    }

    return true;
  }

  @action updateIsCardDetail = (b: boolean): void => {
    this.isCardDetail = b;
  };

  @action toggleShowDeckNameInputDialog = (): void => {
    this.showDeckNameInputDialog = !this.showDeckNameInputDialog;
  };

  @action setShowDecks = (b: boolean): void => {
    this.showDecks = b;
  };

  @action.bound toggleModalCreateDeckVisible = () => {
    if (!this.userSessionStore.isCurator) return;
    this.isModalCreateDeckVisible = !this.isModalCreateDeckVisible;
  };

  @computed get isEditCard(): boolean {
    if (!this.initialState) return false;
    if (
      this.profileStore?.currentTab === ProfileTab.CARDS &&
      this.profileStore?.isLoggedinUser
    ) {
      return true;
    }
    if (!!this.deckDetailStore && this.deckDetailStore.isLoggedinUser) {
      return true;
    }
    if (!!this.cardDetailStore && this.cardDetailStore.isCurrentUser) {
      return true;
    }
    return false;
  }

  @computed get vanityNameAndProfileLinkName(): VanityNameAndProfileLinkName {
    if (!this.urlValid) {
      return { isProfileUrl: false, vanityName: "" };
    }
    return parseVanityNameAndProfileLink(this.url);
  }

  @computed get isExistingProfile(): boolean {
    return !!this.profileRepost;
  }

  @action.bound fetchRepostProfile = flow(function* (this: CreateCardStore) {
    try {
      if (
        isInitialStateEdit(this.initialState) &&
        this.initialState.profileRepost
      ) {
        return;
      }

      this.isDeckNotFound = false;
      this.isUnpublishedUrl = false;
      this.isInValidProfileUrl = false;
      this.deckModel = null;

      const res: User = yield this.profileApi.fetchProfileRepost(
        this.vanityNameAndProfileLinkName.vanityName,
      );
      if (!res.emailAddressVerified) {
        this.isInValidProfileUrl = true;
        return;
      }

      this.profileRepost = new UserModel(
        res,
        this.userSessionStore,
        this.tokenStore,
      );
    } catch (error) {
      this.profileRepost = null;
      const errorType = getAPIErrorType(error as AxiosError);
      if (errorType === ErrorAPIType.ProfileUnPublishException)
        return (this.isUnpublishedUrl = true);
      if (errorType === ErrorAPIType.UserNotFound)
        return (this.isInValidProfileUrl = true);
    }
  });
  @computed get isUnpublishedGroupUrl(): boolean {
    return (
      (!!this.deckModel &&
        !this.deckModel.deck.curator.isPublished &&
        !this.deckModel.deck.curator.currentUser) ||
      this.isDeckNotFound
    );
  }
}
