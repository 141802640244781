import React, { useEffect } from "react";
import { Element, Link } from "react-scroll";

import { etBlue } from "../../shared/colors";
import { useTranslation } from "../../shared/translate/NextI18next";
import { SectionInfo } from "../../stores/PoliciesPresenter";
import { DangerouslyHTMLRenderer } from "../DangerouslyHTMLRenderer/DangerouslyHTMLRenderer";
import sc from "./PoliciesItem.styled";

interface IProps {
  onRegister(section: SectionInfo): void;
}

export const PoliciesItemNoticeAndTakedown = (props: IProps) => {
  const { t } = useTranslation("Policies");

  useEffect(() => {
    props.onRegister({
      key: "notice-take-down",
      label: t("policy.notice-takedown.label"),
    });
  }, [props]);

  return (
    <Element name="notice-take-down">
      <sc.TextHeader>{t("policy.notice-takedown.label")}</sc.TextHeader>
      <sc.TextModified>
        <DangerouslyHTMLRenderer>
          {t("policy.general.text-modified")}
        </DangerouslyHTMLRenderer>
      </sc.TextModified>
      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.notice-takedown.policy.text-header")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.notice-takedown.policy.text-content-1")}
        </DangerouslyHTMLRenderer>{" "}
        <sc.TextPointer>
          <Link
            className="active"
            activeClass="active"
            color={etBlue}
            to="terms-of-service"
            spy
            smooth
            duration={500}
          >
            Terms and Conditions
          </Link>
        </sc.TextPointer>
        .
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.notice-takedown.policy.text-content-2")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.notice-takedown.complaints.text-header")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.notice-takedown.complaints.text-content-1")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.notice-takedown.complaints.text-content-2")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.notice-takedown.complaints.text-content-3")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.notice-takedown.complaints.text-content-4")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.notice-takedown.complaints.text-content-5")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.notice-takedown.complaints.text-content-6-fr-1")}
        </DangerouslyHTMLRenderer>{" "}
        <a href="mailto:support@earthtoday.com">support@earthtoday.com</a>.{" "}
        <DangerouslyHTMLRenderer>
          {t("policy.notice-takedown.complaints.text-content-6-fr-2")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.notice-takedown.notification.text-header")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.notice-takedown.notification.text-content-1")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.notice-takedown.notification.text-content-2")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.notice-takedown.notification.text-content-3")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.notice-takedown.notification.text-content-4")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.notice-takedown.notification.text-content-5")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.notice-takedown.notification.text-content-6")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.notice-takedown.notification.text-content-7")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.notice-takedown.notification.text-content-8")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.notice-takedown.notification.text-content-9")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <DangerouslyHTMLRenderer>
        {t("policy.notice-takedown.notification.personal-data-used")}
      </DangerouslyHTMLRenderer>
      <sc.TextContent />
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.notice-takedown.notification.date-signature")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
    </Element>
  );
};
