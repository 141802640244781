import styled from "styled-components";

import { mediumScreenLowerLimit } from "../../shared/breakpoints";
import { etBlue, etWhite } from "../../shared/colors";
import zIndex from "../../shared/zIndex";
import { IconSvgContainer } from "../IconSvg/IconSvg.styled";

namespace sc {
  export const ContainerDouble = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 54px;
    width: 680px;
    padding: 20px 12px 20px;
    border: none;
    overflow: hidden;
    font-size: 0.875rem;
    color: ${etWhite};
    @media (max-width: ${mediumScreenLowerLimit}) {
      display: block;
      height: 148px;
      width: 328px;
    }
  `;
  export const ContainerSingle = styled(ContainerDouble)`
    position: relative;
    bottom: 0;
    left: -12px;
    display: block;
    align-items: center;
    justify-content: space-between;
    height: 149px;
    width: 328px;
    padding: 16px 12px 12px 12px;
  `;
  export const CardActionsNumber = styled.div`
    display: none;
    padding: 0 0.2rem;
  `;
  export const CardFooterText = styled.div`
    height: 100%;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: -0.2px;
    align-items: center;
    justify-content: space-between;
  `;
  export const ReadMore = styled.a`
    cursor: pointer;
    font-weight: bold;
    color: ${etWhite};
    text-decoration: none;
    width: 100px;
    &:hover {
      color: ${etWhite};
      text-decoration: none;
    }
  `;
  export const CardFooterTextContainer = styled.div`
    position: relative;
    width: 100%;
    height: 44px;
    margin: 7px 0 25px 0;
  `;
  export const Repost = styled.div`
    text-align: left;
    flex: 1;

    ${IconSvgContainer} {
      transition: 200ms;
      &:hover {
        transform: rotate(15deg);
      }
    }
  `;
  export const Vote = styled.div`
    text-align: center;
    flex: 1;
  `;
  export const Star = styled.div`
    text-align: right;
    flex: 1;

    ${IconSvgContainer} {
      transition: 200ms;
      &:hover {
        transform: scale(1.125);
      }
    }
  `;
  export const Curator = styled.div`
    position: relative;
    z-index: ${zIndex.CURATOR};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    letter-spacing: -0.5px;
    height: 14px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    > a {
      color: ${etWhite};
      text-decoration: none;
      font-weight: bold;
      text-transform: uppercase;
      display: inline-block;
      max-width: 182px;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: -3px;
    }
  `;

  export const CardActionsDetail = styled.div`
    display: none;
    height: 20px;
    width: 42px;
    font-size: 14px;
    line-height: 1.43;
    color: ${etWhite};
    &.active {
      color: ${etBlue};
    }
  `;
  export const CardActionStar = styled.div`
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 20px;
    height: 20px;
    &:hover svg {
      filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.15));
    }
  `;
  export const CardActionsContainer = styled.div`
    position: absolute;
    right: 12px;
    bottom: 12px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    z-index: ${zIndex.ACTION_CONTAINER};
  `;
}
export default sc;
