/* eslint-disable unicorn/consistent-function-scoping */
import {
  MobileSystemDeckResponseDto,
  MobileSystemDeckSavePayloadDto,
  MobileSystemDeckStatus,
  MobileSystemDeckTextAlignment,
} from "@earthtoday/contract";
import { action, computed, flow, makeObservable, observable } from "mobx";
import { toFlowGeneratorFunction } from "to-flow-generator-function";

import { ModalSystemDeckLayoutDriver } from "../../components/ModalSystemDeck/ModalSystemDeckLayout";
import { ITheMessageStore } from "../../components/TheMessage/TheMessageStore";
import { IDeckDetailApi } from "../../shared/apis/DeckDetailApi";
import { etBlack } from "../../shared/colors";
import { translateAPIError } from "../../shared/helpers/translateApiError";
import { IModalStore } from "../ModalStore";

export class ModalSystemDeckPresenter implements ModalSystemDeckLayoutDriver {
  @observable systemDeck: MobileSystemDeckResponseDto | null = null;
  constructor(
    private parentStore: {
      deckId: string;
      updateSystemDeck(systemDeck: MobileSystemDeckResponseDto): void;
    },
    private deckDetailApi: IDeckDetailApi,
    private theMessageStore: ITheMessageStore,
    private modalStore: IModalStore,
  ) {
    makeObservable(this);
  }

  @observable isFetchingData: boolean = false;
  @action.bound onMount = flow(function* onMount(
    this: ModalSystemDeckPresenter,
  ) {
    try {
      this.isFetchingData = true;

      const systemDeckResponse = yield* toFlowGeneratorFunction(
        this.deckDetailApi.getMobileSystemDeckDetail,
      )(this.parentStore.deckId);

      this.systemDeck = systemDeckResponse;
      this.getInitialState(systemDeckResponse);
      this.parentStore.updateSystemDeck(systemDeckResponse);
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "Error",
        content: translateAPIError(error),
      });
    } finally {
      this.isFetchingData = false;
    }
  });

  @action.bound onClose(): void {
    this.modalStore.openModal("");
  }

  @observable isSystemDeck: boolean =
    this.systemDeck?.data.status === MobileSystemDeckStatus.ACTIVE;
  @action.bound onSetAsSystemDeckCheckboxClicked(): void {
    this.isSystemDeck = !this.isSystemDeck;
  }

  @observable textAlignment: MobileSystemDeckTextAlignment =
    MobileSystemDeckTextAlignment.CENTER;
  @action.bound onTextAlignmentChanged(
    textAlignment: MobileSystemDeckTextAlignment,
  ): void {
    this.textAlignment = textAlignment;
  }

  @observable textColor: string = etBlack;
  @action.bound onTextColorChanged(color: string): void {
    this.textColor = color;
  }

  @observable displayOrder: number | undefined = undefined;
  @action.bound onDisplayOrderChanged(order: number): void {
    if (order === 0) {
      this.displayOrder = undefined;
      return;
    }

    this.displayOrder = order;
  }

  @computed get isInitialState(): boolean {
    if (!this.systemDeck) {
      return true;
    }

    const { status, textAlignment, textColor, displayOrder } =
      this.systemDeck.data;
    const initialIsSystemDeck = status === MobileSystemDeckStatus.ACTIVE;

    return (
      this.isSystemDeck === initialIsSystemDeck &&
      this.textAlignment === textAlignment &&
      this.textColor === textColor &&
      this.displayOrder === displayOrder
    );
  }

  @computed get isSaveButtonDisabled(): boolean {
    return this.isInitialState || this.isFetchingData;
  }

  @action.bound onSaveButtonClicked = flow(function* onSaveButtonClicked(
    this: ModalSystemDeckPresenter,
  ) {
    try {
      this.isFetchingData = true;
      const payload: MobileSystemDeckSavePayloadDto = {
        data: {
          deckId: this.parentStore.deckId,
          status: this.isSystemDeck
            ? MobileSystemDeckStatus.ACTIVE
            : MobileSystemDeckStatus.INACTIVE,
          textAlignment: this.textAlignment,
          textColor: this.textColor,
          displayOrder: this.displayOrder,
        },
      };

      const savedResponse = yield* toFlowGeneratorFunction(
        this.deckDetailApi.saveMobileSystemDeck,
      )(payload);
      this.parentStore.updateSystemDeck(savedResponse);
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "Error",
        content: translateAPIError(error),
      });
    } finally {
      this.onClose();
      this.isFetchingData = false;
    }
  });

  private getInitialState(systemDeck: MobileSystemDeckResponseDto): void {
    const { status, textAlignment, textColor, displayOrder } = systemDeck.data;
    this.isSystemDeck = status === MobileSystemDeckStatus.ACTIVE;
    this.textAlignment = textAlignment;
    this.textColor = textColor;
    this.displayOrder = displayOrder;
  }
}
