import {
  PaymentMethodHealthStatus,
  PaymentMethodName,
  PaymentMethodResponse,
  PaymentProvider,
} from "../apis/PaymentApi";

export const alternativePaymentMethods = (
  paymentMethodsResponse: PaymentMethodResponse[],
) => {
  const isMollieCardAvailable = paymentMethodsResponse.some(
    (method) =>
      method.paymentMethodName === PaymentMethodName.MOLLIE_CREDITCARD &&
      method.healthStatus === PaymentMethodHealthStatus.OK,
  );
  /* Use Stripe */
  if (!isMollieCardAvailable) {
    paymentMethodsResponse.push({
      healthStatus: PaymentMethodHealthStatus.OK,
      paymentMethodName: PaymentMethodName.STRIPE_CREDITCARD,
      provider: PaymentProvider.STRIPE,
    });
  }

  return paymentMethodsResponse;
};
