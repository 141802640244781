import styled from "styled-components";

import {
  etBrightLightBlue,
  etDark,
  etGhostGray,
  etGray,
  etGrayBlue,
  etIceBlue,
  etLicoriceBlue,
  etNevadaGrey,
  etWhite,
} from "../../shared/colors";
import transparentize from "../../shared/helpers/transparentize";
import {
  PROFILE_AVATAR_HEIGHT_DEFAULT,
  PROFILE_AVATAR_WIDTH_DEFAULT,
} from "../TheNavbarControllers/TheNavbarControllers.styled";
export const ONE_FLOW_INDENTITY_AVATAR_WIDTH: number = 76;
export const ONE_FLOW_INDENTITY_AVATAR_HEIGHT: number = 76;

namespace sc {
  export const Container = styled.div`
    height: 496px;
    max-height: 496px;
    width: 328px;
    max-width: 328px;
    padding: 19px 24px 24px 24px;
    position: relative;
  `;

  export const Description = styled.div``;
  export const DescriptionHeader = styled.h2`
    font-size: 20px;
    margin-bottom: 2px;
  `;
  export const DescrriptionText = styled.p`
    font-size: 14px;
    color: ${etNevadaGrey};
    margin-bottom: 13px;
  `;

  export const UserIdentityContainer = styled.div``;
  export const UserIdentityHeader = styled.h3`
    font-size: 14px;
    height: 20px;
    line-height: 20px;.
    letter-spacing: 0px;
    font-weight: 500;
    margin-bottom: 1px;

  `;
  export const UserIdentity = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100px;
    padding: 12px;
    border: 1px solid ${etGray};
    border-radius: 4px;

    &.active {
      border-color: ${etBrightLightBlue};
      background-color: ${transparentize(etBrightLightBlue, 0.05)};
    }
  `;
  export const UserIdentityAvatar = styled.div<{ src: string }>`
    border-radius: 50%;
    flex-shrink: 0;
    width: ${ONE_FLOW_INDENTITY_AVATAR_WIDTH}px;
    height: ${ONE_FLOW_INDENTITY_AVATAR_HEIGHT}px;
    background-size: cover;
    background-position: center;
    background-image: url(${(props) => props.src});
  `;
  export const UserIdentityName = styled.p`
    font-size: 14px;
    margin: 0 0 0 16px;
    padding: 0;
    color: ${etDark};
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 20px;
  `;

  export const OrganizationContainer = styled.div`
    margin-top: 19px;
  `;
  export const OrganizationHeader = styled.h3`
    font-size: 14px;
    margin-bottom: 3px;
    height: 20px;
    width: 118px;
    line-height: 20px;
  `;
  export const OrganizationDropdowWrapper = styled.div``;
  export const OrganizationContent = styled.div`
    display: flex;
  `;
  export const OrganizationDropdowDropDownCheck = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    height: 40px;
    background-color: ${etBrightLightBlue};
    border: 1px solid ${etBrightLightBlue};

    svg {
      height: 18px;
    }
  `;
  export const OrganizationDropdowToggle = styled.div<{ isSelected: boolean }>`
    display: flex;
    flex: 1;
    align-items: center;
    color: ${etGrayBlue};
    padding-left: 10px;
    font-size: 14px;
    height: 40px;
    max-height: 40px;
    border: 1px solid
      ${(props) => (props.isSelected ? etBrightLightBlue : etIceBlue)};
    border-radius: 4px 0 0 4px;
  `;
  export const OrganizationDropdow = styled.button`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    border: none;
    border-radius: 4px;
    padding: 0;
    background-color: ${etWhite};
    height: 40px;
    max-height: 40px;
    min-width: 235px;
    overflow: hidden;

    &:disabled {
      cursor: default;
      svg {
        cursor: default;
      }
      ${OrganizationDropdowDropDownCheck} {
        opacity: 0.5;
      }
      ${OrganizationDropdowToggle} {
        opacity: 0.5;
      }
    }
  `;
  export const OrganizationButtonAdd = styled.button`
    min-width: 40px;
    max-width: 40px;
    max-height: 40px;
    min-height: 40px;
    border-radius: 4px;
    color: ${etWhite};
    background-color: ${etGray};
    font-size: 16px;
    margin-left: 6px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  export const OrganizationsBorder = styled.div`
    position: absolute;
    z-index: 3;
    top: 279px;
    left: 24px;
    right: 24px;
    overflow: hidden;
    border-radius: 0 0 4px 4px;
    box-shadow: 0px 16px 18px 0px rgba(42, 54, 66, 0.2);
  `;

  export const OrganizationsListWrapper = styled.div`
    max-height: 300px;
    overflow: hidden auto;
    background-color: ${etWhite};
    padding: 12px;
  `;
  export const OrganizationsList = styled.ol`
    list-style: none;
    display: grid;
    row-gap: 6px;
    padding: 0;
    margin: 0;
  `;
  export const OrganizationsListOverlay = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
  `;
  export const OrganizationItem = styled.li`
    padding: 0 10px;
    border: 1px solid ${etGhostGray};
    border-radius: 4px;
    display: flex;
    width: 257px;
    max-width: 257px;
    height: 64px;
    max-height: 64px;
    align-items: center;
    cursor: pointer;
    :hover {
      background-color: ${transparentize(etBrightLightBlue, 0.05)};
      border-color: ${etBrightLightBlue};
    }
  `;
  export const OrgnizationAvatar = styled.img`
    height: ${PROFILE_AVATAR_HEIGHT_DEFAULT}px;
    width: ${PROFILE_AVATAR_WIDTH_DEFAULT}px;
    border-radius: 50%;
    margin-right: 10px;
  `;
  export const OrganizationName = styled.p`
    margin: 0;
    font-size: 14px;
  `;

  export const ButtonsContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
  `;
  export const ButtonStyle = styled.button`
    height: 40px;
    max-height: 40px;
    width: 130px;
    border-radius: 4px;
  `;
  export const BackButton = styled(ButtonStyle)`
    border: 1px solid ${etGray};
    background-color: ${etWhite};
    color: ${etGray};
  `;

  export const ContinueButton = styled(ButtonStyle)`
    border: none;
    background-color: ${etBrightLightBlue};
    color: ${etWhite};
  `;

  export const ActAsOption = styled.div<{ selected: boolean }>`
    cursor: pointer;
    background: ${(props) =>
      props.selected ? transparentize(etBrightLightBlue, 0.05) : "transparent"};
    border-radius: 4px;
    border: 1px solid
      ${(props) => (props.selected ? etBrightLightBlue : etIceBlue)};
    min-height: 100px;
    max-height: 100px;
    min-width: 280px;
    max-width: 280px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 12px;
  `;

  export const Photo = styled.img`
    border: 1px solid ${etIceBlue};
    min-height: ${ONE_FLOW_INDENTITY_AVATAR_HEIGHT}px;
    max-height: ${ONE_FLOW_INDENTITY_AVATAR_HEIGHT}px;
    min-width: ${ONE_FLOW_INDENTITY_AVATAR_WIDTH}px;
    max-width: ${ONE_FLOW_INDENTITY_AVATAR_WIDTH}px;

    border-radius: 50%;
    margin: 0px 17px 0px 12px;
  `;

  export const FullName = styled.p`
    color: ${etLicoriceBlue};
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    width: 159px;
    letter-spacing: 0px;
    line-height: 20px;
    margin-bottom: 0px;
    > span {
      font-weight: 500;
    }
  `;
}

export default sc;
