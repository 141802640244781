import React, { useEffect } from "react";
import { Element, Link } from "react-scroll";

import { etBlue } from "../../shared/colors";
import { useTranslation } from "../../shared/translate/NextI18next";
import { SectionInfo } from "../../stores/PoliciesPresenter";
import { DangerouslyHTMLRenderer } from "../DangerouslyHTMLRenderer/DangerouslyHTMLRenderer";
import sc from "./PoliciesItem.styled";

interface IProps {
  onRegister(section: SectionInfo): void;
}

export const PoliciesItemPrivacy = (props: IProps) => {
  const { t } = useTranslation("Policies");

  useEffect(() => {
    props.onRegister({
      key: "privacy",
      label: t("policy.privacy.label"),
    });
  }, [props]);

  return (
    <Element name="privacy">
      <sc.TextHeader>{t("policy.privacy.label")}</sc.TextHeader>
      <sc.TextModified>
        <DangerouslyHTMLRenderer>
          {t("policy.general.text-modified")}
        </DangerouslyHTMLRenderer>
      </sc.TextModified>
      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.introduction.text-header")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.introduction.text-content-1")}
        </DangerouslyHTMLRenderer>{" "}
        <a href="mailto:support@earthtoday.com">support@earthtoday.com</a>.
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.introduction.text-content-2-fr-1")}
        </DangerouslyHTMLRenderer>{" "}
        <a target="_blank" rel="noreferrer" href="https://earthtoday.com/">
          earthtoday.com
        </a>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.introduction.text-content-2-fr-2")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.introduction.text-content-3")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.user-processing.text-header")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.user-processing.text-content-1")}
        </DangerouslyHTMLRenderer>{" "}
        <sc.TextPointer>
          <Link
            className="active"
            activeClass="active"
            color={etBlue}
            to="cookie-policy"
            spy
            smooth
            duration={500}
          >
            <DangerouslyHTMLRenderer>
              {t("policy.general.cookie-policy")}
            </DangerouslyHTMLRenderer>
          </Link>
        </sc.TextPointer>
        .
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.user-processing.text-content-2")}
        </DangerouslyHTMLRenderer>{" "}
        <sc.TextPointer>
          <Link
            className="active"
            activeClass="active"
            color={etBlue}
            to="cookie-policy"
            spy
            smooth
            duration={500}
          >
            <DangerouslyHTMLRenderer>
              {t("policy.general.cookie-policy")}
            </DangerouslyHTMLRenderer>
          </Link>
        </sc.TextPointer>
        .
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.user-processing.text-content-3")}
        </DangerouslyHTMLRenderer>
        <sc.Indent>
          <sc.IndentInline>■ </sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.user-processing.text-indent-1")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■ </sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.user-processing.text-indent-2")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.user-processing.text-content-4")}
        </DangerouslyHTMLRenderer>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.user-processing.indent-identity")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.user-processing.indent-contact")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>{" "}
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.user-processing.indent-profile")}
          </DangerouslyHTMLRenderer>{" "}
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.user-processing.indent-personal-quotes")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.user-processing.indent-content")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.user-processing.indent-transaction")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.user-processing.text-content-5")}
        </DangerouslyHTMLRenderer>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.user-processing.indent-technical")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.user-processing.indent-analytical")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
      </sc.TextContent>
      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.data-purpose-legal.text-header")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.data-purpose-legal.text-content-1")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.data-purpose-legal.text-content-2")}
        </DangerouslyHTMLRenderer>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.data-purpose-legal.text-indent-1")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.data-purpose-legal.text-indent-2")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.data-purpose-legal.text-indent-3")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.data-purpose-legal.text-indent-4")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.data-purpose-legal.text-indent-5")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.data-purpose-legal.text-indent-6")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.data-purpose-legal.text-indent-7")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.data-purpose-legal.text-indent-8")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.data-purpose-legal.text-content-3")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.data-consent.text-header")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.data-consent.text-content-1")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.data-consent.text-content-2")}
        </DangerouslyHTMLRenderer>{" "}
        <sc.TextPointer>
          <Link
            className="active"
            activeClass="active"
            color={etBlue}
            to="cookie-policy"
            spy
            smooth
            duration={500}
          >
            <DangerouslyHTMLRenderer>
              {t("policy.general.cookie-policy")}
            </DangerouslyHTMLRenderer>
          </Link>
        </sc.TextPointer>
        .
      </sc.TextContent>
      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.data-security.text-header")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.data-security.text-content-1")}
        </DangerouslyHTMLRenderer>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.data-security.text-indent-1")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.data-security.text-indent-2")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.data-security.text-indent-3")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.data-security.text-indent-4")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.data-security.text-content-2")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.data-security.text-content-3")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.data-security.text-content-4")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.data-security.text-content-5")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.third-parties.text-header")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.third-parties.text-content-1")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.third-parties.text-content-2")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.third-parties.text-content-3")}
        </DangerouslyHTMLRenderer>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.third-parties.text-indent-1")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.third-parties.text-indent-2")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.third-parties.text-indent-3")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.third-parties.text-content-4")}
        </DangerouslyHTMLRenderer>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.third-parties.text-indent-4")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>{" "}
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.third-parties.text-indent-5")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>{" "}
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.third-parties.text-indent-6")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.third-parties.text-content-5")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.data-transfer.text-header")}
        </DangerouslyHTMLRenderer>{" "}
      </sc.TitleSections>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.data-transfer.text-content")}
        </DangerouslyHTMLRenderer>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.data-transfer.text-indent-1")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.data-transfer.text-indent-2")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.data-transfer.text-indent-3")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
      </sc.TextContent>
      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.your-rights.text-header")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>
      <DangerouslyHTMLRenderer>
        {t("policy.privacy.your-rights.rights")}
      </DangerouslyHTMLRenderer>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.your-rights.text-content-1")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.your-rights.text-content-2")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.your-rights.text-content-3")}
        </DangerouslyHTMLRenderer>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.your-rights.text-indent-1")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.your-rights.text-indent-2")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.your-rights.text-indent-3")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.your-rights.text-indent-4")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.your-rights.text-indent-5")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.your-rights.text-indent-6")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.your-rights.text-content-4")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.your-rights.text-content-5")}
        </DangerouslyHTMLRenderer>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>{" "}
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.your-rights.text-indent-7")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>{" "}
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.your-rights.text-indent-8")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>{" "}
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.your-rights.text-indent-9")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
        <sc.Indent>
          <sc.IndentInline>■</sc.IndentInline>{" "}
          <DangerouslyHTMLRenderer>
            {t("policy.privacy.your-rights.text-indent-10")}
          </DangerouslyHTMLRenderer>
        </sc.Indent>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.your-rights.text-content-6")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.your-rights.exercise-right-fr-1")}
        </DangerouslyHTMLRenderer>{" "}
        <a href="mailto:support@earthtoday.com">support@earthtoday.com</a>{" "}
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.your-rights.exercise-right-fr-2")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.your-rights.request-complaint")}
        </DangerouslyHTMLRenderer>{" "}
        <a href="mailto:support@earthtoday.com">support@earthtoday.com</a>.
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.privacy.your-rights.lodge-complaint")}
        </DangerouslyHTMLRenderer>{" "}
        (
        <a target="_blank" href="autoriteitpersoonsgegevens.nl">
          autoriteitpersoonsgegevens.nl
        </a>
        ).
      </sc.TextContent>
    </Element>
  );
};
