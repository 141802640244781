import topoLinesGrp1 from "../../assets/img/earthLines/topoLinesGrp1.png";
import topoLinesGrp2 from "../../assets/img/earthLines/topoLinesGrp2.png";
import topoLinesGrp3 from "../../assets/img/earthLines/topoLinesGrp3.png";
import topoLinesGrp4 from "../../assets/img/earthLines/topoLinesGrp4.png";
import topoLinesGrp5 from "../../assets/img/earthLines/topoLinesGrp5.png";
import topoLinesGrp6 from "../../assets/img/earthLines/topoLinesGrp6.png";
import topoLinesGrp7 from "../../assets/img/earthLines/topoLinesGrp7.png";

export const getEarthLinesNextImage = (uonCount: number): string => {
  if (uonCount <= 4) {
    return topoLinesGrp1;
  }
  if (uonCount > 4 && uonCount <= 9) {
    return topoLinesGrp2;
  }
  if (uonCount > 9 && uonCount <= 19) {
    return topoLinesGrp3;
  }
  if (uonCount > 19 && uonCount <= 99) {
    return topoLinesGrp4;
  }
  if (uonCount > 99 && uonCount <= 499) {
    return topoLinesGrp5;
  }
  if (uonCount > 499 && uonCount <= 999) {
    return topoLinesGrp6;
  }
  return topoLinesGrp7;
};
