import {
  largeScreenUpperLimit,
  mediumScreenUpperLimit,
  mobileDeviceWidth,
  VIEWPORT_MAX_WIDTH,
  ViewPortLargeScreenLowerLimit,
  ViewPortLargeScreenUpperLimit,
  ViewPortMaxWidth,
  ViewPortMediumScreenUpperLimit,
  ViewPortMobileDeviceWidth,
} from "../../shared/breakpoints";
import { CampaignDefaultStatus } from "../../stores/CampaignDefaultModel";

export interface IStylesSplitFlapEffect {
  fontSizeLargeScreen: string;
  fontSizeTablet: string;
  fontSizeMobile: string;
  lineHeight: string;
  lineHeightTablet: string;
  lineHeightMobile: string;
  paddingMobile: string;
  paddingTablet: string;
  padding: string;
  borderBottomLargeScreen: string;
}
type ViewPortOption =
  | ViewPortMaxWidth
  | ViewPortLargeScreenLowerLimit
  | ViewPortLargeScreenUpperLimit
  | ViewPortMediumScreenUpperLimit
  | ViewPortMobileDeviceWidth
  | "0px";
interface IBreakPointsFlapEffect {
  col3: ViewPortOption;

  col2: ViewPortOption;
  col1: ViewPortOption;
}

const breakPointsFlapEffectHeaderCard: IBreakPointsFlapEffect = {
  col3: VIEWPORT_MAX_WIDTH,
  col2: VIEWPORT_MAX_WIDTH,
  col1: mediumScreenUpperLimit,
};

export const getStylesSplitFlapEffectDefaultCampaign = (
  state: CampaignDefaultStatus,
): IStylesSplitFlapEffect => {
  return {
    fontSizeLargeScreen: "29px",
    fontSizeTablet:
      state?.toUpperCase() === CampaignDefaultStatus.State2 ? "25.6px" : "29px",
    fontSizeMobile: "31px",
    lineHeight: "32px",
    lineHeightMobile: "28px",
    lineHeightTablet:
      state?.toUpperCase() === CampaignDefaultStatus.State2 ? "25.6px" : "32px",
    paddingMobile: "2.4px",
    paddingTablet:
      state?.toUpperCase() === CampaignDefaultStatus.State2 ? "2.4px" : "4.8px",
    padding: "4.8px",
    borderBottomLargeScreen: "1px",
  };
};

const breakPointsFlapEffectDefaultCampaignState1: IBreakPointsFlapEffect = {
  col3: VIEWPORT_MAX_WIDTH,
  col2: VIEWPORT_MAX_WIDTH,
  col1: mediumScreenUpperLimit,
};

const breakPointsFlapEffectDefaultCampaignState2 = {
  col3: VIEWPORT_MAX_WIDTH,
  col2: VIEWPORT_MAX_WIDTH,
  col1: largeScreenUpperLimit,
};

const breakPointsFlapEffectProtectPage: IBreakPointsFlapEffect = {
  col3: largeScreenUpperLimit,
  col2: mediumScreenUpperLimit,
  col1: "0px",
};

const breakPointsFlapEffectNewProtectPage: IBreakPointsFlapEffect = {
  col3: VIEWPORT_MAX_WIDTH,
  col2: mediumScreenUpperLimit,
  col1: mobileDeviceWidth,
};

const breakPointsFlapEffectCardSystem: IBreakPointsFlapEffect = {
  col3: VIEWPORT_MAX_WIDTH,
  col2: VIEWPORT_MAX_WIDTH,
  col1: VIEWPORT_MAX_WIDTH,
};

const breakPointsFlapEffectCharityHeaderCard: IBreakPointsFlapEffect = {
  col3: VIEWPORT_MAX_WIDTH,
  col2: VIEWPORT_MAX_WIDTH,
  col1: mediumScreenUpperLimit,
};

export enum UonCountNumberLocation {
  Overlay = "overlay",
  HeaderCard = "header-card",
  DefaultCampaign = "default-campaign",
  ProtectPage = "protect-page",
  NewProtectPage = "new-protect-page",
  CardSystem = "card-system",
  CharityHeaderCard = "charity-header-card",
}

export const getBreakPointsFlapEffect = (
  location: UonCountNumberLocation,
  campaignDefaultStatus?: CampaignDefaultStatus,
): IBreakPointsFlapEffect => {
  switch (location) {
    case UonCountNumberLocation.HeaderCard: {
      return breakPointsFlapEffectHeaderCard;
    }
    case UonCountNumberLocation.DefaultCampaign: {
      return campaignDefaultStatus?.toUpperCase() ===
        CampaignDefaultStatus.State1
        ? breakPointsFlapEffectDefaultCampaignState1
        : breakPointsFlapEffectDefaultCampaignState2;
    }

    case UonCountNumberLocation.ProtectPage: {
      return breakPointsFlapEffectProtectPage;
    }
    case UonCountNumberLocation.NewProtectPage: {
      return breakPointsFlapEffectNewProtectPage;
    }
    case UonCountNumberLocation.CardSystem: {
      return breakPointsFlapEffectCardSystem;
    }
    case UonCountNumberLocation.CharityHeaderCard: {
      return breakPointsFlapEffectCharityHeaderCard;
    }
    default: {
      return breakPointsFlapEffectCardSystem;
    }
  }
};
