import styled from "styled-components";

import {
  mediumScreenUpperLimit,
  smallScreenUpperLimit,
} from "../../../shared/breakpoints";
import { etSolitudeLight } from "../../../shared/colors";

export namespace sc {
  export const Section = styled.section`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${etSolitudeLight};
    height: 780px;
    border-radius: 32px 32px 0 0;

    @media (max-width: ${mediumScreenUpperLimit}) {
      flex-direction: column;
      justify-content: center;
      height: 900px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      height: 750px;
    }
  `;
  export const SectionContent = styled.div`
    position: relative;
    margin-left: auto;
    padding-left: 24px;

    @media (max-width: ${mediumScreenUpperLimit}) {
      margin: 0;
      padding: 24px;
    }
  `;
  export const Title = styled.h2`
    font-family: Roboto Slab;
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: -0.5px;
    text-align: left;

    @media (max-width: ${mediumScreenUpperLimit}) {
      font-size: 32px;
      line-height: 40px;
      text-align: center;
    }
  `;
  export const Subtitle = styled.p`
    font-family: Roboto Slab;
    font-size: 40px;
    font-weight: 400;
    line-height: 50px;
    letter-spacing: -0.5px;
    text-align: left;
    max-width: 580px;

    @media (max-width: ${mediumScreenUpperLimit}) {
      font-size: 30px;
      line-height: 35px;
      text-align: center;
    }
  `;
  export const PaymentBlock = styled.div`
    margin-left: 32px;

    @media (max-width: ${mediumScreenUpperLimit}) {
      margin: 0;
    }
  `;
  export const Payment = styled.img`
    max-height: 615px;
    max-width: 700px;

    @media (max-width: ${mediumScreenUpperLimit}) {
      max-width: 500px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      max-width: 300px;
    }
  `;
}
