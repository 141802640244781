import topoLinesGrp1Png from "../../assets/img/earthLines/topoLinesGrp1.png";
import topoLinesGrp1Webp from "../../assets/img/earthLines/topoLinesGrp1.webp";
import topoLinesGrp2Png from "../../assets/img/earthLines/topoLinesGrp2.png";
import topoLinesGrp2Webp from "../../assets/img/earthLines/topoLinesGrp2.webp";
import topoLinesGrp3Png from "../../assets/img/earthLines/topoLinesGrp3.png";
import topoLinesGrp3Webp from "../../assets/img/earthLines/topoLinesGrp3.webp";
import topoLinesGrp4Png from "../../assets/img/earthLines/topoLinesGrp4.png";
import topoLinesGrp4Webp from "../../assets/img/earthLines/topoLinesGrp4.webp";
import topoLinesGrp5Png from "../../assets/img/earthLines/topoLinesGrp5.png";
import topoLinesGrp5Webp from "../../assets/img/earthLines/topoLinesGrp5.webp";
import topoLinesGrp6Png from "../../assets/img/earthLines/topoLinesGrp6.png";
import topoLinesGrp6Webp from "../../assets/img/earthLines/topoLinesGrp6.webp";
import topoLinesGrp7Png from "../../assets/img/earthLines/topoLinesGrp7.png";
import topoLinesGrp7Webp from "../../assets/img/earthLines/topoLinesGrp7.webp";
import topoLinesGrp8Png from "../../assets/img/earthLines/topoLinesGrp8.png";

export function getEarthLines(
  uonCount: number,
  isWebpSupported: boolean,
  enableNew1KUons?: boolean,
) {
  if (uonCount <= 4) {
    return isWebpSupported ? topoLinesGrp1Webp : topoLinesGrp1Png;
  }
  if (uonCount > 4 && uonCount <= 9) {
    return isWebpSupported ? topoLinesGrp2Webp : topoLinesGrp2Png;
  }
  if (uonCount > 9 && uonCount <= 19) {
    return isWebpSupported ? topoLinesGrp3Webp : topoLinesGrp3Png;
  }
  if (uonCount > 19 && uonCount <= 99) {
    return isWebpSupported ? topoLinesGrp4Webp : topoLinesGrp4Png;
  }
  if (uonCount > 99 && uonCount <= 499) {
    return isWebpSupported ? topoLinesGrp5Webp : topoLinesGrp5Png;
  }
  if (uonCount > 499 && uonCount <= 999) {
    return isWebpSupported ? topoLinesGrp6Webp : topoLinesGrp6Png;
  }

  if (enableNew1KUons) {
    return topoLinesGrp8Png;
  }

  return isWebpSupported ? topoLinesGrp7Webp : topoLinesGrp7Png;
}
