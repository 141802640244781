/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable class-methods-use-this */
import { ImageResponseDto } from "@earthtoday/contract";
import { action, computed, flow, makeObservable, observable } from "mobx";
import getConfig from "next/config";
import { ReactNode } from "react";
import NumberFormat from "react-number-format";

import {
  CARD_CAMPAIGN_DEFAULT_IMAGE_HEIGHT,
  CARD_CAMPAIGN_DEFAULT_IMAGE_MOBILE_HEIGHT,
  CARD_CAMPAIGN_DEFAULT_IMAGE_MOBILE_WIDTH,
  CARD_CAMPAIGN_DEFAULT_IMAGE_TABLET_WIDTH,
  CARD_CAMPAIGN_DEFAULT_IMAGE_WIDTH,
  CARD_CAMPAIGN_DEFAULT_WITHOUT_LEADERBOARD_IMAGE_MOBILE_WIDTH,
  CARD_CAMPAIGN_DEFAULT_WITHOUT_LEADERBOARD_IMAGE_TABLET_WIDTH,
  CARD_CAMPAIGN_DEFAULT_WITHOUT_LEADERBOARD_IMAGE_WIDTH,
} from "../components/CardCampaignDefault/CardCampaignDefault.styled";
import { DynamicLazyModalLoader } from "../components/DynamicModalLoader/DynamicLazyModalLoader";
import { IUserSessionStore } from "../components/ModalLogin/UserSessionStore";
import {
  IMessage,
  ITheMessageStore,
} from "../components/TheMessage/TheMessageStore";
import { ICampaignDefaultApi } from "../shared/apis/CampaignDefaultApi";
import {
  largeScreenUpperLimitValue,
  mediumScreenUpperLimitValue,
  smallScreenUpperLimitValue,
} from "../shared/breakpoints";
import { CAMPAIGN_DEFAULT_TITLE_MAX_LENGTH } from "../shared/constants";
import { validateAlphanumericEmoji } from "../shared/helpers/commonValidations";
import {
  detectSharingApp,
  SharingApp,
} from "../shared/helpers/detectSharingApp";
import { formatNumber } from "../shared/helpers/formatNumber";
import { getResizeImageUrl } from "../shared/helpers/getResizeImageUrl";
import { openLinkInNewWindow } from "../shared/helpers/openLinkInNewWindow";
import { translateAPIError } from "../shared/helpers/translateApiError";
import {
  GroupMemberRole,
  GroupProfileType,
  UserType,
} from "../shared/models/User";
import { IDeviceStore } from "./DeviceStore";
import { Currency } from "./DonateStore/DonateStore";
import { IModalStore } from "./ModalStore";
import { ProfilePagePresenter } from "./ProfilePagePresenter";
import { UserModel } from "./UserModel";

export enum CampaignDefaultStatus {
  State1 = "STATE1",
  State2 = "STATE2",
}

export enum ProfileHeaderCardDisplayType {
  CONTRIBUTION = "CONTRIBUTION",
  GLOBAL = "GLOBAL",
}

export type ICoverImage = {
  id: string;
  url: string;
  primaryColor: string;
  originalWidth: 0;
  originalHeight: 0;
  awsKey: string;
  awsBucket: string;
  isTransparent: null | boolean;
};
export type CampaignDefaultResponse = {
  id: string; // id of campaign, use this for realtime.
  coverImage: ICoverImage;
  name: string;
  linkName: string;
  protectedCount: number;
  globalProtectedCount: number;
  state: CampaignDefaultStatus;
  profileHeaderCardDisplayType: ProfileHeaderCardDisplayType;
};
export enum ChannelSharing {
  Facebook = "facebook",
  Twitter = "twitter",
  Pinterest = "pinterest",
  Linkedin = "linkedin",
  Whatsapp = "whatsapp",
  Link = "link",
}

export class CampaignDefaultModel {
  @observable campaignDefault: CampaignDefaultResponse;

  @observable user: UserModel | null = null;

  @observable contextMenuIsActive: boolean = false;

  @observable imageCoverEdit: File | null = null;

  @observable titleEdit: string = "";

  @observable isEditing: boolean = false;

  @observable modalBackground: ImageResponseDto | null = null;

  @observable isResetBackground: boolean = false;

  @observable isTouched: boolean = false;

  @observable isSharedLeaderBoard: boolean = false;

  @observable previewImageForSharing: string = "";

  @observable pinterestTitle: string = "";
  @observable campaignTitle: string | null = null;

  @observable currentChannelSharing: ChannelSharing | "" = "";

  @observable shareLoading: boolean = false;

  constructor(
    private campaignDefaultApi: ICampaignDefaultApi,
    private modalStore: IModalStore,
    private profileStore: ProfilePagePresenter,
    private theMessageStore: ITheMessageStore,
    private userSessionStore: IUserSessionStore,
    private deviceStore: IDeviceStore,
    user: UserModel,
    campaignDefault: CampaignDefaultResponse,
  ) {
    makeObservable(this);
    this.campaignDefault = campaignDefault;
    this.user = user;
    this.titleEdit = campaignDefault.name;
    this.campaignTitle = campaignDefault.name;
    this.modalBackground = this.user?.cover || null;
  }
  @computed get state(): CampaignDefaultStatus {
    return this.campaignDefault.state;
  }
  @computed get id(): string {
    return this.campaignDefault.id;
  }
  @computed get cover(): ImageResponseDto | null {
    return this.user?.cover || null;
  }

  @computed get uonCount(): number {
    if (this.isUseGlobalCounter) {
      return this.campaignDefault.globalProtectedCount;
    }
    return this.campaignDefault.protectedCount;
  }

  @computed get formatedUserUonCount(): ReactNode | number {
    return (
      <NumberFormat
        value={this.user?.uonCount || 0}
        displayType="text"
        thousandSeparator="."
        decimalSeparator=","
        style={{ display: "inline-block" }}
      />
    );
  }

  @computed get isCampaignDefaultConSumerState2(): boolean {
    return (
      this.state?.toUpperCase() === CampaignDefaultStatus.State2 &&
      this.user?.userType === UserType.CONSUMER
    );
  }

  @computed get isCampaignDefaultGroupState1(): boolean {
    return (
      this.user?.userType === UserType.GROUP_ADAPTER &&
      this.state?.toUpperCase() === CampaignDefaultStatus.State1
    );
  }

  @computed get isCampaignDefaultBrandGroupState1(): boolean {
    return (
      this.profileStore.groupProfileType === GroupProfileType.BRAND &&
      this.state?.toUpperCase() === CampaignDefaultStatus.State1
    );
  }
  @computed get isCampaignDefaultBrandGroupState2(): boolean {
    return (
      this.profileStore.groupProfileType === GroupProfileType.BRAND &&
      this.state?.toUpperCase() === CampaignDefaultStatus.State2
    );
  }
  @computed get isNpoGroup(): boolean {
    return (
      this.profileStore.groupProfileType?.toUpperCase() ===
      GroupProfileType.NPO.toUpperCase()
    );
  }

  @computed get isCharityGroup(): boolean {
    return (
      this.profileStore.groupProfileType?.toUpperCase() ===
      GroupProfileType.CHARITY.toUpperCase()
    );
  }
  @computed get title(): {
    key: string;
    vars?: Record<string, number | string | undefined>;
    raw?: boolean;
  } {
    if (this.campaignTitle) {
      return {
        key: this.campaignTitle,
        raw: true,
      };
    }

    if (this.isNpoGroup || this.isCharityGroup) {
      return {
        key: "campaigndefault.title.npo-or-charity",
        vars: {
          fullName: this.user?.fullName || "",
          currency: this.profileStore.currency || Currency.EUR,
        },
      };
    }

    if (
      this.isCampaignDefaultBrandGroupState1 ||
      this.isCampaignDefaultBrandGroupState2
    ) {
      return {
        key: "campaigndefault.title.brand",
        vars: {
          fullName: this.user?.fullName || "",
        },
      };
    }

    if (this.isCampaignDefaultGroupState1) {
      return {
        key: "campaigndefault.title.has-state",
        vars: {
          fullName: this.user?.fullName || "",
          uonCount: formatNumber(this.user?.uonCount || 0),
        },
      };
    }

    if (this.isCampaignDefaultConSumerState2) {
      return {
        key: "campaigndefault.title.has-state",
        vars: {
          fullName: this.profileStore.lastProtector?.name || "",
          uonCount: this.profileStore.lastProtector?.count || 0,
        },
      };
    }

    //note: below is title of state 1 default campaign for regular user and title of state 2 default campaign for group
    if (!this.profileStore.groupProfileType) {
      return {
        key: "campaigndefault.title.title-update",
        vars: {
          fullName: this.user?.fullName || "",
        },
      };
    }

    return {
      key: "campaigndefault.title.default",
      vars: {
        fullName: this.user?.fullName || "",
        currency: this.profileStore.currency || Currency.EUR,
      },
    };
  }
  @computed get subTitle(): {
    key: string;
    vars?: Record<string, number | string | undefined>;
    raw?: boolean;
  } {
    if (this.isCharityGroup) {
      return {
        key: "campaigndefault.sub-title.charity-group",
        vars: {
          fullName: this.user?.fullName || "",
          currency: this.profileStore.currency || Currency.EUR,
        },
      };
    }
    if (this.isNpoGroup) {
      return {
        key: "campaigndefault.sub-title.npo-group",
        vars: {
          fullName: this.user?.fullName || "",
          uonCount: formatNumber(this.user?.uonCount || 0),
        },
      };
    }

    if (
      this.isCampaignDefaultBrandGroupState1 ||
      this.isCampaignDefaultBrandGroupState2
    ) {
      return {
        key: "campaigndefault.sub-title.brand",
        vars: {
          fullName: this.user?.fullName || "",
          currency: this.profileStore.currency || Currency.EUR,
        },
      };
    }

    if (
      this.state?.toUpperCase() === CampaignDefaultStatus.State2 ||
      this.campaignTitle !== "" ||
      (!this.profileStore.groupProfileType && !this.isLoggedinUser)
    ) {
      return {
        key: "campaigndefault.sub-title.state-two",
        vars: {
          fullName: this.user?.fullName || "",
          currency: this.profileStore.currency || Currency.EUR,
        },
      };
    }

    if (!this.profileStore.groupProfileType) {
      return {
        key: "campaigndefault.sub-title.sub-title-update",
        vars: {
          currency: this.profileStore.currency || Currency.EUR,
        },
      };
    }

    return {
      key: "campaigndefault.sub-title.default",
      vars: {
        fullName: this.user?.fullName || "",
        uonCount: formatNumber(this.user?.uonCount || 0),
      },
    };
  }

  @computed get isLoggedinUser(): boolean {
    return this.profileStore.isLoggedinUser;
  }

  @action closeModal = (): void => {
    this.currentChannelSharing = "";
    this.modalStore.openModal("");
  };

  @action toggleShowContextMenu = (): void => {
    this.contextMenuIsActive = true;
  };
  @action closeShowContextMenu = (): void => {
    this.contextMenuIsActive = false;
  };

  @action.bound onSubmitEdit = flow(function* onSubmitEdit(
    this: CampaignDefaultModel,
  ) {
    this.isEditing = true;

    // only update when has differance
    const newTitle =
      this.titleEdit === this.campaignTitle ? null : this.titleEdit;

    yield this.profileStore.onEditCampaignDefault(
      this.imageCoverEdit,
      newTitle,
      this.isResetBackground,
      this.titleEdit,
    );

    this.closeModal();
    this.closeShowContextMenu();
    this.isEditing = false;
  });

  @action.bound showMessage = (message: IMessage): void => {
    this.theMessageStore.showMessage(message);
  };

  @action.bound updateImageCoverEdit = (cover: File): void => {
    this.imageCoverEdit = cover;
    this.isTouched = true;
  };
  @action.bound updateTitleEdit = (title: string): void => {
    this.titleEdit = title.slice(0, CAMPAIGN_DEFAULT_TITLE_MAX_LENGTH);
    this.isTouched = true;
  };

  @action openModalEditCampaignDefault = (): void => {
    this.modalStore.openLazyModal({
      name: "editCampaignDefault",
      component: (
        <DynamicLazyModalLoader
          loadComponent={() =>
            import(
              "../components/ModalEditCampaignDefault/ModalEditCampaignDefault"
            )
          }
          driver={this}
        />
      ),
    });
  };
  @action openModalShareCampaignDefault = (): void => {
    this.shareCampaignDefault();
    this.modalStore.openLazyModal({
      name: "shareCampaignDefault",
      component: (
        <DynamicLazyModalLoader
          loadComponent={() =>
            import(
              "../components/ModalShareCampaignDefault/ModalShareCampaignDefault"
            )
          }
          driver={this}
        />
      ),
    });
  };

  @action openModalShareLeaderboard = (): void => {
    this.shareLeaderBoard();
    this.modalStore.openLazyModal({
      name: "shareCampaignDefault",
      component: (
        <DynamicLazyModalLoader
          loadComponent={() =>
            import(
              "../../src/components/ModalShareCampaignDefault/ModalShareCampaignDefault"
            )
          }
          driver={this}
        />
      ),
    });
  };

  @action openModalDonate = (dataCy: string): void => {
    this.profileStore.donateToCampaignDefault(dataCy);
  };

  @computed get isValidTitle(): boolean {
    return validateAlphanumericEmoji(`${this.titleEdit}`);
  }

  @computed get isDisable(): boolean {
    const isChanging =
      this.modalBackground?.url !== this.user?.cover?.url ||
      this.titleEdit !== this.campaignTitle;

    return !isChanging || this.isEditing || !this.isValidTitle;
  }

  @action.bound setBackground = (url: string): void => {
    this.isResetBackground = false;
    this.modalBackground = {
      id: "",
      url,
      awsBucket: null,
      awsKey: null,
      isTransparent: null,
    };
  };

  @computed get charactersCount(): number {
    return this.titleEdit?.length || 0;
  }

  @action.bound resetBackground(): void {
    this.isResetBackground = true;
    this.isTouched = true;
    this.modalBackground = null;
  }

  @computed get fullName(): string {
    return this.user?.fullName || "";
  }
  @action.bound resetOnClose(): void {
    if (this.isTouched) {
      this.titleEdit = this.campaignDefault.name;
      this.modalBackground = this.user?.cover || null;
      this.imageCoverEdit = null;
      this.isTouched = false;
      this.isResetBackground = false;
    }
  }

  @action.bound cardDefaultUrl = (social: ChannelSharing) => {
    const time = Date.now();
    // url for sharing defaultCampaign
    const url = `${getConfig().publicRuntimeConfig.REACT_APP_HOST}/${
      this.user?.vanityName
    }?channel=${social}${this.isLoggedinUser ? "&owner=true" : ""}`;

    // url for sharing leaderboard
    if (this.isSharedLeaderBoard) {
      return `${url}&leaderboard=true&ts=${time}`;
    }

    return `${url}&ts=${time}`;
  };
  @computed get shareText(): string {
    if (this.isNpoGroup || this.isCharityGroup) {
      return `Go to ${this.user?.fullName}'s page and start protecting nature for just €1.20 per m².`;
    }
    const text = `${this.isSharedLeaderBoard ? "Join us! " : ""}Go to ${
      this.isLoggedinUser ? "my" : `${this.profileStore.fullName}'s`
    } page and start protecting nature for just €1.20 per m².`;

    return text;
  }

  @action.bound openDefaultCardInNewWindow = (cardUrl: string) => {
    openLinkInNewWindow(cardUrl);
  };

  @action.bound copyLinkToClipboard = flow(function* copyLinkToClipboard(
    this: CampaignDefaultModel,
    cardUrl: string,
  ) {
    yield navigator.clipboard.writeText(cardUrl);

    this.showMessage({
      typeMessage: "Close",
      title: "toast-message.link-copied.title",
      content: "toast-message.link-copied.content",
    });
  });

  @action.bound shareCampaignDefault = (): void => {
    this.isSharedLeaderBoard = false;
  };

  @action.bound shareLeaderBoard = (): void => {
    this.isSharedLeaderBoard = true;
  };

  @computed get isNPOHeaderCardEmptyLeaderboard(): boolean {
    return (
      this.isNpoGroup &&
      this.state?.toUpperCase() === CampaignDefaultStatus.State1.toUpperCase()
    );
  }
  @action.bound fetchDataForShare = flow(function* copyLinkToClipboard(
    this: CampaignDefaultModel,
    channel: ChannelSharing,
  ) {
    this.shareLoading = true;
    this.currentChannelSharing = channel;
    yield this.profileStore.fetchSharingCampaignOrLeaderboard(
      channel,
      this.profileStore.vanityName,
      this.isLoggedinUser,
      this.isSharedLeaderBoard,
    );
    this.shareLoading = false;
  });

  @action.bound updatePreviewImageForSharing(imageUrl: string): void {
    this.previewImageForSharing = imageUrl;
  }

  @computed get titleModalSharing(): string {
    if (
      !this.isSharedLeaderBoard &&
      this.user?.details?.type?.toUpperCase() ===
        UserType.GROUP_ADAPTER.toUpperCase()
    ) {
      return "SHARE HEADER CARD";
    }
    return this.isSharedLeaderBoard ? "SHARE LEADERBOARD" : "SHARE CAMPAIGN";
  }

  @action.bound detectWhatsapp(
    shareText: string,
    shareUrl: string,
  ): Promise<unknown> {
    return detectSharingApp(shareText, shareUrl, SharingApp.WHATSAPP);
  }

  @computed get isCommunityOrPartnerGroup(): boolean {
    return (
      this.user?.groupProfileType === GroupProfileType.COMMUNITY ||
      this.user?.groupProfileType === GroupProfileType.PARTNER
    );
  }

  @computed get profileHeaderCardDisplayType(): ProfileHeaderCardDisplayType {
    return this.campaignDefault.profileHeaderCardDisplayType;
  }

  @computed get hasToggleCounter(): boolean {
    if (!this.isLoggedinUser || !this.isCommunityOrPartnerGroup) return false;
    return (
      this.userSessionStore.user?.groupMemberRole === GroupMemberRole.OWNER ||
      this.userSessionStore.user?.groupMemberRole === GroupMemberRole.ADMIN
    );
  }
  @computed get isUseGlobalCounter(): boolean {
    return (
      this.campaignDefault.profileHeaderCardDisplayType ===
      ProfileHeaderCardDisplayType.GLOBAL
    );
  }
  @action.bound toggleCounterDisplay = flow(function* toggleCounterDisplay(
    this: CampaignDefaultModel,
  ) {
    const newProfileHeaderCardDisplayType =
      this.campaignDefault.profileHeaderCardDisplayType ===
      ProfileHeaderCardDisplayType.CONTRIBUTION
        ? ProfileHeaderCardDisplayType.GLOBAL
        : ProfileHeaderCardDisplayType.CONTRIBUTION;
    try {
      const { profileHeaderCardDisplayType } =
        yield this.campaignDefaultApi.toggleCounterDisplay({
          profileHeaderCardDisplayType: newProfileHeaderCardDisplayType,
        });
      this.campaignDefault.profileHeaderCardDisplayType =
        profileHeaderCardDisplayType;
      if (
        this.campaignDefault.profileHeaderCardDisplayType ===
        ProfileHeaderCardDisplayType.CONTRIBUTION
      ) {
        yield this.profileStore.fetchDefaultCampaignCount();
      }
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    }
  });

  @computed get campaignDefaultBackground(): string {
    const { browserWidth } = this.deviceStore;
    if (
      this.state.toUpperCase() === CampaignDefaultStatus.State2 ||
      this.isNPOHeaderCardEmptyLeaderboard
    ) {
      return getResizeImageUrl(this.cover, {
        width:
          browserWidth > largeScreenUpperLimitValue
            ? CARD_CAMPAIGN_DEFAULT_IMAGE_WIDTH
            : browserWidth > smallScreenUpperLimitValue
            ? CARD_CAMPAIGN_DEFAULT_IMAGE_TABLET_WIDTH
            : CARD_CAMPAIGN_DEFAULT_IMAGE_MOBILE_WIDTH,
        height:
          browserWidth > largeScreenUpperLimitValue
            ? CARD_CAMPAIGN_DEFAULT_IMAGE_HEIGHT
            : CARD_CAMPAIGN_DEFAULT_IMAGE_MOBILE_HEIGHT,
      });
    }

    return getResizeImageUrl(this.cover, {
      width:
        browserWidth > largeScreenUpperLimitValue
          ? CARD_CAMPAIGN_DEFAULT_WITHOUT_LEADERBOARD_IMAGE_WIDTH
          : browserWidth > mediumScreenUpperLimitValue
          ? CARD_CAMPAIGN_DEFAULT_WITHOUT_LEADERBOARD_IMAGE_TABLET_WIDTH
          : CARD_CAMPAIGN_DEFAULT_WITHOUT_LEADERBOARD_IMAGE_MOBILE_WIDTH,
      height:
        browserWidth > mediumScreenUpperLimitValue
          ? CARD_CAMPAIGN_DEFAULT_IMAGE_HEIGHT
          : CARD_CAMPAIGN_DEFAULT_IMAGE_MOBILE_HEIGHT,
    });
  }
}
