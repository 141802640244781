import { isTranslateEnv } from "../env";

export const initPhrase = () => {
  if (isTranslateEnv) {
    Object.assign(window || {}, {
      PHRASEAPP_CONFIG: {
        projectId: "69a8473e794f82df12b9f69c0a49d64b",
        prefix: "{{__",
        suffix: "__}}",
        fullReparse: true,
        scriptAutoLoad: true,
      },
    });

    const phraseapp = document.createElement("script");
    phraseapp.type = "text/javascript";
    phraseapp.async = true;
    phraseapp.src = [
      "https://",
      "phraseapp.com/assets/in-context-editor/2.0/app.js?",
      Date.now(),
    ].join("");
    const s = document.querySelectorAll("script")[0];
    s.parentNode?.insertBefore(phraseapp, s);
  }
};
