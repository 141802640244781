const trimAndUppercase = (s: string): string => {
  if (!s) return "";
  return s
    .trim()
    .split(" ")
    .reduce((str, char) => {
      return str + char.replace(char[0], char[0]?.toUpperCase());
    }, "#");
};
export default trimAndUppercase;
