import styled from "styled-components";

import {
  mediumScreenLowerLimit,
  mediumScreenUpperLimit,
} from "../../shared/breakpoints";
import { etBlack, etBlue, etWhite } from "../../shared/colors";
import transparentize from "../../shared/helpers/transparentize";
import zIndex from "../../shared/zIndex";
import { IconSvgContainer } from "../IconSvg/IconSvg.styled";
import footerSc from "./CardItemMusicFooter.styled";

export const CARD_ITEM_MUSIC_DOUBLE_SIZE_HEIGHT: number = 301;
export const CARD_ITEM_MUSIC_DOUBLE_SIZE_WIDTH: number = 300;
export const CARD_ITEM_MUSIC_HEIGHT: number = 230;
export const CARD_ITEM_MUSIC_WIDTH: number = 230;

export namespace sc {
  export const ContextMenuContainer = styled.div`
    width: 32px;
    height: 44px;
  `;
  export const Header = styled.div`
    display: flex;
    align-items: center;
    height: 44px;
    width: 100%;
    cursor: pointer;
  `;
  export const HeaderTitle = styled.a<{ iconColor: string }>`
    flex: 1;
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-weight: normal;
    font-stretch: Condensed;
    font-style: normal;
    font-size: 14px;
    font-weight: normal;
    height: 100%;
    width: calc(100% - 26px);
    letter-spacing: 0px;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${etWhite};

    > ${IconSvgContainer} {
      margin-right: 6px;
    }

    &:hover {
      color: ${etWhite};
    }

    > div > svg {
      height: 18px;
      width: 19px;
      > g > g {
        stroke: ${(props) => props.iconColor};
        fill: ${(props) => props.iconColor};
      }
    }
  `;

  export const CoverContainer = styled.div`
    width: 304px;
    height: 304px;

    display: flex;
    flex-direction: column;
    position: relative;

    padding-top: 12px;
    border-bottom: 1px solid ${transparentize(etWhite, 0.25)};
  `;
  export const OverlayCopy = styled.div`
    opacity: 0.15;
    height: 230px;
    width: 230px;
    border-radius: 50%;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${etBlack};
    @media (max-width: ${mediumScreenUpperLimit}) {
      display: none;
    }
  `;
  export const CoverOverlay = styled.div`
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    .cardDouble-actions {
      width: 656px;
    }
  `;
  export const PreviewImage = styled.div<{ src: string }>`
    margin: 0 auto 0 auto;
    height: ${CARD_ITEM_MUSIC_HEIGHT}px;
    width: ${CARD_ITEM_MUSIC_WIDTH}px;
    border-radius: 50%;
    overflow: hidden;
    background-image: url(${(props) => props.src});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    position: relative;
    .cardDouble-overlay {
      height: 100%;
      width: 100%;
    }
    .cardDouble-source {
      left: 130px;
    }
  `;
  export const OverlaySource = styled.div`
    content: "";
    width: 100%;
    height: 44px;
    opacity: 0.6;
    background: linear-gradient(
      -180deg,
      rgba(0, 0, 0, 0) 0%,
      rgb(0, 0, 0) 100%
    );
    position: absolute;
    bottom: 0;
    z-index: 1;
  `;
  export const CardSource = styled.a`
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 9px;
    left: 95px;
    z-index: ${zIndex.CARDITEM_LINK};
    text-transform: uppercase;
    height: 20px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${etWhite};
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: ${etWhite};
      text-decoration: none;
    }
  `;

  export const Body = styled.a`
    width: 100%;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: ${etWhite};

    &:hover {
      color: ${etWhite};
      text-decoration: none;
    }

    display: flex;
    flex-direction: column;
  `;
  export const BodyTitle = styled.div<{ isCardSizeDouble: boolean }>`
    margin-top: ${(props) => (props.isCardSizeDouble ? "17px" : "10px")};
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font-family: "Roboto Slab";
    font-size: 18px;
  `;
  export const BodyAuthor = styled.div``;

  export const ContainerContent = styled.div<{ isActive: boolean }>`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: ${(props) => (props.isActive ? "17" : "16")};

    padding: 0 12px;

    display: flex;
    flex-direction: column;
  `;
  export const ArrowLink = styled.div`
    visibility: hidden;
    display: inline-block;
    @media (max-width: ${mediumScreenUpperLimit}) {
      display: none;
    }
  `;
  export const Container = styled.div<{ bg: string }>`
    position: relative;
    height: 100%;
    min-height: 496px;
    position: relative;
    background-color: ${etBlack};
    color: ${etWhite};
    &:hover,
    &.hover {
      ${OverlayCopy} {
        visibility: visible;
      }

      ${CoverOverlay} {
        visibility: visible;
      }
      ${CoverContainer} {
        @media (min-width: ${mediumScreenUpperLimit}) {
          border-bottom-color: transparent;
        }
      }
      ${ArrowLink} {
        visibility: visible;
      }
    }
    &.double-size {
      ${footerSc.CardFooterText} {
        display: none;
      }
    }

    @media (min-width: ${mediumScreenLowerLimit}) {
      &.double-size {
        ${CoverContainer} {
          height: 402px;
          flex: 1;
        }
        ${PreviewImage} {
          margin-top: 0;
          width: ${CARD_ITEM_MUSIC_DOUBLE_SIZE_WIDTH}px;
          height: ${CARD_ITEM_MUSIC_DOUBLE_SIZE_HEIGHT}px;
        }

        ${footerSc.ContainerDouble} {
          min-height: 50px;
          height: 50px;

          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
        ${footerSc.CardFooterTextContainer} {
          width: auto;
          height: auto;
          margin: 0;
        }
        ${footerSc.ReadMore} {
          position: relative;
          bottom: 0;
        }
      }
    }
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 9;
      background: url(${(props) => props.bg});
      background-repeat: no-repeat;
      background-size: cover;
      -webkit-filter: blur(10px);
      filter: blur(10px);
    }
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 10;
      background-color: rgba(0, 0, 0, 0.5);
      background-repeat: no-repeat;
      background-size: cover;
    }
  `;

  export const BlackOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    background: ${etBlack};
    border-radius: 8px;
    height: 100%;
    width: 100%;
    opacity: 0.6;
    z-index: 15;
  `;

  export const RemovedCardBody = styled.div`
    text-align: center;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    flex: 3;
    color: ${etWhite};
    background-color: ${etBlack};
    padding: 1rem 1rem 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    height: 304px;
    font-weight: 600;
  `;
  export const RemovedCardBodyTitle = styled.div`
    font-weight: bold;
    font-size: 0.625rem;
    padding: 1rem 0;
  `;
  export const RemovedCardBodyText = styled.div`
    font-size: 0.875rem;
    font-weight: lighter;
  `;

  export const Wrap = styled.div`
    display: flex;
    justify-content: center;
  `;

  export const OverlayActions = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 13;
    margin-top: 12px;
    width: 304px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: ${etWhite};
    &.active {
      border: 1px solid ${etBlue};
    }
    @media (max-width: ${mediumScreenUpperLimit}) {
      display: none;
    }
  `;

  export const OpenCard = styled.a`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    z-index: 13;
  `;

  export const OverlayLinkContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 91%;
    z-index: ${zIndex.OVERLAY_LINK};
    > a {
      display: inline-block;
      width: 100%;
      height: 100%;
      text-decoration: none;
      :hover {
        text-decoration: none;
      }
    }
  `;

  export const SaveButtonWrapper = styled.div<{ isButtonHiden: boolean }>`
    display: ${(props) => (props.isButtonHiden ? "none" : "block")};
  `;
}
