/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */

import {
  i18n as nextI18n,
  useTranslation as nextUseTranslation,
} from "next-i18next";

import { isTranslateEnv } from "../env";

const __prefix = "{{__phrase_";
const __suffix = "__}}";

const toPhraseKey = (input: string) => `${__prefix}${input}${__suffix}`;

const useTranslation = function (ns?: string) {
  if (isTranslateEnv) {
    return { t: toPhraseKey };
  }
  return nextUseTranslation(ns);
};
const i18n = isTranslateEnv
  ? {
      t: toPhraseKey,
      language: nextI18n?.language,
      getFixedT: (lang: any, ns: string) => toPhraseKey,
    }
  : nextI18n;

export declare type UseTranslation = typeof useTranslation;

export { i18n, useTranslation };
