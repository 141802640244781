// ts-gql-integrity:1401489f96bc0cc62895140657978989
/*
ts-gql-meta-begin
{
  "hash": "df2bf0f2435ad61aa9fc2ebe26da4a64"
}
ts-gql-meta-end
*/

import * as SchemaTypes from "./@schema";
import { TypedDocumentNode } from "@ts-gql/tag";

type uonCountUpdatedSubscriptionVariables = SchemaTypes.Exact<{ [key: string]: never; }>;


type uonCountUpdatedSubscription = { readonly __typename: 'Subscription', readonly uonCountUpdated: { readonly __typename: 'UonCountOutput', readonly count: number } | null };


      
export type type = TypedDocumentNode<{
  type: "subscription";
  result: uonCountUpdatedSubscription;
  variables: {};
  documents: SchemaTypes.TSGQLDocuments;
  fragments: SchemaTypes.TSGQLRequiredFragments<"none">
}>

declare module "./@schema" {
  interface TSGQLDocuments {
    uonCountUpdated: type;
  }
}

export const document = JSON.parse("{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"subscription\",\"name\":{\"kind\":\"Name\",\"value\":\"uonCountUpdated\"},\"variableDefinitions\":[],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"uonCountUpdated\"},\"arguments\":[],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"count\"},\"arguments\":[],\"directives\":[]}]}}]}}]}")
