import { VanityNameGeneratorStrategy } from "./VanityNameGenerator";

export class StrategyFullNameAbbreviation
  implements VanityNameGeneratorStrategy
{
  private abbreviateIndex = 0;

  constructor(private nameParts: string[]) {}

  generate(): { vanityName: string; next: boolean } {
    const abbrebriateName = this.nameParts[this.abbreviateIndex];
    const abbrebrivation = abbrebriateName.slice(0, 1);

    const vanityName =
      this.nameParts.slice(0, this.abbreviateIndex).join("") +
      abbrebrivation +
      this.nameParts
        .slice(this.abbreviateIndex + 1, this.nameParts.length)
        .join("");
    if (this.abbreviateIndex >= this.nameParts.length - 1) {
      return { vanityName, next: true };
    }

    this.abbreviateIndex += 1;
    return { vanityName, next: false };
  }
}
