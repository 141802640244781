import { observer } from "mobx-react-lite";
import React from "react";

import { useRootStore } from "../../stores/rootStore";
import { TheMessageLayout } from "./TheMessageLayout";

export default observer(function () {
  const rootStore = useRootStore();
  const { isShowMessage, message, close } = rootStore.theMessageStore;

  return (
    <TheMessageLayout
      isActive={isShowMessage}
      onClose={close}
      message={message}
    />
  );
});
