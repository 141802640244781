import { StrategyFirstLastName } from "./StrategyFirstLastName";
import { StrategyFirstLastNameAbbreviation } from "./StrategyFirstLastNameAbbreviation";
import { StrategyFullName } from "./StrategyFullName";
import { StrategyFullNameAbbreviation } from "./StrategyFullNameAbbreviation";
import { StrategyMiddleNameAbbreviation } from "./StrategyMiddleNameAbbreviation";
import {
  RandomAlphanumericFunction,
  VanityNameGeneratorStrategy,
} from "./VanityNameGenerator";

export class StrategyPostfixRandomAlphanumeric
  implements VanityNameGeneratorStrategy
{
  private strategies: VanityNameGeneratorStrategy[];

  private activeStrategyIndex = 0;

  constructor(
    private nameParts: string[],
    private randomAlphanumeric: RandomAlphanumericFunction,
  ) {
    this.strategies = [
      new StrategyFirstLastName(this.nameParts),
      new StrategyFullName(this.nameParts),
      new StrategyFullNameAbbreviation(this.nameParts),
      new StrategyMiddleNameAbbreviation(this.nameParts),
      new StrategyFirstLastNameAbbreviation(this.nameParts),
    ];
  }

  generate(): { vanityName: string; next: boolean } {
    const strategy = this.strategies[this.activeStrategyIndex];
    const { vanityName, next } = strategy.generate();
    if (next) {
      this.activeStrategyIndex += 1;
    }
    if (this.activeStrategyIndex >= this.strategies.length) {
      this.activeStrategyIndex = 0;
    }

    return {
      vanityName: `${vanityName}-${this.randomAlphanumeric()}`,
      next: false,
    };
  }
}
