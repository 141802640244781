/* eslint-disable unicorn/filename-case */
import {
  etBrightLightBlue,
  etGreen,
  etLightBlue,
  etOrange,
  etPurpleUonCard,
  etRed,
  etYellowDeck,
} from "../colors";

export function getUONColor(uonCount: number) {
  if (uonCount <= 4) {
    return etRed;
  }
  if (uonCount > 4 && uonCount <= 9) {
    return etOrange;
  }
  if (uonCount > 9 && uonCount <= 19) {
    return etYellowDeck;
  }
  if (uonCount > 19 && uonCount <= 49) {
    return etBrightLightBlue;
  }
  if (uonCount > 49 && uonCount <= 99) {
    return etGreen;
  }
  if (uonCount > 99 && uonCount <= 499) {
    return etLightBlue;
  }

  if (uonCount > 499 && uonCount < 1000) {
    return etPurpleUonCard;
  }
  if (uonCount >= 1000 && uonCount < 5000) {
    return etRed;
  }
  return etYellowDeck;
}
