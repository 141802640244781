import { FlapDisplay } from "earthtoday-react-split-flap-effect";
import { observer } from "mobx-react-lite";

import { CampaignDefaultStatus } from "../../stores/CampaignDefaultModel";
import {
  getBreakPointsFlapEffect,
  UonCountNumberLocation,
} from "./StylesUonCountNumberAnimation";
import sc from "./UonCountNumberAnimation.styled";

interface IProps {
  lastNumber: number;
  countNumbers: number[];
  location: UonCountNumberLocation;
  noAnimationCounter?: boolean;

  state?: CampaignDefaultStatus; // only for default campaign

  enableOneFlow?: boolean;
  dataCy?: string;
  flags: {
    counterFlapEffectDuration: number;
  };
}

export const UonCountNumberAnimation = observer((props: IProps) => {
  const { col3, col2, col1 } = getBreakPointsFlapEffect(
    props.location,
    props.state,
  );
  return (
    <sc.Container
      data-cy={props.dataCy}
      col3={col3}
      col2={col2}
      col1={col1}
      enableOneFlow={props.enableOneFlow || false}
      isNewProtectPage={
        props.location === UonCountNumberLocation.NewProtectPage
      }
    >
      <FlapDisplay
        className={`flap ${props.noAnimationCounter && "no-animation"}`}
        length={1}
        timing={props.flags.counterFlapEffectDuration}
        value={`${props.lastNumber}`}
      />
      <sc.Chain
        className="uon-counter-number-chain"
        col3={col3}
        col2={col2}
        col1={col1}
        enableOneFlow={props.enableOneFlow || false}
        isNewProtectPage={
          props.location === UonCountNumberLocation.NewProtectPage
        }
      />
    </sc.Container>
  );
});
