import { formatUonNumber } from "../../components/CardItemTransactionStandard/helper/formatUonNumber";

export interface OptionBoxUonGift {
  option1: number;
  option2: number;
  option3: number | string;
  hasOtherOption: boolean;
}
export function getOptionBoxUonGift(uonNumber: number): OptionBoxUonGift {
  let optionBox: OptionBoxUonGift = {
    option1: 0,
    option2: 0,
    option3: 0,
    hasOtherOption: uonNumber > 2,
  };

  if (uonNumber === 1) {
    optionBox = { ...optionBox, option1: uonNumber, option2: 0, option3: 0 };
  }

  if (uonNumber >= 2 && uonNumber <= 5) {
    optionBox = {
      ...optionBox,
      option1: 1,
      option2: uonNumber,
      option3: 0,
    };
  }

  if (uonNumber > 5 && uonNumber <= 50) {
    optionBox = {
      ...optionBox,
      option1: 1,
      option2: 5,
      option3: uonNumber,
    };
  }

  if (uonNumber > 10 && uonNumber <= 50) {
    optionBox = {
      ...optionBox,
      option1: 5,
      option2: 10,
      option3: uonNumber,
    };
  }
  if (uonNumber > 50 && uonNumber <= 100) {
    optionBox = {
      ...optionBox,
      option1: 10,
      option2: 50,
      option3: uonNumber,
    };
  }

  if (uonNumber > 100 && uonNumber <= 500) {
    optionBox = {
      ...optionBox,
      option1: 50,
      option2: 100,
      option3: uonNumber,
    };
  }
  if (uonNumber > 500 && uonNumber <= 1000) {
    optionBox = {
      ...optionBox,
      option1: 100,
      option2: 500,
      option3: uonNumber,
    };
  }

  if (uonNumber > 1000) {
    optionBox = {
      ...optionBox,
      option1: 500,
      option2: 1000,
      option3: uonNumber >= 10000 ? formatUonNumber(uonNumber) : uonNumber,
    };
  }

  return optionBox;
}
