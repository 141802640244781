import { observer } from "mobx-react-lite";

import { etWhite } from "../../shared/colors";
import { getCurrencySymbol } from "../../shared/helpers/getCurrencySymbol";
import { useTranslation } from "../../shared/translate/NextI18next";
import { Currency } from "../../stores/DonateStore/DonateStore";
import { IconSvg } from "../IconSvg/IconSvg";
import { sc } from "./InfoBubble.styled";

export interface InfoBubbleLayoutDriver {
  currency: Currency;
  isActiveBubble: boolean;
  onInfoIconClicked(): void;
  onOverlayClicked(): void;
}
interface IProps {
  driver: InfoBubbleLayoutDriver;
}

export const InfoBubbleLayout = observer((props: IProps) => {
  const { t } = useTranslation("ToastMessage");

  return (
    <sc.Container>
      <sc.IconWrapper>
        <IconSvg
          dataCy="InfoBubbleLayout-Icon"
          icon="i-light-blue"
          padding="0"
          width="15px"
          height="15px"
          pointer={true}
          backgroundColor={etWhite}
          onClick={props.driver.onInfoIconClicked}
        />
      </sc.IconWrapper>

      {props.driver.isActiveBubble && (
        <>
          <sc.InfoContent>
            {t("toast-message.payment.tax", {
              currency: getCurrencySymbol(props.driver.currency),
            })}
          </sc.InfoContent>
          <sc.InfoOverlay onClick={props.driver.onOverlayClicked} />
        </>
      )}
    </sc.Container>
  );
});
