/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable class-methods-use-this */
import { action, computed, flow, makeObservable, observable } from "mobx";
import getConfig from "next/config";
import Router from "next/router";
import { TFunction } from "next-i18next";
import { ReactNode } from "react";

import { CardSystemDriver } from "../components/CardSystem/CardSystem";
import { DangerouslyHTMLRenderer } from "../components/DangerouslyHTMLRenderer/DangerouslyHTMLRenderer";
import { DynamicLazyModalLoader } from "../components/DynamicModalLoader/DynamicLazyModalLoader";
import { InfoBubble } from "../components/InfoBubble/InfoBubble";
import { IUserSessionStore } from "../components/ModalLogin/UserSessionStore";
import {
  IMessage,
  ITheMessageStore,
} from "../components/TheMessage/TheMessageStore";
import { SystemDeckApi } from "../shared/apis/SystemDeckApi";
import {
  detectSharingApp,
  SharingApp,
} from "../shared/helpers/detectSharingApp";
import { getCurrencySymbol } from "../shared/helpers/getCurrencySymbol";
import { getResizeImageUrl } from "../shared/helpers/getResizeImageUrl";
import { openLinkInNewWindow } from "../shared/helpers/openLinkInNewWindow";
import { truncateWithEllipsis } from "../shared/helpers/truncateWithEllipsis";
import { IConsumer } from "../shared/models/Consumer";
import { GroupProfileType, UserType } from "../shared/models/User";
import {
  CampaignDefaultStatus,
  ChannelSharing,
  ICoverImage,
} from "./CampaignDefaultModel";
import { Currency } from "./DonateStore/DonateStore";
import { FeatureFlaggingData } from "./FeatureFlaggingStore";
import { IModalStore } from "./ModalStore";
import { SystemDeckParam, SystemDeckStore } from "./SystemDeckStore";

const CARD_SYSTEM_PHOTO_WIDTH_DEFAULT: number = 80;
const CARD_SYSTEM_PHOTO_HEIGHT_DEFAULT: number = 80;
const CARD_SYSTEM_COVER_WIDTH_DEFAULT: number = 304;
const CARD_SYSTEM_COVER_HEIGHT_DEFAULT: number = 472;

type CardSystemImage = Pick<
  ICoverImage,
  "id" | "url" | "awsKey" | "awsBucket" | "isTransparent"
>;
type ReserveResponse = {
  id: string;
  name: string;
  country: string;
  state: string;
  deckId: string;
};

export type ICardSystem = {
  id: string;
  campaignId?: string;
  photo: CardSystemImage;
  cover?: CardSystemImage;
  title?: string;
  fullName: string;
  vanityName: string;
  contributionCount?: number; // for userBrand
  headerCount?: number; // for userCharity
  protectedCount?: number; // for userNPO
  bio?: string;
  quote?: string;
  state?: CampaignDefaultStatus;
  lastProtector?: Pick<IConsumer, "count" | "name">;
  reserve?: ReserveResponse;
  isDisabledProtect?: boolean;
};

export class CardSystemStore implements CardSystemDriver {
  @observable cardSystem: ICardSystem;
  @observable cardSystemUserType: GroupProfileType | UserType | null;
  @observable previewImageForSharing: string = "";
  @observable pinterestTitle: string = "";
  @observable isSharedLeaderBoard: boolean = false;
  @observable isDonatorBrandPublished: boolean = true; //default value for system page and update it in UON open page

  @observable currentChannelSharing: ChannelSharing | "" = "";

  @observable shareLoading: boolean = false;

  constructor(
    cardSystem: ICardSystem,
    private systemDeckStore: SystemDeckStore,
    private modalStore: IModalStore,
    private theMessageStore: ITheMessageStore,
    private systemDeckApi: SystemDeckApi,
    private userSessionStore: IUserSessionStore,
    private featureFlaggingData: FeatureFlaggingData,
    private isCardOnUonOpenPage?: boolean,
  ) {
    makeObservable(this);
    this.cardSystem = cardSystem;
    this.cardSystemUserType = this.systemDeckStore.cardSystemUserType;
  }

  toJSON(): ICardSystem {
    return this.cardSystem;
  }
  @computed get id(): string {
    return this.cardSystem.id;
  }

  @computed get coverUrl(): string {
    return getResizeImageUrl(this.cardSystem.cover || null, {
      width: CARD_SYSTEM_COVER_WIDTH_DEFAULT,
      height: CARD_SYSTEM_COVER_HEIGHT_DEFAULT,
    });
  }

  @action.bound onCardSystemLinkClicked() {
    Router.push(`/${this.vanityName}`);
  }

  @computed get avatarUrl(): string {
    return getResizeImageUrl(this.cardSystem.photo, {
      width: CARD_SYSTEM_PHOTO_WIDTH_DEFAULT,
      height: CARD_SYSTEM_PHOTO_HEIGHT_DEFAULT,
    });
  }

  @computed get uonCount(): number {
    return (
      this.cardSystem.contributionCount ||
      this.cardSystem.headerCount ||
      this.cardSystem.protectedCount ||
      0
    );
  }

  @computed get currency(): Currency {
    return this.systemDeckStore.donateStore.currency;
  }
  @computed get fullName(): string {
    return this.cardSystem.fullName;
  }

  @computed get vanityName(): string {
    return this.cardSystem.vanityName;
  }

  @computed get state(): CampaignDefaultStatus | undefined {
    return this.cardSystem.state;
  }

  @computed get reserveResponse(): ReserveResponse | undefined {
    return this.cardSystem.reserve;
  }

  @computed get isCardSystemBrandGroupState1(): boolean {
    return (
      this.isGroupBrand &&
      this.state?.toUpperCase() === CampaignDefaultStatus.State1.toUpperCase()
    );
  }

  @computed get isLoggedinUser(): boolean {
    return this.userSessionStore.user?.id === this.id;
  }

  @computed get isGroupBrand(): boolean {
    return !!(
      this.cardSystem.campaignId &&
      this.cardSystemUserType === GroupProfileType.BRAND
    );
  }

  @computed get isCardSystemBrandGroupState2(): boolean {
    return (
      this.isGroupBrand &&
      this.state?.toUpperCase() === CampaignDefaultStatus.State2.toUpperCase()
    );
  }

  @computed get isCardSystemConSumerState2(): boolean {
    return (
      this.cardSystemUserType === UserType.CONSUMER &&
      this.state?.toUpperCase() === CampaignDefaultStatus.State2
    );
  }

  @computed get isCardSystemGroupState1(): boolean {
    return (
      this.isGroupBrand &&
      this.state?.toUpperCase() === CampaignDefaultStatus.State1
    );
  }

  @computed get isDisabledProtect(): boolean {
    return this.cardSystem.isDisabledProtect ?? false;
  }

  @computed get currentPage(): SystemDeckParam | null {
    return this.systemDeckStore.currentPage;
  }

  @action getCardSystemTitle(t: TFunction): ReactNode {
    if (this.cardSystem.title) {
      return <p>{truncateWithEllipsis(this.cardSystem.title, 70)}</p>;
    }

    if (this.cardSystem.quote) {
      return <p>{truncateWithEllipsis(this.cardSystem.quote, 70)}</p>;
    }

    if (this.cardSystemUserType === UserType.CHARITY) {
      return (
        <p>
          <DangerouslyHTMLRenderer>
            {t("cardsystem.title.charity")}
          </DangerouslyHTMLRenderer>
        </p>
      );
    }

    if (this.cardSystemUserType === UserType.NPO) {
      return (
        <p>
          <DangerouslyHTMLRenderer>
            {t("cardsystem.title.npo", {
              country: this.reserveResponse?.country,
            })}
          </DangerouslyHTMLRenderer>
        </p>
      );
    }

    if (this.cardSystemUserType === UserType.CONSUMER) {
      return (
        <p>
          <DangerouslyHTMLRenderer>
            {`${this.fullName} ${t("cardsystem.title.consumer-update")}`}
          </DangerouslyHTMLRenderer>
        </p>
      );
    }

    if (
      this.isCardSystemBrandGroupState1 ||
      this.isCardSystemBrandGroupState2
    ) {
      if (this.isCardOnUonOpenPage) {
        return (
          <p>
            <DangerouslyHTMLRenderer>
              {t("cardsystem.title.brand-state-1-or-2", {
                fullName: this.fullName || "",
              })}
            </DangerouslyHTMLRenderer>
          </p>
        );
      }

      return (
        <p>
          {this.fullName}
          <span>
            <DangerouslyHTMLRenderer>
              {t("cardsystem.title.consumer-update")}
            </DangerouslyHTMLRenderer>
          </span>
        </p>
      );
    }

    if (this.isCardSystemGroupState1) {
      return (
        <p>
          <DangerouslyHTMLRenderer>
            {t("cardsystem.title.group-has-state", {
              fullName: this.fullName,
              uonCount: this.uonCount.toLocaleString("de-DE"),
            })}
          </DangerouslyHTMLRenderer>
        </p>
      );
    }

    if (this.isCardSystemConSumerState2) {
      return (
        <p>
          <DangerouslyHTMLRenderer>
            {t("cardsystem.title.consumer-has-state", {
              fullName: this.cardSystem.lastProtector?.name || "",
              uonCount: this.cardSystem.lastProtector?.count || 0,
            })}
          </DangerouslyHTMLRenderer>
        </p>
      );
    }

    //note: below is title of state 1 default campaign for regular user and title of state 2 default campaign for group
    return (
      <p>
        <DangerouslyHTMLRenderer>
          {t("cardsystem.title.default", {
            fullName: this.fullName,
            currency: getCurrencySymbol(this.currency),
          })}
        </DangerouslyHTMLRenderer>
        &nbsp;
        <InfoBubble driver={this} />
      </p>
    );
  }
  @action getSubTitle(t: TFunction): ReactNode {
    if (this.cardSystemUserType === UserType.CHARITY) {
      return (
        <p>
          <DangerouslyHTMLRenderer>
            {t("cardsystem.sub-title.charity", {
              fullName: this.fullName,
              currency: getCurrencySymbol(this.currency),
            })}
          </DangerouslyHTMLRenderer>
        </p>
      );
    }

    if (this.cardSystemUserType === UserType.NPO) {
      return (
        <p>
          <DangerouslyHTMLRenderer>
            {t("cardsystem.sub-title.npo", {
              fullName: this.reserveResponse?.name,
              uonCount: this.uonCount.toLocaleString("de-DE"),
            })}
          </DangerouslyHTMLRenderer>
        </p>
      );
    }

    if (
      this.isCardSystemBrandGroupState1 ||
      this.isCardSystemBrandGroupState2
    ) {
      return (
        <p>
          <DangerouslyHTMLRenderer>
            {t("cardsystem.sub-title.update", {
              currency: getCurrencySymbol(this.currency),
            })}
          </DangerouslyHTMLRenderer>
          &nbsp;
          <InfoBubble driver={this} />
        </p>
      );
    }

    if (this.cardSystemUserType === UserType.CONSUMER) {
      // topproector
      return (
        <p>
          <DangerouslyHTMLRenderer>
            {t("cardsystem.sub-title.update", {
              currency: getCurrencySymbol(this.currency),
            })}
          </DangerouslyHTMLRenderer>
          &nbsp;
          <InfoBubble driver={this} />
        </p>
      );
    }

    if (
      this.state?.toUpperCase() === CampaignDefaultStatus.State2 ||
      this.cardSystem.title !== ""
    ) {
      return (
        <p>
          <DangerouslyHTMLRenderer>
            {t("cardsystem.sub-title.default-state-two", {
              fullName: this.fullName,
              currency: getCurrencySymbol(this.currency),
            })}
          </DangerouslyHTMLRenderer>
          &nbsp;
          <InfoBubble driver={this} />
        </p>
      );
    }

    return (
      <p>
        <DangerouslyHTMLRenderer>
          {t("cardsystem.sub-title.default", {
            fullName: this.fullName,
            uonCount: this.uonCount.toLocaleString("de-DE"),
          })}
        </DangerouslyHTMLRenderer>
      </p>
    );
  }

  @computed get npoReserveName(): ReactNode {
    if (this.cardSystemUserType === UserType.NPO) {
      return <p>{this.reserveResponse?.name}</p>;
    }

    return null;
  }

  @computed get npoReserveLocation(): ReactNode {
    if (
      this.cardSystemUserType === UserType.NPO &&
      this.reserveResponse?.state
    ) {
      return (
        <p>
          {this.reserveResponse?.state}, {this.reserveResponse?.country}
        </p>
      );
    }

    if (this.cardSystemUserType === UserType.NPO) {
      return <p>{this.reserveResponse?.country}</p>;
    }

    return null;
  }

  @action closeModal = (): void => {
    this.currentChannelSharing = "";
    this.modalStore.openModal("");
  };

  @action.bound showMessage = (message: IMessage): void => {
    this.theMessageStore.showMessage(message);
  };

  @action.bound cardDefaultUrl = (social: string) => {
    const time = Date.now();

    const url = `${getConfig().publicRuntimeConfig.REACT_APP_HOST}/${
      this.cardSystem.vanityName
    }?channel=${social}${this.isLoggedinUser ? "&owner=true" : ""}`;

    return `${url}&ts=${time}`;
  };

  @action.bound fetchSharingCampaigngnOrLeaderboardImage = flow(
    function* fetchSharingCampaigngnOrLeaderboardImage(
      this: CardSystemStore,
      vanityName: string,
      channel: ChannelSharing,
    ) {
      const res =
        yield this.systemDeckApi.fetchSharingCampaigngnOrLeaderboardData(
          vanityName,
          channel,
        );
      this.previewImageForSharing = res["og:image"];
    },
  );

  @action openModalShareCampaignDefault = (): void => {
    this.modalStore.openLazyModal({
      name: "shareCampaignDefault",
      component: (
        <DynamicLazyModalLoader
          loadComponent={() =>
            import(
              "../components/ModalShareCampaignDefault/ModalShareCampaignDefault"
            )
          }
          driver={this}
        />
      ),
    });
  };

  @action donateToCampaignDefault = (): void => {
    if (this.cardSystem === null) {
      return;
    }

    const sourceId =
      this.cardSystemUserType === UserType.CHARITY ||
      this.cardSystemUserType === UserType.NPO
        ? this.id
        : this.cardSystem.campaignId || "";

    if (this.cardSystem.isDisabledProtect) {
      this.systemDeckStore.donateStore.updateNpoId("");
      this.systemDeckStore.donateStore.prepareCampaignDonation(
        this.cardSystem,
        sourceId,
      );
      return;
    }

    if (this.cardSystemUserType === UserType.NPO) {
      this.systemDeckStore.donateStore.updateNpoId(sourceId);
      this.modalStore.openModal("donate");
      return;
    }

    if (this.cardSystemUserType === UserType.CHARITY) {
      this.systemDeckStore.donateStore.prepareCharityDonation(
        this.cardSystem.fullName || "",
        sourceId,
      );
      return;
    }

    this.systemDeckStore.donateStore.prepareCampaignDonation(
      this.cardSystem,
      sourceId,
    );
  };

  @action.bound charityDonate = (): void => {
    this.systemDeckStore.donateStore.campaignSource = null;
    this.systemDeckStore.donateStore.charityProfile = {
      name: this.fullName,
      id: this.id,
    };
    this.systemDeckStore.donateStore.openPreselection();
  };

  @action.bound prepareNpoCampaignDonation = (): void => {
    this.systemDeckStore.donateStore.campaignSource = null;
    this.systemDeckStore.donateStore.updateNpoId(
      this.cardSystem.isDisabledProtect === true ? "" : this.id,
    );
    this.systemDeckStore.donateStore.openPreselection();
  };

  @action.bound prepareCampaignDonation = (): void => {
    this.systemDeckStore.donateStore.campaignSource = {
      fullName: this.fullName,
      campaignId: this.campaignId,
    };
    this.systemDeckStore.donateStore.openPreselection();
  };

  @action.bound openPreselectionModal(dataCy: string): void {
    this.systemDeckStore.donateStore.updateTriggerPointDataCy(dataCy);
    // donate to charity group's campaign
    if (
      this.cardSystemUserType?.toLocaleLowerCase() ===
      GroupProfileType.CHARITY.toLocaleLowerCase()
    ) {
      this.charityDonate();
      return;
    }
    // donate to NPO's reserves
    if (
      this.cardSystemUserType?.toLocaleLowerCase() ===
      GroupProfileType.NPO.toLocaleLowerCase()
    ) {
      this.prepareNpoCampaignDonation();
      return;
    }
    // donate to brand's campaign and topprotector's campaign
    this.prepareCampaignDonation();
  }

  @computed get shareText(): string {
    const text = `Go to ${
      this.isLoggedinUser ? "my" : `${this.fullName}'s`
    } page and start protecting nature for just €1.20 per m².`;
    return text;
  }

  @action.bound openDefaultCardInNewWindow = (cardUrl: string): void => {
    openLinkInNewWindow(cardUrl);
  };

  @action.bound copyLinkToClipboard = flow(function* copyLinkToClipboard(
    this: CardSystemStore,
    cardUrl: string,
  ) {
    yield navigator.clipboard.writeText(cardUrl);

    this.showMessage({
      typeMessage: "Close",
      title: "toast-message.link-copied.title",
      content: "toast-message.link-copied.content",
    });
  });

  @computed get campaignId(): string {
    return this.cardSystem.campaignId || "";
  }

  @action.bound updateDonatorBrandPublished(b: boolean): void {
    this.isDonatorBrandPublished = b;
  }

  @action.bound fetchDataForShare = flow(function* copyLinkToClipboard(
    this: CardSystemStore,
    channel: ChannelSharing,
  ) {
    this.shareLoading = true;
    this.previewImageForSharing = "";
    this.currentChannelSharing = channel;
    yield this.fetchSharingCampaigngnOrLeaderboardImage(
      this.vanityName,
      channel,
    );
    this.shareLoading = false;
  });

  //not have sharing leaderboard in system page
  @computed get titleModalSharing(): string {
    return "SHARE CAMPAIGN";
  }
  @action.bound detectWhatsapp(
    shareText: string,
    shareUrl: string,
  ): Promise<unknown> {
    return detectSharingApp(shareText, shareUrl, SharingApp.WHATSAPP);
  }
}
