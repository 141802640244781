import { IMAGE_UPLOADS } from "../constants";
import { ImagePresignUploadStatus } from "../models/Image";
import { ETLocalStorage } from "./EtStorages";
import { isBrowser } from "./isBrowser";
export type ImageUpload = {
  uploadToken: string;
  status: ImagePresignUploadStatus;
};

export const pushOrUpdate = (
  currentImageUploads: ImageUpload[],
  imageUpload: ImageUpload,
): ImageUpload[] => {
  let isAlreadyExists = false;

  const updatedCurrentStatus = currentImageUploads.map((item) => {
    if (item.uploadToken === imageUpload.uploadToken) {
      isAlreadyExists = true;
      return imageUpload;
    }

    return item;
  });

  if (!isAlreadyExists) {
    updatedCurrentStatus.push(imageUpload);
  }

  return updatedCurrentStatus;
};

export const imageUploadsStorage = {
  pushOrUpdate: (imageUpload: ImageUpload): void => {
    if (!isBrowser()) return;

    const currentStatusJson = ETLocalStorage.getItem(IMAGE_UPLOADS);

    if (!currentStatusJson) {
      ETLocalStorage.setItem(IMAGE_UPLOADS, JSON.stringify([imageUpload]));

      return;
    }

    const currentImageUploads: Array<ImageUpload> =
      JSON.parse(currentStatusJson);

    const newImagesUpload = pushOrUpdate(currentImageUploads, imageUpload);

    ETLocalStorage.setItem(IMAGE_UPLOADS, JSON.stringify(newImagesUpload));
  },

  remove: (uploadToken: string | Record<string, string>): void => {
    if (!isBrowser()) return;

    const currentStatusJson = ETLocalStorage.getItem(IMAGE_UPLOADS);
    if (!currentStatusJson) {
      return;
    }

    const updatedCurrentStatus = JSON.parse(currentStatusJson).filter(
      (cs: ImageUpload) => {
        return cs.uploadToken !== uploadToken;
      },
    );

    ETLocalStorage.setItem(IMAGE_UPLOADS, JSON.stringify(updatedCurrentStatus));
  },
};
