import { VanityNameGeneratorStrategy } from "./VanityNameGenerator";

export class StrategyFullName implements VanityNameGeneratorStrategy {
  constructor(private nameParts: string[]) {}

  generate(): { vanityName: string; next: boolean } {
    const vanityName = this.nameParts.join("");
    return { vanityName, next: true };
  }
}
