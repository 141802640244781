import { ImageSource, Uon } from "@earthtoday/contract";
import { observer } from "mobx-react-lite";

import star from "../../assets/img/star-x4.png";
import UonM2Logo from "../../assets/svgs/uon-m2-logo.svg";
import { getEarthLinesNextImage } from "../../shared/helpers/getEarthLinesNextImage";
import { formatUonNumber } from "../CardItemTransactionStandard/helper/formatUonNumber";
import { LazyImage } from "../LazyImage/LazyImage";
import sc from "./CardUonBlock.styled";
export type RenderLocation =
  | "leaderboardRange"
  | "preselection-modal"
  | "oneFlow"
  | "";

export interface IProps {
  maxSize: number;
  frameSize: number;
  numberSize: number;
  dataCyPrefix: string;
  uonTextSize?: number;
  uonBoldTextSize?: number;
  coordinatesSize?: number;
  coordinatesTop?: number;
  coordinateRight?: number;
  startWidth?: number;
  startHeight?: number;
  startPosition?: number;
  uonSizeMargin?: number;
  marginTopUonReserveTitle?: number;
  widthUonReserveTitle?: number;
  location?:
    | "leaderboardRange"
    | "preselection-modal"
    | "oneFlow"
    | "landing-page";
  driver: {
    numberOfUon: number;
    coordinates: string;
    isEndWithOne: boolean;
    uonImageUrl: string;
    card?: Uon;
  };
}

export const CardUonBlock = observer((props: IProps) => {
  return (
    <sc.UonContainer maxSize={props.maxSize}>
      {props.driver.card?.imageSource === ImageSource.UON_IMAGE ? (
        <></>
      ) : (
        <sc.Overlay
          maxSize={props.maxSize}
          uonCount={props.driver.numberOfUon}
        />
      )}
      <sc.UonBackground zIndex={0} maxSize={props.maxSize}>
        <LazyImage
          src={props.driver.uonImageUrl}
          width="100%"
          maxWidth="100%"
          height="100%"
          maxHeight="100%"
          alt="uon reserve image"
          position="absolute"
          top="0px"
          left="0px"
        />
      </sc.UonBackground>
      <sc.UonBackground zIndex={1} maxSize={props.maxSize}>
        <LazyImage
          src={getEarthLinesNextImage(props.driver.numberOfUon)}
          width="100%"
          maxWidth="100%"
          height="100%"
          maxHeight="100%"
          alt="earth lines image"
          position="absolute"
          top="0px"
          left="0px"
        />
      </sc.UonBackground>
      <sc.Square maxSize={props.maxSize} uonCount={props.driver.numberOfUon} />
      <sc.UonFrame frameSize={props.frameSize} bg={UonM2Logo}>
        <sc.UonSize
          uonSizeMargin={props.uonSizeMargin}
          isEndWithOne={props.driver.isEndWithOne}
          numberSize={props.numberSize}
          data-cy={`${props.dataCyPrefix}-UonSize`}
        >
          {formatUonNumber(props.driver.numberOfUon)}
        </sc.UonSize>

        <sc.UonReserveTitleNormal
          size={props.uonTextSize}
          marginTop={props.marginTopUonReserveTitle}
          width={props.widthUonReserveTitle}
          data-cy={`${props.dataCyPrefix}-UonReserveTitleNormal`}
        >
          <sc.UonReserveTitleBold size={props.uonBoldTextSize}>
            uon
          </sc.UonReserveTitleBold>
          unit of nature
        </sc.UonReserveTitleNormal>

        <sc.TopRightStar
          startPosition={props.startPosition}
          width={props.startWidth}
          height={props.startHeight}
          src={star}
        />
        <sc.TopRightText
          top={props.coordinatesTop}
          right={props.coordinateRight}
        >
          <sc.Coordinate
            size={props.coordinatesSize}
            data-cy={`${props.dataCyPrefix}-Coordinate`}
          >
            {props.driver.coordinates}
          </sc.Coordinate>
        </sc.TopRightText>
      </sc.UonFrame>
    </sc.UonContainer>
  );
});

// getImageUonNextImageForGift(props.driver.numberOfUon)
