import { TokenInterceptorStore } from "../../stores/TokenInterceptorStore";
import {
  EnirchedCollectCodeBatchStatisticsDto,
  EnirchedUonIssuingBatch,
  SaleType,
  TransferCodeBatchType,
  UonIssuingBatchType,
  UonsIssueResponse,
} from "../DTOs/UONDto";
import { getAPIBaseUrl } from "../env";
import { CollectCodesBatchDownloadUrl } from "../models/CollectCodesBatchDownloadUrl";

export type CollectCodeRequestData = {
  batchType: TransferCodeBatchType;
  batchName: string;
  uonPerCode: number;
  codeAmount: number;
};

export interface IUonManagementApi {
  getUonIssuingBatchStatistic(
    idOrVanityName: string,
    batchType: UonIssuingBatchType,
  ): Promise<UonsIssueResponse>;
  getUonIssuingBatches(
    idOrVanityName: string,
    batchType: UonIssuingBatchType,
  ): Promise<EnirchedUonIssuingBatch[]>;
  getCodeCollectBatchDetails(
    idOrVanityName: string,
    batchType: UonIssuingBatchType,
  ): Promise<EnirchedCollectCodeBatchStatisticsDto[]>;
  orderUON(
    requestor: string,
    batchName: string,
    description: string,
    idOrVanityName: string,
    numberOfUon: number,
    saleTypes: SaleType,
  ): Promise<{ status: number; data: UonsIssueResponse }>;
  createCollectCodes(
    idOrVanityName: string,
    data: CollectCodeRequestData,
  ): Promise<{ status: number }>;
  fetchCollectCodesBatchDownloadUrl(
    idOrVanityName: string,
    transferCodesBatchId: string,
  ): Promise<CollectCodesBatchDownloadUrl>;
  invalidateRemainingCodes(
    idOrVanityName: string,
    batchId: string,
  ): Promise<{ status: number }>;
}

export class UonManagementApi implements IUonManagementApi {
  constructor(private tokenInterceptorStore: TokenInterceptorStore) {}

  getUonIssuingBatchStatistic = async (
    idOrVanityName: string,
    batchType: UonIssuingBatchType,
  ): Promise<UonsIssueResponse> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/users/${idOrVanityName}/uon_issuing_batches/${batchType}/statistics`,
      method: "GET",
    });

    return res.data;
  };

  getUonIssuingBatches = async (
    idOrVanityName: string,
    batchType: UonIssuingBatchType,
  ): Promise<EnirchedUonIssuingBatch[]> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/users/${idOrVanityName}/uon_issuing_batches/${batchType}/list`,
      method: "GET",
    });

    return res.data;
  };

  getCodeCollectBatchDetails = async (
    idOrVanityName: string,
    batchType: UonIssuingBatchType,
  ): Promise<EnirchedCollectCodeBatchStatisticsDto[]> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/users/${idOrVanityName}/collect_code_batches/${batchType}/list`,
      method: "GET",
    });

    return res.data;
  };

  fetchCollectCodesBatchDownloadUrl = async (
    idOrVanityName: string,
    transferCodesBatchId: string,
  ): Promise<CollectCodesBatchDownloadUrl> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/users/${idOrVanityName}/uon/collect_codes_batch/download/presign`,
      method: "POST",
      data: { transferCodesBatchId },
    });

    return res.data;
  };

  orderUON = async (
    requestor: string,
    batchName: string,
    description: string,
    idOrVanityName: string,
    numberOfUon: number,
    saleType: SaleType,
  ): Promise<{ status: number; data: UonsIssueResponse }> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/administration/uons/issue`,
      method: "POST",
      data: {
        requestor,
        batchName,
        description,
        idOrVanityName,
        numberOfUon,
        saleType,
      },
    });

    return { status: res.status, data: res.data };
  };

  createCollectCodes = async (
    idOrVanityName: string,
    { batchType, batchName, uonPerCode, codeAmount }: CollectCodeRequestData,
  ): Promise<{ status: number }> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/users/${idOrVanityName}/collect_code_batches`,
      method: "POST",
      data: {
        batchType,
        batchName,
        codeSize: uonPerCode,
        amount: codeAmount,
      },
    });

    return { status: res.status };
  };

  invalidateRemainingCodes = async (
    idOrVanityName: string,
    batchId: string,
  ): Promise<{ status: number }> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/users/${idOrVanityName}/collect_code_batches/${batchId}/invalidate`,
      method: "POST",
      data: {},
    });

    return { status: res.status };
  };
}
