import { gql } from "@ts-gql/tag";

export function uonCountQuery() {
  return gql`
    query uonCountQuery {
      uonCount {
        count
      }
    }
  ` as import("../../../__generated__/ts-gql/uonCountQuery").type;
}

export function uonCountUpdatedSubscription() {
  return gql`
    subscription uonCountUpdated {
      uonCountUpdated {
        count
      }
    }
  ` as import("../../../__generated__/ts-gql/uonCountUpdated").type;
}

export function globalLeaderboardUpdatedSubscription() {
  return gql`
    subscription globalLeaderboardUpdated {
      globalLeaderboardUpdated {
        name
        count
        date
        photo {
          id
          url
          awsBucket
          awsKey
          isTransparent
        }
        userEarthId
        isPublished
        type
        rewardedBy {
          id
          name
          photo {
            id
            url
          }
        }
      }
    }
  ` as import("../../../__generated__/ts-gql/globalLeaderboardUpdated").type;
}
