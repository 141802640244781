import { host } from "../env";

export const getVanityNameFromProfileUrl = (
  profileUrl: string,
): string | null => {
  const url = new URL(profileUrl);
  const sanitizeUrl = `${url.origin}${url.pathname}`.replace(/\/*$/, "");
  const regex = new RegExp(`${host}/(?<vanityName>[^/][a-zA-Z0-9]+)$`);

  const match = sanitizeUrl.match(regex);

  if (!match || !match.groups) {
    return null;
  }

  return match.groups.vanityName;
};

export const parseVanityNameAndProfileLink = (
  userInputUrl: string,
): { isProfileUrl: boolean; vanityName: string } => {
  const urlWithProtocol =
    !userInputUrl.startsWith("http://") && !userInputUrl.startsWith("https://")
      ? `http://${userInputUrl}`
      : userInputUrl;

  const slicedUrl = urlWithProtocol.endsWith("/")
    ? urlWithProtocol.slice(0, -1)
    : urlWithProtocol;

  const url = new URL(slicedUrl);

  if (!host.includes(url.host)) {
    return { isProfileUrl: false, vanityName: "" };
  }

  const splitedPath = url.pathname.split("/");

  if (splitedPath.length !== 2 || splitedPath[1] === "") {
    return { isProfileUrl: false, vanityName: "" };
  }

  return {
    isProfileUrl: true,
    vanityName: splitedPath[1],
  };
};
