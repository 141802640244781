// ts-gql-integrity:51b39968c1c974d3b693e3f3a9c95dda
/*
ts-gql-meta-begin
{
  "hash": "f4a0fdb35a109d2f99f12f39a8a55321"
}
ts-gql-meta-end
*/

import * as SchemaTypes from "./@schema";
import { TypedDocumentNode } from "@ts-gql/tag";

type userMetadataQueryQueryVariables = SchemaTypes.Exact<{
  cardIds: ReadonlyArray<SchemaTypes.InputMaybe<SchemaTypes.Scalars['ID']>> | SchemaTypes.InputMaybe<SchemaTypes.Scalars['ID']>;
}>;


type userMetadataQueryQuery = { readonly __typename: 'Query', readonly userMetadata: { readonly __typename: 'UserMetadataOutput', readonly cards: ReadonlyArray<{ readonly __typename: 'UserMetadataItemOutput', readonly id: string | null, readonly starred: boolean | null } | null> } | null };


      
export type type = TypedDocumentNode<{
  type: "query";
  result: userMetadataQueryQuery;
  variables: userMetadataQueryQueryVariables;
  documents: SchemaTypes.TSGQLDocuments;
  fragments: SchemaTypes.TSGQLRequiredFragments<"none">
}>

declare module "./@schema" {
  interface TSGQLDocuments {
    userMetadataQuery: type;
  }
}

export const document = JSON.parse("{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"query\",\"name\":{\"kind\":\"Name\",\"value\":\"userMetadataQuery\"},\"variableDefinitions\":[{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"cardIds\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"ListType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"ID\"}}}},\"directives\":[]}],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"userMetadata\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"cardIds\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"cardIds\"}}}],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"cards\"},\"arguments\":[],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"starred\"},\"arguments\":[],\"directives\":[]}]}}]}}]}}]}")
