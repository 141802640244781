import {
  ImageTarget,
  PromotionDto,
  PromotionUpdatePayloadDto,
  UserMatchingResponseDto,
  UserProfilePhotoUploadCommitPayloadDto,
  UserProfilePhotoUploadCommitResponseDto,
} from "@earthtoday/contract";
import axios, { AxiosResponse } from "axios";

import { TokenInterceptorStore } from "../../stores/TokenInterceptorStore";
import { getAPIBaseUrl } from "../env";
import { hashFile } from "../helpers/hashedFile";
import { GroupMemberRole, GroupProfileType, User } from "../models/User";

export type PresignImageResponse = {
  url: string;
  uploadToken: string;
};

export const groupProfileMapping: Record<GroupProfileType, string> = {
  unclassified_org: "UNCLASSIFIED_ORG",
  brand: "BRAND",
  charity: "CHARITY",
  npo: "NPO",
  community: "COMMUNITY",
  partner: "PARTNER",
};

export interface ISettingsApi {
  editInformation(data: {
    fullName: string;
    firstName: string;
    lastName: string;
    bio: string;
    tagline: string;
    website: string | undefined;
    facebookUsername: string | undefined;
    twitterUsername: string | undefined;
    instagramUsername: string | undefined;
    linkedInUsername: string | undefined;
  }): Promise<AxiosResponse>;

  editVanityName(data: { vanityName: string }): Promise<AxiosResponse>;
  presignImage(
    photo: File,
    imageTarget: ImageTarget,
  ): Promise<PresignImageResponse>;
  deletePhoto(): Promise<AxiosResponse>;
  changePassword(data: {
    oldPasswordHash: string;
    newPasswordHash: string;
  }): Promise<AxiosResponse>;
  changePassword(data: {
    oldPasswordHash: string;
    newPasswordHash: string;
  }): Promise<AxiosResponse>;
  publishProfile(): Promise<AxiosResponse>;
  deleteAccount(): Promise<AxiosResponse>;
  deletePresignImage(
    uploadToken: string,
    reason: string,
  ): Promise<AxiosResponse>;
  commitUploadImage(
    payload: UserProfilePhotoUploadCommitPayloadDto,
  ): Promise<UserProfilePhotoUploadCommitResponseDto>;
  fetchSearchMemberResult(
    vanityName: string,
  ): Promise<UserMatchingResponseDto | null>;

  deleteGroupMember(userId: string, groupId: string): Promise<void>;
  editMemberGroup(
    userId: string,
    groupId: string,
    role: GroupMemberRole,
  ): Promise<AxiosResponse>;
  revokeInvitationGroup(userId: string): Promise<AxiosResponse>;
  transferGroup(
    groupId: string,
    data: { appointeeId: string },
  ): Promise<AxiosResponse>;
  generateApiKey(
    userVanityName: string,
    expireDuration?: number,
  ): Promise<AxiosResponse>;
  getApiKeyStatus(): Promise<AxiosResponse>;
  getGlobalPromotions(collectionName: string): Promise<{ id: string }[]>;
}

export class SettingsApi implements ISettingsApi {
  constructor(private tokenInterceptorStore: TokenInterceptorStore) {}

  editInformation = async (data: {
    fullName?: string;
    firstName?: string;
    lastName?: string;
    bio?: string;
    tagline?: string;
    website?: string;
    facebookUsername?: string;
    twitterUsername?: string;
    instagramUsername?: string;
    linkedInUsername?: string;
  }): Promise<AxiosResponse> => {
    const res: AxiosResponse<User> = await this.tokenInterceptorStore.call({
      method: "PUT",
      url: `${getAPIBaseUrl()}/users/me`,
      data,
    });
    return res;
  };

  editVanityName = async (data: {
    vanityName: string;
  }): Promise<AxiosResponse> => {
    const res = await this.tokenInterceptorStore.call({
      method: "PUT",
      url: `${getAPIBaseUrl()}/users/me/vanity`,
      data,
    });
    return res;
  };

  deletePhoto = async (): Promise<AxiosResponse> => {
    const res = await this.tokenInterceptorStore.call({
      method: "DELETE",
      url: `${getAPIBaseUrl()}/users/me/photo`,
    });
    return res;
  };

  changePassword = async (data: {
    oldPasswordHash: string;
    newPasswordHash: string;
  }): Promise<AxiosResponse> => {
    const res = await this.tokenInterceptorStore.call({
      method: "PUT",
      url: `${getAPIBaseUrl()}/users/me/registrations/password`,
      data,
    });
    return res;
  };

  publishProfile = async (): Promise<AxiosResponse> => {
    const res = await this.tokenInterceptorStore.call({
      method: "PUT",
      url: `${getAPIBaseUrl()}/users/me/publish`,
    });

    return res;
  };
  publicEmail = async (data: { isEmailPublic: boolean }): Promise<boolean> => {
    const res = await this.tokenInterceptorStore.call({
      method: "PUT",
      url: `${getAPIBaseUrl()}/users/me/publicEmail`,
      data,
    });

    return res.data.isEmailPublic;
  };
  deleteAccount = async (): Promise<AxiosResponse> => {
    const res = await this.tokenInterceptorStore.call({
      method: "DELETE",
      url: `${getAPIBaseUrl()}/users/me`,
    });
    return res;
  };
  presignImage = async (
    photo: File,
    imageTarget: ImageTarget,
  ): Promise<PresignImageResponse> => {
    const fileName = photo.name;
    const hashedFile = await hashFile(photo);

    const resPresignImage = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/uploads/presign`,
      data: {
        fileName,
        contentType: photo.type,
        target: imageTarget,
        hashedFile,
      },
    });

    return resPresignImage.data;
  };

  deletePresignImage = async (
    uploadToken: string,
    reason: string,
  ): Promise<AxiosResponse> => {
    const res = await this.tokenInterceptorStore.call({
      method: "DELETE",
      url: `${getAPIBaseUrl()}/images/presign`,
      data: {
        uploadToken,
        reason,
      },
    });

    return res;
  };

  uploadToS3 = async (url: string, photo: File): Promise<Response> => {
    const hashedFile = await hashFile(photo);
    const resUploadToS3 =
      await this.tokenInterceptorStore.callWithoutExtraConfigs({
        method: "PUT",
        headers: {
          "Content-Type": photo.type,
          "Content-Encoding": "utf8",
          "Content-MD5": hashedFile,
        },
        data: photo,
        url,
      });
    return resUploadToS3;
  };

  commitUploadImage = async (
    payload: UserProfilePhotoUploadCommitPayloadDto,
  ): Promise<UserProfilePhotoUploadCommitResponseDto> => {
    const commitUpload =
      await this.tokenInterceptorStore.tsRestClient.user.commitUpload({
        body: payload,
      });
    if (commitUpload.status == 200) {
      return commitUpload.body;
    }
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw commitUpload;
  };

  //TODO: update search by vanityName
  fetchSearchMemberResult = async (
    vanityName: string,
  ): Promise<UserMatchingResponseDto | null> => {
    try {
      const requestId = "fetchSearchResult";
      const config: any = {
        url: `${getAPIBaseUrl()}/search/users/${vanityName}`,
        requestId,
      };

      return await this.tokenInterceptorStore
        .call(config)
        .then((res) => {
          return res.data;
        })
        .catch((error: any) => {
          if (axios.isCancel(error)) {
            console.log("request 1 cancelled");
          }
          return null;
        });
    } catch {
      return null;
    }
  };

  addGroupMember = async (
    userId: string,
    data?: { role: string },
  ): Promise<AxiosResponse> => {
    const res = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/groups/users/${userId}/memberships`,
      data,
    });
    return res;
  };

  getGroupMembers = async (userId: string): Promise<User[]> => {
    const res = await this.tokenInterceptorStore.call<User[]>({
      method: "GET",
      url: `${getAPIBaseUrl()}/groups/${userId}/members?includePending=true`,
    });

    return res.data;
  };

  deleteGroupMember = async (
    userId: string,
    groupId: string,
  ): Promise<void> => {
    await this.tokenInterceptorStore.call({
      method: "DELETE",
      url: `${getAPIBaseUrl()}/groups/users/${userId}/memberships/${groupId}`,
    });
  };

  editMemberGroup = async (
    userId: string,
    groupId: string,
    role: GroupMemberRole,
  ): Promise<AxiosResponse> => {
    const res = await this.tokenInterceptorStore.call({
      method: "PUT",
      url: `${getAPIBaseUrl()}/groups/users/${userId}/memberships/${groupId}/role/${role}`,
    });
    return res;
  };
  revokeInvitationGroup = async (userId: string): Promise<AxiosResponse> => {
    const res = await this.tokenInterceptorStore.call({
      method: "DELETE",
      url: `${getAPIBaseUrl()}/groups/users/${userId}/memberships`,
    });
    return res;
  };
  transferGroup = async (
    groupId: string,
    data: { appointeeId: string },
  ): Promise<AxiosResponse> => {
    const res = await this.tokenInterceptorStore.call({
      method: "PUT",
      url: `${getAPIBaseUrl()}/groups/${groupId}/transfer`,
      data,
    });
    return res;
  };

  sendRoleRequestEmail = async (
    groupId: string,
    roleRequest: GroupProfileType,
  ): Promise<number> => {
    const res = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/groups/${groupId}/role/request`,
      data: {
        roleRequest: roleRequest.toLocaleUpperCase(),
      },
    });
    return res.status;
  };

  generateApiKey = async (
    userVanityName: string,
    expireDuration?: number,
  ): Promise<AxiosResponse> => {
    const response = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/users/${userVanityName}/api-keys`,
      data: {
        expireDuration,
      },
    });

    return response.data;
  };
  getApiKeyStatus = async (): Promise<AxiosResponse> => {
    const response = await this.tokenInterceptorStore.call({
      method: "GET",
      url: `${getAPIBaseUrl()}/users/me/api-key`,
    });

    return response.data;
  };

  getGlobalPromotions = async (
    collectionName: string,
  ): Promise<{ id: string }[]> => {
    const response =
      await this.tokenInterceptorStore.tsRestClient.promotion.getGlobalPromotions(
        {
          query: {
            collectionName,
          },
        },
      );

    if (response.status === 200) {
      return response.body.data;
    }

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw response;
  };

  editPromotion = async (
    promotionId: string,
    payload: PromotionUpdatePayloadDto,
  ): Promise<PromotionDto> => {
    const response =
      await this.tokenInterceptorStore.tsRestClient.promotion.updatePromotion({
        params: { promotionId },
        body: payload,
      });

    if (response.status === 200) {
      return response.body;
    }

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw response;
  };
}
