import { action, flow, makeObservable, observable } from "mobx";
import { ReactNode } from "react";

import { wait } from "../../shared/helpers/wait";

export type TypeIsShowMessage = "initialization" | boolean;
export type ErrorMessage = null | string | ReactNode;

export type ToastMessageActionHref = {
  key: string;
  name: string;
  href: string; // e.g. /policies/[param]
  as: string; // e.g. /policies/cookie-policy
};

export type ToastMessageActionFunc = {
  key: string;
  name: string;
  action: Function;
};

export type ToastMessageAction =
  | ToastMessageActionHref
  | ToastMessageActionFunc;

export enum ToastMessageStatus {
  DANGER,
  SUCCESS,
  INFO,
  WARNING,
}

export type IMessage =
  | {
      typeMessage: "Close";
      title: string;
      content: string;
      vars?: Record<string, string | number>;
    }
  | {
      typeMessage: "Error";
      title: string;
      content: string;
      vars?: Record<string, string | number>;
    }
  | {
      typeMessage: "Action";
      status: ToastMessageStatus;
      title: string;
      content: string;
      vars?: Record<string, string | number>;

      actions: ToastMessageAction[];
    };

export type IOption = {
  closeDuration?: number | "never";
};

export interface ITheMessageStore {
  isShowMessage: TypeIsShowMessage;
  message: IMessage;

  close: () => void;

  resetState: () => void;

  isShowMessageUpdate: (f: TypeIsShowMessage) => void;

  showMessage: (message: IMessage, options?: IOption) => void;
}

export class TheMessageStore implements ITheMessageStore {
  @observable isShowMessage: TypeIsShowMessage = "initialization";
  @observable message: IMessage = {
    typeMessage: "Close",
    title: "",
    content: "",
  };
  constructor() {
    makeObservable(this);
  }

  @action.bound close = (): void => {
    this.isShowMessage = false;
  };

  @action.bound resetState = (): void => {
    this.isShowMessage = "initialization";
  };

  @action isShowMessageUpdate = (f: TypeIsShowMessage): void => {
    this.isShowMessage = f;
  };

  @action.bound showMessage = flow(function* (
    this: TheMessageStore,
    message: IMessage,
    options?: IOption,
  ) {
    this.message = message;
    this.isShowMessage = true;

    const defaultOption: IOption = {
      closeDuration: 5000,
    };

    const localOptions = { ...defaultOption, ...options };

    if (localOptions && localOptions.closeDuration !== "never") {
      yield wait(localOptions.closeDuration || 0);
      this.isShowMessage = false;
      yield wait(100);
      this.resetState();
    }
  });
}
