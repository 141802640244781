import styled from "styled-components";

import { smallScreenUpperLimit } from "../../shared/breakpoints";
import { etBrightLightBlue, etWhite } from "../../shared/colors";

export namespace sc {
  export const Container = styled.span`
    display: inline-block;
    position: relative;
    list-style: none;
    max-width: 15px;
    min-width: 15px;
    max-height: 15px;
    min-height: 15px;
  `;

  export const IconWrapper = styled.div`
    position: absolute;
    border-radius: 50%;
    overflow: hidden;
  `;

  export const InfoContent = styled.div`
    position: absolute;
    right: -24px;
    top: 25px;
    padding: 4px 8px;
    width: 233px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    white-space: break-spaces;

    border-radius: 4px;
    color: ${etWhite};
    background: ${etBrightLightBlue};
    box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 25%);

    z-index: 101;

    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 20px;
    text-transform: none;
    text-align: center;

    &::before {
      content: "";
      position: absolute;
      top: -6px;
      right: 24px;

      height: 12px;
      width: 10px;
      border-style: solid;
      border-width: 0 8px 15px 8px;
      border-color: transparent transparent ${etBrightLightBlue} transparent;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      top: 22px;
      right: -18px;
      &::before {
        display: none;
      }
    }
  `;
  export const InfoOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
  `;
}
