import { OneFlowType } from "../../stores/ModalOneFlowPresenter/ModalOneFlowPresenter";

export const isValidOneFlowType = (
  oneFlowType?: string,
): oneFlowType is OneFlowType => {
  if (!oneFlowType) {
    return false;
  }

  return (
    oneFlowType === OneFlowType.SUBSCRIBE || oneFlowType === OneFlowType.PROTECT
  );
};
