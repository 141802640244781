import styled from "styled-components";

import { etWhite } from "../../shared/colors";
import { getDiamondImg } from "../../shared/helpers/getDiamondImg";
import { getUONColor } from "../../shared/helpers/getUONColor";

namespace sc {
  export const UonContainer = styled.div<{ maxSize: number }>`
    height: ${(props) => props.maxSize}px;
    width: ${(props) => props.maxSize}px;
    max-height: ${(props) => props.maxSize}px;
    max-width: ${(props) => props.maxSize}px;
    position: relative;
  `;

  export const Square = styled.div<{ uonCount: number; maxSize: number }>`
    display: ${(props) => (props.uonCount > 999 ? "block" : "none")};
    position: absolute;
    z-index: 1;
    background-image: url(${(props) => getDiamondImg(props.uonCount)});
    background-position: center;

    background-size: cover;
    background-repeat: no-repeat;
    width: ${(props) => props.maxSize}px;
    max-width: ${(props) => props.maxSize}px;
    height: ${(props) => props.maxSize}px;
    max-height: ${(props) => props.maxSize}px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `;

  export const UonBackground = styled.div<{
    zIndex: number;
    maxSize: number;
  }>`
    border-radius: 0px;
    max-height: ${(props) => props.maxSize}px;
    max-width: ${(props) => props.maxSize}px;
    height: ${(props) => props.maxSize}px;
    width: ${(props) => props.maxSize}px;

    position: absolute;
    z-index: ${(props) => props.zIndex};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  `;

  export const UonFrame = styled.div<{ bg: string; frameSize: number }>`
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);

    width: ${(props) => props.frameSize}px;
    height: ${(props) => props.frameSize}px;
    max-width: ${(props) => props.frameSize}px;
    max-height: ${(props) => props.frameSize}px;

    background: url(${(props) => props.bg}) center center no-repeat;
    background-size: cover;

    display: flex;
    align-items: flex-start;
  `;

  export const UonSize = styled.div<{
    numberSize: number;
    isEndWithOne: boolean;
    uonSizeMargin?: number;
  }>`
    color: ${etWhite};
    font-family: Roboto Condensed;
    font-weight: normal;

    letter-spacing: 0px;

    font-size: ${(props) => props.numberSize}px;
    line-height: ${(props) => props.numberSize}px;

    margin-top: ${(props) => props.uonSizeMargin || 8}px;
    margin-left: ${(props) => props.uonSizeMargin || 8}px;

    margin-right: ${(props) => (props.isEndWithOne ? 2 : 6)}px;

    overflow: hidden;
  `;

  export const UonReserveTitleNormal = styled.div<{
    size?: number;
    marginTop?: number;
    width?: number;
  }>`
    color: ${etWhite};
    font-family: Roboto Condensed;

    text-transform: uppercase;
    font-weight: 400;
    font-size: ${(props) => props.size || 10}px;
    line-height: 1.03;

    width: ${(props) => props.width || 40}px;
    margin-top: ${(props) => props.marginTop || 12}px;
  `;

  export const UonReserveTitleBold = styled.span<{ size?: number }>`
    color: ${etWhite};
    font-family: Roboto Condensed;
    font-weight: bold;
    font-size: ${(props) => props.size || 14}px;
    line-height: 1;
    margin-top: -5px;

    display: block;
    text-transform: lowercase;
  `;

  export const Overlay = styled.div<{ uonCount: number; maxSize: number }>`
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: ${(props) => getUONColor(props.uonCount)};
    opacity: 0.2;

    max-height: ${(props) => props.maxSize}px;
    max-width: ${(props) => props.maxSize}px;
    height: ${(props) => props.maxSize}px;
    width: ${(props) => props.maxSize}px;
  `;

  export const Coordinate = styled.span<{ size?: number }>`
    font-family: Roboto Condensed;
    font-size: ${(props) => props.size || 8}px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
  `;

  export const TopRightStar = styled.img<{
    width?: number;
    height?: number;
    startPosition?: number;
  }>`
    width: ${(props) => props.width || 12}px;
    max-width: ${(props) => props.width || 12}px;
    height: ${(props) => props.height || 12}px;
    max-height: ${(props) => props.height || 12}px;
    display: block;
    position: absolute;
    z-index: 4;
    top: ${(props) => props.startPosition || 8}px;
    right: ${(props) => props.startPosition || 8}px;
  `;

  export const TopRightText = styled.p<{ top?: number; right?: number }>`
    writing-mode: tb-rl;
    transform: rotate(-180deg);
    text-transform: uppercase;
    color: ${etWhite};
    font-size: 8px;
    position: absolute;
    top: ${(props) => props.top || 24}px;
    right: ${(props) => props.right || 7}px;
    margin: 0;
  `;
}

export default sc;
