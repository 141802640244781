/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-expressions */
/* eslint-disable unicorn/consistent-function-scoping */
import { debounce } from "lodash";
import { action, computed, flow, makeObservable, observable, toJS } from "mobx";
import { toFlowGeneratorFunction } from "to-flow-generator-function";

import { CardActionContextMenuDriver } from "../../components/CardItemActionContextMenu/CardItemActionContextMenu";
import { DynamicLazyModalLoader } from "../../components/DynamicModalLoader/DynamicLazyModalLoader";
import { IModalDeleteCardStore } from "../../components/ModalDeleteCard/ModalDeleteCardStore";
import { IUserSessionStore } from "../../components/ModalLogin/UserSessionStore";
import { ITheMessageStore } from "../../components/TheMessage/TheMessageStore";
import { IDeckDetailApi } from "../../shared/apis/DeckDetailApi";
import {
  ACTION_CARD_BUTTON_TEXT_MAX_LENGTH,
  MAX_CARDS_IN_DECK,
} from "../../shared/constants";
import { translateAPIError } from "../../shared/helpers/translateApiError";
import {
  CardSize,
  CardState,
  CardTextAlignment,
  CardTextColor,
  CardType,
} from "../../shared/models/Card";
import DeckDetailStore from "../DeckDetailStore/DeckDetailStore";
import { FeatureFlaggingData } from "../FeatureFlaggingStore";
import { IModalStore } from "../ModalStore";
import { CardActionModel, PageStore } from "./CardActionModel";
export class CardActionContextMenuStore implements CardActionContextMenuDriver {
  constructor(
    private parentStore: CardActionModel,
    private modalStore: IModalStore,
    private userSessionStore: IUserSessionStore,
    private deckDetailApi: IDeckDetailApi,
    public theMessageStore: ITheMessageStore,
    public modalDeleteCardStore: IModalDeleteCardStore,
    private pageStore: PageStore,
    private featureFlagging: FeatureFlaggingData,
    private deckDetailStore: DeckDetailStore | null,
  ) {
    makeObservable(this);
  }
  get contentType(): CardType.ACTION {
    return CardType.ACTION;
  }

  @computed get cardSize(): CardSize {
    return this.parentStore.cardSize;
  }
  @computed get isPublishBtnVisible(): boolean {
    return this.parentStore.card.state === CardState.DRAFTING;
  }

  @computed get isGuestView(): boolean {
    if (!this.userSessionStore.user) return true;

    return (
      this.parentStore.card.curator.id !== this.userSessionStore.user.id &&
      !this.userSessionStore.user.administrator
    );
  }

  @action.bound onCardSizeChanged = flow(function* onCardSizeChange(
    this: CardActionContextMenuStore,
    size: CardSize,
  ) {
    try {
      const card = yield* toFlowGeneratorFunction(
        this.deckDetailApi.onEditCardAction,
      )(this.parentStore.card.id, {
        type: CardType.ACTION,
        size: size.toUpperCase(),
      });
      this.parentStore.card.size = card.size || size;
      this.parentStore.toggleContextMenu();
      this.theMessageStore.showMessage({
        typeMessage: "Close",
        title: "toast-message.general.success",
        content: "toast-message.edit-card.content",
      });
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    }
  });
  @computed get textAlign(): CardTextAlignment {
    return this.parentStore.textAlign;
  }
  @action.bound onTextAlignmentChanged = flow(function* onTextAlignmentChanged(
    this: CardActionContextMenuStore,
    textAlign: CardTextAlignment,
  ) {
    try {
      const card = yield* toFlowGeneratorFunction(
        this.deckDetailApi.onEditCardAction,
      )(this.parentStore.card.id, {
        type: CardType.ACTION,
        textAlignment: textAlign,
      });
      this.parentStore.card.textAlignment = card.textAlignment;
      this.parentStore.toggleContextMenu();
      this.theMessageStore.showMessage({
        typeMessage: "Close",
        title: "toast-message.general.success",
        content: "toast-message.edit-card.content",
      });
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    }
  });
  @computed get textColor(): CardTextColor {
    return this.parentStore.textColor;
  }
  @action.bound onTextColorChanged = flow(function* onTextColorChanged(
    this: CardActionContextMenuStore,
    textColor: CardTextColor,
  ) {
    try {
      const card = yield* toFlowGeneratorFunction(
        this.deckDetailApi.onEditCardAction,
      )(this.parentStore.card.id, { type: CardType.ACTION, textColor });
      this.parentStore.card.textColor = card.textColor;
      this.parentStore.toggleContextMenu();
      this.theMessageStore.showMessage({
        typeMessage: "Close",
        title: "toast-message.general.success",
        content: "toast-message.edit-card.content",
      });
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    }
  });
  @observable buttonText: string = this.parentStore.buttonText;

  @action.bound onButtonTextInputChanged(value: string): void {
    this.buttonText = value.slice(0, ACTION_CARD_BUTTON_TEXT_MAX_LENGTH);
    this.updateButtonText(this.buttonText);
  }

  @action.bound updateButtonText = debounce(
    flow(function* updateButtonText(
      this: CardActionContextMenuStore,
      buttonText: string,
    ) {
      try {
        const card = yield* toFlowGeneratorFunction(
          this.deckDetailApi.onEditCardAction,
        )(this.parentStore.card.id, {
          type: CardType.ACTION,
          buttonText,
        });
        this.parentStore.card.buttonText = card.buttonText;
        this.theMessageStore.showMessage({
          typeMessage: "Close",
          title: "toast-message.general.success",
          content: "toast-message.edit-card.content",
        });
      } catch (error) {
        this.theMessageStore.showMessage({
          typeMessage: "Error",
          title: "toast-message.general.error",
          content: translateAPIError(error),
        });
      }
    }),
    500,
  );

  @computed get shouldRenderCardOrder(): boolean {
    if (
      this.parentStore.isFeaturedDeck ||
      !this.deckDetailStore?.isLoggedinUser
    ) {
      return false;
    }
    return this.featureFlagging.flags.enableSortCardInDeck;
  }
  @observable cardOrder: number | null = null;
  @computed get cardOrderInput(): string | null {
    return this.cardOrder?.toString() || null;
  }
  @computed get sortNumberInputError(): string | null {
    if (this.cardOrder === null) return null;

    return this.cardOrder > MAX_CARDS_IN_DECK || this.cardOrder < 1
      ? `Card's order should be in range from 1 to ${MAX_CARDS_IN_DECK}`
      : null;
  }
  @action.bound onCardOrderChanged(order: string): void {
    const orderNumber = Number(order);

    if (orderNumber > MAX_CARDS_IN_DECK) {
      this.cardOrder = MAX_CARDS_IN_DECK;
      return;
    }

    this.cardOrder = orderNumber;
  }
  @action.bound onCardOrderBlured = flow(function* onCardOrderBlured(
    this: CardActionContextMenuStore,
  ) {
    if (
      this.sortNumberInputError ||
      !this.userSessionStore.user ||
      !this.deckDetailStore?.isLoggedinUser
    ) {
      return;
    }

    try {
      const userVanityName = this.userSessionStore.user.vanityName;
      const deckId = this.parentStore.card.deck?.id || "";
      const response = yield* toFlowGeneratorFunction(
        this.deckDetailApi.updateCardOrder,
      )(
        userVanityName,
        deckId,
        this.parentStore.card.id,
        Number(this.cardOrderInput),
      );
      const cardIds = response.data.map((item) => item.id.toString());
      if (this.deckDetailStore) {
        this.deckDetailStore.updateOrderOfCards(cardIds);
      }
      this.parentStore.closeContextMenu();
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    }
  });
  @action.bound getCardOrder(): void {
    if (!this.deckDetailStore) {
      this.cardOrder = null;
      return;
    }
    const foundIndex = this.deckDetailStore.cards.findIndex(
      (card) => card.id === this.parentStore.id,
    );
    this.cardOrder = foundIndex > -1 ? foundIndex + 1 : null;
  }

  @action.bound onCardEditButtonClicked(): void {
    this.parentStore.toggleContextMenu();
    this.modalStore.openLazyModal({
      name: "cardCreate",
      component: (
        <DynamicLazyModalLoader
          loadComponent={() =>
            // eslint-disable-next-line more/no-then
            import(
              "../../components/ModalCreateCardAction/ModalCreateCardActionSmart"
            ).then((component) => component.ModalCreateCardActionSmart)
          }
          editCard={toJS(this.parentStore.card)}
        />
      ),
    });
  }
  @action.bound onCardDeleteButtonClicked(): void {
    this.modalDeleteCardStore.updateCardDelete(this.parentStore);
    this.parentStore.toggleContextMenu();
    this.modalStore.openModal("cardDelete");
  }
  @action.bound onDeleteCard(): void {
    this.pageStore && this.pageStore.onDeleteCard(this.parentStore.id);
  }

  @action.bound onCardReportButtonClicked(): void {
    this.parentStore.toggleContextMenu();
    this.modalStore.openModal("cardReport");
  }

  @computed get isPublishable(): boolean {
    if (!this.parentStore.card.resource || !this.parentStore.card.deck) {
      return false;
    }

    return (
      !!this.parentStore.card.title ||
      !!this.parentStore.card.subtitle ||
      !!this.parentStore.card.backgroundImage
    );
  }
  @action.bound onCardPublishButtonClicked = flow(function* (
    this: CardActionContextMenuStore,
  ) {
    this.parentStore.toggleContextMenu();
    yield* toFlowGeneratorFunction(this.deckDetailApi.onPublishCardAction)(
      this.parentStore.card.id,
    );
    if (this.parentStore.onCardPublishedSuccessfully) {
      this.parentStore.onCardPublishedSuccessfully();
    }
  });
}
