import { observer } from "mobx-react-lite";
import React from "react";

import { ETReactProps } from "../../../typings/types";
import { ButtonAction, FontStretch, FontWeight, TextTransform } from "./Button";
import sc from "./Button.styled";

export interface ButtonDriver {
  readonly action: ButtonAction;
  readonly padding: string;
  readonly dataCy: string;
  readonly round: boolean;
  readonly height: string;
  readonly width: string;
  readonly disabled: boolean;
  readonly fontSize: string;
  readonly textTransform: TextTransform;
  readonly fontWeight: FontWeight;
  readonly fontStretch: FontStretch;
  readonly mobileHeight: string;
  readonly mobileWidth: string;
  readonly mobileFontsize: string;
  readonly mobileVisible: boolean;
  readonly fontFamily: string;
  readonly dataTrackId?: string;
  readonly bgOnHovered?: string;
  readonly cursor?: "default" | "pointer";

  onClick(param: any): void;
}

interface IProps extends ETReactProps {
  driver: ButtonDriver;
}

export const PrimaryButton = observer((props: IProps) => {
  const { driver, children } = props;

  return (
    <sc.PrimaryButton
      data-cy={driver.dataCy}
      data-track-id={driver.dataTrackId}
      driver={driver}
      disabled={driver.disabled}
      type={driver.action}
      onClick={driver.onClick}
    >
      {children}
    </sc.PrimaryButton>
  );
});
