import styled from "styled-components";

import { etOrangeAutumn, etWhite } from "../../shared/colors";

export namespace sc {
  export const Container = styled.span<{
    height: number;
    width: number;
    pointerEvents: string;
  }>`
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
    height: ${(props) => props.height}px;
    width: ${(props) => props.width}px;
    pointer-events: ${(props) => props.pointerEvents};
  `;
  export const StoreLink = styled.a<{ imageUrl: string }>`
    background: url(${(props) => props.imageUrl}) no-repeat;
    background-size: contain;
    display: inline-block;
    overflow: hidden;
    text-decoration: none;
    height: 100%;
    width: 100%;
  `;

  export const ComingSoonBanner = styled.div`
    position: absolute;
    padding: 0 32px;
    transform: rotate(24deg);
    top: 12px;
    right: -18px;
    background-color: ${etOrangeAutumn};
    font-size: 8px;
    color: ${etWhite};
    text-transform: uppercase;
  `;
}
