import {
  CampaignDefaultStatus,
  ProfileHeaderCardDisplayType,
} from "../../stores/CampaignDefaultModel";
import { TokenInterceptorStore } from "../../stores/TokenInterceptorStore";
import { getAPIBaseUrl } from "../env";
import { getGraphqlClient } from "../graphqlClient";
import { isBrowser } from "../helpers/isBrowser";
import {
  campaignCountUpdatedSubscription,
  getDefaultCampaignCount,
} from "./campaignDefaultApiQueries";
import { UnsubscribeFn } from "./UnsubscribeFn";

export enum CampaignStatusUpdateStatus {
  START = "start",
  STOP = "stop",
}

export interface CampaignCountResponse {
  campaignCount: {
    count: number;
    status: CampaignDefaultStatus;
    name: string;
    profileHeaderCardDisplayType: ProfileHeaderCardDisplayType;
  };
}

export interface ICampaignDefaultApi {
  subscribeCampaignDefaultCount(
    campaignId: string,
    subscribeFn: (
      error: Error | null,
      uonCount: number,
      status: CampaignDefaultStatus,
    ) => void,
  ): UnsubscribeFn;
  fetchDefaultCampaignCount(campaignId: string): Promise<CampaignCountResponse>;
  toggleCounterDisplay(data: {
    profileHeaderCardDisplayType: ProfileHeaderCardDisplayType;
  }): Promise<{ profileHeaderCardDisplayType: ProfileHeaderCardDisplayType }>;
}

export class CampaignDefaultApi implements ICampaignDefaultApi {
  constructor(private tokenInterceptorStore: TokenInterceptorStore) {}

  subscribeCampaignDefaultCount = (
    campaignId: string,
    subscribeFn: (
      error: Error | null,
      uonCount: number,
      status: CampaignDefaultStatus,
    ) => void,
  ): UnsubscribeFn => {
    if (!isBrowser()) {
      return () => {};
    }

    const observable = getGraphqlClient()
      .subscribe<{
        campaignCountUpdated: { count: number; status: CampaignDefaultStatus };
      }>({
        query: campaignCountUpdatedSubscription() as any,
        variables: {
          id: campaignId,
        },
        context: {
          tokenInterceptorStore: this.tokenInterceptorStore,
        },
      })
      .subscribe((result) => {
        if (Array.isArray(result.errors) && result.errors.length > 0) {
          subscribeFn(
            new Error(result.errors.toString()),
            -1,
            CampaignDefaultStatus.State1,
          );
          return;
        }
        subscribeFn(
          null,
          result.data?.campaignCountUpdated.count || 0,
          result.data?.campaignCountUpdated.status ||
            CampaignDefaultStatus.State1,
        );
      });

    return () => observable.unsubscribe();
  };

  fetchDefaultCampaignCount = async (
    campaignId: string,
  ): Promise<CampaignCountResponse> => {
    const result = await getGraphqlClient().query({
      query: getDefaultCampaignCount() as any,
      context: {
        tokenInterceptorStore: this.tokenInterceptorStore,
      },
      variables: {
        id: campaignId,
      },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    });

    return result.data;
  };

  toggleCounterDisplay = async (data: {
    profileHeaderCardDisplayType: ProfileHeaderCardDisplayType;
  }): Promise<{
    profileHeaderCardDisplayType: ProfileHeaderCardDisplayType;
  }> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/users/me/presentation`,
      method: "PATCH",
      data,
    });
    return res.data;
  };
}
