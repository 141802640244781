/* eslint-disable unicorn/consistent-function-scoping*/
import { action, flow, makeObservable, observable } from "mobx";

import { ErrorMessage } from "../components/TheMessage/TheMessageStore";
import {
  validateUserEmail,
  ValidationCheck,
} from "../shared/helpers/commonValidations";
import { translateAPIError } from "../shared/helpers/translateApiError";
import { RootStore } from "./rootStore";

export class AuthFormsStore {
  @observable email: string = "";
  @observable emailTouched: boolean = false;

  @observable formForgetPassword: {
    submitting: boolean;
    errorMessage: ErrorMessage;
  } = {
    submitting: false,
    errorMessage: null,
  };

  constructor(private rootStore: RootStore) {
    makeObservable(this);
  }

  get emailFeedback(): ValidationCheck {
    const [valid, msg, { vars }] = validateUserEmail(this.email);

    if (!this.emailTouched) {
      return { valid, msg: "" };
    }

    return { valid, msg, vars };
  }

  get submitForgotPassBtnEnabled(): boolean {
    return !this.formForgetPassword.submitting && this.emailFeedback.valid;
  }

  @action.bound updateEmail(email: string): void {
    this.email = email;
  }

  @action.bound updateEmailTouched(touched: boolean): void {
    this.emailTouched = touched;
  }

  @action.bound submitFormForgotPassword = flow(
    function* submitFormForgotPassword(this: AuthFormsStore) {
      this.formForgetPassword.errorMessage = null;
      this.formForgetPassword.submitting = true;
      try {
        yield this.rootStore.userSessionApi.requestEmailForPasswordReset(
          this.email,
        );
        this.rootStore.modalStore.openModal("");
        this.rootStore.theMessageStore.showMessage(
          {
            typeMessage: "Close",
            title: "toast-message.password-reset-sent.title",
            content: "We've sent you a link to reset your password.",
          },
          { closeDuration: "never" },
        );
        this.email = "";
        this.emailTouched = false;
      } catch (error) {
        this.formForgetPassword.errorMessage = translateAPIError(error);
      } finally {
        this.formForgetPassword.submitting = false;
      }
    },
  );
}
