import { gql } from "@ts-gql/tag";

export function charityCountQuery() {
  return gql`
    query charityCountQuery($id: ID!) {
      charityCount(id: $id) {
        count
      }
    }
  ` as import("../../../__generated__/ts-gql/charityCountQuery").type;
}

export function uonCharityCountUpdatedSubscription() {
  return gql`
    subscription charityCountUpdated($id: ID!) {
      charityCountUpdated(id: $id) {
        count
      }
    }
  ` as import("../../../__generated__/ts-gql/charityCountUpdated").type;
}
export function charityReceivedCountUpdatedSubscription() {
  return gql`
    subscription charityReceivedCountUpdated($id: ID!) {
      charityReceivedCountUpdated(id: $id) {
        count
      }
    }
  ` as import("../../../__generated__/ts-gql/charityReceivedCountUpdated").type;
}
