import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { scroller } from "react-scroll";

import { useBodyWidth } from "../../shared/helpers/useBodySize";
import { Currency } from "../../stores/DonateStore/DonateStore";
import { SectionInfo } from "../../stores/PoliciesPresenter";
import sc from "./Policies.styled";
import { PoliciesItemCookieSetting } from "./PoliciesItemCookieSetting";
import { PoliciesItemDisclaimer } from "./PoliciesItemDisclaimer";
import { PoliciesItemNoticeAndTakedown } from "./PoliciesItemNoticeAndTakedown";
import { PoliciesItemPrivacy } from "./PoliciesItemPrivacy";
import { PoliciesItemTermsOfService } from "./PoliciesItemTermsOfService";

export interface PoliciesDriver {
  readonly cookieSettingsChanged: boolean;
  readonly cookieSocialAccepted: boolean;
  readonly currency: Currency;
  readonly currentSection: string;
  cookieSettingsChangedUpdate(b: boolean): void;
  sectionsRegister(section: SectionInfo): void;
  onSaveCookieSettingsChanged(): void;
  currentSectionUpdate(s: string): void;
}
interface IProps {
  driver: PoliciesDriver;
}

export const Policies = observer((props: IProps) => {
  useEffect(() => {
    scroller.scrollTo(props.driver.currentSection, {
      duration: 500,
      smooth: true,
      containerId: "PoliciesOverlay",
      offset: useBodyWidth() <= 60 * 16 ? -8 * 4 : -6 * 4,
    });
  }, [props.driver.currentSection]);

  return (
    <sc.Container>
      <PoliciesItemDisclaimer
        onRegister={(section: SectionInfo) =>
          props.driver.sectionsRegister(section)
        }
      />
      <PoliciesItemTermsOfService
        onRegister={(section: SectionInfo) =>
          props.driver.sectionsRegister(section)
        }
        currency={props.driver.currency}
      />
      <PoliciesItemPrivacy
        onRegister={(section: SectionInfo) =>
          props.driver.sectionsRegister(section)
        }
      />
      <PoliciesItemCookieSetting driver={props.driver} />
      <PoliciesItemNoticeAndTakedown
        onRegister={(section: SectionInfo) =>
          props.driver.sectionsRegister(section)
        }
      />
    </sc.Container>
  );
});
