/* eslint-disable unicorn/no-document-cookie */
/* eslint-disable more/no-then */
import { action, flow, makeObservable, observable } from "mobx";
import { i18n } from "next-i18next";

import { USER_LOCALE } from "../../server/LocaleDetector";
import { isBrowser } from "../shared/helpers/isBrowser";
import { Logger } from "../shared/models/Logger";

export type LocaleData = {
  currentLocale: string;
};

export class LocaleStore {
  @observable currentLocale: string = "en";

  constructor(private logger: Logger) {
    makeObservable(this);
  }

  public dehydrate(): LocaleData {
    return {
      currentLocale: this.currentLocale,
    };
  }

  @action.bound public hydrate = (data: LocaleData): void => {
    this.currentLocale = data.currentLocale;
  };

  @action.bound public updateLocale = flow(function* (
    this: LocaleStore,
    locale: string,
  ) {
    if (!i18n) {
      this.logger.warn(`i18n not initialized yet`);
      return;
    }

    if (!locale) return;

    yield i18n.changeLanguage(locale);
    this.logger.info(`Language changed to ${locale}`);
    this.currentLocale = locale.split("-")[0];

    if (isBrowser()) {
      document.cookie = `${USER_LOCALE}=${locale}`;
    }
  });
}
