import styled, { keyframes } from "styled-components";

import { etBlack } from "../../shared/colors";

export const fadeInAnimaion = keyframes`
  from{
    opacity: 0;
    transform: translate(0, -2.5rem);
  }
  to{
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const fadeOutAnimaion = keyframes`
  from{
    opacity: 1;
    transform: translate(0, 0);
  }
  to{
    opacity: 0;
    transform: translate(0, -2.5rem);
  }
`;

namespace sc {
  export const ModalBody = styled.div<{
    top: string;
    left: string;
    right: string;
    bottom: string;
  }>`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    top: ${(props) => props.top};
    bottom: ${(props) => props.bottom};
    left: ${(props) => props.left};
    right: ${(props) => props.right};
    opacity: 1;
    &.active {
      animation: ${fadeInAnimaion} 500ms ease;
    }
    &.inactive {
      animation: ${fadeOutAnimaion} 500ms ease;
    }
  `;

  export const ModalBodyStateLess = styled.div<{
    top: string;
    left: string;
    right: string;
    bottom: string;
  }>`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    top: ${(props) => props.top};
    bottom: ${(props) => props.bottom};
    left: ${(props) => props.left};
    right: ${(props) => props.right};
  `;
  export const ModalOverlay = styled.div<{ opacity: number }>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: ${etBlack};
    opacity: ${(props) => props.opacity};
  `;
  export const Outer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
  `;
}

export default sc;
