import { gql } from "@ts-gql/tag";
export function campaignCountUpdatedSubscription() {
  return gql`
    subscription campaignCountUpdated($id: ID!) {
      campaignCountUpdated(id: $id) {
        count
        status
      }
    }
  ` as import("../../../__generated__/ts-gql/campaignCountUpdated").type;
}

export function getDefaultCampaignCount() {
  return gql`
    query campaignCount($id: ID!) {
      campaignCount(id: $id) {
        count
        status
        name
        profileHeaderCardDisplayType
      }
    }
  ` as import("../../../__generated__/ts-gql/campaignCount").type;
}

export function campaignLeaderboardTopUpdatedSubscription() {
  return gql`
    subscription campaignLeaderboardTopUpdated($id: ID!) {
      campaignLeaderboardTopUpdated(id: $id) {
        consumerId
        name
        count
        date
        photo {
          id
          url
          awsBucket
          awsKey
          isTransparent
        }
        vanityName
        userEarthId
        isPublished
        type
      }
    }
  ` as import("../../../__generated__/ts-gql/campaignLeaderboardTopUpdated").type;
}
