import { IUserSessionStore } from "../../components/ModalLogin/UserSessionStore";
import {
  ITheMessageStore,
  ToastMessageStatus,
} from "../../components/TheMessage/TheMessageStore";

export const showMessageEmailNotVerified = (
  theMessageStore: ITheMessageStore,
  userSessionStore: IUserSessionStore,
): void => {
  theMessageStore.showMessage(
    {
      typeMessage: "Action",
      status: ToastMessageStatus.WARNING,
      title: "toast-message.email-not-verified.title",
      content: "toast-message.email-not-verified.content",
      actions: [
        {
          key: "close",
          name: "toast-message.general.action-close",
          action: () => theMessageStore.close(),
        },
        {
          key: "resend",
          name: "toast-message.general.action-resend",
          action: () => userSessionStore.resendVerificationEmail(),
        },
      ],
    },
    { closeDuration: "never" },
  );
};
