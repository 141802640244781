/* eslint-disable unicorn/prefer-spread */
export const protectPageSectionOneNumberAnimation = (
  countNumber: number,
): { lastNumber: number; countNumbers: number[] }[] => {
  const countDigits = `${countNumber}`.split("");

  if (countNumber > 0) {
    countDigits.unshift("0");
  }

  return countDigits.map((count, i) => {
    const lastNumber = Number.parseInt(count, 10);
    const countNumbers = Array.from({ length: (i + 1) * 2 })
      .map((_, y) => {
        return Math.abs(lastNumber - y - 1);
      })
      .reverse();

    return {
      lastNumber,
      countNumbers,
    };
  });
};
