/* eslint-disable unicorn/prefer-spread */
/* eslint-disable unicorn/no-array-reduce */
/* eslint-disable unicorn/consistent-function-scoping */

import { action, flow, makeObservable, observable, runInAction } from "mobx";

import {
  ErrorMessage,
  ITheMessageStore,
  ToastMessageStatus,
} from "../../components/TheMessage/TheMessageStore";
import { ICampaignDefaultApi } from "../../shared/apis/CampaignDefaultApi";
import { IProfileApi } from "../../shared/apis/ProfileApi";
import { IProtectPageApi } from "../../shared/apis/ProtectPageAPI";
import { UnsubscribeFn } from "../../shared/apis/UnsubscribeFn";
import { isBrowser } from "../../shared/helpers/isBrowser";
import { translateAPIError } from "../../shared/helpers/translateApiError";
import { User } from "../../shared/models/User";

export type CounterSourceType =
  | "global"
  | "campaign"
  | "brand"
  | "npo"
  | "charity";

export type CounterSource = {
  sourceType: CounterSourceType;
  idOrVanityName: string;
};

export type MultipleCounterSources = {
  sources: string; // in pattern: "{sourceType:brand,idOrVanityName:thoibrand};{sourceType:campaign,idOrVanityName:123456789}"
};

export interface CounterPageData {
  isPageLoading: boolean;
  fetchPageDataError: ErrorMessage;
}

export class CounterStore {
  @observable fetchPageDataError: ErrorMessage = null;
  @observable fetchReserveCountDataError: ErrorMessage = null;
  @observable isPageLoading: boolean = false;

  @observable protectCount: number = 0;

  @observable loadingProtectedCount: boolean = false;

  constructor(
    private protectPageApi: IProtectPageApi,
    private profileApi: IProfileApi,
    private campaignDefaultApi: ICampaignDefaultApi,

    private theMessageStore: ITheMessageStore,
  ) {
    makeObservable(this);
  }

  public dehydrate(): CounterPageData {
    return {
      fetchPageDataError: this.fetchPageDataError,
      isPageLoading: this.isPageLoading,
    };
  }

  @action.bound public hydrate(data: CounterPageData): void {
    this.fetchPageDataError = data.fetchPageDataError;
    this.isPageLoading = data.isPageLoading;
  }

  @action.bound
  updateLoadingReserve = (b: boolean): void => {
    this.loadingProtectedCount = b;
  };

  @action.bound clicked = () => {
    this.theMessageStore.showMessage(
      {
        typeMessage: "Action",
        status: ToastMessageStatus.SUCCESS,
        title: "toast-message.copy-linked.title",
        content: "toast-message.copy-linked.content",
        actions: [],
      },
      { closeDuration: 3500 },
    );
  };

  @action.bound
  fetchPageDataBrowser = flow(function* fetchPageDataBrowser(
    this: CounterStore,
    param?: CounterSource | MultipleCounterSources,
  ) {
    if (!isBrowser()) {
      return;
    }

    this.loadingProtectedCount = true;

    if (!param) {
      // fetch global count
      yield this.fetchReserveCount(null);
      this.loadingProtectedCount = false;
      return;
    }

    if (this.paramIsMultipleSources(param)) {
      const sourcesList = this.destructSourcesFromQueryParams(param.sources);
      yield this.fetchReserveCount(sourcesList);
      this.loadingProtectedCount = false;
      return;
    }

    yield this.fetchReserveCount(param);
    this.loadingProtectedCount = false;
  });

  @action.bound fetchReserveCount = flow(function* fetchReserveCount(
    this: CounterStore,
    sourcesList: CounterSource | CounterSource[] | null,
  ) {
    try {
      if (!sourcesList) {
        this.protectCount = yield this.protectPageApi.fetchUonCount();
      }

      if (sourcesList && !Array.isArray(sourcesList)) {
        this.protectCount = yield this.protectPageApi.fetchUonCount(
          sourcesList,
        );
      }

      if (sourcesList && Array.isArray(sourcesList)) {
        this.protectCount = yield this.protectPageApi.fetchMultipleUonCount(
          sourcesList,
        );
      }

      if (this.fetchReserveCountDataError) {
        this.fetchReserveCountDataError = null;
      }
    } catch (error) {
      this.fetchReserveCountDataError = translateAPIError(error);
    }
  });

  unsubscribeFns: UnsubscribeFn[] = [];

  subscriptionMap: Record<
    CounterSourceType,
    (combineMultiSource?: boolean, idOrVanityName?: string) => UnsubscribeFn
  > = {
    global: (combineMultiSource?: boolean) =>
      this.protectPageApi.subscribeUonCount((error, count) => {
        if (error) {
          this.fetchReserveCountDataError = translateAPIError(error);
          this.theMessageStore.showMessage({
            typeMessage: "Error",
            title: "toast-message.general.error",
            content: translateAPIError(error),
          });
          return;
        }

        runInAction(() => {
          if (combineMultiSource) {
            this.protectCount += count;
            this.fetchReserveCountDataError = null;
          } else {
            this.protectCount = count;
            this.fetchReserveCountDataError = null;
          }
        });
      }),
    campaign: (combineMultiSource?: boolean, idOrVanityName?: string) =>
      this.campaignDefaultApi.subscribeCampaignDefaultCount(
        idOrVanityName || "",
        (error, count) => {
          if (error) {
            this.theMessageStore.showMessage({
              typeMessage: "Error",
              title: "toast-message.general.error",
              content: translateAPIError(error),
            });
            return;
          }

          runInAction(() => {
            if (combineMultiSource) {
              this.protectCount += count;
              this.fetchReserveCountDataError = null;
            } else {
              this.protectCount = count;
              this.fetchReserveCountDataError = null;
            }
          });
        },
      ),
    npo: (combineMultiSource?: boolean, idOrVanityName?: string) =>
      this.profileApi.subscribeNPOCount(
        idOrVanityName || "",
        (error, count) => {
          if (error) {
            this.theMessageStore.showMessage({
              typeMessage: "Error",
              title: "toast-message.general.error",
              content: translateAPIError(error),
            });
            return;
          }

          runInAction(() => {
            if (combineMultiSource) {
              this.protectCount += count;
              this.fetchReserveCountDataError = null;
            } else {
              this.protectCount = count;
              this.fetchReserveCountDataError = null;
            }
          });
        },
      ),
    charity: (combineMultiSource?: boolean, idOrVanityName?: string) =>
      this.profileApi.subscribeCharityReceivedCount(
        idOrVanityName || "",
        (error, count) => {
          if (error) {
            this.theMessageStore.showMessage({
              typeMessage: "Error",
              title: "toast-message.general.error",
              content: translateAPIError(error),
            });
            return;
          }

          runInAction(() => {
            if (combineMultiSource) {
              this.protectCount += count;
              this.fetchReserveCountDataError = null;
            } else {
              this.protectCount = count;
              this.fetchReserveCountDataError = null;
            }
          });
        },
      ),
    brand: (combineMultiSource?: boolean, idOrVanityName?: string) =>
      this.profileApi.subscribeBrandCount(
        idOrVanityName || "",
        (error, count) => {
          if (error) {
            this.theMessageStore.showMessage({
              typeMessage: "Error",
              title: "toast-message.general.error",
              content: translateAPIError(error),
            });
            return;
          }

          runInAction(() => {
            if (combineMultiSource) {
              this.protectCount += count;
              this.fetchReserveCountDataError = null;
            } else {
              this.protectCount = count;
              this.fetchReserveCountDataError = null;
            }
          });
        },
      ),
  };

  @action.bound subscribe = flow(function* subscribe(
    this: CounterStore,
    param?: CounterSource | MultipleCounterSources,
  ) {
    if (!param) {
      this.unsubscribeFns.push(this.subscriptionMap.global());
      return;
    }

    if (!this.paramIsMultipleSources(param)) {
      if (param.sourceType === "campaign") {
        // use campaign id if source is campaign
        this.unsubscribeFns.push(
          this.subscriptionMap[param.sourceType](
            undefined,
            param.idOrVanityName,
          ),
        );
        return;
      }

      const sourceUser: User = yield this.profileApi.fetchProfileDetail(
        param.idOrVanityName,
      );
      this.unsubscribeFns.push(
        this.subscriptionMap[param.sourceType](undefined, sourceUser.id),
      );
      return;
    }

    const sourcesList = this.destructSourcesFromQueryParams(param.sources);

    if (!sourcesList) {
      return;
    }

    for (const source of sourcesList) {
      if (source.sourceType === "campaign") {
        // use campaign id if source is campaign
        this.unsubscribeFns.push(
          this.subscriptionMap[source.sourceType](true, source.idOrVanityName),
        );
      } else {
        const sourceUser: User = yield this.profileApi.fetchProfileDetail(
          source.idOrVanityName,
        );
        this.unsubscribeFns.push(
          this.subscriptionMap[source.sourceType](true, sourceUser.id),
        );
      }
    }
  });

  @action.bound unsubscribe = (): void => {
    for (const unsubscribe of this.unsubscribeFns) unsubscribe();
  };

  destructSourcesFromQueryParams = (
    sources?: string,
  ): CounterSource[] | null => {
    if (!sources) {
      return null;
    }
    // split query params by ";" to form sources object lists
    const sourceObjects = sources.split(";");

    // split inner objects into source properties and form list of sources
    const sourcesList = sourceObjects.map((obj) => {
      const sourceInputDetails: string[] = obj
        .trim()
        .replaceAll(/{|}/g, "")
        .split(",");

      const sourceProperties: string[] = sourceInputDetails.reduce(
        (result, input) => {
          return result.concat(input.trim().split(":"));
        },
        [] as string[],
      );

      if (sourceInputDetails.length === 1) {
        const sourceCampaign: CounterSource = {
          sourceType: "campaign",
          idOrVanityName: sourceProperties[1],
        };
        return sourceCampaign;
      }
      const source: CounterSource = {
        sourceType: sourceProperties[1] as CounterSourceType,
        idOrVanityName: sourceProperties[3],
      };
      return source;
    });
    return sourcesList;
  };

  paramIsMultipleSources = (
    parameter: CounterSource | MultipleCounterSources,
  ): parameter is MultipleCounterSources => {
    return !!(parameter as MultipleCounterSources).sources;
  };
}
