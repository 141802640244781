import {
  mediumScreenLowerLimitValue,
  smallScreenLowerLimitValue,
} from "../../shared/breakpoints";

export interface Viewport {
  presetBrowserWidth: number;
  detectedContentHeight: number;
}
export const findViewport = (viewports: Viewport[], browserWidth: number) => {
  const smallScreenCardWidth = smallScreenLowerLimitValue;
  const mediumScreenCardWidth = mediumScreenLowerLimitValue - 1;

  const frameWidth =
    browserWidth <= mediumScreenLowerLimitValue
      ? smallScreenCardWidth
      : mediumScreenCardWidth;
  const viewport = viewports.find((v) => v.presetBrowserWidth === frameWidth);

  return viewport;
};
