// ts-gql-integrity:fcc86a9a93ce5e72ed1ed22a8c35a8c2
/*
ts-gql-meta-begin
{
  "hash": "94e56227a5be751994330dd1a66800f9"
}
ts-gql-meta-end
*/

import * as SchemaTypes from "./@schema";
import { TypedDocumentNode } from "@ts-gql/tag";

type reserveCountUpdatedSubscriptionVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID'];
}>;


type reserveCountUpdatedSubscription = { readonly __typename: 'Subscription', readonly reserveCountUpdated: { readonly __typename: 'UonCountOutput', readonly count: number } | null };


      
export type type = TypedDocumentNode<{
  type: "subscription";
  result: reserveCountUpdatedSubscription;
  variables: reserveCountUpdatedSubscriptionVariables;
  documents: SchemaTypes.TSGQLDocuments;
  fragments: SchemaTypes.TSGQLRequiredFragments<"none">
}>

declare module "./@schema" {
  interface TSGQLDocuments {
    reserveCountUpdated: type;
  }
}

export const document = JSON.parse("{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"subscription\",\"name\":{\"kind\":\"Name\",\"value\":\"reserveCountUpdated\"},\"variableDefinitions\":[{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"ID\"}}},\"directives\":[]}],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"reserveCountUpdated\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"}}}],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"count\"},\"arguments\":[],\"directives\":[]}]}}]}}]}")
