import { Currency } from "../../stores/DonateStore/DonateStore";

export default function formatCurrency(
  n: number,
  optional?: { addSpaceInBetween?: boolean; currency?: Currency },
) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: optional?.currency || "EUR",
    minimumFractionDigits: 2,
  });

  if (optional?.addSpaceInBetween) {
    return formatter.format(n).replaceAll("€", "€");
  }

  return formatter.format(n);
}
