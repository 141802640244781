// ts-gql-integrity:0eab80e1697773fa0b55e041ac833e76
/*
ts-gql-meta-begin
{
  "hash": "647a55e977f770928cc4aa7a7b4dc070"
}
ts-gql-meta-end
*/

import * as SchemaTypes from "./@schema";
import { TypedDocumentNode } from "@ts-gql/tag";

type userCardCreatedNotificationSubscriptionVariables = SchemaTypes.Exact<{
  userId: SchemaTypes.Scalars['ID'];
}>;


type userCardCreatedNotificationSubscription = { readonly __typename: 'Subscription', readonly userCardActionNotification: { readonly __typename: 'CardNotification', readonly cardId: string, readonly path: ReadonlyArray<string | null>, readonly status: SchemaTypes.CardNotificationStatus | null } };


      
export type type = TypedDocumentNode<{
  type: "subscription";
  result: userCardCreatedNotificationSubscription;
  variables: userCardCreatedNotificationSubscriptionVariables;
  documents: SchemaTypes.TSGQLDocuments;
  fragments: SchemaTypes.TSGQLRequiredFragments<"none">
}>

declare module "./@schema" {
  interface TSGQLDocuments {
    userCardCreatedNotification: type;
  }
}

export const document = JSON.parse("{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"subscription\",\"name\":{\"kind\":\"Name\",\"value\":\"userCardCreatedNotification\"},\"variableDefinitions\":[{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"userId\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"ID\"}}},\"directives\":[]}],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"userCardActionNotification\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"userId\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"userId\"}}}],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"cardId\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"path\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"status\"},\"arguments\":[],\"directives\":[]}]}}]}}]}")
