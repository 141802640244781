import { TokenInterceptorStore } from "../../stores/TokenInterceptorStore";
import { getAPIBaseUrl } from "../env";
import { ReserveResponse } from "../models/Reserve";
import { ApiOption } from "./options/ApiOption";

export interface IReserveApi {
  fetchReserve(deckLink: string, options?: ApiOption): Promise<ReserveResponse>;
  fetchAllReserves(options?: ApiOption): Promise<ReserveResponse[]>;
}

export class ReserveApi implements IReserveApi {
  constructor(private tokenInterceptorStore: TokenInterceptorStore) {}

  fetchReserve = async (
    deckLink: string,
    options?: ApiOption,
  ): Promise<ReserveResponse> => {
    const headers: Record<string, string> = {};
    if (options?.byBass) {
      headers["x-apicache-bypass"] = "true";
    }
    if (options?.clearCache) {
      headers["cache-control"] = "max-age=0";
    }
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/reserves?deckLink=${deckLink}`,
      headers,
    });

    return res.data[0];
  };

  fetchAllReserves = async (
    options?: ApiOption,
  ): Promise<ReserveResponse[]> => {
    const headers: Record<string, string> = {};
    if (options?.byBass) {
      headers["x-apicache-bypass"] = "true";
    }
    if (options?.clearCache) {
      headers["cache-control"] = "max-age=0";
    }
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/reserves`,
      headers,
    });

    return res.data;
  };
}
