import { observer } from "mobx-react-lite";
import Link from "next/link";
import { ReactNode } from "react";
import NumberFormat from "react-number-format";

import { formatDateForLeaderBoard } from "../../shared/helpers/formatDate";
import { CustomConsumer } from "../../shared/models/Consumer";
import { useTranslation } from "../../shared/translate/NextI18next";
import sc from "./LeaderboardItem.styled";

interface Driver {
  consumer: CustomConsumer;
  closeModal(): void;
}

interface IProps {
  staticDate?: boolean;
  driver: Driver;
  dataCy: string;
  isGoodCompany?: boolean;
}

export const LeaderboardItem = observer((props: IProps) => {
  const { driver, dataCy, staticDate, isGoodCompany } = props;
  const { t } = useTranslation("Leaderboard");

  const date =
    staticDate && driver.consumer.date
      ? t("leaderboard.item.on-static-date")
      : driver.consumer.date
      ? `${t("leaderboard.item.on-consumer-date")} ${formatDateForLeaderBoard(
          driver.consumer.date,
          isGoodCompany || false,
        )}`
      : "";

  const renderLink = (children: ReactNode): ReactNode => {
    if (
      driver.consumer.isPublished &&
      (driver.consumer.vanityName || driver.consumer.userEarthId)
    ) {
      return (
        <Link
          href="/[vanityName]"
          as={`/${driver.consumer.vanityName || driver.consumer.userEarthId}`}
          passHref
          legacyBehavior
        >
          {children}
        </Link>
      );
    }
    return <>{children}</>;
  };

  return (
    <sc.Container data-cy={dataCy}>
      <sc.LogoContent
        data-cy={`${dataCy}-LogoContent`}
        url={driver.consumer.imageUrl || ""}
      />
      <sc.DonatorInfo data-cy={`${dataCy}-DonatorInfo`}>
        {renderLink(
          <sc.DonatorName
            data-cy={`${dataCy}-DonatorName`}
            onClick={() => {
              if (driver.consumer.isPublished) driver.closeModal();
            }}
          >
            {driver.consumer.name}
          </sc.DonatorName>,
        )}
        <sc.DonatedTime data-cy={`${dataCy}-DonatedTime`}>
          {t("leaderboard.item.protected")}{" "}
          <NumberFormat
            value={driver.consumer.count}
            displayType="text"
            thousandSeparator="."
            decimalSeparator=","
          />
          &nbsp;m<sc.SupM2>2</sc.SupM2> {date}
        </sc.DonatedTime>
      </sc.DonatorInfo>
    </sc.Container>
  );
});
