import { gql } from "@ts-gql/tag";

export function reserveCountUpdatedSubscription() {
  return gql`
    subscription reserveCountUpdated($id: ID!) {
      reserveCountUpdated(id: $id) {
        count
      }
    }
  ` as import("../../../__generated__/ts-gql/reserveCountUpdated").type;
}

export function reserveLeaderboardUpdatedSubscription() {
  return gql`
    subscription reserveLeaderboardUpdated($id: ID!) {
      reserveLeaderboardUpdated(id: $id) {
        consumerId
        name
        count
        date
        photo {
          id
          url
          awsBucket
          awsKey
          isTransparent
        }
        userEarthId
        isPublished
      }
    }
  ` as import("../../../__generated__/ts-gql/reserveLeaderboardUpdated").type;
}
