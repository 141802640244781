import { TokenInterceptorStore } from "../../stores/TokenInterceptorStore";
import { getAPIBaseUrl } from "../env";
import { MetaData, Vote, VoteResponse } from "../models/Card";

export interface ICardApi {
  starCard(id: string, starred: boolean): Promise<MetaData>;
  voteCard(id: string, state: Vote): Promise<VoteResponse>;
  unVoteCard(id: string): Promise<VoteResponse>;
}

export class CardApi implements ICardApi {
  constructor(private tokenInterceptorStore: TokenInterceptorStore) {}

  starCard = async (id: string, starred: boolean): Promise<MetaData> => {
    const payload = {
      starred,
    };
    const res = await this.tokenInterceptorStore.call({
      method: "PUT",
      url: `${getAPIBaseUrl()}/cards/${id}/stars`,
      data: { ...payload },
    });
    return res.data;
  };

  voteCard = async (id: string, state: Vote): Promise<VoteResponse> => {
    const payloadState = {
      state,
    };
    const res = await this.tokenInterceptorStore.call({
      method: "PUT",
      url: `${getAPIBaseUrl()}/cards/${id}/votes`,
      data: {
        ...payloadState,
      },
    });
    return res.data;
  };

  unVoteCard = async (id: string): Promise<VoteResponse> => {
    const res = await this.tokenInterceptorStore.call({
      method: "DELETE",
      url: `${getAPIBaseUrl()}/cards/${id}/votes`,
    });
    return res.data;
  };
}
