import { gql } from "@ts-gql/tag";

export function qrCodeActivatedQuery() {
  return gql`
    subscription qrCodeActivated($uuid: ID!) {
      qrCodeActivated(uuid: $uuid) {
        accessToken
        refreshToken
        status
        user {
          id
          vanityName
          firstName
          lastName
          fullName
          emailAddress
          website
          twitterUsername
          facebookUsername
          instagramUsername
          linkedInUsername
          darkMode
          isPublished
          bio
          title
          groupMemberRole
          tagline
          profileTagOverride
          isApiKeyEnabled
          forceUseTagLine
          providerId
          provider
          cookieConsent
          image {
            id
            url
          }
          joined
          userCounts {
            publishedCards
            uonCount
          }
          emailAddressVerified
          termsAccepted
          termsExpired
          administrator
          curator
          createdAt
        }
      }
    }
  ` as import("../../../__generated__/ts-gql/qrCodeActivated").type;
}
