// ts-gql-integrity:7a064f026deb6e589dc8d52ffd9fba76
/*
ts-gql-meta-begin
{
  "hash": "d7e715cc88747e33649b0860e6d17905"
}
ts-gql-meta-end
*/

import * as SchemaTypes from "./@schema";
import { TypedDocumentNode } from "@ts-gql/tag";

type promotionCountUpdatedSubscriptionVariables = SchemaTypes.Exact<{
  promotionId: SchemaTypes.Scalars['ID'];
}>;


type promotionCountUpdatedSubscription = { readonly __typename: 'Subscription', readonly promotionCountUpdated: { readonly __typename: 'PromotionCount', readonly id: string, readonly count: number } };


      
export type type = TypedDocumentNode<{
  type: "subscription";
  result: promotionCountUpdatedSubscription;
  variables: promotionCountUpdatedSubscriptionVariables;
  documents: SchemaTypes.TSGQLDocuments;
  fragments: SchemaTypes.TSGQLRequiredFragments<"none">
}>

declare module "./@schema" {
  interface TSGQLDocuments {
    promotionCountUpdated: type;
  }
}

export const document = JSON.parse("{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"subscription\",\"name\":{\"kind\":\"Name\",\"value\":\"promotionCountUpdated\"},\"variableDefinitions\":[{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"promotionId\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"ID\"}}},\"directives\":[]}],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"promotionCountUpdated\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"promotionId\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"promotionId\"}}}],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"count\"},\"arguments\":[],\"directives\":[]}]}}]}}]}")
