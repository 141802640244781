import styled from "styled-components";

import { etWhite } from "../../shared/colors";
export namespace sc {
  export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: ${etWhite};
    width: 100%;
  `;
  export const SectionWrapper = styled.div<{
    zIndex?: number;
    marginTop?: number;
  }>`
    position: relative;
    margin-top: ${(props) => props.marginTop || 0}px;
    width: 100%;
    z-index: ${(props) => props.zIndex || 0};
  `;

  export const MoviePlayer = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 100;
  `;
  export const MovieWrapper = styled.div`
    width: 90vw;
    height: calc(90vw * 759 / 1350);
  `;
  export const YoutubeIFrame = styled.iframe`
    border: none;
  `;
}
