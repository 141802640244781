/* eslint-disable @typescript-eslint/no-var-requires */
import { CookieSerializeOptions } from "cookie";
import { destroyCookie, parseCookies, setCookie } from "nookies";

import { ICookie } from "../stores/TokenStore";
import { CacheNoop, ICache } from "./apis/CacheNoop";
import { Logger } from "./models/Logger";

export const logger: Logger = (process as any).browser
  ? (() => {
      const { createLogger, stdSerializers } = require("browser-bunyan");
      const {
        ConsoleFormattedStream,
      } = require("@browser-bunyan/console-formatted-stream");
      return createLogger({
        name: "et-ui",
        streams: [
          {
            level: "debug",
            stream: new ConsoleFormattedStream(),
          },
        ],
        serializers: { ...stdSerializers },
        src: true,
      });
    })()
  : require("bunyan").createLogger({
      name: "et-ui",
      serializers: require("bunyan").stdSerializers,
    });

export const appCache: ICache =
  process.env.NODE_ENV === "test"
    ? new CacheNoop()
    : (process as any).browser
    ? require("./apis/CacheNoop").instance
    : require("./apis/CacheMem").instance;

export const browserCookieManager: ICookie = {
  get(key: string): string {
    const cookies = parseCookies();
    return cookies[key];
  },
  set(key: string, value: string, options: CookieSerializeOptions) {
    setCookie(null, key, value, options);
  },
  remove(key: string) {
    destroyCookie(null, key);
  },
};
