import { OneFlowType } from "../../stores/ModalOneFlowPresenter/ModalOneFlowPresenter";
import { SourceType } from "../apis/PaymentApi";
import { OneFlowSessionSource, Payment } from "./Payment";
import { User } from "./User";

export enum OneFlowActAs {
  USER = "USER",
  GROUP = "GROUP",
}

export enum PaymentMethods {
  IDEAL = "IDEAL",
  CREDIT_CARD = "CREDIT_CARD",
  PAYPAL = "PAYPAL",
  APPLE_PAY = "APPLE_PAY",
  NONE = "NONE",
}

export enum OneFlowDonationType {
  PROTECT = "PROTECT",
  SUBSCRIBE_GIFT = "SUBSCRIBE_GIFT",
  SUBSCRIBE_PERSONAL = "SUBSCRIBE_PERSONAL",
}

export interface UserRegisterPayload {
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  emailAddress: string;
  preHashedPassword: string;
  invitationToken?: string;
  consent?: string;
  cookieConsentCreatedAt?: string;
  acceptedTerms?: boolean;
}

export interface NewGroupPayload {
  name: string;
  vanityName?: string;
  description?: string;
}

export interface PreparePaymentPayload {
  method: PaymentMethods;
  numberOfUon: number;
  redirectUrl?: string;
  issuer?: string;
  sourceId?: string;
  promotionId?: string;
  sourceType?: SourceType;
  redirectUrlExtraParameters?: Record<string, string>;
}

export interface OneFlowAccountPreparePayload {
  type: OneFlowType;
  actAs: OneFlowActAs;
  oneFlowId?: string;
  signupEmail?: UserRegisterPayload;
  newGroup?: NewGroupPayload;
  existingGroupId?: string;
  sessionSource?: OneFlowSessionSource;
}

export interface OneFlowAccountPrepareResponse {
  oneFlowId: string;
  userResponse: User;
  groupResponse?: User;
}

export interface OneFlowPaymentPreparePayload {
  payment: PreparePaymentPayload;
  giftsCount?: number;
}

export interface OneFlowPaymentRetryPayload {
  payment: PreparePaymentPayload;
}
export interface OneFlowPaymentPrepareResponse {
  oneFlowId: string;
  paymentResponse: Payment;
}
