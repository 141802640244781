/* eslint-disable no-unused-expressions */
import { observer } from "mobx-react-lite";
import { RefObject, useEffect, useRef } from "react";

import { etGrayBold } from "../../shared/colors";
import { googleClientID } from "../../shared/env";
import { isBrowser } from "../../shared/helpers/isBrowser";
import { wait } from "../../shared/helpers/wait";
import { logger } from "../../shared/requireCrossEnv";
import { trackSignUpWithGoogleButtonClickEvent } from "../../shared/snowplow";
import { ButtonSocialGoogle } from "../ButtonSocial/ButtonSocialGoogle";
import { sc } from "./GoogleButton.styled";

export type GoogleButtonDriver = {
  submitting: boolean;
  onGoogleSuccess: (token: string) => void;
  onGoogleFailure: (error: Error) => void;
};
export type IProps = {
  driver: GoogleButtonDriver;

  text: string;
  dataCy: string;
  dataTrackId?: string;
  color?: string;
};

export const initGoogleClient = async (
  driver: Pick<GoogleButtonDriver, "onGoogleFailure" | "onGoogleSuccess">,
) => {
  if (!isBrowser()) return;

  while (!window || !window.google) {
    let retryCount = 0;

    retryCount = +1;
    logger.debug("initGoogleClient :: retry", retryCount);
    await wait(500);
  }

  try {
    window.google.accounts.id.initialize({
      client_id: googleClientID,
      callback: async (res) => {
        try {
          if (!res.credential) {
            throw new Error("login with google failed");
          }
          driver.onGoogleSuccess(res.credential);
        } catch (error) {
          if (error instanceof Error) {
            driver.onGoogleFailure(error as Error);
            return;
          }

          throw error;
        }
      },

      ux_mode: "popup",
    });
  } catch (error) {
    logger.error({ err: error }, "initGoogleClient :: error");
  }
};

export const renderGooglePrompt = () => {
  if (!window.google) return;

  window.google.accounts.id.prompt();
};

export const renderGoogleButton = (
  divRef: RefObject<HTMLDivElement>,
  onClick: () => void,
) => {
  if (!window.google || !divRef.current) {
    return;
  }
  window.google.accounts.id.renderButton(divRef.current, {
    size: "large",
    width: "280px",
    type: "standard",
    click_listener: onClick,
  });
};

export const GoogleButton = observer((props: IProps) => {
  const divRef = useRef<HTMLDivElement>(null);
  const { driver, text, dataCy, color = etGrayBold, dataTrackId } = props;

  useEffect(() => {
    if (!window.google || !divRef.current) {
      return;
    }

    try {
      /* override with modal state */
      initGoogleClient(driver);

      renderGoogleButton(divRef, () =>
        trackSignUpWithGoogleButtonClickEvent(dataCy, dataTrackId),
      );
    } catch (error) {
      throw error;
    }
  }, [googleClientID, divRef.current]);

  return (
    <sc.Wrapper>
      <sc.HoverableDiv>
        <sc.CustomComponent>
          <ButtonSocialGoogle
            driver={{
              color,
              text,
              dataCy,
              dataTrackId,
              disabled: driver.submitting,
              onClick: () => {}, // just implement style
            }}
          />
        </sc.CustomComponent>
        {!driver.submitting && <div ref={divRef} />}
      </sc.HoverableDiv>
    </sc.Wrapper>
  );
});
