/* eslint-disable unicorn/consistent-function-scoping */

import { action, flow, makeObservable, observable } from "mobx";

import {
  ISubscriptionApi,
  ISubscriptionInfo,
} from "../shared/apis/SubscriptionApi";

export enum SubscriptionIntervalUnit {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
}

export class SubscriptionStore {
  @observable subscriptionInfo: ISubscriptionInfo | null = null;
  @observable isLoadingSubscriptionInfo: boolean = false;
  constructor(private subscriptionApi: ISubscriptionApi) {
    makeObservable(this);
  }

  @action.bound fetchSubscriptionById = flow(function* fetchSubscriptionById(
    this: SubscriptionStore,
    subscriptionId: string,
  ) {
    try {
      this.isLoadingSubscriptionInfo = true;

      const response = yield this.subscriptionApi.fetchSubscriptionById(
        subscriptionId,
      );

      if (response.isSelfRequest) {
        this.subscriptionInfo = response;
      }
    } finally {
      this.isLoadingSubscriptionInfo = false;
    }
  });
}
