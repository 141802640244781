/* eslint-disable more/no-then */
/* eslint-disable unicorn/prefer-spread */
import {
  DeckBackgroundImageType,
  DeckCuratingResponseDto,
  DeckDetailResponseDto,
  MobileSystemDeckResponseDto,
} from "@earthtoday/contract";
import { action, computed, makeObservable, observable } from "mobx";
import getConfig from "next/config";

import {
  CARD_DECK_HEADER_IMAGE_HEIGHT,
  CARD_DECK_HEADER_IMAGE_MOBILE_WIDTH,
  CARD_DECK_HEADER_IMAGE_TABLET_WIDTH,
  CARD_DECK_HEADER_IMAGE_WIDTH,
  CARD_PROFILE_REPOST_HEADER_IMAGE_HEIGHT,
  CARD_PROFILE_REPOST_HEADER_IMAGE_MOBILE_HEIGHT,
  CARD_PROFILE_REPOST_HEADER_IMAGE_MOBILE_WIDTH,
  CARD_PROFILE_REPOST_HEADER_IMAGE_TABLET_HEIGHT,
  CARD_PROFILE_REPOST_HEADER_IMAGE_TABLET_WIDTH,
  CARD_PROFILE_REPOST_HEADER_IMAGE_WIDTH,
} from "../components/AutoSlide/AutoSlide.styled";
import { CardDeckHeaderDriver } from "../components/CardDeckHeader/CardDeckHeader";
import { DynamicLazyModalLoader } from "../components/DynamicModalLoader/DynamicLazyModalLoader";
import { IModalDeleteDeckStore } from "../components/ModalDeleteDeck/ModalDeleteDeckStore";
import { IUserSessionStore } from "../components/ModalLogin/UserSessionStore";
import {
  ITheMessageStore,
  ToastMessageStatus,
} from "../components/TheMessage/TheMessageStore";
import { IDeckDetailApi } from "../shared/apis/DeckDetailApi";
import {
  largeScreenUpperLimitValue,
  smallScreenUpperLimitValue,
} from "../shared/breakpoints";
import {
  CARD_DECK_PREVIEW_IMAGES_LIMIT,
  EARTH_TODAY_VANITY_NAME,
} from "../shared/constants";
import { host } from "../shared/env";
import { buildResizedAwsImageRequest } from "../shared/helpers/buildAwsImageRelated";
import { copyLinkToClipboard } from "../shared/helpers/copyLinkToClipboard";
import { getResizeImageUrl } from "../shared/helpers/getResizeImageUrl";
import trimAndUppercase from "../shared/helpers/trimAndUppercase";
import { wait } from "../shared/helpers/wait";
import { Card } from "../shared/models/Card";
import { UonReserve } from "../shared/models/Uon";
import { AutoPlayDeckStore, AutoplayStatus } from "./AutoplayDeckStore";
import { ChannelPage } from "./DeckDetailStore/DeckDetailStore";
import { IDeviceStore } from "./DeviceStore";
import { FeatureFlaggingData } from "./FeatureFlaggingStore";
import { IFollowingRequest } from "./FollowingStore";
import { ModalEditDeckPresenterDependencies } from "./ModalEditDeckPresenter/ModalEditDeckPresenter";
import { IModalStore, ModalType } from "./ModalStore";
import { ModalSystemDeckPresenter } from "./ModalSystemDeckPresenter/ModalSystemDeckPresenter";

export const BACKGROUND_IMAGE_PREVIEW_HEIGHT = 496;
export const BACKGROUND_IMAGE_PREVIEW_WIDTH = 328;

interface CardDeckHeaderModelDependencies {
  onDeleteDeck?(id: string): void;
  updateDeckDeleteId?(id: string): void;
  toggleFollowing(data: IFollowingRequest): void;
  submitAutoplay?(
    sourceDeckId: string,
    targetDeckId: string,
    numberOfCards: number,
    status: AutoplayStatus,
  ): void;
  getAutoplayItem?(sourceDeckId: string): void;
  cancelAutoplay?(sourceDeckId: string, targetDeckId: string): void;
  saveDeckShortcut?(deckId: string, isShortcut: boolean): void;
  onOpenLoginModal?: () => void;
}

export class CardDeckHeaderModel implements CardDeckHeaderDriver {
  @observable contextMenuActive: boolean = false;
  @observable shareActive: boolean = false;
  @observable starActive: boolean = false;
  @observable isOpen = false;
  @observable deck: DeckDetailResponseDto;
  @observable autoplayStatus: AutoplayStatus = AutoplayStatus.NONE;
  @observable autoplayTargetDeckId: string = "";
  @observable isDeckShortcut: boolean = false;

  constructor(
    private modalStore: IModalStore,
    private theMessageStore: ITheMessageStore,
    private userSessionStore: IUserSessionStore,
    private modalDeleteDeckStore: IModalDeleteDeckStore,
    private autoplayDeckStore: AutoPlayDeckStore,
    private dependencies: CardDeckHeaderModelDependencies,
    deck: DeckDetailResponseDto,
    private deviceStore: IDeviceStore,
    private featureFlaggingData: FeatureFlaggingData,
    private deckDetailApi: IDeckDetailApi,
    private goalNumber?: number,
  ) {
    makeObservable(this);
    this.deck = deck;
    this.modalSystemDeckDriver = new ModalSystemDeckPresenter(
      this,
      this.deckDetailApi,
      this.theMessageStore,
      this.modalStore,
    );
  }

  @action toJSON = (): DeckDetailResponseDto => {
    return this.deck;
  };

  @computed get deckId(): string {
    return this.deck.data.id;
  }

  @action openEditDeck = (): void => {
    this.modalStore.openLazyModal({
      name: "editDeck",
      component: (
        <DynamicLazyModalLoader
          loadComponent={() =>
            import("../components/ModalEditDeck/ModalEditDeck").then(
              (component) => component.ModalEditDeck,
            )
          }
          driver={this}
        />
      ),
    });
  };

  @action.bound onDeleteDeck(): void {
    if (!this.dependencies.onDeleteDeck) {
      return;
    }
    this.dependencies.onDeleteDeck(this.deck.data.id);
  }

  @action openContextMenu = (): void => {
    this.dependencies.getAutoplayItem &&
      this.dependencies.getAutoplayItem(this.deck.data.id);
    this.contextMenuActive = true;
  };

  @action closeContextMenu = (): void => {
    this.contextMenuActive = false;
  };

  @action.bound onIconMoreClicked(): void {
    if (this.contextMenuActive) {
      this.closeContextMenu();
      return;
    }
    this.openContextMenu();
  }

  @action toggleShareActive = (): void => {
    this.shareActive = !this.shareActive;
  };

  @action toggleStarActive = (): void => {
    this.starActive = !this.starActive;
  };

  @computed get reserves(): UonReserve[] | undefined {
    return this.deck.data.reserves as UonReserve[];
  }

  @action.bound getWidthHeightCardDeckPreviewImage(
    browserWidth: number,
    isProfileImage: boolean,
  ): {
    width: number;
    height: number;
  } {
    if (!isProfileImage) {
      if (browserWidth > largeScreenUpperLimitValue)
        return {
          width: (CARD_DECK_HEADER_IMAGE_WIDTH * 75) / 100,
          height: (CARD_DECK_HEADER_IMAGE_HEIGHT * 75) / 100,
        };

      if (browserWidth > smallScreenUpperLimitValue)
        return {
          width: CARD_DECK_HEADER_IMAGE_TABLET_WIDTH,
          height: CARD_DECK_HEADER_IMAGE_HEIGHT,
        };

      return {
        width: CARD_DECK_HEADER_IMAGE_MOBILE_WIDTH,
        height: CARD_DECK_HEADER_IMAGE_HEIGHT,
      };
    }

    if (browserWidth > largeScreenUpperLimitValue)
      return {
        width: CARD_PROFILE_REPOST_HEADER_IMAGE_WIDTH,
        height: CARD_PROFILE_REPOST_HEADER_IMAGE_HEIGHT,
      };

    if (browserWidth > smallScreenUpperLimitValue)
      return {
        width: CARD_PROFILE_REPOST_HEADER_IMAGE_TABLET_WIDTH,
        height: CARD_PROFILE_REPOST_HEADER_IMAGE_TABLET_HEIGHT,
      };

    return {
      width: CARD_PROFILE_REPOST_HEADER_IMAGE_MOBILE_WIDTH,
      height: CARD_PROFILE_REPOST_HEADER_IMAGE_MOBILE_HEIGHT,
    };
  }
  @computed get browserWidth(): number {
    return this.deviceStore.browserWidth;
  }

  @computed get cardDeckPreviewImages(): Array<{
    url: string;
    isProfileImage: boolean;
  }> {
    if (
      this.deck.data.previewImages === null ||
      this.deck.data.previewImages === undefined ||
      this.deck.data.previewImages.length === 0
    )
      return [];

    const previewImages: Array<{ url: string; isProfileImage: boolean }> =
      Array.from(
        new Set(
          this.deck.data.previewImages.map((previewImage) => {
            if (!previewImage.awsBucket || !previewImage.awsKey) {
              return {
                url: previewImage.url || "",
                isProfileImage: previewImage.isProfileImage,
              };
            }

            const { width, height } = this.getWidthHeightCardDeckPreviewImage(
              this.browserWidth,
              previewImage.isProfileImage,
            );

            const url = buildResizedAwsImageRequest(previewImage, {
              width,
              height,
            });
            return {
              url,
              isProfileImage: previewImage.isProfileImage,
            };
          }),
        ),
      );

    while (previewImages.length > CARD_DECK_PREVIEW_IMAGES_LIMIT) {
      const randomIndex = Math.floor(Math.random() * previewImages.length);
      previewImages.splice(randomIndex, 1);
    }

    return previewImages;
  }
  @computed get imageSharing(): string {
    if (this.deck.data.previewImages.length === 0) return "";

    const previewImages: Array<string> = Array.from(
      new Set(
        this.deck.data.previewImages.map(
          (previewImage) => previewImage.url || "",
        ),
      ),
    );

    while (previewImages.length > CARD_DECK_PREVIEW_IMAGES_LIMIT) {
      const randomIndex = Math.floor(Math.random() * previewImages.length);
      previewImages.splice(randomIndex, 1);
    }

    return previewImages[0];
  }

  @computed get name(): string {
    return this.deck.data.name || "";
  }

  @computed get description(): string {
    return this.deck.data.description || "";
  }
  @computed get cardsCount(): number {
    return this.deck.data.cardsCount || 0;
  }

  @computed get followersCount(): number {
    return this.deck.data.followersCount || 0;
  }

  @computed get following(): boolean {
    return this.deck.data.following.following || false;
  }

  @computed get followingCounts(): number {
    return this.deck.data.following.count || 0;
  }

  @computed get id(): string {
    return this.deck.data.id || "";
  }

  @computed get presentation(): {
    labelIcon: string;
    labelTitle: string;
    labelColor: string;
  } {
    return {
      labelIcon: this.deck.data.presentation.labelIcon || "",
      labelTitle: this.deck.data.presentation.labelTitle || "",
      labelColor: this.deck.data.presentation.labelColor || "",
    };
  }

  @computed get number(): number {
    return this.goalNumber || 0;
  }

  @computed get vanityName(): string {
    return this.deck.data.curator.vanityName || "";
  }

  @computed get linkName(): string {
    return this.path.length >= 2 ? this.path[1] : "";
  }

  @computed get deckAuthor(): string {
    return this.deck.data.curator.name || "";
  }

  @computed get logo(): string {
    return this.deck.data.curator.image?.url || "";
  }

  @computed get path(): string[] {
    return this.deck.data.path || [];
  }

  @computed get currentUser(): boolean {
    return (
      this.deck.data.curator.currentUser ||
      this.userSessionStore.user?.vanityName ===
        this.deck.data.curator.vanityName ||
      false
    );
  }

  @computed get cards(): Card[] {
    return this.deck.data.cards as Card[];
  }

  @computed get deckStatistic(): {
    cardsCount: number;
    followersCount: number;
  } {
    return {
      cardsCount: this.deck.data.cardsCount,
      followersCount: this.deck.data.followersCount,
    };
  }

  @computed get isDeleteOptionVisible(): boolean {
    return (
      this.currentUser || this.userSessionStore.user?.administrator || false
    );
  }

  @computed get isLoggedInUser(): boolean {
    return !!this.userSessionStore.user;
  }

  @computed get formatedDeckName(): string {
    return trimAndUppercase(this.deck.data.name);
  }

  @computed get deckUrl(): string {
    const url = getConfig().publicRuntimeConfig.REACT_APP_HOST;

    return `${url}/${this.path.join("/")}`;
  }
  @computed get deckMobileUrl(): string {
    const url = getConfig().publicRuntimeConfig.REACT_APP_HOST;

    return `${url}/mobile/${this.path.join("/")}`;
  }

  @computed get isChannelPage(): boolean {
    if (this.deck?.data.path.length > 1) {
      return (
        Object.values(ChannelPage).includes(
          this.deck.data.path[1] as ChannelPage,
        ) && this.deck.data.path[0] === EARTH_TODAY_VANITY_NAME
      );
    }

    return false;
  }

  @action.bound updateDeckDelete(): void {
    this.modalDeleteDeckStore.updateDeckDelete(this);
  }

  @action.bound
  updateDeckDeleteId(s: string): void {
    if (!this.dependencies.updateDeckDeleteId) {
      return;
    }
    this.dependencies.updateDeckDeleteId(s);
  }

  @action.bound
  onToggleFollowing(following: boolean): void {
    if (
      !!this.userSessionStore.user &&
      !this.userSessionStore.user.emailAddressVerified
    ) {
      this.theMessageStore.showMessage(
        {
          typeMessage: "Action",
          status: ToastMessageStatus.WARNING,
          title: "toast-message.email-not-verified.title",
          content: "toast-message.email-not-verified.content",
          actions: [
            {
              key: "close",
              name: "toast-message.general.action-close",
              action: () => this.theMessageStore.close(),
            },
            {
              key: "resend",
              name: "toast-message.general.action-resend",
              action: () => this.userSessionStore.resendVerificationEmail(),
            },
          ],
        },
        { closeDuration: "never" },
      );
      return;
    }

    const deckId = this.deck.data.id;
    this.dependencies.toggleFollowing({
      following,
      deckId,
    });
  }

  @action.bound
  openModal(modalTypes: ModalType): void {
    this.modalStore.openModal(modalTypes);
  }

  @action.bound
  updateFollowCounts(newCounts: number, following: boolean): void {
    Object.assign(this.deck, {
      followersCount: newCounts,
      following: {
        following,
        count: newCounts,
      },
    });
  }

  @action.bound
  updateDeck(deck: DeckCuratingResponseDto): void {
    const deckMeta = this.deck.meta;
    Object.assign(this.deck, { data: deck, meta: deckMeta });
  }

  @action.bound updateOpenedDeck(opened: boolean): void {
    this.isOpen = opened;
  }

  @action.bound updateCardsCount(cardsCount: number): void {
    this.deck.data.cardsCount = cardsCount;
  }

  @action copyCardLinkToClipboard = async (
    urlToCopy: string,
  ): Promise<void> => {
    copyLinkToClipboard(urlToCopy);
    await wait(500);
    this.toggleShareActive();
  };

  @action.bound onSaveDeck(): void {
    if (!this.isLoggedInUser) {
      if (
        this.featureFlaggingData.flags.enableLoginWithQRCode &&
        this.dependencies.onOpenLoginModal
      ) {
        this.dependencies.onOpenLoginModal();
      } else {
        this.modalStore.openModal("loginForm");
      }
      return;
    }

    this.modalStore.openLazyModal({
      name: "cardCreate",
      component: (
        <DynamicLazyModalLoader
          loadComponent={() =>
            import("../components/ModalCreateCard/ModalCreateCard")
          }
          driver={{
            deckUrl: `${host}/${this.deck.data.path.join("/")}`,
          }}
        />
      ),
    });
  }

  @action.bound updateSourceDeck(): void {
    this.autoplayDeckStore.updateSourceDeck(this);
  }

  @action.bound submitAutoplay(
    targetDeckId: string,
    numberOfCards: number,
    status: AutoplayStatus,
  ): void {
    this.dependencies.submitAutoplay &&
      this.dependencies.submitAutoplay(
        this.deck.data.id,
        targetDeckId,
        numberOfCards,
        status,
      );
  }

  @action.bound updateAutoplayStatus(s: AutoplayStatus): void {
    this.autoplayStatus = s;
  }

  @action.bound autoplayAction(): void {
    if (
      [AutoplayStatus.STARTED, AutoplayStatus.STOPPED].includes(
        this.autoplayStatus,
      )
    ) {
      this.dependencies.cancelAutoplay &&
        this.dependencies.cancelAutoplay(
          this.deck.data.id,
          this.autoplayTargetDeckId,
        );
    } else {
      this.modalStore.openModal("autoPlayDeck");
    }
  }

  @action.bound updateAutoplayTargetDeckId(s: string): void {
    this.autoplayTargetDeckId = s;
  }

  @action.bound cancelAutoplay(targetDeckId: string): void {
    this.dependencies.cancelAutoplay &&
      this.dependencies.cancelAutoplay(this.deck.data.id, targetDeckId);
  }

  @action.bound updateShortcut(b: boolean): void {
    this.isDeckShortcut = b;
  }

  @action.bound toggleDeckShortcut(): void {
    this.dependencies.saveDeckShortcut &&
      this.dependencies.saveDeckShortcut(
        this.deck.data.id,
        !this.isDeckShortcut,
      );
  }

  @computed get backgroundImageUrl(): string | null {
    if (
      this.deck.data.backgroundImage.type ===
      DeckBackgroundImageType.PREVIEW_IMAGES
    ) {
      return null;
    }

    const bgImageData = this.deck.data.backgroundImage.bgImage;
    return getResizeImageUrl(
      { ...bgImageData, url: bgImageData.url || "" },
      {
        width: BACKGROUND_IMAGE_PREVIEW_WIDTH,
        height: BACKGROUND_IMAGE_PREVIEW_HEIGHT,
      },
    );
  }

  @computed.struct
  private get localModalEditDeckDependencies(): ModalEditDeckPresenterDependencies {
    return {
      onDeckUpdated: this.updateDeck,
    };
  }

  @computed
  get modalEditDeckDependencies(): ModalEditDeckPresenterDependencies {
    return this.localModalEditDeckDependencies;
  }

  @computed get shouldRenderSystemDeckButton(): boolean {
    if (!this.featureFlaggingData.flags.enableSetSystemDeck) {
      return false;
    }

    return !!this.userSessionStore.user?.administrator;
  }

  modalSystemDeckDriver: ModalSystemDeckPresenter;

  @action.bound onSystemDeckButtonClicked(): void {
    this.modalStore.openLazyModal({
      name: "systemDeck",
      component: (
        // eslint-disable-next-line react/react-in-jsx-scope
        <DynamicLazyModalLoader
          loadComponent={() =>
            import("../components/ModalSystemDeck/ModalSystemDeck").then(
              (component) => component.ModalSystemDeck,
            )
          }
          driver={this.modalSystemDeckDriver}
        />
      ),
    });
  }

  @observable systemDeck: MobileSystemDeckResponseDto | null = null;
  @action.bound updateSystemDeck(
    systemDeck: MobileSystemDeckResponseDto,
  ): void {
    this.systemDeck = systemDeck;
  }
}
