import { CategoryPrincipleType } from "../../../__generated__/ts-gql/@schema";
import { EDITOR_VANITY_NAME } from "../constants";
import { ContentData, RepostContentData } from "../models/Card";

function isDeckOfCategoryPrinciple(
  deckName: CategoryPrincipleType | string,
): deckName is CategoryPrincipleType {
  return ["people", "creation", "world", "nature", "keys", "spirit"].includes(
    deckName.toLowerCase(),
  );
}

export function isRepostCardByEditorInDeckCategory(
  content: ContentData,
  vanityName: string,
  deckName: string,
): content is RepostContentData {
  return (
    (content as RepostContentData)?.original !== undefined &&
    vanityName === EDITOR_VANITY_NAME &&
    isDeckOfCategoryPrinciple(deckName)
  );
}
