import styled from "styled-components";

import {
  mediumScreenUpperLimit,
  smallScreenUpperLimit,
} from "../../../shared/breakpoints";
import { etNevadaGrey, etWhite } from "../../../shared/colors";

export namespace sc {
  export const Section = styled.section`
    display: flex;
    justify-content: center;

    background-color: ${etWhite};
    height: 752.87px;
    border-radius: 32px 32px 0 0;
    padding-top: 90px;

    @media (max-width: ${mediumScreenUpperLimit}) {
      padding: 48px 0;
      height: 700px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: 850px;
    }
  `;
  export const SectionContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 643px;
    height: 425px;
    margin-left: 48px;

    @media (max-width: ${smallScreenUpperLimit}) {
      height: fit-content;
      margin-left: 0;
      padding: 24px;
    }
  `;
  export const Title = styled.h2`
    font-family: Roboto Slab;
    font-size: 50px;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: -0.5px;
    text-align: left;

    @media (max-width: ${smallScreenUpperLimit}) {
      font-size: 35px;
      line-height: 40px;
    }
  `;
  export const Subtitle = styled.p`
    font-family: Roboto Condensed;
    font-size: 30px;
    font-weight: 400;
    line-height: 50px;
    letter-spacing: -0.5px;
    text-align: left;

    @media (max-width: ${smallScreenUpperLimit}) {
      font-size: 20px;
      line-height: 25px;
    }
  `;
  export const Description = styled.p`
    font-family: Roboto;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    letter-spacing: -0.5px;
    text-align: left;
    color: ${etNevadaGrey};

    @media (max-width: ${smallScreenUpperLimit}) {
      font-size: 16px;
      line-height: 20px;
      text-align: justify;
    }
  `;
  export const Globe = styled.div`
    max-width: 425px;
    min-width: 425px;
    max-height: 425px;
    min-height: 425px;

    @media (max-width: ${smallScreenUpperLimit}) {
      max-width: 300px;
      min-width: 300px;
      max-height: 300px;
      min-height: 300px;
    }
  `;
}
