import { observer } from "mobx-react-lite";

import { useTranslation } from "../../../shared/translate/NextI18next";
import { Currency } from "../../../stores/DonateStore/DonateStore";
import { sc } from "./SectionFive.styled";
interface LandingPageSectionFiveDriver {
  currency: Currency;
}
interface IProps {
  driver: LandingPageSectionFiveDriver;
}
export const SectionFive = observer((props: IProps) => {
  const { t } = useTranslation("LandingPage");

  return (
    <sc.Section className="visual-reg">
      <sc.SectionContent>
        <sc.Title> {t("landing-page.section-five.title")}</sc.Title>
        <sc.Subtitle>
          {props.driver.currency === Currency.EUR
            ? t("landing-page.section-five.sub-title-EUR")
            : t("landing-page.section-five.sub-title-USD")}
        </sc.Subtitle>
      </sc.SectionContent>
      <sc.PaymentBlock>
        <sc.Payment
          src={require("./assets/payment.png")}
          alt="payment details"
        />
      </sc.PaymentBlock>
    </sc.Section>
  );
});
