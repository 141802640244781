import styled from "styled-components";

import {
  BREAK_POINT_ACTIVE_DARK_MODE,
  largeScreenUpperLimit,
  mediumScreenUpperLimit,
  mobileMaxHeight,
  smallScreenUpperLimit,
  xLargeScreenLowerLimit,
} from "../../shared/breakpoints";
import {
  etBluePattens,
  etBrightLightBlue,
  etGray,
  etGrayDark,
  etGrayLight,
  etRegentGray,
  etScarletRed,
  etWhite,
} from "../../shared/colors";
import transparentize from "../../shared/helpers/transparentize";
import { IconSvgContainer } from "../IconSvg/IconSvg.styled";

export const PROFILE_AVATAR_WIDTH_DEFAULT: number = 40;
export const PROFILE_AVATAR_HEIGHT_DEFAULT: number = 40;

namespace sc {
  export const NavbarLinkItemProtectContainer = styled.div`
    display: none;
    @media (max-width: ${mediumScreenUpperLimit}) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: calc((328px - 76px) / 2);
      padding-left: 8px;
      margin-top: 16px;
    }
  `;

  export const ItemLogoProtect = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${etGray};
    height: 1.875rem;
    width: 1.875rem;
    line-height: 1.625rem;
    text-align: center;
    font-size: 0.875rem;
    margin-right: 8px;
  `;

  export const ItemProtectContainer = styled.a`
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    font-weight: bold;
    color: ${etGray};
    cursor: pointer;
    margin-right: 0;
    &:not([href]) {
      color: ${etGray};
    }
    :hover {
      text-decoration: none;
      color: ${etGrayDark};
      > :first-child {
        background-color: ${etGrayDark};
        border-color: ${etGrayDark};
        color: ${etWhite};
      }
      ${IconSvgContainer} {
        fill: ${etWhite};
      }
    }
  `;

  export const CreateCardButton = styled.div`
    padding: 0.5rem;
    font-weight: bold;
    margin: 0;
  `;
  export const SignInContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  `;
  export const SignUpButton = styled.button`
    white-space: nowrap;
    width: 110px;
    height: 40px;
    color: ${etWhite};
    background-color: ${etBrightLightBlue};
    border-radius: 1.5rem;
    font-family: "Roboto Condensed";
    font-weight: 500;
    line-height: 2.12rem;
    padding: 0 1rem;
    margin-right: 10px;
    border: 1.5px solid;
    border-color: transparent;
    :hover {
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    }
    @media (max-width: ${smallScreenUpperLimit}) {
      padding: 0;
      margin-right: 0;
      min-width: 80px;
      max-width: 80px;
    }
  `;

  export const LoginButtonContainer = styled.div`
    > div > button {
      @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
        background-color: ${(props) =>
          props.theme.themeStyle.defaultBackground};
        color: ${(props) => props.theme.themeStyle.defaultSmallTextColor};
        border-color: ${(props) =>
          props.theme.themeStyle.defaultButtonBorderColor};
      }
    }
  `;

  export const UserControllersContainerNav = styled.div`
    @media (max-width: ${largeScreenUpperLimit}) {
      display: none;
    }
  `;

  export const UserControllersContainerMenu = styled.div`
    padding-left: 8px;
    margin-top: 16px;
    @media (min-width: ${xLargeScreenLowerLimit}) {
      display: none;
    }
  `;

  export const UserControllers = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
  `;
  export const ItemLogoCreateCard = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${etGray};
    border-radius: 50%;
    height: 32px;
    width: 32px;
    margin-right: 0.5rem;
    &.inactive {
      border: 1px solid ${etGrayLight};
      > ${IconSvgContainer} {
        color: ${etGrayLight};
      }
    }
  `;
  export const Item = styled.li`
    position: relative;
    display: flex;
    align-items: center;
    font-weight: bold;
    color: ${etGray};
    margin-right: 1.875rem;
    cursor: pointer;
    @media (max-width: ${mediumScreenUpperLimit}) {
      margin-right: 8px;
    }
    &.active {
      :hover {
        color: ${etGrayDark};
        > ${ItemLogoCreateCard} {
          background-color: ${etGrayDark};
          border-color: ${etGrayDark};
          color: ${etWhite};
          > ${IconSvgContainer} {
            fill: ${etWhite};
          }
        }
      }
    }
    &.inactive {
      color: ${etGrayLight};
      cursor: default;
    }
  `;
  export const BellContainer = styled.li<{
    showNotifications: boolean;
    mLandscape?: boolean;
  }>`
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 20px;
    @media (max-width: ${mediumScreenUpperLimit}) {
      margin-right: 0;
    }
    > div > svg {
      @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
        fill: ${(props) =>
          props.showNotifications
            ? props.theme.themeStyle.searchIconActiveColor
            : props.theme.themeStyle.searchIconColor};
      }
      // to detect mobile landscape
      @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
        fill: ${(props) =>
          props.showNotifications ? etBrightLightBlue : etWhite};
      }
    }
  `;
  export const BlueDot = styled.div`
    position: absolute;
    bottom: 12px;
    left: 11px;
    width: 6px;
    height: 6px;
    background-color: ${etBrightLightBlue};
    border: 1px solid ${etWhite};
    border-radius: 50%;
  `;
  export const UserImg = styled.div<{ src: string }>`
    height: ${PROFILE_AVATAR_HEIGHT_DEFAULT}px;
    width: ${PROFILE_AVATAR_WIDTH_DEFAULT}px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-image: url(${(props) => props.src});
    cursor: pointer;
  `;
  export const Hamburger = styled.div`
    display: flex;
    align-items: center;
    user-select: none;
    margin-left: 8px;
    height: 16px;
    width: 24px;
    border: solid ${etGray};
    border-width: 2px 0;
    cursor: pointer;
    > div {
      width: 100%;
      height: 0;
      border-top: 2px solid ${etGray};
    }
    :hover,
    &.active {
      border-color: ${etGrayDark};
      > div {
        border-top: 2px solid ${etGrayDark};
      }
    }
  `;
  export const UserProfile = styled.div<{ position: "relative" | "unset" }>`
    display: flex;
    align-items: center;
    position: ${(props) => props.position};
    @media (max-width: ${mediumScreenUpperLimit}) {
      display: none;
    }
  `;
  export const UserMenuContainer = styled.div``;
  export const UserMenuOverLay = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  `;
  export const UserMenu = styled.div<{ emailAddressVerified: boolean }>`
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 48px;
    right: 0;

    padding: 6px;
    width: 200px;

    border-radius: 4px;
    background-color: ${etWhite};
    box-shadow: 0px 16px 18px 0px rgba(42, 54, 66, 0.2);
  `;

  export const UserName = styled.a`
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.875rem;
    font-weight: bold;
    padding: 1rem 0.5rem 0 0.5rem;
    color: ${etGrayDark};
    :hover {
      color: ${etGrayDark};
    }
    :not([href]) {
      text-decoration: none;
    }
  `;
  export const UserEmail = styled.div`
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.75rem;
    color: ${etGray};
    padding: 0 0.5rem 0.5rem 0.5rem;
  `;
  export const UserMenuItem = styled.a`
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;
    padding: 12px;
    border-radius: 4px;
    height: 40px;
    width: 188px;
    color: ${etRegentGray};
    :hover,
    &.hover {
      color: ${etBrightLightBlue};
      text-decoration: none;
      background-color: ${etBluePattens};
    }
  `;

  export const UserMenuItemLogout = styled(UserMenuItem)`
    color: ${etScarletRed};
    :hover,
    &.hover {
      color: ${etScarletRed};
      background-color: ${transparentize(etScarletRed, 0.1)};
    }
  `;

  export const UserMenuItemCreateCard = styled(UserMenuItem)`
    color: ${etBrightLightBlue};
    :hover,
    &.hover {
      color: ${etBrightLightBlue};
      background-color: ${etBluePattens};
    }
  `;

  export const DraftCount = styled.div`
    margin-left: auto;
  `;
  export const HR = styled.div`
    min-height: 0.0625rem;
    width: 100%;
    background-color: ${etGrayLight};
    margin: 6px 0;
  `;

  export const NavbarRightButtons = styled.div<{ didInitLoginStatus: boolean }>`
    visibility: ${(props) => (props.didInitLoginStatus ? "visible" : "hidden")};
    display: flex;
  `;
}

export default sc;
