/* eslint-disable no-param-reassign */
import { AxiosResponse } from "axios";

import { ICardSystem } from "../../stores/CardSystemStore";
import { TokenInterceptorStore } from "../../stores/TokenInterceptorStore";
import { getAPIBaseUrl } from "../env";
import { isBrowser } from "../helpers/isBrowser";
import { Card } from "../models/Card";
import { UonCard } from "../models/UonCard";
import { User } from "../models/User";
import { UserSessionApi } from "./UserSessionApi";

export interface IUonCardDetailApi {
  fetchCardDetail(vanityName: string, transactionId: string): Promise<UonCard>;
  fetchCardReposts(transactionId: string): Promise<Card[]>;
  fetchCardRelated(
    transactionId: string,
  ): Promise<{ cards: Card[]; singleDeck: boolean }>;
  fetchCardDonatorBrand(transactionId: string): Promise<ICardSystem>;
  fetchCardProfileNPO(uonCardId: string): Promise<User>;
}

export class UonCardDetailApi implements IUonCardDetailApi {
  constructor(
    private tokenInterceptorStore: TokenInterceptorStore,
    private userSessionApi: UserSessionApi,
  ) {}

  fetchCardDetail = async (
    vanityName: string,
    transactionId: string,
  ): Promise<UonCard> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/users/${vanityName}/cards/uon-${transactionId}?metaTags=${!isBrowser()}`,
    });

    return res.data;
  };

  fetchCardReposts = async (transactionId: string): Promise<Card[]> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/cards/uon-${transactionId}/reposts?count=52`,
    });

    return res.data.cards;
  };

  fetchCardRelated = async (
    transactionId: string,
  ): Promise<{ cards: Card[]; singleDeck: boolean }> => {
    const res: AxiosResponse = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/cards/uon-${transactionId}/related?count=52`,
    });

    return res.data;
  };

  // for collector User only
  fetchCardDonatorBrand = async (
    transactionId: string,
  ): Promise<ICardSystem> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/users/donatorBrand/uon-${transactionId}`,
    });

    return res.data;
  };

  fetchCardProfileNPO = async (uonCardId: string): Promise<User> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/uons/cards/${uonCardId}/npo`,
    });

    return res.data;
  };
}
