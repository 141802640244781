import { observer } from "mobx-react-lite";
import React from "react";
import Modal from "react-modal";

import zIndex from "../../shared/zIndex";
import { ModalType } from "../../stores/ModalStore";
import { useRootStore } from "../../stores/rootStore";
import sc from "./ModalWrapper.styled";

type IProps = {
  modalTypes: ModalType;
  children: React.ReactNode;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  onClose?: () => void;
};

export const ModalWrapper = observer((props: IProps) => {
  const store = useRootStore().modalStore;

  const {
    modalTypes,
    top = "auto",
    bottom = "auto",
    left = "auto",
    right = "auto",
  } = props;

  const isActive = store.activeModals.includes(modalTypes);

  return (
    <Modal
      ariaHideApp={false}
      bodyOpenClassName="bodyOpenModal"
      isOpen={isActive}
      closeTimeoutMS={500}
      style={{
        overlay: {
          position: "fixed",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          backgroundColor: "transparent",
          height: "100%",
          minHeight: "100vh",
          overflow: "hidden overlay",
          zIndex: zIndex.MODAL,
        },
        content: {
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          position: "relative",
          width: "100%",
          height: "100%",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 0,
          border: "none",
          borderRadius: 0,
          background: "none",
          overflow: "hidden overlay",
        },
      }}
    >
      <sc.ModalOverlay
        data-cy="ModalWrapper-Overlay"
        opacity={isActive ? 0.5 : 0}
      />
      <sc.ModalBody
        top={top}
        bottom={bottom}
        left={left}
        right={right}
        className={isActive ? "active" : "inactive"}
      >
        <>
          {props.children}
          <sc.Outer
            className="ModalWrapper-Outer"
            onClick={() => {
              props.onClose && props.onClose();
              store.openModal("");
            }}
          />
        </>
      </sc.ModalBody>
    </Modal>
  );
});

export const LazyModalWrapper = observer(function LazyModalWrapper(props: {
  children: React.ReactNode;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  disableClickOutSide?: boolean;
  fadeAnimaion?: boolean;
  onClose?: () => void;
}) {
  const store = useRootStore().modalStore;

  const {
    top = "auto",
    bottom = "auto",
    left = "auto",
    right = "auto",
  } = props;

  const isActive = true;
  const fadeAnimaion =
    props.fadeAnimaion === undefined ? true : props.fadeAnimaion;
  const bodyClassName = fadeAnimaion ? (isActive ? "active" : "inactive") : "";

  return (
    <Modal
      ariaHideApp={false}
      bodyOpenClassName="bodyOpenModal"
      isOpen={isActive}
      closeTimeoutMS={500}
      style={{
        overlay: {
          position: "fixed",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          backgroundColor: "transparent",
          width: "100%",
          height: "100%",
          minHeight: "100vh",
          overflow: "hidden overlay",
          zIndex: zIndex.MODAL,
        },
        content: {
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          position: "relative",
          width: "100%",
          height: "100%",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 0,
          border: "none",
          borderRadius: 0,
          background: "none",
          overflow: "hidden overlay",
        },
      }}
    >
      <sc.ModalOverlay opacity={isActive ? 0.5 : 0} />
      <sc.ModalBody
        top={top}
        bottom={bottom}
        left={left}
        right={right}
        className={bodyClassName}
      >
        <>
          {props.children}
          {!props.disableClickOutSide && (
            <sc.Outer
              onClick={() => {
                props.onClose && props.onClose();
                store.openLazyModal();
              }}
            />
          )}
        </>
      </sc.ModalBody>
    </Modal>
  );
});
