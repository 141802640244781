import styled from "styled-components";

import {
  mediumScreenUpperLimit,
  smallScreenUpperLimit,
} from "../../../shared/breakpoints";
import {
  etBlack,
  etBrightLightBlue,
  etIceBlue,
  etNevadaGrey,
  etWhite,
} from "../../../shared/colors";
import transparentize from "../../../shared/helpers/transparentize";

export namespace sc {
  const PTag = styled.p`
    margin: 0;
    padding: 0;
    text-align: center;
  `;
  export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100%;
    height: max-content;
    margin-top: 104px;
    font-family: Roboto Condensed;
    background-color: ${etWhite};
    overflow: hidden;
  `;

  export const ImageWrapper = styled.div`
    position: relative;
    width: 100vw;
    max-width: 100vw;
    height: 350px;
    max-height: 350px;
    overflow: hidden;
  `;
  export const Image = styled.img`
    position: absolute;
    top: 0;
    left: calc(50% + 35px);
    transform: translateX(-50%);
  `;

  export const CounterContainer = styled.div``;
  export const CounterWrapper = styled.div`
    margin-top: 13px;
    margin-left: 3px;
    display: flex;
    justify-content: center;

    @media (max-width: ${mediumScreenUpperLimit}) {
      margin-top: 17px;
    }
  `;

  export const CounterMeterLogo = styled.img`
    min-height: 57px;
    max-height: 57px;
    min-width: 57px;
    max-width: 57px;
    margin-left: -4px;

    z-index: 1;

    @media (max-width: ${mediumScreenUpperLimit}) {
      min-height: 26.74px;
      max-height: 26.74px;
      min-width: 26.74px;
      max-width: 26.74px;
      margin-left: -2px;
    }
  `;

  export const CounterDescription = styled(PTag)`
    font-family: Roboto Condensed;
    width: 100vw;
    font-size: 16px;
    line-height: 30px;
    text-transform: uppercase;
    text-align: center;
    margin-top: 5px;
  `;

  export const DownloadAppContainer = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  `;
  export const DownloadAppText = styled.div`
    text-align: center;
    margin-bottom: 20px;
    font-family: "Roboto Slab";
  `;
  export const BigText = styled(PTag)`
    font-weight: 700;
    font-size: 70px;
    line-height: 70px;
  `;
  export const SmallText = styled(PTag)`
    font-weight: 300;
    font-size: 40px;
    line-height: 30px;
    @media (max-width: ${smallScreenUpperLimit}) {
      margin-top: 5px;
      line-height: 40px;
    }
  `;
  export const DowloadOptionsContainer = styled.div`
    width: 350px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 130px;
  `;

  export const LearnMoreButtonContainer = styled.div`
    position: relative;
    width: 100%;
  `;
  export const LearnMore = styled(PTag)`
    width: 100%;
    text-align: center;
    margin: 50px 0;
  `;
  export const ArrowButton = styled.button`
    width: 142px;
    height: 142px;
    border-radius: 50%;
    position: absolute;
    bottom: -55px;
    left: 50%;
    transform: translateX(-50%);
    border: none;
    background-color: ${etWhite};
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
  `;

  export const LearnMoreContainer = styled.div`
    position: relative;
    width: 100%;
  `;
  export const IconWrapper = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${etBrightLightBlue};
    border-radius: 50%;
    transform: rotate(180deg);
    cursor: pointer;
  `;
  export const LearnMoreYouOnEarth = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 870px;
    width: 100%;
    overflow: hidden;
  `;

  export const MovieContainer = styled.div`
    position: absolute;
    width: 3480px;
    height: 1518px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
  `;
  export const MovieWrapper = styled.div`
    height: 100%;
    width: 100%;
  `;

  export const GridContainer = styled(MovieContainer)`
    z-index: 1;
  `;
  export const UonGrid = styled.div`
    opacity: 0.5;
    height: 100%;
    width: 100%;
  `;
  export const VideoOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${transparentize(etBlack, 0.3)};
    z-index: 2;
  `;
  export const YouOnEarth = styled(PTag)`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 100px;
    z-index: 3;
    font-family: "Roboto Slab";
    color: ${etIceBlue};
    font-size: 50px;
    line-height: 50px;
    width: 660px;
    text-align: center;
    margin-top: 140px;

    @media (max-width: ${smallScreenUpperLimit}) {
      width: 370px;
      margin-top: 50px;
    }
  `;
  export const ForTheFirstTime = styled(PTag)`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 400px;
    z-index: 3;
    font-family: Roboto;
    color: ${etIceBlue};
    font-size: 20px;
    width: 500px;
    text-align: center;
    margin: 40px 0;
    > span {
      color: ${etWhite};
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      width: 350px;
    }
  `;
  export const Span = styled.span`
    font-weight: bold;
  `;
  export const LearnMoreWatch = styled.a`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 550px;
    z-index: 3;
    min-width: 252px;
    height: 40px;
    border-radius: 20px;
    background-color: ${etBrightLightBlue};
    color: ${etWhite};
    text-align: center;
    font-size: 20px;
    line-height: 40px;
    padding: 0 20px;
    text-transform: uppercase;
    cursor: pointer;

    :hover {
      text-decoration: none;
      color: ${etWhite};
    }
  `;
  export const LearnMoreProtect = styled.div`
    position: relative;
    width: 100%;
    height: 900px;
  `;
  export const LearnMoreProtectContent = styled.div`
    position: absolute;
    width: 100%;
    height: 900px;
    top: -20px;
    left: 0;
    border-radius: 20px 20px 0 0;
    background-color: ${etIceBlue};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 4;
  `;
  export const UonImage = styled.img`
    margin-top: 60px;
    width: 328px;
    height: 328px;

    @media (max-width: ${smallScreenUpperLimit}) {
      width: 250px;
      height: 250px;
    }
  `;
  export const ProtectTitle = styled(PTag)`
    font-family: "Roboto Slab";
    font-size: 50px;
    line-height: 55px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 20px;
  `;
  export const ProtectSubtitle = styled(PTag)`
    font-family: Roboto;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    width: 620px;
    color: ${etNevadaGrey};
    margin-bottom: 50px;
    @media (max-width: ${smallScreenUpperLimit}) {
      width: 330px;
    }
  `;
}
