import { ReserveImageUrls } from "../models/Uon";

export const getImageUonUrl = (
  reserveImageUrls: ReserveImageUrls | string,
  isWebpSupported: boolean,
): string => {
  if (typeof reserveImageUrls === "string") {
    return reserveImageUrls;
  }
  if (isWebpSupported) {
    return reserveImageUrls.webp;
  }
  return reserveImageUrls.jpg;
};
