import React, { useEffect } from "react";
import { Element } from "react-scroll";

import { useTranslation } from "../../shared/translate/NextI18next";
import { SectionInfo } from "../../stores/PoliciesPresenter";
import { DangerouslyHTMLRenderer } from "../DangerouslyHTMLRenderer/DangerouslyHTMLRenderer";
import sc from "./PoliciesItem.styled";

interface IProps {
  onRegister(section: SectionInfo): void;
}

export const PoliciesItemDisclaimer = (props: IProps) => {
  const { t } = useTranslation("Policies");

  useEffect(() => {
    props.onRegister({
      key: "disclaimer",
      label: t("policy.disclaimer.label"),
    });
  }, [props]);

  return (
    <Element name="disclaimer">
      <sc.TextHeader>{t("policy.disclaimer.label")}</sc.TextHeader>
      <sc.TextModified>
        <DangerouslyHTMLRenderer>
          {t("policy.general.text-modified")}
        </DangerouslyHTMLRenderer>
      </sc.TextModified>

      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.disclaimer.definitions.text-header")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.disclaimer.definitions.text-content")}
        </DangerouslyHTMLRenderer>
        <sc.BlockMB6>
          <DangerouslyHTMLRenderer>
            {t("policy.disclaimer.definitions.block-mb6-a")}
          </DangerouslyHTMLRenderer>
        </sc.BlockMB6>
        <sc.BlockMB6>
          <DangerouslyHTMLRenderer>
            {t("policy.disclaimer.definitions.block-mb6-b")}
          </DangerouslyHTMLRenderer>
        </sc.BlockMB6>
        <sc.BlockMB6>
          <DangerouslyHTMLRenderer>
            {t("policy.disclaimer.definitions.block-mb6-c")}
          </DangerouslyHTMLRenderer>
        </sc.BlockMB6>
        <sc.BlockMB6>
          <DangerouslyHTMLRenderer>
            {t("policy.disclaimer.definitions.block-mb6-d")}
          </DangerouslyHTMLRenderer>
        </sc.BlockMB6>
        <sc.BlockMB6>
          <DangerouslyHTMLRenderer>
            {t("policy.disclaimer.definitions.block-mb6-e")}
          </DangerouslyHTMLRenderer>
        </sc.BlockMB6>
        <sc.BlockMB6>
          <DangerouslyHTMLRenderer>
            {t("policy.disclaimer.definitions.block-mb6-f")}
          </DangerouslyHTMLRenderer>
        </sc.BlockMB6>
      </sc.TextContent>

      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.disclaimer.intellectual-property.text-header")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.disclaimer.intellectual-property.text-content-1")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.disclaimer.intellectual-property.text-content-2")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.disclaimer.content-and-liability.text-header")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.disclaimer.content-and-liability.text-content-1")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.disclaimer.content-and-liability.text-content-2")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.disclaimer.content-and-liability.text-content-3")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.disclaimer.content-and-liability.text-content-4")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.disclaimer.website-and-liability.text-header")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.disclaimer.website-and-liability.text-content-1")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.disclaimer.website-and-liability.text-content-2")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
    </Element>
  );
};
