import { action, computed, flow, makeObservable, observable } from "mobx";
import Router from "next/router";
import { toFlowGeneratorFunction } from "to-flow-generator-function";

import { HomeApi } from "../../shared/apis/HomeApi";
import { UnsubscribeFn } from "../../shared/apis/UnsubscribeFn";
import { deepLinkHost } from "../../shared/env";
import { translateAPIError } from "../../shared/helpers/translateApiError";
import { DonateStore } from "../../stores/DonateStore/DonateStore";
import FeatureFlaggingStore from "../../stores/FeatureFlaggingStore";
import { MobileMenuStore } from "../../stores/MobileMenuStore/MobileMenuStore";
import { PoliciesPresenter } from "../../stores/PoliciesPresenter";
import { UserSessionStore } from "../ModalLogin/UserSessionStore";
import { TheMessageStore } from "../TheMessage/TheMessageStore";
import { TheNavbarStore } from "../TheNavbarTop/TheNavbarStore/TheNavbarStore";
import { MobileLandingPageDriver } from "./MobileLandingPage";

export class MobileLandingPagePresenter implements MobileLandingPageDriver {
  constructor(
    private homeApi: HomeApi,
    private theMessageStore: TheMessageStore,
    private featureFlaggingStore: FeatureFlaggingStore,
    private donateStore: DonateStore,
    private mobileMenuStore: MobileMenuStore,
    private userSessionStore: UserSessionStore,
    private theNavbarStore: TheNavbarStore,
  ) {
    makeObservable(this);
  }
  @observable protectedCount: number | null = null;

  @action.bound fetchProtectedCount = flow(function* (
    this: MobileLandingPagePresenter,
  ) {
    try {
      const result = yield* toFlowGeneratorFunction(
        this.homeApi.fetchProtectedCount,
      )();
      this.protectedCount = result;
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    }
  });

  static INCLUDED_PATHS = [
    "/",
    "/[vanityName]",
    "/[vanityName]/[deckName]",
    "/[vanityName]/[deckName]/[cardId]",
    "/earthtoday/what-is-new",
    "/policies/[param]",
    "/following",
    "/m2/[transactionId]",
    "/play",
    "/protect",
    "/system", // redirect from protect and naturereserves path
    "/settings",
    "/welcome",
  ];
  @computed get shouldDisplayMobileLandingPage() {
    if (
      !this.featureFlaggingStore.flags.enableLandingPageMobileWeb ||
      this.domain === new URL(deepLinkHost).host // this.isMobileDevice && this make state lag
    )
      return false;

    return MobileLandingPagePresenter.INCLUDED_PATHS.includes(this.pathName);
  }
  @observable domain: string = "";
  @observable pathName: string = "";

  @action.bound setPageInfo(domain: string, pathName: string) {
    this.domain = domain;
    this.pathName = pathName;
  }
  @computed get flags() {
    return this.featureFlaggingStore.flags;
  }
  get currentYear() {
    return new Date().getFullYear();
  }
  unsubscribeFns: UnsubscribeFn[] = [];
  @action.bound subscribe = (): void => {
    this.unsubscribeFns.push(
      this.homeApi.subscribeUonCount((error, count) => {
        if (error) {
          this.theMessageStore.showMessage({
            typeMessage: "Error",
            title: "toast-message.general.error",
            content: translateAPIError(error),
          });
          return;
        }

        this.protectedCount = count;
      }),
    );
  };

  @action.bound unsubscribe = (): void => {
    for (const unsubscribe of this.unsubscribeFns) unsubscribe();
  };
  @action.bound onMount = async () => {
    await this.fetchProtectedCount();
    this.subscribe();
  };
  @action.bound onUnmount = (): void => {
    this.unsubscribe();
  };
  @action.bound onVisitButtonClicked(): void {
    Router.push(deepLinkHost);
  }

  @observable isPolicesOverlayVisible = false;
  policiesDriver = new PoliciesPresenter(
    this.donateStore,
    this.mobileMenuStore,
    this.userSessionStore,
    this.theMessageStore,
    this.theNavbarStore,
  );

  @action.bound onDisclaimerClicked() {
    this.isPolicesOverlayVisible = true;
    this.policiesDriver.currentSectionUpdate("disclaimer");
  }
  @action.bound onPrivacyPolicyClicked() {
    this.isPolicesOverlayVisible = true;
    this.policiesDriver.currentSectionUpdate("privacy");
  }
  @action.bound onCookiePolicyClicked() {
    this.isPolicesOverlayVisible = true;
    this.policiesDriver.currentSectionUpdate("cookie-policy");
  }

  @action.bound onClosePolicyButtonClicked() {
    this.isPolicesOverlayVisible = false;
  }
}
