import styled from "styled-components";

import {
  largeScreenUpperLimit,
  smallScreenUpperLimit,
} from "../../shared/breakpoints";
import { etGray, etWhite } from "../../shared/colors";

export const CARD_DECK_HEADER_IMAGE_WIDTH = 1032;
export const CARD_DECK_HEADER_IMAGE_HEIGHT = 496;
export const CARD_DECK_HEADER_IMAGE_TABLET_WIDTH = 680;
export const CARD_DECK_HEADER_IMAGE_MOBILE_WIDTH = 328;
export const CARD_PROFILE_REPOST_HEADER_IMAGE_WIDTH = 240;
export const CARD_PROFILE_REPOST_HEADER_IMAGE_HEIGHT = 240;
export const CARD_PROFILE_REPOST_HEADER_IMAGE_TABLET_WIDTH = 160;
export const CARD_PROFILE_REPOST_HEADER_IMAGE_TABLET_HEIGHT = 160;
export const CARD_PROFILE_REPOST_HEADER_IMAGE_MOBILE_WIDTH = 120;
export const CARD_PROFILE_REPOST_HEADER_IMAGE_MOBILE_HEIGHT = 120;

namespace sc {
  export const Container = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    div {
      height: inherit;
      border-radius: 8px;
    }
  `;
  export const Background = styled.div<{ url: string }>`
    background-position: center;
    background-size: cover;
    background-image: url(${(props) => props.url});
  `;

  export const PreviewImageProfileRepost = styled.div<{ url: string }>`
    background: ${etGray};
    border-radius: 8px;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.05),
      0px 2px 3px 0px rgba(0, 0, 0, 0.1);

    border-radius: 8px;
    height: ${CARD_DECK_HEADER_IMAGE_HEIGHT}px;
    max-width: ${CARD_DECK_HEADER_IMAGE_WIDTH}px;
    position: relative;

    ::after {
      content: "";
      height: ${CARD_PROFILE_REPOST_HEADER_IMAGE_HEIGHT}px;
      width: ${CARD_PROFILE_REPOST_HEADER_IMAGE_WIDTH}px;

      position: absolute;
      top: 69px;
      right: 0;

      transform: translateX(-50%);
      background-color: ${etWhite};
      background-image: url(${(props) => props.url});
      background-size: cover;
      background-position: center center;
      border-radius: 50%;
    }

    @media (max-width: ${largeScreenUpperLimit}) {
      max-width: 680px;
      ::after {
        height: ${CARD_PROFILE_REPOST_HEADER_IMAGE_TABLET_HEIGHT}px;
        width: ${CARD_PROFILE_REPOST_HEADER_IMAGE_TABLET_WIDTH}px;
        right: 40px;
      }
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      max-width: 328px;
      ::after {
        height: ${CARD_PROFILE_REPOST_HEADER_IMAGE_MOBILE_WIDTH}px;
        width: ${CARD_PROFILE_REPOST_HEADER_IMAGE_MOBILE_HEIGHT}px;

        left: 50%;
        transform: translateX(-50%);
        right: unset;
      }
    }
  `;
}

export default sc;
