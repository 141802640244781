// From "angular-coordinates" with some modifications:
// https://github.com/mateuszkocz/angular-coordinates

export enum Direction {
  Latitude = 1,
  Longitude,
}

function transformNumberToDegrees(value: number): string {
  const absoluteDegrees = Math.abs(Number(value));
  let fullDegrees = Math.floor(absoluteDegrees);

  const remainingMinutes = (absoluteDegrees - fullDegrees) * 60;
  let minutes = Math.floor(remainingMinutes);

  const remainingSeconds = (remainingMinutes - minutes) * 60;
  let seconds = Math.round(remainingSeconds * 100) / 100;

  if (seconds === 60) {
    minutes += 1;
    seconds = 0;
  }
  if (minutes === 60) {
    fullDegrees += 1;
    minutes = 0;
  }

  return `${fullDegrees}°${minutes}’${seconds}”`;
}

function resolveDirection(
  degrees: number,
  direction: Direction,
): "N" | "S" | "W" | "E" {
  switch (direction) {
    case Direction.Latitude: {
      return degrees < 0 ? "S" : "N";
    }
    case Direction.Longitude: {
      return degrees < 0 ? "W" : "E";
    }
  }
}

export function transformDigitToDegrees(
  value: number | string,
  direction?: Direction,
): string {
  const numberValue = Number(value);

  if (typeof value === "string" && Number.isNaN(numberValue)) {
    return value;
  }

  return (
    transformNumberToDegrees(numberValue) +
    (direction ? ` ${resolveDirection(numberValue, direction)}` : "")
  );
}
