import { AxiosResponse } from "axios";

import { AutoplayStatus } from "../../stores/AutoplayDeckStore";
import { TokenInterceptorStore } from "../../stores/TokenInterceptorStore";
import { getAPIBaseUrl } from "../env";
import { Deck } from "../models/Deck";

type AutoplayDeckPayload = {
  sourceDeckId: string;
  numberOfCards: number;
  status: AutoplayStatus;
};

export type SourceDeckItemResponse = {
  deckId: string;
  deckLinkName: string;
  deckName: string;
  autoplayStatus: AutoplayStatus;
  targetId?: string;
};

export interface IAutoplayDeckApi {
  fetchAutoplayTargetDecks: () => Promise<Deck[]>;
  submitAutoplay: (
    sourceDeckId: string,
    targetDeckId: string,
    numberOfcard: number,
    status: AutoplayStatus,
  ) => Promise<AxiosResponse>;
  getAutoplayItem: (sourceDeckId: string) => Promise<SourceDeckItemResponse[]>;
  cancelAutoplay: (
    sourceDeckId: string,
    targetDeckId: string,
  ) => Promise<AxiosResponse>;
}

export class AutoplayDeckApi implements IAutoplayDeckApi {
  constructor(private tokenInterceptorStore: TokenInterceptorStore) {}

  fetchAutoplayTargetDecks = async (): Promise<Deck[]> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/users/me/decks/curating?autoplayable=true`,
    });

    return res.data.decks;
  };

  submitAutoplay = async (
    sourceDeckId: string,
    targetDeckId: string,
    numberOfCards: number,
    status: AutoplayStatus,
  ): Promise<AxiosResponse> => {
    const data: AutoplayDeckPayload = {
      sourceDeckId,
      numberOfCards,
      status,
    };

    const res = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/decks/${targetDeckId}/autoplay`,
      data,
    });

    return res.data;
  };

  cancelAutoplay = async (
    sourceDeckId: string,
    targetDeckId: string,
  ): Promise<AxiosResponse> => {
    const res = await this.tokenInterceptorStore.call({
      method: "DELETE",
      url: `${getAPIBaseUrl()}/decks/${targetDeckId}/autoplay/${sourceDeckId}`,
    });

    return res.data;
  };

  getAutoplayItem = async (
    sourceDeckId: string,
  ): Promise<SourceDeckItemResponse[]> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/users/me/deck_autoplay_items?sortedDeckIds=${sourceDeckId}`,
    });

    return res.data;
  };
}
