import { format } from "date-fns";
import { observer } from "mobx-react-lite";

import UonGiftedIcon from "../../assets/svgs/UONGiftedIcon.svg";
import { DATE_FORMAT_DD_MM_YYYY } from "../../shared/constants";
import { useTranslation } from "../../shared/translate/NextI18next";
import { HydratedDateWrapper } from "../HydratedDateWrapper/HydratedDateWrapper";
import sc from "./CardItemTransactionStandard.styled";
import { formatUonNumber } from "./helper/formatUonNumber";

export interface CardItemGiftDriver {
  uonReserveSize: number;
  giftExpiredAt: Date | null;
  shouldRenderGiftItem: boolean;
  unwrapGiftCode: () => void;
  isSubmiting: boolean;
  triggerShare: (options?: { fadeAnimaion?: boolean }) => void;
}

interface IProps {
  driver: CardItemGiftDriver;
}

export const CardItemGift = observer(function CardItemGift(props: IProps) {
  const { t } = useTranslation("CardItemGift");

  return (
    <sc.GiftedStateOverlay data-cy="CardTransaction-GiftedStateOverlay">
      <sc.GiftedStateTop>
        <sc.GiftedStateLogo
          data-cy="CardTransaction-GiftedStateLogo"
          src={UonGiftedIcon}
        />
        <sc.GiftedStateAmount data-cy="CardTransaction-GiftedStateAmount">
          {formatUonNumber(props.driver.uonReserveSize)} uon gifted
        </sc.GiftedStateAmount>
        <sc.GiftedStateExpiry data-cy="CardTransaction-GiftedStateExpiry">
          {t("date-of-expiry")}{" "}
          <HydratedDateWrapper>
            {format(props.driver.giftExpiredAt || 0, DATE_FORMAT_DD_MM_YYYY)}
          </HydratedDateWrapper>
        </sc.GiftedStateExpiry>
      </sc.GiftedStateTop>
      <sc.GiftedStateBottom>
        <sc.CancelGiftBtn
          data-cy="CardTransaction-CancelGiftBtn"
          onClick={props.driver.unwrapGiftCode}
          disabled={props.driver.isSubmiting}
        >
          {t("button.cancel-gift")}
        </sc.CancelGiftBtn>
        <sc.ReshareGiftBtn
          data-cy="CardTransaction-ReshareGiftBtn"
          onClick={() => props.driver.triggerShare()}
          disabled={props.driver.isSubmiting}
        >
          {t("button.share-gift")}
        </sc.ReshareGiftBtn>
      </sc.GiftedStateBottom>
    </sc.GiftedStateOverlay>
  );
});
