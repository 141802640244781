import { ImageResponseDto } from "@earthtoday/contract";

import {
  AuthorData,
  CardType,
  ICardFeaturesContent,
  MasterLinkType,
} from "../models/Card";

interface IContent {
  title: string;

  cover: ImageResponseDto | null;
  linkSource: string;
  source: string;
  summary: string;
  name: string;
  authors: AuthorData[];
}

export const getContentData = (
  content: ICardFeaturesContent | null,
): IContent => {
  const data: IContent = {
    title: "",
    cover: null,
    linkSource: "",
    source: "",
    summary: "",
    name: "",
    authors: [],
  };

  if (!content) return data;

  if (
    content.type === CardType.LINK_REPOST &&
    content.original &&
    content.original.content
  ) {
    return getContentData(content.original.content);
  } else if (content.type !== CardType.LINK_REPOST) {
    data.title = content.title || "";
    data.cover = content.image || null;
    data.linkSource = content.url;
    data.source = content?.source ? content?.source.url : "";
    data.summary =
      (content.type === MasterLinkType.MUSIC && content.summary) || "";
    data.name = content?.source ? content?.source.name.toLocaleUpperCase() : "";
    data.authors = content.type === MasterLinkType.MUSIC ? content.authors : [];
  }
  return data;
};
