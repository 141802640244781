/* eslint-disable class-methods-use-this */
import _ from "lodash";
import { action, makeObservable, observable } from "mobx";

import { FlyingMapDriver } from "../../components/FlyingMap/FlyingMap";

export type GeoData = {
  type: "Feature";
  properties: {
    description: string | undefined;
    amount: string | undefined;
    type: string;
  };
  geometry: {
    type: "Point";
    coordinates: number[];
  };
};

export type FlightPathItem = {
  center: [number, number];
  bearing: number;
  flightDuration: number;
  started: number;
};

export class FlyingOverPresenter implements FlyingMapDriver {
  constructor() {
    makeObservable(this);
  }

  flightPath: FlightPathItem[] = [
    {
      center: [-71.72358722083258, -8.241731173300408],
      bearing: 120,
      flightDuration: 18000,
      started: 0,
    },
    {
      center: [-71.72866051005221, -8.2519132107021],
      bearing: 120,
      flightDuration: 26000,
      started: 0,
    },
    {
      center: [-71.720594016716, -8.252182905800893],
      bearing: 120,
      flightDuration: 22000,
      started: 0,
    },
    {
      center: [-71.71859393107792, -8.243275133175729],
      bearing: 120,
      flightDuration: 24000,
      started: 0,
    },
  ];

  @observable initialCamera: FlightPathItem = this.flightPath[0];

  @observable nextCamera: FlightPathItem = this.flightPath[1];

  @action.bound updateCamera(cam: FlightPathItem): void {
    this.nextCamera = cam;
  }

  @observable flyingSection: number = 2; // first section is already setted
  @action.bound checkFly = () => {
    if (
      this.flightPath[(this.flyingSection - 1) % this.flightPath.length]
        .started >
      Date.now() -
        this.flightPath[(this.flyingSection - 1) % this.flightPath.length]
          .flightDuration
    ) {
      console.log("not finished, returning");
      return;
    }
    this.flightPath[this.flyingSection % this.flightPath.length].started =
      Date.now();
    const newFlightPathItem =
      this.flightPath[this.flyingSection % this.flightPath.length];
    this.updateCamera(newFlightPathItem);
    this.flyingSection = this.flyingSection + 1;
  };
}
