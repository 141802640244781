/* eslint-disable unicorn/consistent-function-scoping */
import {
  ImageTarget,
  UserProfilePhotoUploadCommitResponseDto,
} from "@earthtoday/contract";
import {
  action,
  computed,
  flow,
  IObservableArray,
  makeObservable,
  observable,
} from "mobx";
import { SingletonRouter } from "next/router";
import { RefObject } from "react";
import { toFlowGeneratorFunction } from "to-flow-generator-function";

import { ModalCreateGroupContentDriver } from "../../../components/ModalCreateGroup/ModalCreateGroupContent";
import { IUserSessionStore } from "../../../components/ModalLogin/UserSessionStore";
import { ITheMessageStore } from "../../../components/TheMessage/TheMessageStore";
import { IGroupApi } from "../../../shared/apis/GroupApi";
import {
  ALLOWED_MIME_TYPES,
  GROUP_DESC_MAX_SIZE,
  GROUP_IMAGE_MAX_SIZE,
  GROUP_NAME_MAX_LENGTH,
  GROUP_VANITY_NAME_MAX_LENGTH,
} from "../../../shared/constants";
import { excludedVanityNames } from "../../../shared/env";
import {
  validateAlphanumeric,
  ValidationCheck,
} from "../../../shared/helpers/commonValidations";
import { FlowGenerator } from "../../../shared/helpers/FlowGenerator";
import { imageUploadsStorage } from "../../../shared/helpers/imageUploadsStorage";
import { replaceNonAlphanumericChars } from "../../../shared/helpers/replaceNonAlphanumeric";
import {
  getAPIErrorStatusCode,
  isAxiosError,
} from "../../../shared/helpers/translateApiError";
import { IGroup, IUserVanityNameVerify } from "../../../shared/models/Group";
import { ImagePresignUploadStatus } from "../../../shared/models/Image";
import { IModalStore } from "../../ModalStore";
import { ProfilePagePresenter } from "../../ProfilePagePresenter";
import { randomAlphanumeric } from "./VanityNameGenerator/randomAlphanumeric";
import { VanityNameGenerator } from "./VanityNameGenerator/VanityNameGenerator";
export class GroupPresenter implements ModalCreateGroupContentDriver {
  @observable groupName: string = "";
  @observable groupNameTouched: boolean = false;
  @observable groupDescription: string = "";
  @observable groupDescriptionTouched: boolean = false;
  @observable groupVanityName: string = "";
  @observable groupVanityNameTouched: boolean = false;
  @observable groupImage: File | null = null;
  @observable groupImagePath = "";
  @observable isConflictGroupName: boolean = false;
  @observable isConflictGroupVanityName: boolean = false;
  @observable recommendVanityNames: IObservableArray<string> =
    observable<string>([]);
  @observable isSubmiting: boolean = false;
  @observable isSuggestedVanityName: boolean = false;
  @observable isValidatingVanityName: boolean = false;
  @observable isGroupVanityNameExcluded = false;

  constructor(
    public groupApi: IGroupApi,
    private modalStore: IModalStore,
    private theMessageStore: ITheMessageStore,
    private userSessionStore: IUserSessionStore,
    private profileStore: ProfilePagePresenter | null,
    private Router?: SingletonRouter,
  ) {
    makeObservable(this);
  }

  @computed get areFormFieldsDisabled() {
    return this.isSubmiting || this.isValidatingVanityName;
  }

  @computed get groupNameFeedback(): ValidationCheck {
    const groupName = this.groupName.trim();
    if (!this.groupNameTouched) {
      return {
        valid: false,
        msg: "",
      };
    }

    if (groupName.length === 0) {
      return {
        valid: false,
        msg: "groups.name-required",
      };
    }

    if (this.isConflictGroupName) {
      return {
        valid: false,
        msg: "groups.already-in-used",
      };
    }

    return {
      valid: true,
      msg: "",
    };
  }

  @computed get groupVanityNameFeedback(): ValidationCheck {
    const groupVanityName = this.groupVanityName.trim();
    if (!this.groupVanityNameTouched) {
      return {
        valid: false,
        msg: "",
      };
    }

    if (groupVanityName.length === 0) {
      return {
        valid: false,
        msg: "groups.vanity-required",
      };
    }

    if (
      this.groupVanityName.length < 3 ||
      this.groupVanityName.length > GROUP_VANITY_NAME_MAX_LENGTH
    ) {
      return {
        valid: false,
        msg: "groups.vanity-length",
        vars: {
          length: GROUP_VANITY_NAME_MAX_LENGTH,
        },
      };
    }

    if (!validateAlphanumeric(groupVanityName)) {
      return {
        valid: false,
        msg: "groups.alphanumeric",
      };
    }

    if (this.isConflictGroupVanityName) {
      return {
        valid: false,
        msg: "groups.already-in-used",
      };
    }

    if (this.isGroupVanityNameExcluded) {
      return {
        valid: false,
        msg: "groups.can-not-be-used",
      };
    }

    return {
      valid: true,
      msg: "",
    };
  }

  @computed get canSubmit(): boolean {
    if (this.isSubmiting || this.isValidatingVanityName) {
      return false;
    }
    return this.groupNameFeedback.valid && this.groupVanityNameFeedback.valid;
  }

  @action.bound uploadToS3 = flow(function* uploadToS3(
    this: GroupPresenter,
    url: string,
    photo: File,
    uploadToken: string,
  ) {
    try {
      imageUploadsStorage.pushOrUpdate({
        uploadToken,
        status: ImagePresignUploadStatus.uploading,
      });

      yield* toFlowGeneratorFunction(this.groupApi.uploadToS3)(url, photo);

      imageUploadsStorage.pushOrUpdate({
        uploadToken,
        status: ImagePresignUploadStatus.uploaded,
      });
    } catch (error) {
      if (isAxiosError(error)) {
        yield this.groupApi.deletePresignImage(
          uploadToken,
          `Error response with status ${getAPIErrorStatusCode(
            error,
          )} when upload to s3: ${error.response?.data}`,
        );
      }
      imageUploadsStorage.remove(uploadToken);
      throw error;
    }
  });

  @action.bound commitUploadImage = flow(function* commitUploadImage(
    this: GroupPresenter,
    uploadToken: string,
  ): FlowGenerator<UserProfilePhotoUploadCommitResponseDto> {
    imageUploadsStorage.pushOrUpdate({
      uploadToken,
      status: ImagePresignUploadStatus.committing,
    });

    const res = yield* toFlowGeneratorFunction(this.groupApi.commitUploadImage)(
      {
        target: ImageTarget.USER_PROFILE,
        uploadToken,
      },
    );

    imageUploadsStorage.remove(uploadToken);
    return res;
  });

  @action.bound editPhoto = flow(function* editPhoto(this: GroupPresenter) {
    if (!this.groupImage) return;

    const { url, uploadToken } = yield* toFlowGeneratorFunction(
      this.groupApi.presignImage,
    )(this.groupImage);

    yield* toFlowGeneratorFunction(this.uploadToS3)(
      url,
      this.groupImage,
      uploadToken,
    );

    const res = yield* toFlowGeneratorFunction(this.commitUploadImage)(
      uploadToken,
    );

    if (!res || res.target === ImageTarget.UON_PROMOTION) {
      return;
    }
    const image =
      res.target === ImageTarget.USER_PROFILE_PROMOTION
        ? res.data.profileImage
        : res;

    this.userSessionStore.user?.setImage(image);
    this.profileStore?.userProfile?.setImage(image);
  });

  @action.bound uploadGroupImage = flow(function* (this: GroupPresenter) {
    if (this.groupImage) {
      if (!this.userSessionStore.user) return;

      yield* toFlowGeneratorFunction(this.editPhoto)();
    }
  });

  @action updateGroupNameTouched = (b: boolean): void => {
    this.groupNameTouched = b;

    if (this.groupName) {
      this.isSuggestedVanityName = false;
      this.generateVanityName();
    }
  };

  @action.bound generateVanityName = flow(function* (this: GroupPresenter) {
    const vanity = yield new VanityNameGenerator(
      this.groupName,
      randomAlphanumeric,
      excludedVanityNames,
    ).createVanityName();

    // only alphanumeric characters
    this.updateGroupVanityName(vanity.replace("-", ""));
    this.updateGroupVanityNameTouched(true);
  });

  @action updateGroupVanityNameTouched = flow(function* (
    this: GroupPresenter,
    b: boolean,
  ) {
    this.groupVanityNameTouched = b;

    if (this.groupName && this.groupVanityName) {
      this.isValidatingVanityName = true;
      yield this.validateVanityName();
      this.isValidatingVanityName = false;
      this.isSuggestedVanityName = true;
    }
  });

  @action updateGroupDescriptionTouched = (b: boolean): void => {
    this.groupDescriptionTouched = b;
  };

  @action updateIsConflictGroupName = (b: boolean): void => {
    this.isConflictGroupName = b;
  };

  @action updateIsConflictGroupVanityName = (b: boolean): void => {
    this.isConflictGroupVanityName = b;
  };

  @action updateGroupName = (s: string): void => {
    this.groupName = s.slice(0, GROUP_NAME_MAX_LENGTH);
  };

  @action updateGroupVanityName = (s: string): void => {
    if (this.isValidatingVanityName) {
      return;
    }
    this.groupVanityName = replaceNonAlphanumericChars(s, "").slice(
      0,
      GROUP_VANITY_NAME_MAX_LENGTH,
    );
  };

  @action updateGroupDescription = (s: string): void => {
    this.groupDescription = s.slice(0, GROUP_DESC_MAX_SIZE);
  };

  @action onSelectImage = (
    inputRef: RefObject<HTMLInputElement>,
  ): File | undefined => {
    if (!inputRef.current?.files || !inputRef.current.files[0]) {
      return;
    }

    const fileType = inputRef.current.files[0]?.type;
    const fileSize = inputRef.current.files[0]?.size;
    const fileBlob = inputRef.current.files[0];

    if (!ALLOWED_MIME_TYPES.includes(fileType)) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: "toast-message.invalid-card-img-type",
        vars: {
          ALLOWED_MIME_TYPES: ALLOWED_MIME_TYPES.join(", "),
          fileType,
        },
      });
      return;
    }

    if (fileSize > GROUP_IMAGE_MAX_SIZE) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: "toast-message.campaign-error.img-too-large",
      });
      return;
    }

    return fileBlob;
  };

  @action previewGroupImage = (file: File): void => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.addEventListener("load", (e) => {
      const localFilePath = e.target?.result as string;
      if (localFilePath) {
        this.groupImagePath = localFilePath;
      }
    });
  };

  @action updateGroupImage = (inputRef: RefObject<HTMLInputElement>): void => {
    const file = this.onSelectImage(inputRef);
    if (!file) {
      return;
    }
    this.groupImage = file;
    this.previewGroupImage(file);
  };

  @action resetForm = (): void => {
    this.groupName = "";
    this.groupVanityName = "";
    this.groupDescription = "";

    this.groupNameTouched = false;
    this.groupDescriptionTouched = false;
    this.groupVanityNameTouched = false;

    this.groupImage = null;
    this.groupImagePath = "";

    this.isConflictGroupName = false;
    this.isConflictGroupVanityName = false;
    this.recommendVanityNames.replace([]);
  };

  @action.bound validateVanityName = flow(function* (this: GroupPresenter) {
    if (
      this.groupVanityName.length < 3 ||
      this.groupVanityName.length > GROUP_VANITY_NAME_MAX_LENGTH
    ) {
      return;
    }

    this.isConflictGroupVanityName = false;
    this.isGroupVanityNameExcluded = false;
    this.recommendVanityNames.replace([]);

    try {
      const res: IUserVanityNameVerify = yield this.groupApi.verifyVanityName({
        fullName: this.groupName,
        vanityName: this.groupVanityName,
      });

      if (res.isValid) return;

      if (this.isSuggestedVanityName) {
        this.isConflictGroupVanityName = true;
        return;
      }

      const recommendList = res.recommend.map((vanity) =>
        vanity.replace("-", ""),
      ); // only alphanumeric characters

      this.recommendVanityNames.replace(recommendList);

      if (recommendList[0]) {
        this.groupVanityName = recommendList[0];
        this.isSuggestedVanityName = true;
        return;
      }
    } catch (error) {
      // * invalid (excluded) vanity name
      if (isAxiosError(error) && error.response?.status === 400) {
        this.isGroupVanityNameExcluded = true;
        // * keep showing the vanity name for user to know which one is wrong and let them put another one
        this.recommendVanityNames.replace([this.groupVanityName]);
      } else {
        throw error;
      }
    }
  });

  @action.bound onSubmit = flow(function* (this: GroupPresenter) {
    try {
      if (!this.canSubmit) {
        return;
      }

      this.isSubmiting = true;

      const adapter: IGroup = yield this.groupApi.createGroup({
        name: this.groupName,
        vanityName: this.groupVanityName,
        description: this.groupDescription,
      });

      this.userSessionStore.setUser(adapter);

      yield this.userSessionStore.onAcceptTerm();

      this.modalStore.openModal("");

      this.uploadGroupImage();

      if (this.Router) {
        this.Router.push("/" + adapter.vanityName);
      }
    } catch (error) {
      // * invalid (excluded) vanity name
      if (isAxiosError(error) && error.response?.status === 400) {
        this.isGroupVanityNameExcluded = true;
        // * keep showing the vanity name for user to know which one is wrong and let them put another one
        this.recommendVanityNames.replace([this.groupVanityName]);
      } else {
        throw error;
      }
    } finally {
      this.isSubmiting = false;
    }
  });
}
