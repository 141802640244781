import { observer } from "mobx-react-lite";
import Link from "next/link";
import { ReactNode } from "react";

import { CustomConsumer } from "../../shared/models/Consumer";
import { useTranslation } from "../../shared/translate/NextI18next";
import { LeaderBoardRangeDriver } from "./LeaderBoardRange";
import sc from "./LeaderBoardRange.styled";

interface IProps {
  data: CustomConsumer;
  driver: Pick<
    LeaderBoardRangeDriver,
    "getDonatedDate" | "getPhotoUrl" | "effectDuration"
  >;
}
export const LeaderboardItem = observer((props: IProps) => {
  const { data } = props;
  const { t } = useTranslation("Leaderboard");

  const renderLink = (children: ReactNode): ReactNode => {
    if (
      props.data.isPublished &&
      (props.data.userEarthId || props.data.vanityName)
    ) {
      return (
        <Link
          href="/[vanityName]"
          as={`/${data.vanityName || data.userEarthId}`}
          passHref
          legacyBehavior
        >
          {children}
        </Link>
      );
    }

    return <>{children}</>;
  };

  const renderLinkRewardedBy = (children: ReactNode): ReactNode => {
    if (
      !!data.rewardedBy &&
      !!data.rewardedBy.vanityName &&
      !!data.rewardedBy.isPublished
    ) {
      return (
        <Link
          href="/[vanityName]"
          as={`/${data.rewardedBy.vanityName}`}
          passHref
          legacyBehavior
        >
          {children}
        </Link>
      );
    }

    return <>{children}</>;
  };

  const { key, vars } = props.driver.getDonatedDate(data);

  return (
    <sc.Item
      duration={props.driver.effectDuration}
      className={`leaderboard-item ${props.data.isWS && "animate"}`}
    >
      <sc.InfoBlock>
        <sc.UserPhoto photo={data.imageUrl} />
        <div>
          {renderLink(
            <sc.Name data-cy="leaderboardRange-donatorName">
              {data.name}
            </sc.Name>,
          )}
          <sc.Info>{t(key, { ...vars })}</sc.Info>
        </div>
      </sc.InfoBlock>
      {data.rewardedBy &&
        renderLinkRewardedBy(
          <sc.Photo
            data-cy="LeaderboardRangeItem-LinkRewardedBy"
            title={data.rewardedBy.name}
            photo={props.driver.getPhotoUrl(data.rewardedBy.photo)}
          />,
        )}
    </sc.Item>
  );
});
