import { action, makeObservable, observable } from "mobx";

import { Currency } from "../../stores/DonateStore/DonateStore";

export class InfoBubblePresenter {
  constructor(public currency: Currency) {
    makeObservable(this);
  }
  @observable isActiveBubble = false;
  @action.bound onInfoIconClicked() {
    this.isActiveBubble = true;
  }
  @action.bound onOverlayClicked() {
    this.isActiveBubble = false;
  }
}
