// eslint-disable-next-line unicorn/prefer-node-protocol
import { createHash } from "crypto";

function readFileAsync(
  file: File,
): Promise<
  | WithImplicitCoercion<string>
  | { [Symbol.toPrimitive](hint: "string"): string }
> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener("load", () => {
      resolve((reader?.result as string) || "");
    });

    reader.addEventListener("error", reject);

    // eslint-disable-next-line unicorn/prefer-blob-reading-methods
    reader.readAsArrayBuffer(file);
  });
}

export async function hashFile(file: File): Promise<string> {
  if (!file) return "";
  const contentFile = await readFileAsync(file);
  return createHash("md5").update(Buffer.from(contentFile)).digest("base64");
}
