import styled from "styled-components";

import {
  mediumScreenUpperLimit,
  smallScreenUpperLimit,
} from "../../../shared/breakpoints";
import {
  etBlack,
  etBrightLightBlue,
  etGreen,
  etWhite,
} from "../../../shared/colors";

export namespace sc {
  export const Section = styled.section`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-image: url(${require("../../../assets/img/kunal-shinde.png")});
    background-color: ${etBlack};
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 565px;
    border-radius: 32px 32px 0 0;
    padding-top: 135px;

    @media (max-width: ${mediumScreenUpperLimit}) {
      height: 500px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      height: 550px;
    }
  `;
  export const SectionContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: ${smallScreenUpperLimit}) {
      padding: 24px;
    }
  `;
  export const Title = styled.h2`
    font-family: Roboto Slab;
    font-size: 50px;
    line-height: 55px;
    font-weight: 400;
    letter-spacing: -0.5px;
    text-align: center;
    color: ${etWhite};
    max-width: 650px;
    margin-bottom: 35px;

    @media (max-width: ${mediumScreenUpperLimit}) {
      font-size: 40px;
      line-height: 45px;
      margin-bottom: 25px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      font-size: 30px;
      line-height: 35px;
      margin-bottom: 20px;
    }
  `;
  export const Description = styled.p`
    font-family: Roboto;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: center;
    color: ${etWhite};
    max-width: 900px;
    margin-bottom: 45px;

    @media (max-width: ${mediumScreenUpperLimit}) {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 25px;
    }
  `;
  export const ActionBlock = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
    width: fit-content;

    @media (max-width: ${mediumScreenUpperLimit}) {
      grid-gap: 16px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      grid-gap: 12px;
    }
  `;
  export const UnionOfNatureLink = styled.a`
    display: block;
    width: 168px;
    height: 40px;
    border-radius: 20px;
    background-color: ${etGreen};
    color: ${etBlack};
    font-family: Roboto Condensed;
    font-size: 16px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;

    :hover {
      text-decoration: none;
      color: ${etBlack};
    }
  `;
  export const EarthTodayLink = styled(UnionOfNatureLink)`
    width: 158px;
    height: 40px;
    background-color: ${etBrightLightBlue};

    color: ${etWhite};

    :hover {
      color: ${etWhite};
    }
  `;
}
