import { ImageResponseDto } from "@earthtoday/contract";

import { buildResizedAwsImageRequest } from "./buildAwsImageRelated";

export function getResizeImageUrl(
  imageData: ImageResponseDto | null,
  size: { width: number; height: number },
  bgImage?: string,
): string {
  if (!imageData) {
    return "";
  }

  const { isTransparent, awsBucket, awsKey, url, extract } = imageData;
  const { width, height } = size;

  if (!awsBucket || !awsKey) {
    if (bgImage && !url) return bgImage;
    return url;
  }

  return buildResizedAwsImageRequest(
    {
      isTransparent,
      awsBucket,
      awsKey,
      extract,
    },
    {
      width,
      height,
    },
  );
}
