const spotifyUrl = (cardLink: string): string => {
  const url = new URL(cardLink);

  // clear all queries
  url.search = "";

  return url.toString();
};

export const transformCardUrl = (cardLink: string): string => {
  if (cardLink.includes("spotify")) {
    return spotifyUrl(cardLink);
  }

  return cardLink;
};
