/* eslint-disable unicorn/consistent-function-scoping */
import {
  action,
  computed,
  flow,
  IObservableArray,
  makeObservable,
  observable,
} from "mobx";

import { IUserSessionStore } from "../components/ModalLogin/UserSessionStore";
import {
  ErrorMessage,
  ITheMessageStore,
} from "../components/TheMessage/TheMessageStore";
import { SystemDeckApi } from "../shared/apis/SystemDeckApi";
import {
  getAPIErrorStatusCode,
  isAxiosError,
  translateAPIError,
} from "../shared/helpers/translateApiError";
import { GroupProfileType, UserType } from "../shared/models/User";
import { CardSystemStore, ICardSystem } from "./CardSystemStore";
import { DonateStore } from "./DonateStore/DonateStore";
import { FeatureFlaggingData } from "./FeatureFlaggingStore";
import { MobileMenuStore } from "./MobileMenuStore/MobileMenuStore";
import { IModalStore } from "./ModalStore";

export interface SystemDeckData {
  systemCards: ICardSystem[];
  fetchSystemDeckError: ErrorMessage;
  errorStatus: number | null;
  cardSystemUserType: GroupProfileType | UserType | null;
}

export enum SystemDeckParam {
  BRAND = "brands",
  NPO = "naturereserves",
  PROTECTOR = "topprotectors",
}

const mapSystemDeckParam: Record<SystemDeckParam, GroupProfileType | UserType> =
  {
    brands: GroupProfileType.BRAND || UserType.BRAND,
    naturereserves: UserType.NPO,
    topprotectors: UserType.CONSUMER,
  };

export class SystemDeckStore {
  @observable systemCards: IObservableArray<CardSystemStore> =
    observable<CardSystemStore>([]);
  @observable fetchSystemDeckError: ErrorMessage = null;
  @observable errorStatus: number | null = null;
  @observable isLoadingPage: boolean = false;
  @observable cardSystemUserType: GroupProfileType | UserType | null = null;
  @observable currentPage: SystemDeckParam | null = null;
  constructor(
    private systemDeckApi: SystemDeckApi,
    public modalStore: IModalStore,
    private theMessageStore: ITheMessageStore,
    public donateStore: DonateStore,
    private userSessionStore: IUserSessionStore,
    private featureFlaggingStore: FeatureFlaggingData,
    private mobileMenuStore: MobileMenuStore,
  ) {
    makeObservable(this);
  }

  public dehydrate(): SystemDeckData {
    return {
      systemCards: this.systemCards.map((deck) => deck.toJSON()) || [],
      fetchSystemDeckError: this.fetchSystemDeckError,
      errorStatus: this.errorStatus,
      cardSystemUserType: this.cardSystemUserType,
    };
  }

  @action.bound public hydrate(data: SystemDeckData): void {
    this.systemCards.replace(
      data.systemCards.map(
        (deck) =>
          new CardSystemStore(
            deck,
            this,
            this.modalStore,
            this.theMessageStore,
            this.systemDeckApi,
            this.userSessionStore,
            this.featureFlaggingStore,
          ),
      ),
    );
    this.fetchSystemDeckError = data.fetchSystemDeckError;
    this.errorStatus = data.errorStatus;
    this.cardSystemUserType = data.cardSystemUserType;
  }

  @action.bound fetchPageDataServer = flow(function* fetchPageDataServer(
    this: SystemDeckStore,
    systemDeckTypeParam: SystemDeckParam,
  ) {
    yield this.fetchSystemDeck(systemDeckTypeParam);
  });

  @action.bound fetchPageDataBrowser = flow(function* fetchPageDataBrowser(
    this: SystemDeckStore,
    systemDeckTypeParam: SystemDeckParam,
  ) {
    yield this.fetchSystemDeck(systemDeckTypeParam);
  });

  @action.bound fetchSystemDeck = flow(function* fetchSystemDeck(
    this: SystemDeckStore,
    systemDeckTypeParam: SystemDeckParam,
  ) {
    try {
      this.isLoadingPage = true;
      const res = yield this.systemDeckApi.fetchSystemDeckData(
        mapSystemDeckParam[systemDeckTypeParam],
      );

      this.cardSystemUserType = mapSystemDeckParam[systemDeckTypeParam];
      this.systemCards.replace(
        res.map(
          (deck) =>
            new CardSystemStore(
              deck,
              this,
              this.modalStore,
              this.theMessageStore,
              this.systemDeckApi,
              this.userSessionStore,
              this.featureFlaggingStore,
            ),
        ),
      );

      this.fetchSystemDeckError = null;
      this.errorStatus = null;
    } catch (error) {
      if (isAxiosError(error)) {
        this.fetchSystemDeckError = translateAPIError(error);
        this.errorStatus = getAPIErrorStatusCode(error);
      }
    } finally {
      this.isLoadingPage = false;
    }
  });

  @computed get isBannerActive(): boolean {
    const isMobileMenuOpen =
      this.mobileMenuStore.isMenuAppOpen ||
      this.mobileMenuStore.isCardJoiningOpen;

    if (isMobileMenuOpen) {
      return false;
    }

    return true;
  }

  @action.bound updateCurrentPage(systemDeckParam: SystemDeckParam) {
    this.currentPage = systemDeckParam;
  }
}
