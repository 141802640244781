import styled from "styled-components";

import {
  mediumScreenLowerLimit,
  smallScreenUpperLimit,
} from "../../shared/breakpoints";
import {
  etBlack,
  etGray,
  etHeatherBlue,
  etLicoriceBlue,
  etNevadaGrey,
  etWhite,
} from "../../shared/colors";
import zIndex from "../../shared/zIndex";
import { IconSvgContainer } from "../IconSvg/IconSvg.styled";

export const CARD_OPEN_TYPE_ARTICLE_IMAGE_WIDTH = 656;
export const CARD_OPEN_TYPE_ARTICLE_IMAGE_HEIGHT = 358;
export const CARD_OPEN_TYPE_ARTICLE_WITHOUT_TITLE_IMAGE_HEIGHT = 453;
export const CARD_OPEN_TYPE_ARTICLE_IMAGE_MOBILE_WIDTH = 304;
export const CARD_OPEN_TYPE_ARTICLE_IMAGE_MOBILE_HEIGHT = 204;
export const CARD_OPEN_TYPE_ARTICLE_WITHOUT_TITLE_IMAGE_MOBILE_HEIGHT = 358;

namespace sc {
  export const CardSource = styled.a`
    position: absolute;
    bottom: 9px;
    left: 12px;
    height: 14px;

    width: 160px;
    height: 20px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${etWhite};
    z-index: 1;
    cursor: pointer;
    :hover {
      text-decoration: none;
      color: ${etWhite};
    }
  `;
  export const Header = styled.div`
    display: flex;
    height: 44px;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px 10px 0 0;
    padding: 0 12px;
  `;
  export const HeaderTitleContainer = styled.a<{ iconColor: string }>`
    display: flex;

    > div > svg {
      height: 18px;
      width: 19px;
      > g > g {
        stroke: ${(props) => props.iconColor};
        fill: ${(props) => props.iconColor};
      }
    }
  `;
  export const HeaderTitle = styled.span`
    height: 20px;
    font-family: Roboto;
    font-size: 14px;
    font-stretch: condensed;
    margin-bottom: 0;
    padding-left: 6px;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${etNevadaGrey};
    :hover {
      color: ${etBlack};
    }
  `;
  export const ContextMenuContainer = styled.div`
    width: 32px;
    height: 44px;
  `;
  export const PreviewImageContainer = styled.div<{
    isCardLayoutEditorial: boolean;
    isCardLayoutText: boolean;
    bgColor: string;
  }>`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;

    background-color: ${(props) =>
      props.isCardLayoutText ? props.bgColor : etWhite};
    height: ${(props) =>
      props.isCardLayoutEditorial
        ? `${CARD_OPEN_TYPE_ARTICLE_IMAGE_HEIGHT}px`
        : `${CARD_OPEN_TYPE_ARTICLE_WITHOUT_TITLE_IMAGE_HEIGHT}px`};
    max-height: ${(props) =>
      props.isCardLayoutEditorial
        ? `${CARD_OPEN_TYPE_ARTICLE_IMAGE_HEIGHT}px`
        : `${CARD_OPEN_TYPE_ARTICLE_WITHOUT_TITLE_IMAGE_HEIGHT}px`};

    @media (max-width: ${smallScreenUpperLimit}) {
      height: ${(props) =>
        props.isCardLayoutEditorial
          ? `${CARD_OPEN_TYPE_ARTICLE_IMAGE_MOBILE_HEIGHT}px`
          : `${CARD_OPEN_TYPE_ARTICLE_WITHOUT_TITLE_IMAGE_MOBILE_HEIGHT}px`};
      max-height: ${(props) =>
        props.isCardLayoutEditorial
          ? `${CARD_OPEN_TYPE_ARTICLE_IMAGE_MOBILE_HEIGHT}px`
          : `${CARD_OPEN_TYPE_ARTICLE_WITHOUT_TITLE_IMAGE_MOBILE_HEIGHT}px`};
    }
  `;

  export const CardLayoutTextTitle = styled.p<{ color: string }>`
    padding-left: 24px;
    padding-right: 66px;
    font-family: "Roboto Slab";
    font-size: 31px;
    font-weight: normal;
    letter-spacing: -0.5px;
    line-height: 38px;
    color: ${(props) => props.color || etWhite};

    @media (max-width: ${smallScreenUpperLimit}) {
      padding: 58px 17px 38px 12px;
    }
  `;

  export const PreviewImage = styled.div<{
    url?: string;
    isCardLayoutText: boolean;
  }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 3;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: ${(props) =>
      props.isCardLayoutText ? "unset" : `url(${props.url})`};

    height: 100%;
  `;
  export const Body = styled.div`
    height: 93.5px;
    font-family: "Roboto Slab";
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    padding: 11px 0 18.5px 0;
    color: ${etLicoriceBlue};
    display: flex;
    background-color: ${etWhite};
    border-bottom: 1px solid ${etHeatherBlue};
    @media (max-width: ${smallScreenUpperLimit}) {
      height: 100px;
    }
  `;
  export const BodyText = styled.div`
    min-height: 46px;
    flex: 1;
    margin: 0 20px 0 12px;
    font-family: "Roboto Slab";
    font-size: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 32px;
    letter-spacing: 0px;

    @media (max-width: ${smallScreenUpperLimit}) {
      font-size: 16px;
      line-height: 26px;
      height: 78px;
    }
  `;
  export const Container = styled.div`
    height: 100%;
    border-radius: 8px;
    background-color: ${etWhite};
    color: ${etBlack};
  `;

  export const RemovedCardBody = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 3;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 277px;
    max-height: 277px;
    @media (min-width: ${mediumScreenLowerLimit}) {
      min-height: 452px;
      max-height: 452px;
    }
  `;

  export const RemovedCardBodyTitle = styled.div`
    font-size: 12px;
    padding: 10px 0;
    font-family: "Roboto Slab";
    font-weight: bold;
    @media (min-width: ${mediumScreenLowerLimit}) {
      font-size: 16px;
    }
  `;
  export const RemovedCardBodyText = styled.div`
    margin-left: 5px;
    font-size: 12px;
    padding: 10px 0;
    font-family: "Roboto Slab";
    @media (min-width: ${mediumScreenLowerLimit}) {
      font-size: 16px;
    }
  `;
  export const ButtonLink = styled.a`
    display: block;
    font-family: Roboto;
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

    height: 20px;
    font-size: 12px;
    color: ${etWhite};
    text-align: center;
    background-color: transparent;
    display: flex;
    align-items: center;
    :hover {
      text-decoration: none;
      color: ${etWhite};
    }
    > ${IconSvgContainer} {
      margin-left: 4px;
    }
  `;
  export const ContainerBody = styled.div`
    position: relative;
    margin: 0 12px;
  `;
  export const SaveCommentsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    height: 14px;
  `;
  export const CommentTextWord = styled.div`
    color: ${etGray};

    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

    @media (max-width: ${smallScreenUpperLimit}) {
      font-size: 10px;
    }
  `;
  export const NumberComment = styled.div`
    margin-right: 3px;
    color: ${etGray};

    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

    @media (max-width: ${smallScreenUpperLimit}) {
      font-size: 10px;
    }
  `;
  export const SaveCommentsText = styled.div`
    text-decoration: underline;
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${etGray};

    margin-left: 3px;

    @media (max-width: ${smallScreenUpperLimit}) {
      font-size: 10px;
    }
  `;
  export const BlackOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    background: ${etBlack};
    height: 100%;
    width: 100%;
    opacity: 0.6;
    z-index: ${zIndex.CARDITEM_BLACK_OVERLAY};
  `;
  export const Gradient = styled.div`
    content: "";
    width: 100%;
    height: 75px;
    opacity: 0.6;
    background: linear-gradient(
      -180deg,
      rgba(0, 0, 0, 0) 0%,
      rgb(0, 0, 0) 100%
    );
    position: absolute;
    bottom: 0;
    z-index: 0;
  `;
}
export default sc;
