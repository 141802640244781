/* eslint-disable import/no-default-export */
import styled, { css } from "styled-components";

import { mediumScreenUpperLimit } from "../../shared/breakpoints";
import { etWhite } from "../../shared/colors";
import bgBottom from "./img/bg-bottom.svg";
import bgTop from "./img/bg-top.svg";

namespace sc {
  export const Container = styled.div<{
    borderColor?: string;
    fixedSize?: boolean;
  }>`
    position: relative;
    border-width: 4px;
    margin-left: -4px;
    border-style: solid;
    border-color: ${(props) => props.borderColor || etWhite};

    min-height: 57.01px;
    max-height: 57.01px;
    min-width: 57.01px;
    max-width: 57.01px;

    :nth-last-child(3n + 2) {
      margin-right: 8px;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;

    .flap.no-animation {
      [data-kind="digit"] > div {
        animation-duration: 0ms !important;
      }
    }

    .flap {
      height: 48px !important;
      width: 48px !important;
      font-size: 50px !important;
      line-height: 52px !important;
      padding: 0 !important;

      [data-kind="digit"] {
        width: 100%;
        height: 100%;
        font-size: 42px !important;
        line-height: 49px !important;
        font-family: Roboto !important;
        font-weight: bold !important;
        font-stretch: condensed !important;

        background: transparent;

        > div {
          border-radius: 5px;
        }
        > div:nth-child(1) {
          clip-path: polygon(0 47%, 100% 47%, 100% 0, 0 0);
        }
        > div:nth-child(2) {
          margin-top: 1px !important;
          clip-path: polygon(0 100%, 100% 100%, 100% 51%, 0 51%);
        }
      }

      [data-kind="digit"] > div {
        background-image: url(${bgTop}) !important;
        background-repeat: no-repeat !important;
        background-size: 100% 57% !important;
        background-position: top !important;
      }

      [data-kind="digit"] > div + div {
        background-image: url(${bgBottom}) !important;
        background-repeat: no-repeat !important;
        background-size: 100% 57% !important;
        background-position: bottom !important;
      }

      [data-kind="digit"] > div:nth-child(1) {
        clip-path: polygon(0 47%, 100% 47%, 100% 0, 0 0);
      }
    }
    ${(props) =>
      !props.fixedSize &&
      css`
        @media (max-width: ${mediumScreenUpperLimit}) {
          min-height: 26.74px;
          max-height: 26.74px;
          min-width: 26.74px;
          max-width: 26.74px;

          border-width: 2px;
          margin-left: -2px;

          :nth-last-child(3n + 2) {
            margin-right: 4px;
          }
          .flap {
            height: 24px !important;
            width: 24px !important;
            font-size: 22px !important;
            line-height: 25px !important;
            [data-kind="digit"] {
              font-size: 22px !important;
              line-height: 25px !important;
            }
          }
        }
      `}
  `;

  export const Chain = styled.div`
    position: absolute;
    z-index: 10;

    width: 3px;
    max-width: 3px;
    height: 9px;
    max-height: 9px;
    top: 19px;
    right: 5.5px;

    border-radius: 100%;

    background: radial-gradient(
      ellipse 100% 100% at 50% 0%,
      rgb(71, 71, 71) 0%,
      rgb(24, 24, 24) 100%
    );

    @media (max-width: ${mediumScreenUpperLimit}) {
      width: 2px;
      max-width: 2px;
      height: 4px;
      max-height: 4px;
      top: 9px;
      right: 2px;
    }

    &:before {
      content: "";
      position: absolute;
      z-index: 10;
      top: 0px;
      right: 35.5px;

      width: 3px;
      max-width: 3px;
      height: 9px;
      max-height: 9px;

      border-radius: 100%;

      background: radial-gradient(
        ellipse 100% 100% at 50% 0%,
        rgb(71, 71, 71) 0%,
        rgb(24, 24, 24) 100%
      );

      @media (max-width: ${mediumScreenUpperLimit}) {
        width: 2px;
        max-width: 2px;
        height: 4px;
        max-height: 4px;

        right: 17px;
      }
    }
  `;
}

export default sc;
