import { etGray } from "@earthtoday/fe-shared-code";
import styled from "styled-components";

import {
  mediumScreenUpperLimit,
  smallScreenUpperLimit,
} from "../../../shared/breakpoints";
import { etBrightLightBlue, etWhite } from "../../../shared/colors";

export namespace sc {
  export const Section = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-image: url(${require("./assets/m2-forest.png")});
    background-color: ${etGray};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 825px;
    border-radius: 32px 32px 0 0;
    overflow: hidden;
    width: 100vw;

    @media (max-width: ${mediumScreenUpperLimit}) {
      height: 650px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      height: 750px;
    }
  `;
  export const SectionContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;

    padding: 100px;
  `;
  export const M2 = styled.div`
    width: 155.98px;
    height: 79.88px;
    background-image: url(${require("./assets/m2-expand.png")});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 20px;

    @media (max-width: ${mediumScreenUpperLimit}) {
      width: 120px;
      height: 60px;
      margin-bottom: 15px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      margin-bottom: 10px;
    }
  `;
  export const Title = styled.h2`
    font-family: Roboto Slab;
    font-size: 50px;
    line-height: 55px;
    font-weight: 400;
    letter-spacing: -0.5px;
    text-align: center;
    color: ${etWhite};
    margin-bottom: 45px;
    max-width: 950px;
    padding: 0 40px;

    @media (max-width: ${mediumScreenUpperLimit}) {
      font-size: 35px;
      line-height: 40px;
      margin-bottom: 35px;
      padding: 0 32px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      font-size: 25px;
      line-height: 30px;
      margin-bottom: 25px;
      padding: 0 24px;
    }
  `;
  export const Subtitle = styled.p`
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: center;
    color: ${etWhite};
    max-width: 950px;
    margin-bottom: 60px;
    padding: 0 40px;

    @media (max-width: ${mediumScreenUpperLimit}) {
      margin-bottom: 35px;
      padding: 0 32px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      margin-bottom: 20px;
      padding: 0 24px;
    }
  `;
  export const NpoLogos = styled.img`
    min-width: 100vw;
    max-width: 100vw;
    max-height: 155px;
  `;
}
