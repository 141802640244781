import NumAbbr from "number-abbreviate";

import {
  floorDecimalAdjust,
  formatToThousand,
} from "../../../shared/helpers/formatProtectedNumber";

const numAbbr = new NumAbbr();

export const formatUonNumber = (num: number): string => {
  let res = num;
  if (num > 999999999999) {
    res = floorDecimalAdjust(res / 1000000000000, -1);
    return numAbbr.abbreviate(res * 1000000000000, 1).toUpperCase();
  }
  if (num > 99999999999) {
    res = floorDecimalAdjust(res / 10000000000, -1);
    return numAbbr.abbreviate(res * 10000000000, 0).toUpperCase();
  }
  if (num > 999999999) {
    res = floorDecimalAdjust(res / 1000000000, -1);
    return numAbbr.abbreviate(res * 1000000000, 1).toUpperCase();
  }
  if (num > 99999999) {
    res = floorDecimalAdjust(res / 10000000, -1);
    return numAbbr.abbreviate(res * 10000000, 0).toUpperCase();
  }
  if (num > 999999) {
    res = floorDecimalAdjust(res / 1000000, -1);
    return numAbbr.abbreviate(res * 1000000, 1).toUpperCase();
  }
  if (num > 99999) {
    res = floorDecimalAdjust(res / 10000, -1);
    return numAbbr.abbreviate(res * 10000, 0).toUpperCase();
  }
  if (num > 9999) {
    res = floorDecimalAdjust(res / 1000, -1);
    return numAbbr.abbreviate(res * 1000, 1).toUpperCase();
  }
  if (num > 999) {
    res = floorDecimalAdjust(res / 1000, -1);
    return numAbbr.abbreviate(res * 1000, 1).toUpperCase();
  }

  return formatToThousand(res, ".").toUpperCase();
};
