import { StrategyFirstLastName } from "./StrategyFirstLastName";
import { VanityNameGeneratorStrategy } from "./VanityNameGenerator";

export class StrategyFirstLastNameAbbreviation
  implements VanityNameGeneratorStrategy
{
  private abbreviateAt: "first" | "last" = "first";

  constructor(private nameParts: string[]) {}

  private get hasBothFirstLastName() {
    return this.nameParts.length >= 2;
  }

  generate(): { vanityName: string; next: boolean } {
    if (!this.hasBothFirstLastName) {
      return new StrategyFirstLastName(this.nameParts).generate();
    }

    if (this.abbreviateAt === "first") {
      const vanityName =
        this.nameParts[0].slice(0, 1) +
        this.nameParts[this.nameParts.length - 1];
      this.abbreviateAt = "last";
      return { vanityName, next: false };
    }

    const vanityName =
      this.nameParts[0] + this.nameParts[this.nameParts.length - 1].slice(0, 1);
    return { vanityName, next: true };
  }
}
