import { Uon } from "@earthtoday/contract";

import { TokenInterceptorStore } from "../../stores/TokenInterceptorStore";
import { getAPIBaseUrl } from "../env";

export type WrappedGiftCodeUnwrapPayload = {
  code: string;
};

export type WrappedGiftCodeCreatePayload = {
  wrappedSize: number;
  uonId: string;
};

export interface IGiftCodeApi {
  unwrapGiftCode: (data: WrappedGiftCodeUnwrapPayload) => Promise<void>;
  createGiftCode: (data: WrappedGiftCodeCreatePayload) => Promise<Uon[]>;
}

export class GiftCodeApi implements IGiftCodeApi {
  constructor(private tokenInterceptorStore: TokenInterceptorStore) {}

  unwrapGiftCode = async (
    data: WrappedGiftCodeUnwrapPayload,
  ): Promise<void> => {
    await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/uon/giftCodes/unwrap`,
      data,
    });
  };

  createGiftCode = async (
    data: WrappedGiftCodeCreatePayload,
  ): Promise<Uon[]> => {
    const res = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/uon/giftCodes`,
      data,
    });

    return res.data;
  };
}
