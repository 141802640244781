import styled from "styled-components";

import { etDark, etGray, etSilverLight } from "../../shared/colors";

namespace sc {
  export const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 280px;
  `;

  export const LogoContent = styled.div<{ url: string }>`
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50%;
    background-color: ${etSilverLight};
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    ${(props) => props.url && `background-image: url(${props.url})`}
  `;

  export const DonatorInfo = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 12px;
    max-width: 240px;
  `;

  export const DonatorName = styled.a`
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${etDark};
    &:hover {
      color: ${etDark};
    }

    :not([href]) {
      text-decoration: none;
    }
  `;

  export const DonatedTime = styled.div`
    font-family: Roboto;
    font-stretch: condensed;
    font-weight: 400;
    font-size: 10px;
    text-transform: uppercase;
    height: 11px;
    color: ${etGray};
  `;

  export const SupM2 = styled.sup`
    position: relative;
    font-size: 8px;
    top: -4px;
    line-height: 0;
    vertical-align: baseline;
  `;
}

export default sc;
