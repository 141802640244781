import { CookieSerializeOptions } from "cookie";
import { NextPageContext } from "next";
import { useRouter } from "next/router";
import { destroyCookie, parseCookies, setCookie } from "nookies";

import { Logger } from "../shared/models/Logger";
import { appCache } from "../shared/requireCrossEnv";
import { RootStore } from "./rootStore";
import { ICookie } from "./TokenStore";

const logger: Logger = (process as any).browser
  ? // eslint-disable-next-line @typescript-eslint/no-var-requires
    require("browser-bunyan").createLogger({ name: "et-ui" })
  : // eslint-disable-next-line @typescript-eslint/no-var-requires
    require("bunyan").createLogger({ name: "et-ui" });

export function rootStoreFromNextPageContext(
  context: NextPageContext,
): Promise<RootStore> {
  const tempCookieStorage: Record<string, string> = {};
  const serverCookieManager: ICookie = {
    get(key: string): string {
      if (tempCookieStorage[key]) {
        return tempCookieStorage[key];
      }
      const cookies = parseCookies(context);
      return cookies[key];
    },
    set(key: string, value: string, options: CookieSerializeOptions) {
      setCookie(context, key, value, options);
      tempCookieStorage[key] = value;
    },
    remove(key: string) {
      destroyCookie(context, key);
      delete tempCookieStorage[key];
    },
  };

  const { req } = context;
  if (!req) {
    throw new Error("no request found in context");
  }

  const ip = req.headers["x-forwarded-for"] || req.connection.remoteAddress;
  const userAgent = (req.headers["User-Agent"] || req.headers["user-agent"])
    ?.toString()
    .trim();

  const isCloudFrontMobileHeader =
    req.headers["cloudfront-is-mobile-viewer"] &&
    req.headers["cloudfront-is-mobile-viewer"] === "true";

  const mockApiBaseUrl = req.headers["x-mock-api-base-url"]?.toString() || "";
  const mockOptions = req.headers["x-mock-options"]?.toString() || "";

  const isMobileDevice = isCloudFrontMobileHeader
    ? true
    : /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent || "",
      );

  logger.info(":: rootStoreFromNextPageContext ::", {
    ip,
    userAgent,
    isMobileDevice,
    isCloudFrontMobileHeader,
  });

  const isMobileWidth = isMobileDevice;

  const protocol = req.headers["x-forwarded-proto"];
  const host = req.headers["x-forwarded-host"] || req.headers.host;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { featureFlagsBase64 } = req as any;

  const rootStore = new RootStore(
    serverCookieManager,
    appCache,
    { isWebPSupported: false, isMobileDevice, isMobileWidth },
    () => `${protocol}://${host}${useRouter().pathname}`,
    { mockApiBaseUrl, mockOptions },
    featureFlagsBase64,
    logger.child({ ip, userAgent }),
  );

  return Promise.resolve(rootStore);
}
