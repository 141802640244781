import styled from "styled-components";

import {
  largeScreenUpperLimit,
  mediumScreenLowerLimit,
  mediumScreenUpperLimit,
  smallScreenUpperLimit,
} from "../../shared/breakpoints";
import { etBlack, etBrightLightBlue, etWhite } from "../../shared/colors";
import zIndex from "../../shared/zIndex";
import { CampaignDefaultStatus } from "../../stores/CampaignDefaultModel";
import { IconSvgContainer } from "../IconSvg/IconSvg.styled";

export const CARD_CAMPAIGN_DEFAULT_IMAGE_WIDTH = 693;
export const CARD_CAMPAIGN_DEFAULT_IMAGE_HEIGHT = 472;
export const CARD_CAMPAIGN_DEFAULT_IMAGE_TABLET_WIDTH = 340;
export const CARD_CAMPAIGN_DEFAULT_IMAGE_MOBILE_WIDTH = 304;
export const CARD_CAMPAIGN_DEFAULT_IMAGE_MOBILE_HEIGHT = 471;
export const CARD_CAMPAIGN_DEFAULT_WITHOUT_LEADERBOARD_IMAGE_WIDTH = 1008;
export const CARD_CAMPAIGN_DEFAULT_WITHOUT_LEADERBOARD_IMAGE_TABLET_WIDTH = 656;
export const CARD_CAMPAIGN_DEFAULT_WITHOUT_LEADERBOARD_IMAGE_MOBILE_WIDTH = 304;

export namespace sc {
  export const Container = styled.div<{ state: CampaignDefaultStatus }>`
    display: flex;
    position: relative;
    font-family: Roboto Condensed;
    background-color: ${etWhite};
    grid-column: 3 span;
    border-radius: 8px;
    color: ${etWhite};
    height: 100%;
    max-height: 496px;
    width: 100%;
    max-width: 100%;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
      0px 2px 3px 0px rgba(0, 0, 0, 0.1);

    -webkit-filter: opacity(1);

    @media (max-width: ${largeScreenUpperLimit}) {
      grid-column: ${(props) =>
        props.state?.toUpperCase() === CampaignDefaultStatus.State1
          ? "1 span"
          : "2 span"};
    }
    @media (min-width: ${mediumScreenUpperLimit}) and (max-width: ${largeScreenUpperLimit}) {
      grid-column: 2 span;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      flex-direction: column;
      grid-column: 1 span;
      grid-row: ${(props) =>
        props.state?.toUpperCase() === CampaignDefaultStatus.State1
          ? "1 span"
          : "2 span"};
      min-width: ${(props) =>
        props.state?.toUpperCase() === CampaignDefaultStatus.State1
          ? "328px"
          : "100%"};
      min-height: ${(props) =>
        props.state?.toUpperCase() === CampaignDefaultStatus.State1
          ? "496px"
          : "100%"};
      max-width: ${(props) =>
        props.state?.toUpperCase() === CampaignDefaultStatus.State1
          ? "328px"
          : "100%"};
      max-height: ${(props) =>
        props.state?.toUpperCase() === CampaignDefaultStatus.State1
          ? "496px"
          : "100%"};
    }
  `;

  export const ContainerWithoutBoxShadow = styled(Container)`
    box-shadow: none;
  `;
  export const BackgroundOverlay = styled.div<{ state: CampaignDefaultStatus }>`
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: ${(props) =>
      props.state?.toUpperCase() === CampaignDefaultStatus.State1
        ? "0px"
        : "-1px"};

    background-color: ${etBlack};
    opacity: 0.3;
    z-index: -1;

    @media (max-width: ${(props) =>
        props.state?.toUpperCase() === CampaignDefaultStatus.State2
          ? largeScreenUpperLimit
          : mediumScreenUpperLimit}) {
      bottom: 1px;
      right: 0px;
    }
  `;

  export const BackgroundContainer = styled.div<{ bg: string }>`
    position: relative;
    z-index: 1;

    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;

    ${(props) =>
      props.bg === ""
        ? `background-image: url(${require("../../assets/img/Header.png")});`
        : `background-image: url(${props.bg});`}
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    font-family: Roboto;
    font-weight: normal;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;

  export const Content = styled.div<{ state: CampaignDefaultStatus }>`
    position: relative;

    width: 100%;

    height: 100%;
    max-width: 100%;
    max-height: 100%;

    padding: ${(props) =>
      props.state?.toUpperCase() === CampaignDefaultStatus.State2
        ? "12px 0px 12px 12px"
        : "12px 12px 12px 12px"};

    @media (max-width: ${smallScreenUpperLimit}) {
      padding: 12px 12px 12px 12px;
    }

    @media (max-width: ${largeScreenUpperLimit}) {
      height: ${(props) =>
        props.state?.toUpperCase() === CampaignDefaultStatus.State2
          ? "496px"
          : "100%"};
    }
  `;
  export const WhiteFrame = styled.div<{ state: CampaignDefaultStatus }>`
    border: 4.8px solid ${etWhite};
    min-height: 80px;
    max-height: 80px;
    min-width: 80px;
    max-width: 80px;

    z-index: 1;

    opacity: 0.5;
    padding: 29px 11px 11px 11px;
    margin-top: 24px;

    @media (max-width: ${(props) =>
        props.state?.toUpperCase() === CampaignDefaultStatus.State2
          ? largeScreenUpperLimit
          : mediumScreenUpperLimit}) {
      padding: 17.2px 6.2px 4px 6px;
      border-width: 3.6px;
      min-height: 53px;
      max-height: 53px;
      min-width: 53px;
      max-width: 53px;

      ${IconSvgContainer} > svg {
        min-height: 22.8px;
        max-height: 22.8px;
        min-width: 34.8px;
        max-width: 34.8px;
      }
    }
  `;

  export const Title = styled.div<{ state: CampaignDefaultStatus }>`
    font-size: 30px;
    font-weight: bold;
    letter-spacing: -0.5px;
    line-height: 35px;
    text-align: center;

    height: 135px;
    max-height: 135px;
    width: 100%;
    max-width: 650px;

    display: flex;
    justify-content: center;
    align-items: center;

    & > p {
      margin: 0;
      text-align: center;
      word-wrap: break-word;
      width: 100%;
      max-width: 100%;
    }

    & > p > span > span {
      font-weight: ${(props) =>
        props.state?.toUpperCase() === CampaignDefaultStatus.State2
          ? "bold"
          : "300"};
    }

    @media (max-width: ${(props) =>
        props.state?.toUpperCase() === CampaignDefaultStatus.State2
          ? largeScreenUpperLimit
          : mediumScreenUpperLimit}) {
      font-size: 24px;
      line-height: 28px;

      width: 303px;
      max-width: 303px;
      height: 190px;
      max-height: 190px;

      padding: 0 10px;
    }
  `;
  export const WrapperCounterM2 = styled.div<{ state: CampaignDefaultStatus }>`
    position: relative;
    display: flex;
    overflow: hidden;
    padding-left: 4.5px;
    height: 50px;
    max-height: 50px;

    @media (max-width: ${(props) =>
        props.state?.toUpperCase() === CampaignDefaultStatus.State2
          ? largeScreenUpperLimit
          : mediumScreenUpperLimit}) {
      height: 38px;
      max-height: 38px;
    }
  `;

  export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 24px;
    margin-bottom: 10px;
  `;
  export const Subtitle = styled.div<{
    state: CampaignDefaultStatus;
    isNpoGroup: boolean;
  }>`
    font-size: 18px;
    font-weight: normal;
    font-stretch: condensed;
    letter-spacing: 0px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;

    height: 44px;
    max-height: 44px;
    width: 100%;
    max-width: 100%;

    margin-bottom: 19px;

    ${(props) =>
      props.isNpoGroup
        ? `span span:nth-child(1) {
      display: inline-block;
      @media (max-width: ${largeScreenUpperLimit}) {
        position: relative;
        top: 1px;
        white-space: nowrap;
        max-width: 157px;
        height: 14px;
        max-height: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 17px;
        
      }

       @media (max-width: ${mediumScreenLowerLimit}) {
        position: relative;
        top: 1px;
        white-space: nowrap;
        max-width: 125px;
        height: 14px;
        max-height: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 17px;
        margin-bottom: 1px;
      }
    }`
        : ""}

    @media (max-width: ${largeScreenUpperLimit}) {
      font-size: 17px;
    }
    @media (max-width: ${(props) =>
        props.state?.toUpperCase() === CampaignDefaultStatus.State2
          ? largeScreenUpperLimit
          : mediumScreenUpperLimit}) {
      font-size: 16px;
      line-height: 20px;
      max-height: 40px;
      min-height: 40px;
    }
  `;

  export const ShareBtn = styled.button<{ state: CampaignDefaultStatus }>`
    border-radius: 23px;
    border: 1px solid ${etWhite};
    height: 40px;
    max-height: 40px;
    width: 180px;
    max-width: 180px;

    font-size: 16px;
    font-weight: normal;
    font-stretch: condensed;
    letter-spacing: 0px;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
    color: ${etWhite};

    background-color: transparent;

    margin: 0 7px;

    justify-content: center;
    align-items: center;

    line-height: 38px;

    &.mobile {
      display: none;
    }
    &.desktop {
      display: flex;
    }

    @media (max-width: ${(props) =>
        props.state?.toUpperCase() === CampaignDefaultStatus.State2
          ? largeScreenUpperLimit
          : mediumScreenUpperLimit}) {
      width: 113px;
      max-width: 113px;
      height: 40px;
      max-height: 40px;

      &.mobile {
        display: flex;
      }
      &.desktop {
        display: none;
      }
    }
  `;
  export const ProtectBtn = styled.button<{ state: CampaignDefaultStatus }>`
    border-radius: 23px;
    border: 1px solid ${etBrightLightBlue};
    height: 40px;
    max-height: 40px;
    width: 180px;
    max-width: 180px;

    font-size: 16px;
    font-weight: normal;
    font-stretch: condensed;
    letter-spacing: 0px;
    line-height: 38px;
    text-align: center;
    text-transform: uppercase;
    color: ${etWhite};

    background-color: ${etBrightLightBlue};

    margin: 0 7px;

    justify-content: center;
    align-items: center;
    &.mobile {
      display: none;
    }
    &.desktop {
      display: flex;
    }

    @media (max-width: ${(props) =>
        props.state?.toUpperCase() === CampaignDefaultStatus.State2
          ? largeScreenUpperLimit
          : mediumScreenUpperLimit}) {
      &.mobile {
        display: flex;
      }
      &.desktop {
        display: none;
      }

      width: 113px;
      max-width: 113px;
      height: 40px;
      max-height: 40px;
    }
  `;
  export const LearnMoreBtn = styled.a`
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 22px;
    text-align: center;
    color: ${etWhite};
    text-decoration: underline;

    background-color: transparent;
    border: none;

    height: 32px;
    max-height: 32px;

    margin-bottom: 11px;

    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
      color: ${etWhite};
    }

    @media (max-width: ${mediumScreenUpperLimit}) {
      font-size: 14px;
      line-height: 26px;
    }
  `;

  export const WrapperContextMenu = styled.div<{
    contextMenuIsActive: boolean;
  }>`
    position: absolute;
    z-index: ${(props) =>
      props.contextMenuIsActive
        ? zIndex.CONTEXT_MENU_CARD_ARTICLE_ACTIVE
        : zIndex.CONTEXT_MENU_CARD_ARTICLE_INACTIVE};
    top: 0;
    right: 0;
  `;
  export const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    background-color: ${etBlack};
    opacity: 0.3;
    @media (max-width: ${mediumScreenUpperLimit}) {
      border-radius: 8px;
    }
  `;
  export const BackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
  `;
  export const LeaderContent = styled.div`
    width: 328px;
    min-width: 328px;
    min-height: 496px;
    height: 496px;
    background-color: ${etWhite};
  `;

  export const TextMobileHide = styled.span`
    @media (max-width: ${mediumScreenUpperLimit}) {
      display: none;
    }
  `;
}
