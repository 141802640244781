// This file's content is copied from https://github.com/tuenti/activity-detector/blob/master/src/activity-detector.js#L18
// then adjusted to run in our app

const ACTIVE = "active";
const IDLE = "idle";

const DEFAULT_INITIAL_STATE = ACTIVE;

const DEFAULT_ACTIVITY_EVENTS = [
  "click",
  "mousemove",
  "keydown",
  "DOMMouseScroll",
  "mousewheel",
  "mousedown",
  "touchstart",
  "touchmove",
  "focus",
];

const DEFAULT_INACTIVITY_EVENTS = ["blur", "visibilitychange"];

const DEFAULT_IGNORED_EVENTS_WHEN_IDLE = ["mousemove"];

/**
 * Creates an activity detector instance
 *
 * @param  {Object}   options
 * @param  {string[]} options.activityEvents        Events which force a transition to 'active'
 * @param  {string[]} options.inactivityEvents      Events which force a transition to 'idle'
 * @param  {string[]} options.ignoredEventsWhenIdle Events that are ignored in 'idle' state
 * @param  {number}   options.timeToIdle            Inactivity time in ms to transition to 'idle'
 * @param  {string}   options.initialState          One of 'active' or 'idle'
 * @param  {boolean}  options.autoInit
 * @return {Object}   activity detector instance
 */
const activityDetector = ({
  activityEvents = DEFAULT_ACTIVITY_EVENTS,
  inactivityEvents = DEFAULT_INACTIVITY_EVENTS,
  ignoredEventsWhenIdle = DEFAULT_IGNORED_EVENTS_WHEN_IDLE,
  timeToIdle = 30000,
  initialState = DEFAULT_INITIAL_STATE,
  autoInit = true,
} = {}) => {
  let hidden, visibilityChangeEvent;
  if (document.hidden === undefined) {
    const prefixes = ["webkit", "moz", "ms"];
    for (const prefix of prefixes) {
      if (document[`${prefix}Hidden`] !== undefined) {
        hidden = `${prefix}Hidden`;
        visibilityChangeEvent = `${prefix}visibilitychange`;
        break;
      }
    }
  } else {
    hidden = "hidden";
    visibilityChangeEvent = "visibilitychange";
  }

  const listeners = { [ACTIVE]: [], [IDLE]: [] };
  let state;
  let timer;

  const setState = (newState) => {
    clearTimeout(timer);
    if (newState === ACTIVE) {
      timer = setTimeout(() => setState(IDLE), timeToIdle);
    }
    if (state !== newState) {
      state = newState;
      for (const l of listeners[state]) l();
    }
  };

  const handleUserActivityEvent = (event) => {
    if (state === ACTIVE || !ignoredEventsWhenIdle.includes(event.type)) {
      setState(ACTIVE);
    }
  };

  const handleUserInactivityEvent = () => {
    setState(IDLE);
  };

  const handleVisibilityChangeEvent = () => {
    setState(document[hidden] ? IDLE : ACTIVE);
  };

  /**
   * Starts the activity detector with the given state.
   * @param {string} firstState 'idle' or 'active'
   */
  const init = (firstState = DEFAULT_INITIAL_STATE) => {
    setState(firstState === ACTIVE ? ACTIVE : IDLE);
    for (const eventName of activityEvents)
      window.addEventListener(eventName, handleUserActivityEvent);

    for (const eventName of inactivityEvents.filter(
      (eventName) => eventName !== "visibilitychange",
    ))
      window.addEventListener(eventName, handleUserInactivityEvent);

    if (
      inactivityEvents.includes("visibilitychange") &&
      visibilityChangeEvent
    ) {
      document.addEventListener(
        visibilityChangeEvent,
        handleVisibilityChangeEvent,
      );
    }
  };

  /**
   * Register an event listener for the required event
   * @param {string} eventName 'active' or 'idle'
   * @param {Function} listener
   */
  const on = (eventName, listener) => {
    listeners[eventName].push(listener);
    const off = () => {
      const index = listeners[eventName].indexOf(listener);
      if (index >= 0) {
        listeners[eventName].splice(index, 1);
      }
    };
    return off;
  };

  /**
   * Stops the activity detector and clean the listeners
   */
  const stop = () => {
    listeners[ACTIVE] = [];
    listeners[IDLE] = [];

    clearTimeout(timer);

    for (const eventName of activityEvents)
      window.removeEventListener(eventName, handleUserActivityEvent);

    for (const eventName of inactivityEvents)
      window.removeEventListener(eventName, handleUserInactivityEvent);

    if (visibilityChangeEvent) {
      document.removeEventListener(
        visibilityChangeEvent,
        handleVisibilityChangeEvent,
      );
    }
  };

  if (autoInit) {
    init(initialState);
  }

  return { on, stop, init };
};

export default activityDetector;
