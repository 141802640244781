import { MOVED_PERMANENTLY } from "../constants";

export class DeckNameChangedError extends Error {
  constructor(private newVanityName: string, private newDeckname: string) {
    super(MOVED_PERMANENTLY);
    this.name = "DeckNameChangedError";

    // Error.captureStackTrace(this, this.constructor);
  }

  getNewVanityName(): string {
    return this.newVanityName;
  }

  getNewDeckname(): string {
    return this.newDeckname;
  }
}

export function isDeckNameChangedError(
  err: unknown,
): err is DeckNameChangedError {
  return (err as DeckNameChangedError).name === "DeckNameChangedError";
}
