import "cross-fetch/polyfill";

import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { SubscriptionClient } from "subscriptions-transport-ws";

import { getGraphqlAPIBaseUrl, getGraphqlWsBaseUrl } from "./env";
import { graphqlOnError } from "./graphqlOnError";
import { graphqlWithAuthToken } from "./graphqlWithAuthToken";
import { isBrowser } from "./helpers/isBrowser";

type WsClient = ApolloClient<NormalizedCacheObject>;
const DEFAULT_KEY = "DEFAULT";
const graphqlClientMap: Record<string, WsClient> = {};

export function getGraphqlClient(
  accessToken?: string,
): ApolloClient<NormalizedCacheObject> {
  const processKey = accessToken || DEFAULT_KEY;

  // cache hit
  if (graphqlClientMap[processKey]) {
    return graphqlClientMap[processKey];
  }

  const httpLink = new HttpLink({
    uri: getGraphqlAPIBaseUrl(),
    useGETForQueries: true,
  });

  if (!isBrowser()) {
    graphqlClientMap[processKey] = new ApolloClient({
      ssrMode: true,
      cache: new InMemoryCache(),
      link: ApolloLink.concat(
        ApolloLink.concat(graphqlWithAuthToken, graphqlOnError),
        httpLink,
      ),
    });

    return graphqlClientMap[processKey];
  }

  const wsClient = new SubscriptionClient(getGraphqlWsBaseUrl(), {
    reconnect: true,
    connectionParams: {
      token: accessToken,
    },
  });

  const wsLink = new WebSocketLink(wsClient);

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink,
  );

  graphqlClientMap[processKey] = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.concat(
      ApolloLink.concat(graphqlWithAuthToken, graphqlOnError),
      splitLink,
    ),
  });

  return graphqlClientMap[processKey];
}
