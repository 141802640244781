import styled from "styled-components";

import { smallScreenUpperLimit } from "../../shared/breakpoints";
import {
  etBrightLightBlue,
  etGray,
  etNevadaGrey,
  etWhite,
} from "../../shared/colors";
import { IconSvgContainer } from "../IconSvg/IconSvg.styled";

export const CARD_OPEN_FOOTER_IMAGE_HEIGHT = 32;
export const CARD_OPEN_FOOTER_IMAGE_WIDHT = 32;

namespace sc {
  export const Container = styled.div`
    position: relative;
    border: none;
    background-color: ${etWhite};
    border-radius: 8px;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.05),
      0px 2px 3px 0px rgba(0, 0, 0, 0.1);
  `;

  export const ImageContainer = styled.a<{ url: string }>`
    margin-right: 10px;
    width: ${CARD_OPEN_FOOTER_IMAGE_WIDHT}px;
    height: ${CARD_OPEN_FOOTER_IMAGE_HEIGHT}px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-image: url(${(props) => props.url});
  `;

  export const ContentText = styled.div`
    width: 632px;
    min-height: 95px;
    max-height: 120px;
    padding: 0 24px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: ${etNevadaGrey};

    margin-top: 7px;

    word-break: break-word;

    @media (max-width: ${smallScreenUpperLimit}) {
      width: 100%;
      padding: 0 25px 0 24px;
      height: unset;
      min-height: 95px;
      max-height: 260px;
    }
  `;

  export const CuratorContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 24px;
    @media (max-width: ${smallScreenUpperLimit}) {
      padding-left: 22px;
    }
  `;

  export const Curator = styled.div`
    height: 14px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${etGray};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `;
  export const CuratorLink = styled.a`
    color: ${etGray};
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    :hover {
      color: ${etGray};
      text-decoration: none;
    }
  `;
  export const SaveCardToComment = styled.button`
    border: none;
    background-color: transparent;
    color: ${etNevadaGrey};
    font-family: Roboto;
    padding: 0;
    font-size: 14px;
    font-weight: 500;

    &:disabled {
      opacity: 0.65;
    }
  `;
  export const ButtonStartCard = styled.button`
    border: none;
    background-color: transparent;
    padding: 0;
    width: 40px;
    height: 40px;
    text-align: center;
    ${IconSvgContainer} {
      margin: auto;
    }
    :hover {
      > ${IconSvgContainer} {
        fill: ${etBrightLightBlue};
        filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.15));
      }
    }
  `;
  export const ContainerAction = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 14px 14px 24px;
    @media (max-width: ${smallScreenUpperLimit}) {
      margin-top: 7px;
    }
    margin-bottom: -8px;
  `;
}
export default sc;
