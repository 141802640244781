/* eslint-disable @typescript-eslint/no-unused-vars */
import uon1 from "../../assets/img/imgUon/1uon.png";
import uon5 from "../../assets/img/imgUon/5uon.png";
import uon10 from "../../assets/img/imgUon/10uon.png";
import uon20 from "../../assets/img/imgUon/20uon.png";
import uon50 from "../../assets/img/imgUon/50uon.png";
import uon100 from "../../assets/img/imgUon/100uon.png";
import uon500 from "../../assets/img/imgUon/500uon.png";
import uon500V2 from "../../assets/img/imgUon/500uon-v2.png";
import uon1000 from "../../assets/img/imgUon/1000uon.png";
import newUon1 from "../../assets/img/imgUon/new1uon.png";
import newUon5 from "../../assets/img/imgUon/new5uons.png";
import newUon10 from "../../assets/img/imgUon/new10uons.png";
import newuons50 from "../../assets/img/imgUon/new50uons.jpg";

export const getImageUonNextImage = (uonCount: number): string => {
  if (uonCount <= 4) {
    return uon1;
  }
  if (uonCount > 4 && uonCount <= 9) {
    return uon5;
  }
  if (uonCount > 9 && uonCount <= 49) {
    return uon10;
  }
  if (uonCount > 49 && uonCount <= 99) {
    return uon50;
  }
  if (uonCount > 99 && uonCount <= 499) {
    return uon100;
  }
  if (uonCount > 499 && uonCount <= 999) {
    return uon500;
  }
  return uon1000;
};

export const getImageUonNextImageForGift = (uonCount: number): string => {
  if (uonCount <= 4) {
    return uon1;
  }
  if (uonCount > 4 && uonCount <= 9) {
    return uon5;
  }
  if (uonCount > 9 && uonCount <= 19) {
    return uon10;
  }
  if (uonCount > 19 && uonCount <= 49) {
    return uon20;
  }
  if (uonCount > 49 && uonCount <= 99) {
    return uon50;
  }
  if (uonCount > 99 && uonCount <= 499) {
    return uon100;
  }
  if (uonCount > 499 && uonCount <= 999) {
    return uon500V2;
  }
  return uon1000;
};

export const getImageUonUpdatePlatform = (uonCount: number): string => {
  if (uonCount <= 4) {
    return newUon1;
  }
  if (uonCount <= 9) {
    return newUon5;
  }
  if (uonCount <= 49) {
    return newUon10;
  }
  if (uonCount <= 99) {
    return newuons50;
  }
  if (uonCount <= 499) {
    return uon100;
  }
  if (uonCount <= 999) {
    return uon500;
  }
  return uon1000;
};
