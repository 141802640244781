import {
  CampaignDefaultStatus,
  ChannelSharing,
} from "../../stores/CampaignDefaultModel";
import { MetaTag } from "../../stores/MetaStore";
import { TokenInterceptorStore } from "../../stores/TokenInterceptorStore";
import { getAPIBaseUrl } from "../env";
import { getGraphqlClient } from "../graphqlClient";
import { getApiMetaUrl } from "../helpers/getApiMetaUrl";
import { isBrowser } from "../helpers/isBrowser";
import { campaignCountUpdatedSubscription } from "./campaignDefaultApiQueries";
import { UnsubscribeFn } from "./UnsubscribeFn";

export interface ISystemDeckApi {
  fetchSharingCampaigngnOrLeaderboardData(
    vanityName: string,
    channel: ChannelSharing,
  ): Promise<MetaTag>;
  subscribeCampaignDefaultCount(
    campaignId: string,
    subscribeFn: (
      error: Error | null,
      uonCount: number,
      status: CampaignDefaultStatus,
    ) => void,
  ): UnsubscribeFn;
}

export class SystemDeckApi implements ISystemDeckApi {
  constructor(private tokenInterceptorStore: TokenInterceptorStore) {}

  fetchSystemDeckData = async (systemDeckTypeParam: string) => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/users/deck-page/${systemDeckTypeParam}`,
    });

    return res.data;
  };

  fetchSharingCampaigngnOrLeaderboardData = async (
    vanityName: string,
    channel: ChannelSharing,
  ): Promise<MetaTag> => {
    const time = Date.now();

    const baseUrl = getApiMetaUrl(vanityName, { channel });

    const res = await this.tokenInterceptorStore.call({
      url: `${baseUrl}&ts=${time}`,
    });

    return res.data.metaTags;
  };

  subscribeCampaignDefaultCount = (
    campaignId: string,
    subscribeFn: (
      error: Error | null,
      uonCount: number,
      status: CampaignDefaultStatus,
    ) => void,
  ): UnsubscribeFn => {
    if (!isBrowser()) {
      return () => {};
    }

    const observable = getGraphqlClient()
      .subscribe<{
        campaignCountUpdated: { count: number; status: CampaignDefaultStatus };
      }>({
        query: campaignCountUpdatedSubscription() as any,
        variables: {
          id: campaignId,
        },
        context: {
          tokenInterceptorStore: this.tokenInterceptorStore,
        },
      })
      .subscribe((result) => {
        if (Array.isArray(result.errors) && result.errors.length > 0) {
          subscribeFn(
            new Error(result.errors.toString()),
            -1,
            CampaignDefaultStatus.State1,
          );
          return;
        }
        subscribeFn(
          null,
          result.data?.campaignCountUpdated.count || 0,
          result.data?.campaignCountUpdated.status ||
            CampaignDefaultStatus.State1,
        );
      });

    return () => observable.unsubscribe();
  };
}
