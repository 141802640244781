/* eslint-disable no-console */
/* eslint-disable consistent-return */
import { ServerError, ServerParseError } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import { TokenInterceptorStore } from "../stores/TokenInterceptorStore";

const MAX_RETRY = 3;

function isServerError(
  networkError: Error | ServerError | ServerParseError,
): networkError is ServerError {
  return (networkError as ServerError).statusCode !== undefined;
}

export const graphqlOnError = onError(
  ({ graphQLErrors, networkError, operation, response, forward }) => {
    if (graphQLErrors)
      for (const { message, locations, path } of graphQLErrors)
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
    if (networkError) console.log(`[Network error]: ${networkError}`);

    if (!response || !networkError) {
      console.error("Empty error response");
      return;
    }

    if (!isServerError(networkError)) {
      return;
    }

    if (networkError.statusCode !== 403) {
      return;
    }

    const context: {
      tokenInterceptorStore: TokenInterceptorStore;
      tryCount?: number;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } = operation.getContext() as any;
    let tryCount = context.tryCount || 0;
    const { tokenInterceptorStore } = context;

    tokenInterceptorStore.reset();

    tryCount += 1;
    if (tryCount >= MAX_RETRY) {
      return;
    }

    // retry
    return forward(operation);
  },
);
