// NOTE : temporary disable these rules for get QR claim code feature out quickly
/* eslint-disable no-useless-constructor */
import { RedeemCodeResponseDto } from "@earthtoday/contract";

import { SocialUserFacebook } from "../../components/ModalSignUpSocialBtnFacebook/ModalSignUpSocialBtnFacebook";
import { TokenInterceptorStore } from "../../stores/TokenInterceptorStore";
import { TokenStore } from "../../stores/TokenStore";
import { getAPIBaseUrl } from "../env";
import { getGraphqlClient } from "../graphqlClient";
import { isBrowser } from "../helpers/isBrowser";
import { ErrorAPIType } from "../helpers/translateApiError";
import { ClaimResponse, ISubscribeCodeInfo } from "../models/Claim";
import { CollectCodeType, IUonCollectedData } from "../models/Events";
import { CookieConsent, User } from "../models/User";
import { snowplowCaptureUonCollectedEvent } from "../snowplow";
import { userUonCardCreatedSubscription } from "./profileApiQueries";
import { UnsubscribeFn } from "./UnsubscribeFn";
import { CookieConsentSignupData } from "./UserSessionApi";

export interface IClaimApi {
  claimCode: (
    codes: string[],
    poolName?: string,
    isRewardEnabled?: boolean,
  ) => Promise<RedeemCodeResponseDto>;

  popClaimCode: (
    poolName: string,
  ) => Promise<{ code: string; brandSlug: string; dry: boolean }>;
  subscribeCodeInfo: (code: string) => Promise<ISubscribeCodeInfo>;

  registerAndClaim: (data: {
    emailAddress: string;
    firstName: string;
    lastName: string;
    preHashedPassword: string;
    consent?: CookieConsent;
    cookieConsentCreatedAt?: string;
    codes: string[];
    isRewardEnabled?: boolean;
    poolName?: string;
  }) => Promise<{
    userResponse: User;
    redeemCodeResponse: ClaimResponse | null;
    redeemCodeError?: {
      id: string;
      type: ErrorAPIType;
      options: Record<string, unknown>;
      status: "ERROR";
      debugMessage: string;
    };
  }>;

  loginWithGoogleAndClaim: (
    token: string,
    consentData: CookieConsentSignupData,
    codes: string[],
    poolName?: string,
    isRewardEnabled?: boolean,
  ) => Promise<{
    userResponse: User;
    redeemCodeResponse: ClaimResponse | null;
    redeemCodeError?: {
      id: string;
      type: ErrorAPIType;
      options: Record<string, unknown>;
      status: "ERROR";
      debugMessage: string;
    };
  }>;

  loginWithFacebookAndClaim: (
    socialUser: SocialUserFacebook.Root,
    consentData: CookieConsentSignupData,
    codes: string[],
    poolName?: string,
    isRewardEnabled?: boolean,
  ) => Promise<{
    userResponse: User;
    redeemCodeResponse: ClaimResponse | null;
    redeemCodeError?: {
      id: string;
      type: ErrorAPIType;
      options: Record<string, unknown>;
      status: "ERROR";
      debugMessage: string;
    };
  }>;

  popSubscribeGiftCode(subscriptionId: string): Promise<{
    code: string;
  }>;

  subscribeOnceUonCardCreated(userId: string): void;
}

export class ClaimApi implements IClaimApi {
  constructor(
    private tokenInterceptorStore: TokenInterceptorStore,
    private tokenStore: TokenStore,
  ) {}

  claimCode = async (
    codes: string[],
    poolName?: string,
    isRewardEnabled?: boolean,
  ): Promise<RedeemCodeResponseDto> => {
    const claimResponse =
      await this.tokenInterceptorStore.tsRestClient.uon.redeemClaimCode({
        body: {
          codes,
          isRewardEnabled: !!isRewardEnabled,
        },
      });

    if (claimResponse.status === 200) {
      return claimResponse.body;
    }

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw claimResponse;
  };

  popClaimCode = async (
    poolName: string,
  ): Promise<{
    code: string;
    brandSlug: string;
    dry: boolean;
    isBypassCookie: boolean;
    outOfStockRedirectPath?: string;
  }> => {
    const res = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/claimcode/pop`,
      data: {
        poolName,
      },
    });

    return res.data;
  };

  popSubscribeGiftCode = async (
    subscriptionId: string,
  ): Promise<{
    code: string;
  }> => {
    const res = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/oneflow/subscriptions/${subscriptionId}/subscribe_codes/distribute`,
    });

    return res.data;
  };

  subscribeCodeInfo = async (code: string): Promise<ISubscribeCodeInfo> => {
    const res = await this.tokenInterceptorStore.call({
      method: "GET",
      url: `${getAPIBaseUrl()}/subscriptions?code=${code}`,
    });

    return res.data;
  };

  registerAndClaim = async (data: {
    emailAddress: string;
    firstName: string;
    lastName: string;
    preHashedPassword: string;
    consent?: CookieConsent;
    cookieConsentCreatedAt?: string;
    codes: string[];
    isRewardEnabled?: boolean;
    poolName?: string;
  }): Promise<{
    userResponse: User;
    redeemCodeResponse: ClaimResponse | null;
    redeemCodeError?: {
      id: string;
      type: ErrorAPIType;
      options: Record<string, unknown>;
      status: "ERROR";
      debugMessage: string;
    };
  }> => {
    const response = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/users/signup_and_collect`,
      data,
    });

    const respHeaders: Record<string, string> = {};
    for (const [key, value] of Object.entries(response.headers)) {
      respHeaders[key] = value.toString();
    }

    this.tokenStore.setTokensFromResponse({ headers: respHeaders });

    return response.data;
  };

  loginWithGoogleAndClaim = async (
    token: string,
    consentData: CookieConsentSignupData,
    codes: string[],
    poolName?: string,
    isRewardEnabled?: boolean,
  ): Promise<{
    userResponse: User;
    redeemCodeResponse: ClaimResponse | null;
    redeemCodeError?: {
      id: string;
      type: ErrorAPIType;
      options: Record<string, unknown>;
      status: "ERROR";
      debugMessage: string;
    };
  }> => {
    const response = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/users/me/logins/signin_and_collect/google`,
      data: {
        accessToken: token,
        ...consentData,
        codes,
        poolName,
        isRewardEnabled,
      },
    });

    const respHeaders: Record<string, string> = {};
    for (const [key, value] of Object.entries(response.headers)) {
      respHeaders[key] = value.toString();
    }

    this.tokenStore.setTokensFromResponse({ headers: respHeaders });

    return response.data;
  };

  loginWithFacebookAndClaim = async (
    socialUser: SocialUserFacebook.Root,
    consentData: CookieConsentSignupData,
    codes: string[],
    poolName?: string,
    isRewardEnabled?: boolean,
  ): Promise<{
    userResponse: User;
    redeemCodeResponse: ClaimResponse | null;
    redeemCodeError?: {
      id: string;
      type: ErrorAPIType;
      options: Record<string, unknown>;
      status: "ERROR";
      debugMessage: string;
    };
  }> => {
    const response = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/users/me/logins/signin_and_collect/facebook`,
      data: {
        accessToken: socialUser._token.accessToken,
        ...consentData,
        codes,
        poolName,
        isRewardEnabled,
      },
    });

    const respHeaders: Record<string, string> = {};
    for (const [key, value] of Object.entries(response.headers)) {
      respHeaders[key] = value.toString();
    }

    this.tokenStore.setTokensFromResponse({ headers: respHeaders });

    return response.data;
  };

  subscribeOnceUonCardCreated = (userId: string): void => {
    if (!isBrowser()) return;

    let disconnect: UnsubscribeFn = () => {};

    const observable = getGraphqlClient()
      .subscribe<{
        userUonCardCreated: {
          id: string;
          collectCodeType: CollectCodeType;
          numberOfUon: number;
          protectedBy: string;
          registeredTo: string;
          sponsoredBy: string;
        };
      }>({
        query: userUonCardCreatedSubscription() as any,
        variables: {
          userId,
        },
        context: {
          tokenInterceptorStore: this.tokenInterceptorStore,
        },
      })
      .subscribe((result) => {
        if (Array.isArray(result.errors) && result.errors.length > 0) {
          return;
        }
        const userUonCardCreated = result.data?.userUonCardCreated;
        if (userUonCardCreated) {
          const {
            numberOfUon,
            collectCodeType,
            protectedBy,
            registeredTo,
            sponsoredBy,
          } = userUonCardCreated;
          const dataEvent: IUonCollectedData = {
            uonValue: numberOfUon,
            codeType: collectCodeType,
            wallet: userId,
            triggerPoint: "collect_page",
            protectedBy,
            sponsoredBy,
            registeredTo,
          };
          snowplowCaptureUonCollectedEvent(dataEvent);
        }
        disconnect();
      });

    disconnect = () => observable.unsubscribe();
  };
}
