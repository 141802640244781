import { action, makeObservable, observable } from "mobx";

export class MockStore {
  @observable mockApiBaseUrl: string;
  @observable mockOptions: string;

  constructor(data: { mockApiBaseUrl: string; mockOptions: string }) {
    this.mockApiBaseUrl = data.mockApiBaseUrl;
    this.mockOptions = data.mockOptions;
    makeObservable(this);
  }

  dehydrate(): { mockApiBaseUrl: string; mockOptions: string } {
    return {
      mockApiBaseUrl: this.mockApiBaseUrl,
      mockOptions: this.mockOptions,
    };
  }

  @action.bound hydrate(data: { mockApiBaseUrl: string; mockOptions: string }) {
    this.mockApiBaseUrl = data.mockApiBaseUrl;
    this.mockOptions = data.mockOptions;
  }
}
