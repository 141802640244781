/* eslint-disable unicorn/filename-case */

import React from "react";

// only use for render translation include html tags
export const DangerouslyHTMLRenderer = function (props: { children: string }) {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: props.children,
      }}
    ></span>
  );
};
