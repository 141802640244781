import { action, makeObservable, observable } from "mobx";

export type IDeviceStore = {
  isWebPSupported: boolean;
  isMobileDevice: boolean;
  isMobileWidth: boolean;
  browserWidth: number;
};

export class DeviceStore implements IDeviceStore {
  @observable isMobileDevice: boolean;

  @observable isWebPSupported: boolean;

  @observable isMobileWidth: boolean;
  @observable browserWidth: number = 0;

  constructor(device: {
    isWebPSupported: boolean;
    isMobileDevice: boolean;
    isMobileWidth: boolean;
  }) {
    this.isWebPSupported = device.isWebPSupported;
    this.isMobileDevice = device.isMobileDevice;
    this.isMobileWidth = device.isMobileWidth;
    makeObservable(this);
  }

  dehydrate(): {
    isWebPSupported: boolean;
    isMobileDevice: boolean;
    isMobileWidth: boolean;
  } {
    return {
      isWebPSupported: this.isWebPSupported,
      isMobileDevice: this.isMobileDevice,
      isMobileWidth: this.isMobileWidth,
    };
  }

  @action.bound hyrate(data: {
    isWebPSupported: boolean;
    isMobileDevice: boolean;
    isMobileWidth: boolean;
  }): void {
    this.isWebPSupported = data.isWebPSupported;
    this.isMobileDevice = data.isMobileDevice;
    this.isMobileWidth = data.isMobileWidth;
  }

  @action updateIsMobileWidth = (b: boolean): void => {
    this.isMobileWidth = b;
    this.isMobileDevice = b;
  };

  @action.bound updateBrowserWidth(w: number): void {
    this.browserWidth = w;
  }
}
