import { logger } from "../requireCrossEnv";

export async function safeAsync<T>(p: Promise<T>): Promise<T | null> {
  try {
    return await p;
  } catch (error) {
    logger.error({ err: error }, "safe async caught error");
    return null;
  }
}
