import { action, computed, makeObservable, observable } from "mobx";

import { IUserSessionStore } from "../components/ModalLogin/UserSessionStore";
import { ITheMessageStore } from "../components/TheMessage/TheMessageStore";
import { IOverlayAboutApi } from "../shared/apis/OverlayAboutApi";
import { FeatureFlaggingData } from "./FeatureFlaggingStore";
import { IModalStore, ModalType } from "./ModalStore";

export class OverlayAboutStore {
  @observable overlayActive: boolean = true;
  constructor(
    private api: IOverlayAboutApi,
    private modalStore: IModalStore,
    private theMessageStore: ITheMessageStore,
    private userSessionStore: IUserSessionStore,
    private featureFlagging: FeatureFlaggingData,
  ) {
    makeObservable(this);
  }

  @computed get isOverlayActive(): boolean {
    if (this.featureFlagging.flags.enableNewHomePage) {
      return false;
    }

    if (this.userSessionStore.hasUserCloseOverlay) {
      return false;
    }
    return this.overlayActive;
  }

  @action.bound openOverlay(): void {
    this.overlayActive = true;
    this.userSessionStore.removeUserCloseOverlay();
    this.userSessionStore.updateHasUserCloseOverlay();
  }

  @action.bound closeOverlay(): void {
    this.overlayActive = false;
    this.userSessionStore.setUserCloseOverlay();
    this.userSessionStore.updateHasUserCloseOverlay();
  }
  @action.bound openModal(t: ModalType): void {
    this.modalStore.openModal(t);
  }

  @action.bound updateOverlayAbout(): void {
    this.userSessionStore.updateHasUserCloseOverlay();
  }
}
