import styled from "styled-components";

import googleIcon from "../../assets/svgs/google-icon.svg";
import {
  etFbPurple,
  etGrayBold,
  etSolitudeLight,
  etWhite,
} from "../../shared/colors";
import { IconSvgContainer } from "../IconSvg/IconSvg.styled";

namespace sc {
  export const ButtonSocialGoogle = styled.button<{ color?: string }>`
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 40px;
    background-color: ${etWhite};
    border: 1px solid ${etSolitudeLight};
    border-radius: 4px;
    font-weight: 500;
    color: ${(props) => props.color || etGrayBold};
    margin-top: 12px;
    padding: 0 12px;
    line-height: 1.2;

    &:hover {
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
        0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    }

    &:disabled {
      opacity: 0.65;
    }
  `;
  export const GoogleIcon = styled.div`
    width: 19px;
    height: 19px;
    margin-right: 12px;
    background-size: 103%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${googleIcon});
  `;
  export const ButtonSocialFacebook = styled(ButtonSocialGoogle)`
    margin-top: 0;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    background-color: ${etFbPurple};
    color: ${etWhite};
    border: none;

    ${IconSvgContainer} {
      margin-right: 12px;
    }
  `;
}

export default sc;
