import { TokenInterceptorStore } from "../../stores/TokenInterceptorStore";
import { getAPIBaseUrl } from "../env";
import { getGraphqlClient } from "../graphqlClient";
import { isBrowser } from "../helpers/isBrowser";
import { IConsumer } from "../models/Consumer";
import {
  globalLeaderboardUpdatedSubscription,
  uonCountUpdatedSubscription,
} from "./protectPageQueries";
import { UnsubscribeFn } from "./UnsubscribeFn";

export interface IOverlayAboutApi {
  subscribeUonCount(
    subscribeFn: (error: Error | null, uonCount: number) => void,
  ): UnsubscribeFn;
  subscribeGlobalLeaderboard(
    subscribeFn: (
      error: Error | null,
      globalLeaderboardUpdated: IConsumer[] | null,
    ) => void,
  ): UnsubscribeFn;
  fetchProtectedCount(): Promise<number>;

  fetchLeaderboardRecent(): Promise<IConsumer[]>;
}

export class OverlayAboutApi implements IOverlayAboutApi {
  constructor(private tokenInterceptorStore: TokenInterceptorStore) {}

  fetchProtectedCount = async (): Promise<number> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/uon/counts`,
    });
    return res.data.uonPreserved;
  };
  fetchLeaderboardRecent = async (): Promise<IConsumer[]> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/uon/leaderboard?type=RECENT&count=50`,
    });
    return res.data;
  };

  subscribeUonCount = (
    subscribeFn: (error: Error | null, uonCount: number) => void,
  ): UnsubscribeFn => {
    if (!isBrowser()) {
      return () => {};
    }

    const observable = getGraphqlClient()
      .subscribe<{ uonCountUpdated: { count: number } }>({
        query: uonCountUpdatedSubscription() as any,
        context: {
          tokenInterceptorStore: this.tokenInterceptorStore,
        },
      })
      .subscribe((result) => {
        if (Array.isArray(result.errors) && result.errors.length > 0) {
          subscribeFn(new Error(result.errors.toString()), -1);
          return;
        }
        subscribeFn(null, result.data?.uonCountUpdated.count || 0);
      });

    return () => observable.unsubscribe();
  };

  subscribeGlobalLeaderboard = (
    subscribeFn: (
      error: Error | null,
      globalLeaderboardUpdated: IConsumer[] | null,
    ) => void,
  ): UnsubscribeFn => {
    if (!isBrowser()) {
      return () => {};
    }

    const observable = getGraphqlClient()
      .subscribe<{ globalLeaderboardUpdated: IConsumer[] | null }>({
        query: globalLeaderboardUpdatedSubscription() as any,
        context: {
          tokenInterceptorStore: this.tokenInterceptorStore,
        },
      })
      .subscribe((result) => {
        if (Array.isArray(result.errors) && result.errors.length > 0) {
          subscribeFn(new Error(result.errors.toString()), null);
          return;
        }
        subscribeFn(null, result.data?.globalLeaderboardUpdated || null);
      });

    return () => observable.unsubscribe();
  };
}
