import styled from "styled-components";

import { etWhiteDark } from "../../shared/colors";

const Container = styled.div`
  background-color: ${etWhiteDark};
  padding: 24px;
`;

export default {
  Container,
};
