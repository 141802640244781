import { setContext } from "@apollo/client/link/context";

import { TokenInterceptorStore } from "../stores/TokenInterceptorStore";

export const graphqlWithAuthToken = setContext(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  async (
    _,
    context: {
      headers: Record<string, string>;
      tokenInterceptorStore: TokenInterceptorStore;
      tryCount: number;
    },
  ) => {
    const { headers, tokenInterceptorStore } = context;

    const token = await tokenInterceptorStore.getToken();

    if (token) {
      return {
        headers: {
          ...headers,
          Authorization: token,
        },
      };
    }

    return {
      headers: {
        ...headers,
      },
    };
  },
);
