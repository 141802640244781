import styled from "styled-components";

import {
  etBlack,
  etDark,
  etLicoriceBlue,
  etNevadaGrey,
  etRegentGray,
  etSilverLight,
  etWhite,
} from "../../shared/colors";

export namespace sc {
  export const Container = styled.div<{
    padding: "20px" | "24px";
  }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
    padding: ${(props) => props.padding};

    color: ${etDark};
  `;

  export const TabContent = styled.div<{
    minWidth: "100%" | "0";
  }>`
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
    height: 25px;
    max-height: 25px;
    min-width: ${(props) => props.minWidth};

    ::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      max-width: 100%;
      max-height: 2px;
      height: 2px;
      background-color: ${etSilverLight};
    }
  `;

  export const Tab = styled.button`
    color: ${etBlack};
    position: relative;
    text-transform: uppercase;
    width: fit-content;
    font-family: Roboto;
    font-stretch: condensed;
    font-size: 14px;
    font-weight: 400;
    background: none;
    border: none;
    padding: 0;
    &.active {
      font-weight: bold;
      border-bottom: 2px solid ${etDark};
    }
  `;

  export const ItemContent = styled.div<{
    gridGap: "12px" | "20px";
  }>`
    display: grid;
    grid-gap: ${(props) => props.gridGap};
    grid-auto-columns: 1fr;
    grid-auto-rows: 40px;
    flex: 1;
    width: 100%;
    max-width: 100%;
    margin-top: 24px;
    overflow: hidden;

    &.scroll {
      overflow: hidden scroll;
      max-height: 300px;
    }
  `;
  export const LeaderboardBtn = styled.button`
    width: 130px;
    max-width: 130px;
    height: 32px;
    max-height: 32px;
    border-radius: 16px;
    background: ${etWhite};
    border: 1px solid ${etRegentGray};
    color: ${etRegentGray};

    :hover {
      background-color: ${etRegentGray};
      color: ${etWhite};
    }
  `;

  export const ShareLeaderboardBtn = styled.button<{
    isLeaderboardCampaignDefault: boolean;
  }>`
    width: 130px;
    max-width: 130px;
    height: 32px;
    max-height: 32px;
    border-radius: 16px;
    background: ${etWhite};
    border: 1px solid ${etRegentGray};
    color: ${etRegentGray};
    font-family: Roboto;
    font-stretch: condensed;
    font-size: ${(props) =>
      props.isLeaderboardCampaignDefault ? "14px" : "16px"};
    text-transform: ${(props) =>
      props.isLeaderboardCampaignDefault ? "uppercase" : "capitalize"};
    :hover {
      background-color: ${etRegentGray};
      color: ${etWhite};
    }
  `;

  export const EmptyLeaderboard = styled.div`
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;
  export const TitleEmpty = styled.div`
    width: 208px;
    max-width: 208px;
    height: 35px;
    max-height: 35px;

    color: ${etLicoriceBlue};
    font-size: 30px;
    font-family: Roboto;
    font-weight: 300;
    text-align: center;
    letter-spacing: -0.5px;
    line-height: 35px;
    margin: 24px 0px 3px 0px;
    & > span {
      font-weight: bold;
    }
  `;

  export const TextEmpty = styled.div`
    width: 272px;
    max-width: 272px;
    height: 30px;
    max-height: 30px;

    color: ${etNevadaGrey};
    font-size: 16px;
    font-family: Roboto;
    font-stretch: condensed;
    font-weight: normal;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0px;
    line-height: 22px;
  `;
}
