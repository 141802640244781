import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Element } from "react-scroll";

import { useTranslation } from "../../shared/translate/NextI18next";
import { Button } from "../Button/Button";
import { DangerouslyHTMLRenderer } from "../DangerouslyHTMLRenderer/DangerouslyHTMLRenderer";
import { PoliciesDriver } from "./Policies";
import sc from "./PoliciesItem.styled";

interface IProps {
  driver: PoliciesDriver;
}

export const PoliciesItemCookieSetting = observer((props: IProps) => {
  const { t } = useTranslation("Policies");

  useEffect(() => {
    props.driver.sectionsRegister({
      key: "cookie-policy",
      label: t("policy.cookie-policy.label"),
    });
  }, [props]);
  return (
    <Element name="cookie-policy">
      <sc.TextHeader>{t("policy.cookie-policy.label")}</sc.TextHeader>
      <sc.TextModified>
        <DangerouslyHTMLRenderer>
          {t("policy.general.text-modified")}
        </DangerouslyHTMLRenderer>
      </sc.TextModified>

      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.cookie-policy.what-are-cookies.text-header")}
        </DangerouslyHTMLRenderer>{" "}
      </sc.TitleSections>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.cookie-policy.what-are-cookies.text-content-1")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.cookie-policy.what-are-cookies.text-content-2")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.cookie-policy.you-choose.text-header")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.cookie-policy.you-choose.text-content-1")}
        </DangerouslyHTMLRenderer>{" "}
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.cookie-policy.you-choose.text-content-2")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>
      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.cookie-policy.you-choose.text-content-3")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.cookie-policy.we-use.text-header")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.cookie-policy.we-use.text-content-1")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.cookie-policy.we-use.text-content-2")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.cookie-policy.we-use.text-content-3")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.cookie-policy.we-use.text-content-4")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.cookie-policy.we-use.text-content-5")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.cookie-policy.we-use.text-content-6-social-cookies")}
        </DangerouslyHTMLRenderer>
        -{" "}
        <DangerouslyHTMLRenderer>
          {t("policy.cookie-policy.we-use.text-content-6")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.cookie-policy.we-use.text-content-7")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.cookie-policy.we-use.text-content-8")}
        </DangerouslyHTMLRenderer>
      </sc.TextContent>

      <sc.TitleSections>
        <DangerouslyHTMLRenderer>
          {t("policy.cookie-policy.contact.text-header")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>

      <sc.TextContent>
        <DangerouslyHTMLRenderer>
          {t("policy.cookie-policy.contact.text-content-1")}
        </DangerouslyHTMLRenderer>{" "}
        <a href="mailto:support@earthtoday.com">support@earthtoday.com</a>.
      </sc.TextContent>

      <sc.TitleSections style={{ color: "#7f868e" }}>
        <DangerouslyHTMLRenderer>
          {t("policy.cookie-policy.cookie-settings.text-header")}
        </DangerouslyHTMLRenderer>
      </sc.TitleSections>

      <div>
        <div>
          <input
            onChange={() => {}}
            disabled
            id="cookie-setting-ess"
            checked
            type="checkbox"
          />
          &nbsp;
          <sc.LabelDisabled htmlFor="cookie-setting-ess">
            <DangerouslyHTMLRenderer>
              {t("policy.cookie-policy.cookie-settings.essential-functional")}
            </DangerouslyHTMLRenderer>
          </sc.LabelDisabled>
        </div>
        <div>
          <input
            onChange={() => {}}
            disabled
            id="cookie-setting-ana"
            checked
            type="checkbox"
          />
          &nbsp;
          <sc.LabelDisabled htmlFor="cookie-setting-ana">
            <DangerouslyHTMLRenderer>
              {t("policy.cookie-policy.cookie-settings.analytics")}
            </DangerouslyHTMLRenderer>
          </sc.LabelDisabled>
        </div>
        <div>
          <input
            onChange={() => {
              props.driver.cookieSettingsChangedUpdate(true);
            }}
            id="cookie-setting-soc"
            type="checkbox"
            name="soc"
            checked={props.driver.cookieSocialAccepted}
          />
          &nbsp;
          <sc.LabelDisabled
            pointer
            data-cy="labelSocialMediaCookies"
            htmlFor="cookie-setting-soc"
          >
            <DangerouslyHTMLRenderer>
              {t("policy.cookie-policy.cookie-settings.social-media")}
            </DangerouslyHTMLRenderer>
          </sc.LabelDisabled>
        </div>
      </div>

      <div className="mt-2 mb-5">
        <Button
          driver={{
            dataCy: "btnSaveCookiesSettings",
            type: "primary",
            width: "120px",
            disabled: !props.driver.cookieSettingsChanged,
            onClick: () => props.driver.onSaveCookieSettingsChanged(),
          }}
        >
          <DangerouslyHTMLRenderer>
            {t("policy.cookie-policy.cookie-settings.save-button")}
          </DangerouslyHTMLRenderer>
        </Button>
      </div>
    </Element>
  );
});
