import { observer } from "mobx-react-lite";

import { etBlack } from "../../shared/colors";
import { CustomConsumer } from "../../shared/models/Consumer";
import { useTranslation } from "../../shared/translate/NextI18next";
import { ILeaderboardModel } from "../../stores/LeaderBoardModel";
import { IconSvg } from "../IconSvg/IconSvg";
import { LeaderboardItem } from "../LeaderboardItem/LeaderboardItem";
import { sc } from "./Leaderboard.styled";

export enum LeaderboardVisibleTab {
  RECENT = "recent",
  TOP = "top",
}

export interface DriverLeaderboardContent {
  readonly scroll: boolean;
  readonly staticDate?: boolean;
  readonly leaderboard: ILeaderboardModel;
  readonly visibleTabs?: LeaderboardVisibleTab[];
  readonly isLeaderboardCampaignDefault?: boolean;
  readonly isLeaderboardProtectPage?: boolean;
  readonly isNPOHeaderCardEmptyLeaderboard?: boolean; //only for leaderboard on npo header card
  readonly dataCy: string;
}

interface IProps {
  driver: DriverLeaderboardContent;
}

export const LeaderboardContent = observer((props: IProps) => {
  const { driver } = props;

  const { t } = useTranslation("Protect");

  return (
    <>
      <sc.TabContent
        data-cy="Leaderboard-TabContent"
        minWidth={
          driver.isLeaderboardCampaignDefault || driver.isLeaderboardProtectPage
            ? "100%"
            : "0"
        }
      >
        {(!driver.visibleTabs ||
          driver.visibleTabs.includes(LeaderboardVisibleTab.RECENT)) && (
          <sc.Tab
            data-cy="Leaderboard-RecentTab"
            className={
              driver.leaderboard.currentTab === "recent" ? "active" : ""
            }
            onClick={() => driver.leaderboard.updateCurrentTab("recent")}
          >
            {t("protect.button.recently-protected")}
          </sc.Tab>
        )}
        {(!driver.visibleTabs ||
          driver.visibleTabs.includes(LeaderboardVisibleTab.TOP)) && (
          <sc.Tab
            data-cy="Leaderboard-MostTab"
            className={driver.leaderboard.currentTab === "top" ? "active" : ""}
            onClick={() => driver.leaderboard.updateCurrentTab("top")}
          >
            {t("protect.button.most-protected")}
          </sc.Tab>
        )}
      </sc.TabContent>
      {driver.isNPOHeaderCardEmptyLeaderboard ? (
        <sc.EmptyLeaderboard data-cy="LeaderboardContent-Empty">
          <IconSvg
            icon="megaphone"
            color={etBlack}
            padding="0"
            width="77px"
            height="60px"
          />
          <sc.TitleEmpty data-cy="LeaderboardContent-TitleEmpty">
            Join.<span>Earth</span>Today
          </sc.TitleEmpty>
          <sc.TextEmpty data-cy="LeaderboardContent-TextEmpty">
            Start protecting
          </sc.TextEmpty>
        </sc.EmptyLeaderboard>
      ) : (
        <sc.ItemContent
          className={driver.scroll ? "scroll" : ""}
          gridGap={
            driver.isLeaderboardCampaignDefault ||
            driver.isLeaderboardProtectPage
              ? "12px"
              : "20px"
          }
          data-cy="Leaderboard-ItemContent"
        >
          {(!driver.visibleTabs ||
            driver.visibleTabs.includes(LeaderboardVisibleTab.RECENT)) &&
            driver.leaderboard.currentTab === "recent" &&
            driver.leaderboard.recentConsumers.map(
              (consumer: CustomConsumer) => (
                <LeaderboardItem
                  dataCy={`${props.driver.dataCy}-LeaderboardItem`}
                  // TODO: revert after fix BE `${consumer.consumerId}_${consumer.date}`
                  key={[
                    consumer.userEarthId || "",
                    consumer.name,
                    consumer.date || "",
                  ].join("")}
                  staticDate={driver.staticDate}
                  driver={{
                    consumer,
                    closeModal: () => driver.leaderboard.closeModal(),
                  }}
                />
              ),
            )}
          {(!driver.visibleTabs ||
            driver.visibleTabs.includes(LeaderboardVisibleTab.TOP)) &&
            driver.leaderboard.currentTab === "top" &&
            driver.leaderboard.topConsumers.map(
              (consumer: CustomConsumer, index) => (
                <LeaderboardItem
                  dataCy={`${props.driver.dataCy}-LeaderboardItem`}
                  // TODO: revert after fix BE `${consumer.consumerId}_${consumer.date}`
                  key={[
                    consumer.userEarthId || "",
                    consumer.name,
                    consumer.consumerId,
                    index,
                    consumer.date || "",
                  ].join("")}
                  staticDate={driver.staticDate}
                  driver={{
                    consumer,
                    closeModal: () => driver.leaderboard.closeModal(),
                  }}
                />
              ),
            )}
        </sc.ItemContent>
      )}
    </>
  );
});
