// noop

// for @nestjs/swagger
export function ApiExtraModels() {}
export function ApiProperty() {}
export function getSchemaPath() {}

// for routing-controllers
export class HttpError {}

// for @nestjs/common
export class HttpException {}
export const HttpStatus = {};

// for class-transformer
export function Type() {}
export function Transform() {}
export function plainToInstance() {}
