import { observer } from "mobx-react-lite";
import dynamic, { LoaderComponent } from "next/dynamic";
import React, { useMemo } from "react";

import { LazyModalWrapper } from "../ModalWrapper/ModalWrapper";
import { TheLoader } from "../TheLoader/TheLoader";

export const DynamicLazyModalLoader = observer(function DynamicLazyModalLoader<
  P,
>(
  props: P & {
    loadComponent: () => LoaderComponent<P>;
  },
) {
  const DynamicComponent = dynamic(props.loadComponent, {
    loading: () => (
      <LazyModalWrapper>
        <TheLoader />
      </LazyModalWrapper>
    ),
  });

  const memoComponent = useMemo(() => {
    return <DynamicComponent {...props} />;
  }, []);

  return memoComponent;
});
