/* eslint-disable consistent-return */
import uon1k from "../../assets/svgs/uon1k.svg";
import uon5k from "../../assets/svgs/uon5k.svg";
import uon10k from "../../assets/svgs/uon10k.svg";

export function getDiamondImg(uonSize: number) {
  if (uonSize <= 999) {
    return;
  }

  if (999 < uonSize && uonSize <= 4999) {
    return uon1k;
  }

  if (4999 < uonSize && uonSize < 9999) {
    return uon5k;
  }

  return uon10k;
}
