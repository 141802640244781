import { gql } from "@ts-gql/tag";

export function brandClaimLeaderboardUpdatedSubscription() {
  return gql`
    subscription brandClaimLeaderboardUpdated($id: ID!) {
      brandClaimLeaderboardUpdated(id: $id) {
        consumerId
        name
        count
        date
        photo {
          id
          url
          awsBucket
          awsKey
          isTransparent
        }
        userEarthId
        isPublished
      }
    }
  ` as import("../../../__generated__/ts-gql/brandClaimLeaderboardUpdated").type;
}

export function campaignLeaderboardUpdatedSubscription() {
  return gql`
    subscription campaignLeaderboardUpdated($id: ID!) {
      campaignLeaderboardUpdated(id: $id) {
        consumerId
        name
        count
        date
        photo {
          id
          url
          awsBucket
          awsKey
          isTransparent
        }
        userEarthId
        isPublished
      }
    }
  ` as import("../../../__generated__/ts-gql/campaignLeaderboardUpdated").type;
}

export function charityReceivedLeaderboardUpdatedSubscription() {
  return gql`
    subscription charityReceivedLeaderboardUpdated($id: ID!) {
      charityReceivedLeaderboardUpdated(id: $id) {
        consumerId
        name
        count
        date
        photo {
          id
          url
          awsBucket
          awsKey
          isTransparent
        }
        userEarthId
        isPublished
      }
    }
  ` as import("../../../__generated__/ts-gql/charityReceivedLeaderboardUpdated").type;
}
