import { isBrowser } from "./isBrowser";

export enum SharingApp {
  WHATSAPP = "WHATSAPP",
  TELEGRAM = "TELEGRAM",
}

export const detectSharingApp = (
  shareText: string,
  shareUrl: string,
  sharingApp: SharingApp,
) => {
  let uri = "";
  let id = "";
  switch (sharingApp) {
    case SharingApp.WHATSAPP: {
      uri = `whatsapp://send/?text=${shareText} ${shareUrl}`;
      id = "wapp-launcher";
      break;
    }
    case SharingApp.TELEGRAM: {
      uri = `tg://msg_url?url=${shareUrl}&text=${shareText}`;
      id = "tg-launcher";
      break;
    }
    default: {
      break;
    }
  }

  const onIE = () => {
    return new Promise((resolve) => {
      (window.navigator as any)?.msLaunchUri(
        uri,
        () => resolve(true),
        () => resolve(false),
      );
    });
  };

  const notOnIE = () => {
    return new Promise((resolve) => {
      const a = document.createElement("a");
      a.id = id;
      a.href = uri;
      a.style.display = "none";
      document.body.append(a);

      const start = Date.now();
      const timeoutToken = setTimeout(() => {
        if (Date.now() - start > 1250) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, 1000);

      const handleBlur = () => {
        clearTimeout(timeoutToken);
        resolve(true);
      };
      window.addEventListener("blur", handleBlur);

      a.click();
    });
  };

  return isBrowser() && (window.navigator as any).msLaunchUri
    ? onIE()
    : notOnIE();
};
