/* eslint-disable @typescript-eslint/no-throw-literal */
// eslint-disable-next-line unicorn/filename-case
import {
  PaymentRegion,
  PaymentRegionChangeSource,
  UserCountsCustomResponseDto,
  UserPaymentRegionResponseDto,
} from "@earthtoday/contract";
import axios, { AxiosResponse } from "axios";

import { SocialUserFacebook } from "../../components/ModalSignUpSocialBtnFacebook/ModalSignUpSocialBtnFacebook";
import { TokenInterceptorStore } from "../../stores/TokenInterceptorStore";
import { TokenStore } from "../../stores/TokenStore";
import { getAPIBaseUrl } from "../env";
import { getGraphqlClient } from "../graphqlClient";
import { CookieConsent, IUserFacebook, User } from "../models/User";
import { ApiOption } from "./options/ApiOption";
import { userMetadataQuery } from "./userQueries";

export interface CookieConsentSignupData {
  consent?: CookieConsent;
  cookieConsentCreatedAt?: string;
}
export enum TokenType {
  USER = "user",
  GROUP = "group",
}
export type TokenSwitchPayload = {
  targetType: TokenType;
  refreshToken: string;
  groupId?: string;
};
export enum UserSocialSignInFlow {
  NAVBAR = "NAVBAR",
  COLLECT = "COLLECT",
  ONEFLOW = "ONEFLOW",
}
export enum UserProviderSocial {
  FACEBOOK = "facebook",
  GOOGLE = "google",
}

export class UserSessionApi {
  constructor(
    private tokenInterceptorStore: TokenInterceptorStore,
    private tokenStore: TokenStore,
  ) {}

  async getFollowingDeckIds(): Promise<string[]> {
    if (this.tokenStore.isAnonymous()) {
      return [];
    }
    throw new Error("Implement this");
  }

  getMe = async (): Promise<User> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/users/me`,
    });

    return res.data;
  };

  login = async (data: {
    emailAddress: string;
    preHashedPassword: string;
  }): Promise<User> => {
    const response = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/users/me/logins/signin`,
      data,
    });

    const respHeaders: Record<string, string> = {};
    for (const [key, value] of Object.entries(response.headers)) {
      respHeaders[key] = value.toString();
    }

    this.tokenStore.setTokensFromResponse({ headers: respHeaders });
    return response.data;
  };

  register = async (data: {
    emailAddress: string;
    firstName: string;
    lastName: string;
    preHashedPassword: string;
    consent?: CookieConsent;
    cookieConsentCreatedAt?: string;
  }): Promise<User> => {
    const response = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/users`,
      data,
    });

    const respHeaders: Record<string, string> = {};
    for (const [key, value] of Object.entries(response.headers)) {
      respHeaders[key] = value.toString();
    }

    this.tokenStore.setTokensFromResponse({ headers: respHeaders });

    return response.data;
  };

  checkIfExistingEmailAddress = async (data: {
    emailAddress: string;
  }): Promise<{ isUsed: boolean }> => {
    const res = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/users/email_addresses/verify`,
      data,
    });

    return res.data;
  };

  getUserSocialInfo = async (
    providerType: UserProviderSocial,
    providerId: string,
  ): Promise<{ isUserExist: boolean; hasEmailAddress: boolean }> => {
    const res = await this.tokenInterceptorStore.call({
      method: "GET",
      url: `${getAPIBaseUrl()}/providers/${providerType}/social_users/${providerId}`,
    });

    return res.data;
  };

  acceptTerm = async (): Promise<User> => {
    const response = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/users/me/terms`,
      data: { accepted: true, version: "1.1.0" },
    });
    const respHeaders: Record<string, string> = {};
    for (const [key, value] of Object.entries(response.headers)) {
      respHeaders[key] = value.toString();
    }

    this.tokenStore.setTokensFromResponse({ headers: respHeaders });

    return response.data;
  };

  requestVerificationEmail = async (oldCode: string): Promise<void> => {
    const res = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/users/me/registrations/email/verification/request`,
      data: { oldCode },
    });

    return res.data;
  };

  verifyEmail = async (code: string): Promise<User> => {
    const response = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/users/me/registrations/email/verification`,
      data: { code },
    });

    const respHeaders: Record<string, string> = {};
    for (const [key, value] of Object.entries(response.headers)) {
      respHeaders[key] = value.toString();
    }

    this.tokenStore.setTokensFromResponse({ headers: respHeaders });

    return response.data;
  };

  facebookConnect = async (
    socialUser: SocialUserFacebook.Root,
    consentData: CookieConsentSignupData,
    options?: { flow?: UserSocialSignInFlow; emailAddress?: string },
  ): Promise<User> => {
    const response = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/users/me/logins/signin/facebook`,
      data: {
        emailAddress: options?.emailAddress,
        flow: options?.flow,
        accessToken: socialUser._token.accessToken,
        ...consentData,
      },
    });

    const respHeaders: Record<string, string> = {};
    for (const [key, value] of Object.entries(response.headers)) {
      respHeaders[key] = value.toString();
    }

    this.tokenStore.setTokensFromResponse({ headers: respHeaders });

    return response.data;
  };

  googleConnect = async (
    token: string,
    consentData: CookieConsentSignupData,
    flow?: UserSocialSignInFlow,
  ): Promise<User> => {
    const response = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/users/me/logins/signin/google`,
      data: {
        flow,
        accessToken: token,
        ...consentData,
      },
    });

    const respHeaders: Record<string, string> = {};
    for (const [key, value] of Object.entries(response.headers)) {
      respHeaders[key] = value.toString();
    }

    this.tokenStore.setTokensFromResponse({ headers: respHeaders });

    return response.data;
  };

  updateCookieContent = async (
    consent: CookieConsent,
  ): Promise<{ cookieConsent: CookieConsent }> => {
    const res = await this.tokenInterceptorStore.call({
      method: "PUT",
      url: `${getAPIBaseUrl()}/users/me/consents/cookies`,
      data: { consent },
    });

    return res.data;
  };

  logout = async (): Promise<AxiosResponse> => {
    const response = await this.tokenInterceptorStore.call({
      method: "PUT",
      url: `${getAPIBaseUrl()}/users/me/logins/signout`,
      data: {},
    });

    return response;
  };

  requestEmailForPasswordReset = async (
    emailAddress: string,
  ): Promise<void> => {
    await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/users/me/registrations/password/forgot`,
      data: { emailAddress },
    });
  };

  verifyRestorePasswordToken = async (
    code: string,
  ): Promise<{ emailAddress: string }> => {
    const resp = await this.tokenInterceptorStore.call({
      method: "GET",
      url: `${getAPIBaseUrl()}/users/me/registrations/password/restore/verify?token=${code}`,
    });

    return resp.data;
  };

  resetPassword = async (data: {
    newPasswordHash: string;
    code: string;
  }): Promise<void> => {
    await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/users/me/registrations/password/restore`,
      data,
    });
  };

  fetchUserCardsMetadata = async (
    cardIds: string[],
  ): Promise<{ id: string; starred: boolean }[]> => {
    const result = await getGraphqlClient().query({
      query: userMetadataQuery() as any,
      context: {
        tokenInterceptorStore: this.tokenInterceptorStore,
      },
      variables: {
        cardIds,
      },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    });

    return result.data.userMetadata.cards;
  };

  fetchUserCounts = async (
    options?: ApiOption,
  ): Promise<UserCountsCustomResponseDto> => {
    const headers: Record<string, string> = {};
    // bypass
    if (options?.byBass) {
      headers["x-apicache-bypass"] = "true";
    }
    // clear existing cache
    if (options?.clearCache) {
      headers["cache-control"] = "max-age=0";
    }

    const res =
      await this.tokenInterceptorStore.tsRestClient.user.userStatistics({
        params: {
          userIdOrVanityName: "me",
        },
        headers,
      });

    if (res.status === 200) {
      return res.body;
    }

    throw res;
  };

  switchToken = async (data: TokenSwitchPayload): Promise<AxiosResponse> => {
    const res = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/token/switch`,
      data,
    });

    return res;
  };
  getUserSocialFacebook = async (
    socialUser: SocialUserFacebook.Root,
  ): Promise<IUserFacebook> => {
    const res = await axios.get(
      `https://graph.facebook.com/v3.1/me?fields=email,name,id,first_name,last_name,picture,middle_name&access_token=${socialUser._token.accessToken}`,
    );

    return res.data;
  };

  detectUserRegion = async (): Promise<UserPaymentRegionResponseDto> => {
    const response =
      await this.tokenInterceptorStore.tsRestClient.payment.detectPaymentRegion(
        { body: { ip: undefined } },
      );

    if (response.status === 200) {
      return response.body;
    }

    return {
      paymentRegion: PaymentRegion.EU,
      paymentRegionChangeSource: PaymentRegionChangeSource.PAGE_LOAD,
    };
  };
}
