import { MetaTag } from "../../stores/MetaStore";
import { TokenInterceptorStore } from "../../stores/TokenInterceptorStore";
import { getAPIBaseUrl } from "../env";

export type ISubscriptionInfo = {
  id: string;
  sponsorBy: {
    id: string;
    vanityName: string;
    fullName: string;
  };
  isSelfRequest: boolean;
};
export interface ISubscriptionApi {
  fetchSubscriptionById(subscriptionId: string): Promise<ISubscriptionInfo>;
  getSubscriptionMetaTags(subscriptionId: string): Promise<MetaTag>;
}

export class SubscriptionApi implements ISubscriptionApi {
  constructor(private tokenInterceptorStore: TokenInterceptorStore) {}

  fetchSubscriptionById = async (
    subscriptionId: string,
  ): Promise<ISubscriptionInfo> => {
    const res: { data: ISubscriptionInfo } =
      await this.tokenInterceptorStore.call({
        url: `${getAPIBaseUrl()}/subscriptions/${subscriptionId}`,
      });

    return res.data;
  };

  getSubscriptionMetaTags = async (
    subscriptionId: string,
  ): Promise<MetaTag> => {
    const res = await this.tokenInterceptorStore.call({
      method: "GET",
      url: `${getAPIBaseUrl()}/subscriptions/${subscriptionId}/metadata`,
    });
    return res.data.metaTags;
  };
}
