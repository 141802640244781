import { observer } from "mobx-react-lite";
import React from "react";

import { theLoaderSc } from "./TheLoader.styled";

interface IProps {
  className?: string;
  loaderSize?: string;
  forcedCenterMiddle?: boolean;
}

export const TheLoader = observer((props: IProps) => {
  return (
    <theLoaderSc.Container className={props.className}>
      <theLoaderSc.Loader
        className="visual-reg-pass"
        size={props.loaderSize || "40px"}
        forcedCenterMiddle={!!props.forcedCenterMiddle}
      />
    </theLoaderSc.Container>
  );
});
