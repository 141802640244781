/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable unicorn/prefer-spread */
import {
  action,
  computed,
  flow,
  IObservableArray,
  makeObservable,
  observable,
} from "mobx";

import { NewCategoryType } from "../components/ModalCreateCard/ModalCreateCardCategories";
import { IUserSessionStore } from "../components/ModalLogin/UserSessionStore";
import { ITheMessageStore } from "../components/TheMessage/TheMessageStore";
import { IAutoplayDeckApi } from "../shared/apis/AutoplayDeckApi";
import { ICreateCardApi } from "../shared/apis/CreateCardApi";
import { translateAPIError } from "../shared/helpers/translateApiError";
import { DeckItemInfo } from "../shared/models/Deck";
import { CardDeckHeaderModel } from "./CardDeckHeaderModel";
import CardDeckOverviewModel from "./CardDeckOverviewModel";
import DeckDetailStore from "./DeckDetailStore/DeckDetailStore";
import FeatureFlaggingStore from "./FeatureFlaggingStore";
import { IModalStore } from "./ModalStore";
import { ProfilePagePresenter } from "./ProfilePagePresenter";

export type AutoplayDeckData = {
  decks: IObservableArray<DeckItemInfo>;
};

export enum AutoplayStatus {
  STARTED = "STARTED",
  STOPPED = "STOPPED",
  CANCELED = "CANCELED",
  NONE = "NONE",
}

export class AutoPlayDeckStore {
  @observable sourceDeck: CardDeckOverviewModel | CardDeckHeaderModel | null =
    null;
  @observable selectedTargetDeckId: string = "";
  @observable showDecks: boolean = false;
  @observable selectedDeckCategoryName: string = "";
  @observable title: string = "autoplay.title";
  @observable description: string = "autoplay.description";
  @observable decks: IObservableArray<DeckItemInfo> = observable<DeckItemInfo>(
    [],
  );
  @observable newPrincipleCategories: IObservableArray<NewCategoryType> =
    observable<NewCategoryType>([]);
  @observable isModalCreateCard: boolean = false;
  constructor(
    private modalStore: IModalStore,
    private createCardAPI: ICreateCardApi,
    private autoplayDeckAPI: IAutoplayDeckApi,
    private theMessageStore: ITheMessageStore,
    private featureFlagStore: FeatureFlaggingStore,
    private userSessionStore: IUserSessionStore,
    private deckDetailStore?: DeckDetailStore,
    private profileStore?: ProfilePagePresenter,
  ) {
    makeObservable(this);
  }

  public dehydrate(): AutoplayDeckData {
    return {
      decks: this.decks,
    };
  }

  @action.bound public hydrate(data: AutoplayDeckData) {
    this.decks.replace(data.decks);
  }

  @computed get sourceDeckId(): string {
    return this.deckDetailStore?.deckDetail?.id || "";
  }

  @action.bound fetchData = flow(function* (this: AutoPlayDeckStore) {
    try {
      const newCategories =
        yield this.createCardAPI.fetchNewPrincipleCategories();
      this.newPrincipleCategories.replace(newCategories);

      if (this.userSessionStore.user) {
        const decks = yield this.autoplayDeckAPI.fetchAutoplayTargetDecks();

        // remove if source deck is belong to current user
        const i = decks.findIndex((d) => d.id === this.sourceDeck?.id);
        if (i > -1) {
          decks.splice(i, 1);
        }
        this.decks.replace(decks);
      }
    } catch (error) {
      const translatedError = translateAPIError(error);
      if (translatedError) {
        this.theMessageStore.showMessage({
          typeMessage: "Error",
          title: "toast-message.general.error",
          content: translatedError,
        });
      }
    }
  });

  @action.bound onClose(): void {
    this.modalStore.openModal("");
    this.selectedTargetDeckId = "";
  }

  @action.bound selectDeck(deckId: string): void {
    this.selectedTargetDeckId = deckId;
    this.showDecks = false;
  }

  @computed get selectedDeck(): DeckItemInfo | undefined {
    if (!this.selectedTargetDeckId) {
      return undefined;
    }

    return this.decks.find((deck) => deck.id === this.selectedTargetDeckId);
  }

  @computed get deckName(): string {
    if (!this.selectedDeck) {
      return "";
    }
    return this.selectedDeck.name;
  }

  @computed get selectedDeckPrinciple() {
    return this.newPrincipleCategories.find(
      (principle) => principle.name === this.selectedDeckCategoryName,
    );
  }

  @computed get selectDeckPlaceholder(): string {
    if (this.decks.length === 0) {
      return "autoplay.create-firt-deck";
    }
    return this.deckName || "autoplay.placeholder";
  }

  @computed get selectedDeckCategory(): NewCategoryType | null {
    if (!this.selectedDeckCategoryName) {
      return null;
    }

    const cat = this.newPrincipleCategories.find(
      (c) => c.name === this.selectedDeckCategoryName,
    );
    if (!cat) {
      return null;
    }

    return cat;
  }

  @computed get isAutoplayBtnActive(): boolean {
    return !!this.selectedDeck;
  }

  @action.bound setShowDecks(b: boolean): void {
    this.showDecks = b;
  }

  @action.bound submitAutoplay = flow(function* (this: AutoPlayDeckStore) {
    if (!this.selectedDeck) {
      this.modalStore.closeLazyModal();
      return;
    }
    this.sourceDeck?.submitAutoplay(
      this.selectedDeck?.id,
      1,
      AutoplayStatus.STARTED,
    );
    this.modalStore.closeLazyModal();
    this.selectedTargetDeckId = "";
  });

  @action updateSourceDeck = (
    deck: CardDeckOverviewModel | CardDeckHeaderModel | null,
  ): void => {
    this.sourceDeck = deck;
  };
}
