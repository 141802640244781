/* eslint-disable import/no-default-export */
import styled, { css } from "styled-components";

import { etBlack, etIceBlue, etWhite } from "../../shared/colors";
import { CardSize } from "../../shared/models/Card";

namespace sc {
  const pTag = styled.p`
    margin: 0;
    padding: 0;
  `;
  const aTag = styled.a<{ color: string }>`
    color: ${(props) => props.color};
    :hover {
      color: ${(props) => props.color};
    }
  `;
  export const Container = styled.div<{
    cardSize: CardSize;
    align: string;
    color: string;
    bgImage?: string;
  }>`
    position: relative;
    height: 496px;
    max-height: 496px;
    background-color: ${etIceBlue};
    background-image: ${(props) =>
      props.bgImage ? `url(${props.bgImage})` : "none"};
    background-position: center;
    background-size: cover;
    border-radius: 8px;
    padding: 12px;
    color: ${(props) => props.color};
    ${(props) => !props.bgImage && `border: 1px solid ${etBlack};`};

    .Button-Wrapper {
      ${(props) =>
        props.cardSize === CardSize.SINGLE
          ? `left: 24px;`
          : props.cardSize === CardSize.DOUBLE
          ? `left: 36px;`
          : `left: 60px;`};

      ${(props) => props.align === "CENTER" && `left: 0`};
    }
  `;
  export const CardLink = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  `;
  export const CardOverLay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: ${etBlack};
    opacity: 0.6;
    z-index: 5;
  `;
  export const CardHeader = styled.div`
    position: relative;
    height: 20px;
    max-height: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 4;
  `;
  export const CardHeaderLeft = styled.div`
    display: flex;
    flex-direction: row;
  `;
  export const Logo = styled.img`
    width: 20px;
    max-width: 20px;
    height: 20px;
    max-height: 20px;
  `;
  export const DeckName = styled.div<{ cardSize: CardSize; color: string }>`
    font-family: Roboto Condensed;
    font-size: 14px;
    font-weight: normal;
    margin-left: 6px;
    color: ${(props) => props.color};
    cursor: pointer;
    ${(props) =>
      props.cardSize === CardSize.SINGLE &&
      css`
        > span {
          max-width: 240px;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      `}
  `;
  export const CardHeaderRight = styled.div``;
  export const ContextMenuWrapper = styled.div`
    position: absolute;
    right: 12px;
    top: 30px;
    z-index: 14;
  `;
  export const ContextMenuFullScreenLayer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 13;
    background-color: ${etBlack};
    opacity: 0;
    cursor: default;
  `;
  export const CardContent = styled.div`
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
  `;
  export const Article = styled.div<{
    textAlign: string;
    padding: string | undefined;
  }>`
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    text-align: ${(props) => props.textAlign};
    ${(props) => props.padding && `padding: 0 ${props.padding}`}
  `;
  export const TitleWrapper = styled.div<{ width: string }>`
    position: relative;
    height: 250px;
    max-height: 250px;
    width: ${(props) => props.width};
  `;
  export const ArticleTitle = styled.h1<{
    fontSize: string;
    lineHeight: string;
  }>`
    width: 100%;
    position: absolute;
    bottom: 0;
    font-family: Roboto;
    font-weight: bold;
    font-size: ${(props) => props.fontSize};
    letter-spacing: -1px;
    line-height: ${(props) => props.lineHeight};
    padding: 0;
    margin: 0;
  `;
  export const ArticleSubtitle = styled(pTag)<{
    width: string;
    fontSize: string;
    lineHeight: string;
  }>`
    margin-top: 20px;
    width: ${(props) => props.width};
    height: 94px;
    max-height: 94px;
    font-family: "Roboto Slab";
    font-size: ${(props) => props.fontSize};
    letter-spacing: 0px;
    line-height: ${(props) => props.lineHeight};
  `;
  export const ButtonWrapper = styled.div<{ align: string }>`
    position: absolute;
    bottom: 44px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: ${(props) => props.align};
    overflow: hidden;
    z-index: 4;
    ${(props) => props.align === "CENTER" && `left: 0`};
  `;
  export const Button = styled.button<{
    shouldHaveBorder: boolean;
    cardSize: CardSize;
    color: string;
  }>`
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 20px;
    background-color: ${etIceBlue};
    font-family: Roboto Condensed;
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    color: ${(props) => props.color};
    padding: 0 20px;
    cursor: pointer;
    ${(props) => props.shouldHaveBorder && `border: 1px solid ${etBlack};`}

    :hover {
      text-decoration: none;
    }

    ${(props) =>
      props.cardSize === CardSize.SINGLE &&
      css`
        > span {
          max-width: 236px;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      `}
  `;
}

export default sc;
