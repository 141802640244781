import { StripeError } from "@stripe/stripe-js";
import axios, { AxiosError } from "axios";

export enum ErrorAPIType {
  UserNotFound = "UserNotFound",
  PasswordRestoreLimitExceeded = "PasswordRestoreLimitExceeded",
  InvalidPasswordRestoringToken = "InvalidPasswordRestoringToken",
  EmailExists = "EmailExists",
  SocialSignupEmailExistsException = "SocialSignupEmailExistsException",
  InvalidCredentialsFormat = "InvalidCredentialsFormat",
  InvalidCredentials = "InvalidCredentials",
  InvalidExternalToken = "InvalidExternalToken",
  IncorrectPassword = "IncorrectPassword",
  VanityNameExists = "VanityNameExists",
  GenericApplicationError = "GenericApplicationError",
  InvalidClaimCode = "InvalidClaimCode",
  CodeAlreadyClaimedError = "CodeAlreadyClaimedError",
  NoPreviewFound = "NoPreviewFound",
  UserEmailNotVerified = "UserEmailNotVerified",
  UserAnonymous = "UserAnonymous",
  DeckNameAlreadyExistsForUser = "DeckNameAlreadyExistsForUser",
  PayloadTooLarge = "PayloadTooLarge",
  TermsNotAccepted = "TermsNotAccepted",
  Blacklisted = "Blacklisted",
  AuthenticationFailed = "AuthenticationFailed",
  CampaignNameAlreadyExistsError = "CampaignNameAlreadyExistsError",
  ValidationError = "ValidationError",
  MaximumNumberOfItemsCreatedError = "MaximumNumberOfItemsCreatedError",
  AccessDeniedError = "AccessDeniedError",
  ProfileUnPublishException = "ProfileUnPublishException",
  UonNotFound = "UonNotFound",
  IllegalState = "IllegalState",
  CodeNotFound = "CodeNotFound",
  CodeUsed = "CodeUsed",
  BadRequest = "BadRequest",
  DeckNotFound = "DeckNotFound",
  ExpiringApiKeyExistedError = "ExpiringApiKeyExistedError",
  CollectionNameAlreadyExistsError = "CollectionNameAlreadyExistsError",
}

export type APIError = AxiosError | StripeError | Error;

type ETErrorResp = {
  id: string;
  options: {};
  status: "ERROR";
  type: string;
};

export function isAxiosError(err: APIError | unknown): err is AxiosError {
  return (err as AxiosError).isAxiosError === true;
}

type TsRestError = {
  status: number;
  body: { type: string; debugMessage?: string; detail?: string };
};

export function isTsRestError(err: APIError | unknown): err is TsRestError {
  return "body" in (err as TsRestError);
}

export function getAPIErrorType(err: AxiosError<ETErrorResp | any>): string {
  if (!err.response || !err.response.data || !err.response.data.type) {
    return "GenericApplicationError";
  }

  return err.response.data.type;
}

export function getAPIErrorMessage(err: AxiosError | TsRestError): string {
  if (isTsRestError(err)) {
    return err.body.debugMessage || err.body.detail || "";
  }

  const { response } = err as any;
  return response?.data?.debugMessage || response?.data?.detail || "";
}

export function getAPIErrorStatusCode(err: AxiosError<unknown>): number {
  if (!err.response) {
    return 400;
  }
  return err.response.status;
}

function parseSocialError(message: string): string {
  const i = message.indexOf("{");
  const j = message.indexOf("}");
  if (i === -1 || j === -1) {
    return "";
  }
  if (j <= i) {
    return "";
  }

  const objStr = message.substring(i, j + 1);
  try {
    const obj = JSON.parse(objStr);
    if (obj.details) {
      return `Social SDK: ${obj.details}`;
    }

    if (obj.error) {
      return `Social SDK: ${obj.error}`;
    }

    return "";
  } catch {
    return "";
  }
}

export const translateAPIError = (err: unknown): string => {
  if (typeof err === "string") {
    return err;
  }

  const error = err as APIError;

  if (error.message && error.message.includes("ORIGINAL ERROR:")) {
    // social error
    const message = parseSocialError(error.message);
    if (message) {
      return message;
    }
  }

  if (axios.isCancel(err)) {
    return "";
  }

  if (isAxiosError(error)) {
    const type = getAPIErrorType(error);

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return translateAPIByErrorType(type, error);
  }
  if (isTsRestError(error)) {
    const errorJson = JSON.parse(JSON.stringify(error));
    const type = errorJson.body.type;

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return translateAPIByErrorType(type, errorJson);
  }

  if (!axios.isCancel(err)) {
    return "";
  }

  const actualErr = error as StripeError;
  return actualErr.message || "";
};

export const translateAPIByErrorType = (
  type: string,
  error: AxiosError<unknown, any> | TsRestError,
) => {
  switch (type) {
    case ErrorAPIType.UserNotFound: {
      return "toast-message.translate-api-error.user-not-found";
    }
    case ErrorAPIType.PasswordRestoreLimitExceeded: {
      return "toast-message.translate-api-error.pw-restore-limit-exceeded";
    }
    case ErrorAPIType.InvalidPasswordRestoringToken: {
      return "toast-message.translate-api-error.invalid-pw-restore-token";
    }
    case ErrorAPIType.EmailExists: {
      return "toast-message.translate-api-error.email-exist";
    }
    case ErrorAPIType.SocialSignupEmailExistsException: {
      return "toast-message.translate-api-error.social-signup-email-exist";
    }
    case ErrorAPIType.InvalidCredentialsFormat: {
      return "toast-message.translate-api-error.invalid-credentials-format";
    }
    case ErrorAPIType.InvalidCredentials: {
      return "These credentials aren’t valid";
    }
    case ErrorAPIType.InvalidExternalToken: {
      return "toast-message.translate-api-error.invalid-external-token";
    }
    case ErrorAPIType.InvalidClaimCode: {
      return "toast-message.translate-api-error.invalid-claim-code";
    }
    case ErrorAPIType.IncorrectPassword: {
      return "toast-message.translate-api-error.incorrect-pw";
    }
    case ErrorAPIType.VanityNameExists: {
      return "toast-message.translate-api-error.exist-vanity-name";
    }
    case ErrorAPIType.GenericApplicationError: {
      const genericMessage = getAPIErrorMessage(error);
      if (genericMessage.startsWith("Unsufficiant stock")) {
        return "toast-message.translate-api-error.unsufficiant-stock";
      }
      return "toast-message.translate-api-error.something-wrong";
    }
    case ErrorAPIType.CodeAlreadyClaimedError: {
      return "toast-message.translate-api-error.code-already-claimed";
    }
    case ErrorAPIType.NoPreviewFound: {
      return "toast-message.translate-api-error.link-parsing-failed";
    }
    case ErrorAPIType.UserEmailNotVerified: {
      return "toast-message.translate-api-error.email-not-verified";
    }
    case ErrorAPIType.UserAnonymous: {
      return "toast-message.translate-api-error.email-not-verified";
    }
    case ErrorAPIType.DeckNameAlreadyExistsForUser: {
      return "toast-message.translate-api-error.exist-deck-name";
    }
    case ErrorAPIType.PayloadTooLarge: {
      return "toast-message.translate-api-error.img-too-large";
    }
    case ErrorAPIType.CampaignNameAlreadyExistsError: {
      return "toast-message.translate-api-error.exist-campaign-name";
    }
    case ErrorAPIType.ValidationError: {
      return "toast-message.translate-api-error.exist-vanity-name";
    }
    case ErrorAPIType.MaximumNumberOfItemsCreatedError: {
      return "toast-message.translate-api-error.maximum-cards";
    }
    case ErrorAPIType.AccessDeniedError: {
      return "toast-message.translate-api-error.access-denied";
    }
    case ErrorAPIType.CodeNotFound: {
      return "toast-message.collect-errors.not-exist";
    }
    case ErrorAPIType.UonNotFound: {
      return "toast-message.collect-errors.not-exist";
    }
    case ErrorAPIType.BadRequest: {
      const genericMessage = getAPIErrorMessage(error);
      if (
        genericMessage.startsWith("Code is") ||
        genericMessage.startsWith("Uon collect response forbidden")
      ) {
        return "toast-message.collect-errors.already-collected-update";
      }
      return "toast-message.translate-api-error.group-not-apply-role";
    }
    case ErrorAPIType.IllegalState: {
      return "toast-message.collect-errors.something-wrong";
    }
    case ErrorAPIType.CodeUsed: {
      return "toast-message.collect-errors.already-collected";
    }
    case ErrorAPIType.ExpiringApiKeyExistedError: {
      return "toast-message.collect-errors.expiring-api-key-exist";
    }
    case ErrorAPIType.CollectionNameAlreadyExistsError: {
      return "toast-message.collect-errors.collection-name-already-exist";
    }
    default: {
      const genericMessage = getAPIErrorMessage(error);
      if (genericMessage.startsWith("Unsufficiant stock")) {
        return "toast-message.translate-api-error.unsufficiant-stock";
      }
      if (genericMessage.startsWith("user is not able to autoplay deck")) {
        return "User is not able to use autoplay deck feature.";
      }
      if (genericMessage.startsWith("autoplay connection between")) {
        return "Autoplay connection between these two decks has been created before.";
      }
      if (genericMessage.startsWith("Subscribe code not found")) {
        return "Subscribe code not found.";
      }
      if (genericMessage.startsWith("user is not able to autoplay deck")) {
        return "User is not able to use autoplay deck feature.";
      }
      if (genericMessage.startsWith("autoplay connection between")) {
        return "Autoplay connection between these two decks has been created before.";
      }
      if (genericMessage.startsWith("user is not able to autoplay deck")) {
        return "User is not able to use autoplay deck feature.";
      }
      if (genericMessage.startsWith("autoplay connection between")) {
        return "Autoplay connection between these two decks has been created before.";
      }
      if (genericMessage.startsWith("Group is not published")) {
        return "toast-message.translate-api-error.group-is-not-published";
      }
      return "toast-message.translate-api-error.something-wrong";
    }
  }
};
