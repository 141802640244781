import { ImageResponseDto } from "@earthtoday/contract";
import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
} from "mobx";

import {
  LeaderBoardRange,
  LeaderBoardRangeDriver,
} from "../components/LeaderBoardRange/LeaderBoardRange";
import {
  PROFILE_AVATAR_HEIGHT_DEFAULT,
  PROFILE_AVATAR_WIDTH_DEFAULT,
} from "../components/TheNavbarControllers/TheNavbarControllers.styled";
import { LIST_PREFIX_KEYS, m2Price } from "../shared/constants";
import { buildNewConsumers } from "../shared/helpers/buildNewConsumers";
import formatCurrency from "../shared/helpers/formatCurrency";
import { formatDateForLeaderBoard } from "../shared/helpers/formatDate";
import { getImageUonUpdatePlatform } from "../shared/helpers/getImageUonNextImage";
import { getResizeImageUrl } from "../shared/helpers/getResizeImageUrl";
import { differenceBy } from "../shared/lodash";
import { IConsumer } from "../shared/models/Consumer";
import { UserType } from "../shared/models/User";
import { DonateStore } from "./DonateStore/DonateStore";
import { IModalStore } from "./ModalStore";

export enum LeaderBoardRangeType {
  ONE_TO_FOUR = 1,
  FIVE_TO_NINE = 5,
  TEN_TO_FOURTY_NINE = 10,
  FIFTY_TO_NINETY_NINE = 50,
}

export enum UonRangeParam {
  ONE_TO_FOUR = "uonRange=1-4",
  FIVE_TO_NINE = "uonRange=5-9",
  TEN_TO_FOURTY_NINE = "uonRange=10-49",
  FIFTY_TO_NINETY_NINE = "uonRange=50-99",
}
export const MAX_RANGE_ITEM = 6;

export class LeaderBoardRangeModel implements LeaderBoardRangeDriver {
  @observable data: IObservableArray<IConsumer> = observable<IConsumer>([]);
  range: LeaderBoardRangeType = LeaderBoardRangeType.ONE_TO_FOUR;
  constructor(
    private modalStore: Pick<IModalStore, "openModal">,
    private featureFlaggingData: {
      leaderboardEffectDuration: number;
    },
    private donationStore: Pick<
      DonateStore,
      | "prepareProtectDonation"
      | "updateTriggerPointDataCy"
      | "paymentRegion"
      | "currency"
    >,
    range: LeaderBoardRangeType,
  ) {
    this.range = range;

    makeObservable(this);
  }

  toJSON(): IConsumer[] {
    return this.data;
  }

  @computed get uonImageUrl(): string {
    return getImageUonUpdatePlatform(this.numberOfUon);
  }

  @action.bound onClickProtectButton = (dataCy: string) => {
    this.donationStore.updateTriggerPointDataCy(dataCy);
    this.donationStore.prepareProtectDonation(this.range);
  };

  @action.bound appendNewItems = (items: IConsumer[]) => {
    const newItems = differenceBy(items, this.data.toJSON(), (item) => {
      return `${item.userEarthId}.${item.count}.${item.date}`;
    }).filter(
      (item) =>
        new Date(item.date || 0).getTime() >=
          new Date(this.data[0]?.date || 0).getTime() &&
        item.type === UserType.CONSUMER,
    );

    if (newItems.length === 0) return;

    const newData = [
      ...newItems.map((item) => {
        item.isWS = true;
        return item;
      }),
      ...this.toJSON(),
    ].splice(0, MAX_RANGE_ITEM);

    this.data.replace(buildNewConsumers(newData));
  };

  @action.bound replaceData(item: IConsumer[]) {
    const newData = [...item, ...this.toJSON()].splice(0, MAX_RANGE_ITEM);

    this.data.replace(buildNewConsumers(newData));
  }

  @action.bound getDonatedDate(item: IConsumer): {
    key: string;
    vars: {
      date: string;
      uonCount: number;
    };
  } {
    return {
      key: "leaderboard.item.protected-on",
      vars: {
        date: formatDateForLeaderBoard(item.date || "", false),
        uonCount: item.count,
      },
    };
  }

  @action.bound getPhotoUrl(photo: ImageResponseDto): string {
    return getResizeImageUrl(photo, {
      width: PROFILE_AVATAR_WIDTH_DEFAULT,
      height: PROFILE_AVATAR_HEIGHT_DEFAULT,
    });
  }

  @computed get UI() {
    return (
      <LeaderBoardRange
        key={LIST_PREFIX_KEYS.leaderboardRange + this.range}
        driver={this}
      />
    );
  }

  @computed get totalPrice(): string {
    const currency = this.donationStore.currency;
    return formatCurrency(this.range * m2Price, { currency });
  }

  get numberOfUon(): number {
    return this.range;
  }

  get isEndWithOne(): boolean {
    // eslint-disable-next-line unicorn/prefer-spread
    const splitNumber = String(this.range).split("");

    return Number(splitNumber[splitNumber.length - 1]) === Number(1);
  }

  get coordinates(): string {
    return "PATHS NATURES GALAS";
  }

  @computed get effectDuration(): number {
    return this.featureFlaggingData.leaderboardEffectDuration || 0;
  }
}
