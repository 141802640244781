import getConfig from "next/config";

import { isBrowser } from "./isBrowser";

export const isNodeEnvProduction = () => {
  if (isBrowser()) {
    return getConfig().publicRuntimeConfig.NODE_ENV === "production";
  }

  return process.env.NODE_ENV === "production";
};
