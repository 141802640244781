import NodeCache from "node-cache";

export interface ICache {
  get<R>(key: string, storeFunction: () => Promise<R>): Promise<R>;
}

export class CacheNoop {
  async get<R>(_: string, storeFunction: () => Promise<R>): Promise<R> {
    return storeFunction();
  }
}

export const instance = new CacheNoop();
