import { action, computed, makeObservable } from "mobx";

import { DynamicLazyModalLoader } from "../components/DynamicModalLoader/DynamicLazyModalLoader";
import { LeaderboardVisibleTab } from "../components/Leaderboard/LeaderboardContent";
import { buildNewConsumers } from "../shared/helpers/buildNewConsumers";
import { CustomConsumer, IConsumer } from "../shared/models/Consumer";
import { IModalStore, LazyModalType } from "./ModalStore";

export type LeaderTab = "recent" | "top";
export enum LeaderboardName {
  Default = "default",
  GoodCompany = "good_company",
}

export interface PageStore {
  readonly visibleTabs: LeaderboardVisibleTab[];
  readonly currentTabLeaderboard: LeaderTab;
  readonly topConsumers: IConsumer[];
  readonly recentConsumers: IConsumer[];
  updateCurrentTab(tab: LeaderTab): void;
}

export type ILeaderboardModel = {
  readonly recentConsumers: CustomConsumer[];
  readonly topConsumers: CustomConsumer[];
  readonly currentTab: LeaderTab;

  closeModal(): void;
  openLazyModal(name: LazyModalType): void;
  updateCurrentTab(tab: LeaderTab): void;
};

export class LeaderboardModel implements ILeaderboardModel {
  constructor(private modalStore: IModalStore, private pageStore: PageStore) {
    makeObservable(this);
  }

  @computed get currentTab(): LeaderTab {
    return this.pageStore.currentTabLeaderboard;
  }

  @computed get topConsumers(): CustomConsumer[] {
    return buildNewConsumers(this.pageStore.topConsumers);
  }

  @computed get recentConsumers(): CustomConsumer[] {
    return buildNewConsumers(this.pageStore.recentConsumers);
  }

  @computed get visibleTabs(): LeaderboardVisibleTab[] {
    return this.pageStore.visibleTabs;
  }

  @action
  updateCurrentTab = (tab: LeaderTab): void => {
    this.pageStore.updateCurrentTab(tab);
  };

  @action closeModal = (): void => {
    this.modalStore.openModal("");
  };

  @action openLazyModal = (name: LazyModalType): void => {
    this.modalStore.openLazyModal({
      name,
      component: (
        <DynamicLazyModalLoader
          loadComponent={() =>
            import("../../src/components/ModalLeaderboard/ModalLeaderboard")
          }
          driver={{
            leaderboardName: LeaderboardName.Default,
            model: this,
          }}
        />
      ),
    });
  };
}
