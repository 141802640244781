import { observer } from "mobx-react-lite";

import { etGray } from "../../shared/colors";
import { LIST_PREFIX_KEYS } from "../../shared/constants";
import { IConsumer } from "../../shared/models/Consumer";
import { IImageCustom } from "../../shared/models/Image";
import { protectflowStarted } from "../../shared/snowplow";
import { useTranslation } from "../../shared/translate/NextI18next";
import { CardUonBlock } from "../CardUonBlock/CardUonBlock";
import { IconSvg } from "../IconSvg/IconSvg";
import { LeaderboardItem } from "./LeaderBoardItem";
import sc from "./LeaderBoardRange.styled";

export interface LeaderBoardRangeDriver {
  readonly data: IConsumer[];
  readonly totalPrice: string;
  readonly coordinates: string;
  readonly numberOfUon: number;
  readonly isEndWithOne: boolean;
  readonly effectDuration: number;
  readonly uonImageUrl: string;
  onClickProtectButton(dataCy: string): void;
  getDonatedDate(item: IConsumer): {
    key: string;
    vars: {
      date: string;
      uonCount: number;
    };
  };
  getPhotoUrl(photo: Pick<IImageCustom, "url">): string;
}

interface IProps {
  driver: LeaderBoardRangeDriver;
}
export const LeaderBoardRange = observer((props: IProps) => {
  const { t } = useTranslation("Protect");

  return (
    <sc.Container
      className="visual-reg"
      data-cy={`LeaderBoardRange-Container-range${props.driver.numberOfUon}`}
    >
      <sc.Context>
        <IconSvg
          icon="more"
          width="18px"
          height="20px"
          padding="0px"
          color={etGray}
          hoverColor={etGray}
          opacity={0.5}
        />
      </sc.Context>

      <sc.Top>
        <CardUonBlock
          driver={props.driver}
          dataCyPrefix="LeaderBoardRange"
          frameSize={132}
          maxSize={144}
          numberSize={31}
          uonBoldTextSize={11}
          uonTextSize={9}
          coordinatesSize={6}
          startHeight={10}
          startWidth={9}
          startPosition={6}
          coordinatesTop={19}
          coordinateRight={4.5}
          uonSizeMargin={6}
          location="leaderboardRange"
        />

        <sc.ProtectBlock>
          <sc.Price data-cy="LeaderBoardRange-Price">
            {props.driver.totalPrice}
          </sc.Price>
          <sc.ProtectButton
            data-cy={`LeaderBoardRange-ProtectButton-range${props.driver.numberOfUon}`}
            data-track-id={`todayDeck_card-${props.driver.numberOfUon}uonLeaderboard_btn-protect`}
            onClick={(e) => {
              props.driver.onClickProtectButton(
                `LeaderBoardRange-ProtectButton-range${props.driver.numberOfUon}`,
              );
              protectflowStarted(e);
            }}
          >
            {t("protect.button.protect")}
          </sc.ProtectButton>
        </sc.ProtectBlock>
      </sc.Top>
      <sc.BodyList data-cy="LeaderBoardRange-BodyList">
        {props.driver.data.map((item) => (
          <LeaderboardItem
            key={
              LIST_PREFIX_KEYS.leaderboardRange +
              item.userEarthId +
              (item.date || item.count)
            }
            data={item}
            driver={props.driver}
          />
        ))}
      </sc.BodyList>
    </sc.Container>
  );
});
