import { action, makeObservable, observable } from "mobx";

import { CardDeckHeaderModel } from "../../stores/CardDeckHeaderModel";
import CardDeckOverviewModel from "../../stores/CardDeckOverviewModel";
import { RootStore } from "../../stores/rootStore";

export interface IModalDeleteDeckStore {
  updateDeckDelete(
    deck: CardDeckOverviewModel | CardDeckHeaderModel | null,
  ): void;
  onDelete(): void;
  onClose(): void;
}
export class ModalDeleteDeckStore implements IModalDeleteDeckStore {
  @observable deck: CardDeckOverviewModel | CardDeckHeaderModel | null = null;

  constructor(private rootStore: RootStore) {
    makeObservable(this);
  }

  @action updateDeckDelete = (
    deck: CardDeckOverviewModel | CardDeckHeaderModel | null,
  ): void => {
    this.deck = deck;
  };

  @action onDelete = (): void => {
    this.deck?.onDeleteDeck();
    this.onClose();
  };

  @action onClose = (): void => {
    this.rootStore.modalStore.openModal("");
  };
}
