/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-underscore-dangle */
import { gql } from "@ts-gql/tag";

export function npoCountUpdatedSubscription() {
  return gql`
    subscription npoCountUpdated($id: ID!) {
      npoCountUpdated(id: $id) {
        count
      }
    }
  ` as import("../../../__generated__/ts-gql/npoCountUpdated").type;
}

export function brandClaimCountUpdatedSubscription() {
  return gql`
    subscription brandClaimCountUpdated($id: ID!) {
      brandClaimCountUpdated(id: $id) {
        count
      }
    }
  ` as import("../../../__generated__/ts-gql/brandClaimCountUpdated").type;
}

export function userProfileCountUpdatedSubscription() {
  return gql`
    subscription userProfileCountUpdated($id: ID!) {
      userProfileCountUpdated(id: $id) {
        count
      }
    }
  ` as import("../../../__generated__/ts-gql/userProfileCountUpdated").type;
}

export function userUonCardCreatedSubscription() {
  return gql`
    subscription userUonCardCreated($userId: ID!) {
      userUonCardCreated(userId: $userId) {
        id
        source
        collectCodeType
        paymentMethod
        numberOfUon
        protectedBy
        registeredTo
        sponsoredBy
      }
    }
  ` as import("../../../__generated__/ts-gql/userUonCardCreated").type;
}

export function promotionCountUpdatedSubscription() {
  return gql`
    subscription promotionCountUpdated($promotionId: ID!) {
      promotionCountUpdated(promotionId: $promotionId) {
        id
        count
      }
    }
  ` as import("../../../__generated__/ts-gql/promotionCountUpdated").type;
}
