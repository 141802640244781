import styled from "styled-components";

import { smallScreenUpperLimit } from "../../shared/breakpoints";

namespace sc {
  export const Container = styled.div<{ spanNumber?: number }>`
    background-color: transparent;
    height: 496px;
    overflow: hidden;

    grid-column: ${(props) => props.spanNumber} span;

    @media (max-width: ${smallScreenUpperLimit}) {
      grid-column: 1;
    }
  `;
}

export default sc;
