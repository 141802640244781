/* eslint-disable unicorn/filename-case */
/* eslint-disable unicorn/no-array-reduce */
/* eslint-disable unicorn/no-array-callback-reference */

const DEFAULT_LOCALE = "en";

const enResources = ((ctx) => {
  let keys = ctx.keys();
  let values = keys.map(ctx);
  return keys.reduce((o, k, i) => {
    o[k.replace("./", "").replace(".json", "")] = values[i];
    return o;
  }, {});
})(require.context(`./public/locales/en/`, true, /\.json$/));

const nlResources = ((ctx) => {
  let keys = ctx.keys();
  let values = keys.map(ctx);
  return keys.reduce((o, k, i) => {
    o[k.replace("./", "").replace(".json", "")] = values[i];
    return o;
  }, {});
})(require.context(`./public/locales/nl-BE/`, true, /\.json$/));

const config = {
  debug: process.env.NODE_ENV === "development",
  i18n: {
    defaultLocale: DEFAULT_LOCALE,
    locales: ["en", "nl-BE"],
    defaultNS: "General",
  },
  localePath: "./public/locales/",
  resources: {
    [DEFAULT_LOCALE]: enResources,
    "nl-BE": nlResources,
  },

  objectNotation: true,
  keySeparator: ".",
  nsSeparator: ":",
};

module.exports = config;
