export const navigatorShare = async (data: ShareData) => {
  if (!data.url) {
    throw new Error("sharing url should not be empty");
  }

  if (window && !!window.navigator.share) {
    const shareData = data;
    console.info(`navigator share :: start`, shareData);
    try {
      await window.navigator.share(shareData);
      console.info("navigator share :: finish");
    } catch (error: any) {
      if (error.name !== "AbortError") {
        throw new Error(error);
      }
    }
    return;
  }
};
