import styled from "styled-components";

import {
  largeScreenLowerLimit,
  mediumScreenLowerLimit,
  xLargeScreenLowerLimit,
} from "../../shared/breakpoints";
import {
  etBrightLightBlue,
  etGray,
  etLicoriceBlue,
  etSilverLight,
  etSolitudeLight,
  etWhite,
} from "../../shared/colors";
import {
  PROFILE_AVATAR_HEIGHT_DEFAULT,
  PROFILE_AVATAR_WIDTH_DEFAULT,
} from "../TheNavbarControllers/TheNavbarControllers.styled";

namespace sc {
  export const Container = styled.div`
    background: ${etWhite};
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.05),
      0px 2px 3px 0px rgba(0, 0, 0, 0.1);

    position: relative;
    display: flex;
    flex-flow: column;

    overflow: hidden;

    border-radius: 8px;
    height: 496px;
    width: 328px;
    max-height: 496px;
    max-width: 328px;

    padding: 12px;
    margin-right: 24px;
  `;

  export const FlexSpaceBetween = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  `;

  export const FlexCenter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
  `;

  export const Top = styled(FlexSpaceBetween)`
    padding-bottom: 16px;

    border-bottom: 1px solid ${etSolitudeLight};
  `;

  export const ProtectBlock = styled.div`
    display: flex;
    flex-flow: column;
  `;

  export const Price = styled.div`
    color: ${etLicoriceBlue};
    font-family: Roboto;
    text-align: center;
    font-weight: bold;

    font-size: 18px;
    height: 18px;
    letter-spacing: 0px;
    line-height: 18px;
    margin-bottom: 12px;
  `;

  export const ProtectButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;

    background: ${etBrightLightBlue};
    border-radius: 23px;
    height: 32px;
    width: 144px;
    max-height: 32px;
    max-width: 144px;

    color: ${etWhite};
    font-family: Roboto Condensed;
    font-weight: normal;
    text-align: center;
    text-transform: uppercase;
    outline: none;
    border: none;

    font-size: 14px;
    letter-spacing: 0px;

    &:hover {
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
        0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    }
  `;

  export const BodyList = styled.div`
    height: 307px;
    width: 304px;
    max-height: 307px;
    max-width: 304px;

    padding-top: 14.5px;

    overflow: hidden;
  `;

  export const Item = styled(FlexSpaceBetween)<{ duration: number }>`
    width: 304px;
    height: 40px;
    max-width: 304px;
    max-height: 40px;
    margin-bottom: 10px;

    display: flex;
    align-items: center;

    @keyframes grow {
      to {
        max-height: 304px;
        opacity: 1;
      }
    }

    &.animate {
      max-height: 0;
      opacity: 0;
      animation: grow ${(props) => props.duration}s ease-in-out forwards;
      -webkit-animation: grow ${(props) => props.duration}s ease-in-out forwards;
    }
  `;

  export const InfoBlock = styled(FlexCenter)`
    flex-flow: row;
  `;

  export const Photo = styled.a<{ photo?: string }>`
    height: ${PROFILE_AVATAR_HEIGHT_DEFAULT}px;
    width: ${PROFILE_AVATAR_WIDTH_DEFAULT}px;
    max-height: 40px;
    max-width: 40px;
    border-radius: 50%;

    background-color: ${etSilverLight};
    ${(props) =>
      props.photo &&
      `background: url('${props.photo}') center center no-repeat`};
    background-size: cover;
  `;

  export const UserPhoto = styled(Photo)`
    margin-right: 12px;
  `;

  export const Name = styled.a`
    color: ${etLicoriceBlue};
    font-family: Roboto;

    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 14px;
    padding-bottom: 2px;

    width: 204px;
    max-width: 204px;

    display: block;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      text-decoration: none;
      color: ${etLicoriceBlue};
    }
  `;

  export const Info = styled.div`
    color: ${etGray};
    font-weight: normal;
    font-family: Roboto Condensed;
    text-transform: uppercase;

    font-size: 10px;
    letter-spacing: 0px;

    > span > sup {
      top: -4px;
      font-size: 8px;
    }
  `;

  export const Context = styled.div`
    position: absolute;

    top: 10px;
    right: 20px;
  `;
}

export default sc;

namespace slider {
  export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;

    width: 100vw;
    min-height: 496px;
    max-height: 496px;

    position: relative;
    grid-column: 4 span;

    height: 100%;
    width: 100%;
    grid-gap: 24px;

    @media (max-width: ${xLargeScreenLowerLimit}) {
      grid-column: 3 span;
    }
    @media (max-width: ${largeScreenLowerLimit}) {
      grid-column: 2 span;
    }
    @media (max-width: ${mediumScreenLowerLimit}) {
      grid-column: 1 span;
    }
  `;

  export const Slider = styled.div`
    min-height: 496px;
    max-height: 496px;
    width: 100%;
    .slick-list {
      overflow-y: visible;
      overflow-x: clip;
    }
    @media (max-width: ${xLargeScreenLowerLimit}) {
      width: 1032px;
    }
    @media (max-width: ${largeScreenLowerLimit}) {
      width: 680px;
    }
    @media (max-width: ${mediumScreenLowerLimit}) {
      width: 328px;
      .slick-list {
        overflow: hidden;
      }
    }
  `;
  export const Arrow = styled.div`
    width: 32px;
    height: 100px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    img {
      width: 100%;
      height: 100%;
    }
  `;

  export const Left = styled(Arrow)`
    left: -54px;
    display: none;

    @media (max-width: ${xLargeScreenLowerLimit}) {
      display: block;
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      display: none;
    }
  `;

  export const Right = styled(Arrow)`
    display: none;
    right: -54px;
    @media (max-width: ${xLargeScreenLowerLimit}) {
      display: block;
    }

    @media (max-width: ${mediumScreenLowerLimit}) {
      display: none;
    }
  `;

  export const NavBottom = styled.div`
    display: none;
    bottom: -24px;
    background: rgb(177, 185, 193);
    border-radius: 0px;
    height: 2px;
    width: 100%;
    @media (max-width: ${mediumScreenLowerLimit}) {
      display: flex;
    }
  `;
}

export { slider };
