import { NextFunction, Request, Response } from "express";

export const SUPPORTED_LOCALES_MAP = {
  en: "en-US",
  "en-US": "en-US",
  nl: "nl-BE",
  "nl-BE": "nl-BE",
};

export const SUPPORTED_LOCALES = ["en", "nl"];

export const DEFAULT_LOCALE = "en-US";
export const USER_LOCALE = "USER_LOCALE";

export class LocaleDetector {
  constructor(
    private supportedLocale: Record<string, string>,
    private defaultLocale: string = "en-US",
  ) {}

  execute = (
    req: Pick<Request, "headers" | "cookies">,
    res: Response,
    next: NextFunction,
  ) => {
    if (req.cookies[USER_LOCALE]) {
      res.setHeader("Content-Language", req.cookies[USER_LOCALE]);
      return next();
    }

    const acceptLanguage = req.headers["accept-language"];

    const allAcceptLocales = this.extractLocales(acceptLanguage);

    let detectedLocale: string = "";

    while (allAcceptLocales.length > 0) {
      const locale = allAcceptLocales.shift();

      if (locale && Object.keys(this.supportedLocale).includes(locale)) {
        detectedLocale = this.supportedLocale[locale];
        break;
      }
    }

    detectedLocale = detectedLocale || this.defaultLocale;

    req.cookies[USER_LOCALE] = detectedLocale;
    res.cookie(USER_LOCALE, detectedLocale);
    res.setHeader("Content-Language", detectedLocale);
    return next();
  };

  private extractLocales(acceptLanguage: string | undefined): Array<string> {
    if (!acceptLanguage) return [this.defaultLocale];

    const result: string[] = [];
    const sliceLocales = acceptLanguage.split(","); // [en-US, vi;q=0.9, nl;q=0.8]

    result.push(sliceLocales[0] || this.defaultLocale);

    for (let i = 1; i < sliceLocales.length; i++) {
      result.push(sliceLocales[i].split(";")[0]);
    }

    return result;
  }
}
