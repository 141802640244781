import { ImageResponseDto } from "@earthtoday/contract";

export type ReserveImageUrls = { jpg: string; webp: string };
export enum UonTransactionType {
  DONATE = "DONATE",
  CLAIM = "CLAIM",
  PRE_ALLOCATION = "PRE_ALLOCATION",
  GIFT_CODE = "GIFT_CODE",
  WRAPPED_CLAIM_CODE = "WRAPPED_CLAIM_CODE",
  WHOLESALE_COLLECT_CODE = "WHOLESALE_COLLECT_CODE",
}

export enum UonTransactionSalesType {
  RETAIL = "RETAIL",
  WHOLESALE = "WHOLESALE",
  PREPAID = "PREPAID",
}
export interface Uon {
  id: string;
  meter: Meter;
  owner?: Consumer; // Consumer who is currently owning the uon
  donatorConsumer?: Consumer; // Consumer who donated/bought the m2
  donatorBrand?: Brand; // Brand who donated/bought the m2
  donatedNpo?: Npo;
  count?: number;
  reserveImageUrl?: string;
  reserveImageUrls?: ReserveImageUrls;
  point: LatLng;
  createdAt: Date;
  type?: UonTransactionType;
  salesType?: UonTransactionSalesType;
  code?: string;
  giftExpiredAt?: string;
}

export interface Meter {
  reserve: UonReserve;
  status: string;
}

export interface UonReserve {
  id: string | number;
  reserveEarthId?: string;
  deckEarthId?: string;
  name: string;
  foundationName?: string;
  alias?: string;
  type?: string;
  country?: string;
  state?: string;
  purpose?: string;
  description?: string;
  funFact?: string;
  uonPurpose?: string;
  sustainableUseType?: string;
  size?: number;
  stock?: number;
  status?: string;
  startPoints?: Geometry;
  owner?: Npo;
  image: ImageResponseDto | null;
  mapAnimationConfig?: string;
}

export interface Consumer {
  id: number;
  alias: string;
  email: string;
  vanityName?: string;
}

export interface Brand {
  id: number;
  name: string;
  vanityName?: string;
  image?: string;
}

export interface LeaderboardEntry {
  name: string;
  count: number;
  date?: string; // date is empty for leaderboard's type === 'TOP'
  imageUrl?: string;
  userEarthId?: string;
}

export enum LeaderboardType {
  RECENT = "RECENT",
  TOP = "TOP",
}

export interface UonCount {
  uonPreserved: number;
}

export enum UonCountFilter {
  USER = "USER",
  ALL = "ALL",
}

export interface Npo {
  id: number;
  email: string;
  name: string;
  state: string;
  vanityName?: string;
  coverImage?: string;
}

interface Geometry {
  type: string;
  coordinates: Point[];
}

export interface LatLng {
  lat: number;
  lng: number;
}

type Point = number[];
