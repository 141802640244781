/* eslint-disable unicorn/text-encoding-identifier-case */
import {
  GroupRoleUpdatePayload,
  GroupRoleUpdateResponseDto,
  ImageResponseDto,
  ImageTarget,
  UserProfilePhotoUploadCommitPayloadDto,
  UserProfilePhotoUploadCommitResponseDto,
} from "@earthtoday/contract";
import { AxiosResponse } from "axios";

import { TokenInterceptorStore } from "../../stores/TokenInterceptorStore";
import { getAPIBaseUrl } from "../env";
import { hashFile } from "../helpers/hashedFile";
import {
  IGroup,
  IGroupCreatePayload,
  IUserVanityNameVerify,
  IUserVanityNameVerifyPayload,
} from "../models/Group";
import { User } from "../models/User";
import { groupProfileMapping, PresignImageResponse } from "./SettingsApi";

export interface IGroupApi {
  createGroup(data: IGroupCreatePayload): Promise<IGroup>;
  presignImage(photo: File): Promise<PresignImageResponse>;
  uploadToS3(url: string, photo: File): Promise<Response>;
  commitUploadImage(
    payload: UserProfilePhotoUploadCommitPayloadDto,
  ): Promise<UserProfilePhotoUploadCommitResponseDto>;
  deletePresignImage(
    uploadToken: string,
    reason: string,
  ): Promise<AxiosResponse>;
  getJoinedGroups(userId: string, filterPublicOnly?: boolean): Promise<User[]>;
  getGroupMembers(groupId: string): Promise<User[]>;
  publishGroup(groupId: string): Promise<AxiosResponse>;
  unpublishGroup(vanityName: string): Promise<AxiosResponse>;
  verifyVanityName(
    payload: IUserVanityNameVerifyPayload,
  ): Promise<IUserVanityNameVerify>;
  applyGroupRole(
    payload: GroupRoleUpdatePayload,
  ): Promise<GroupRoleUpdateResponseDto>;
}

export class GroupApi implements IGroupApi {
  constructor(private tokenInterceptorStore: TokenInterceptorStore) {}

  createGroup = async (data: IGroupCreatePayload): Promise<IGroup> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/groups`,
      method: "POST",
      data,
    });

    return res.data;
  };

  presignImage = async (photo: File): Promise<PresignImageResponse> => {
    const fileName = photo.name;
    const hashedFile = await hashFile(photo);

    const resPresignImage =
      await this.tokenInterceptorStore.tsRestClient.image.presignUpload({
        body: {
          fileName,
          contentType: photo.type,
          target: ImageTarget.USER_PROFILE,
          hashedFile,
        },
      });

    if (resPresignImage.status == 201) {
      return resPresignImage.body;
    }

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw resPresignImage;
  };

  uploadToS3 = async (url: string, photo: File): Promise<Response> => {
    const hashedFile = await hashFile(photo);
    const resUploadToS3 =
      await this.tokenInterceptorStore.callWithoutExtraConfigs({
        method: "PUT",
        headers: {
          "Content-Type": photo.type,
          "Content-Encoding": "UTF-8",
          "Content-MD5": hashedFile,
        },
        data: photo,
        url,
      });
    return resUploadToS3;
  };

  commitUploadImage = async (
    payload: UserProfilePhotoUploadCommitPayloadDto,
  ): Promise<UserProfilePhotoUploadCommitResponseDto> => {
    const commitUpload =
      await this.tokenInterceptorStore.tsRestClient.user.commitUpload({
        body: payload,
      });
    if (
      commitUpload.status == 200 &&
      commitUpload.body.target === ImageTarget.USER_PROFILE
    ) {
      return commitUpload.body;
    }
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw commitUpload;
  };

  deletePresignImage = async (
    uploadToken: string,
    reason: string,
  ): Promise<AxiosResponse> => {
    const res = await this.tokenInterceptorStore.call({
      method: "DELETE",
      url: `${getAPIBaseUrl()}/images/presign`,
      data: {
        uploadToken,
        reason,
      },
    });

    return res;
  };

  getJoinedGroups = async (
    userId: string,
    filterPublicOnly?: boolean,
  ): Promise<User[]> => {
    const res = await this.tokenInterceptorStore.call<User[]>({
      method: "GET",
      url: `${getAPIBaseUrl()}/users/${userId}/groups?filterPublicOnly=${!!filterPublicOnly}`,
    });

    return res.data;
  };

  publishGroup = async (groupId: string): Promise<AxiosResponse> => {
    const res = await this.tokenInterceptorStore.call({
      method: "PUT",
      url: `${getAPIBaseUrl()}/groups/${groupId}/publish`,
    });

    return res;
  };

  unpublishGroup = async (vanityName: string): Promise<AxiosResponse> => {
    const res = await this.tokenInterceptorStore.call({
      method: "DELETE",
      url: `${getAPIBaseUrl()}/administration/users/${vanityName}/publish`,
    });

    return res;
  };

  getGroupMembers = async (groupId: string): Promise<User[]> => {
    const res = await this.tokenInterceptorStore.call<User[]>({
      method: "GET",
      url: `${getAPIBaseUrl()}/groups/${groupId}/members`,
    });

    return res.data;
  };

  verifyVanityName = async (
    payload: IUserVanityNameVerifyPayload,
  ): Promise<IUserVanityNameVerify> => {
    const res = await this.tokenInterceptorStore.call<IUserVanityNameVerify>({
      method: "POST",
      url: `${getAPIBaseUrl()}/users/vanity/verify`,
      data: payload,
    });

    return res.data;
  };

  applyGroupRole = async (
    payload: GroupRoleUpdatePayload,
  ): Promise<GroupRoleUpdateResponseDto> => {
    const resApplyGroupRole =
      await this.tokenInterceptorStore.tsRestClient.admin.applyGroupRole({
        body: {
          ...payload,
          profileType: groupProfileMapping[payload.profileType],
        },
      });

    if (resApplyGroupRole.status == 200) {
      return resApplyGroupRole.body;
    }
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw resApplyGroupRole;
  };
}
