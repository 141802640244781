/* eslint-disable no-restricted-syntax */
import { QueryMetaParams } from "../../stores/ProfilePagePresenter";
import { getAPIBaseUrl } from "../env";
import { isBrowser } from "./isBrowser";

export const getApiMetaUrl = (
  vanityName: string,
  queryMetaParams?: QueryMetaParams,
) => {
  if (!queryMetaParams) {
    return `${getAPIBaseUrl()}/users/${vanityName}?metaTags=${!isBrowser()}`;
  }

  const query: string[] = [];

  for (const key in queryMetaParams) {
    query.push(`${key}=${queryMetaParams[key]}`);
  }

  return `${getAPIBaseUrl()}/users/${vanityName}?metaTags=${!isBrowser()}${
    query.length > 0 ? `&${query.join("&")}` : ""
  }`;
};
