import { ContactData } from "../../stores/ModalContactStore";
import { TokenInterceptorStore } from "../../stores/TokenInterceptorStore";

export default class {
  constructor(private tokenInterceptorStore: TokenInterceptorStore) {}

  sendMailPartner = async (data: ContactData): Promise<ContactData> => {
    const res = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `/api/partner`,
      data,
    });

    return res.data;
  };

  sendMailConnect = async (data: ContactData): Promise<ContactData> => {
    const res = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `/api/connection`,
      data,
    });

    return res.data;
  };
}
