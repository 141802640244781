import { MOVED_PERMANENTLY } from "../constants";

export class VanityNameChangedError extends Error {
  constructor(private newVanityName: string) {
    super(MOVED_PERMANENTLY);
    this.name = "VanityNameChangedError";

    // Error.captureStackTrace(this, this.constructor);
  }

  getNewVanityName(): string {
    return this.newVanityName;
  }
}

export function isVanityNameChangedError(
  err: unknown,
): err is VanityNameChangedError {
  return (err as VanityNameChangedError).name === "VanityNameChangedError";
}
