import styled from "styled-components";

import {
  mediumScreenUpperLimit,
  smallScreenUpperLimit,
} from "../../../shared/breakpoints";
import { etBlack, etBrightLightBlue, etWhite } from "../../../shared/colors";

export namespace sc {
  export const Section = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: ${etWhite};
    height: 1204px;
    border-radius: 32px 32px 0 0;

    @media (max-width: ${mediumScreenUpperLimit}) {
      height: 800px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      height: 550px;
    }
  `;
  export const SectionContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;

    padding-top: 130px;

    @media (max-width: ${smallScreenUpperLimit}) {
      padding-top: 80px;
    }
  `;
  export const Title = styled.h2`
    color: ${etBlack};
    text-align: center;
    font-family: Roboto Slab;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 55px;
    letter-spacing: -0.5px;
    margin-bottom: 95px;

    @media (max-width: ${mediumScreenUpperLimit}) {
      font-size: 40px;
      line-height: 45px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 35px;
    }
  `;
  export const ImageGroup = styled.div`
    display: flex;
    position: relative;
    height: 493.495px;

    @media (max-width: ${mediumScreenUpperLimit}) {
      height: 300px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      height: 150px;
    }
  `;
  export const StepImage = styled.img`
    width: 175px;
    height: 493.495px;

    @media (max-width: ${mediumScreenUpperLimit}) {
      width: 125px;
      height: 300px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      width: 65px;
      height: 150px;
    }
  `;
  export const PhoneImage = styled.img`
    position: relative;
    top: -44px;
    width: 387px;
    height: 703px;

    @media (max-width: ${mediumScreenUpperLimit}) {
      top: -30px;
      width: 215px;
      height: 425px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      top: -15px;
      width: 105px;
      height: 214px;
    }
  `;
  export const DowloadOptionsContainer = styled.div`
    width: 410px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 55px;
    padding-right: 80px;
    margin-bottom: 100px;

    @media (max-width: ${smallScreenUpperLimit}) {
      width: 340px;
      padding-right: 100px;
      margin-top: 30px;

      & > div {
        width: 114px !important;
        height: 36px !important;
      }
    }
  `;
}
