import styled from "styled-components";

import { etGray, etGrayDark } from "../../shared/colors";

namespace sc {
  export const TextHeader = styled.h1`
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
    line-height: 23.44px;
    text-align: left;
    margin-bottom: 0;
  `;

  export const TitleSections = styled.h2`
    font-size: 14px;
    line-height: 20px;
    font-weight: 900;
    margin-bottom: 8px;
    color: ${etGrayDark};
  `;

  export const TextHeaderSmall = styled.h2`
    font-size: 14px;
    font-weight: 900;
    padding-bottom: 14px;
    text-transform: uppercase;
  `;

  export const TextContent = styled.div`
    font-size: 14px;
    padding-bottom: 1rem;
    margin-bottom: 1.1rem;
    color: ${etGrayDark};
  `;

  export const Paragraph = styled.div`
    color: ${etGrayDark};
    margin-bottom: 12px;
  `;

  export const WrapListType = styled.ul`
    padding: 2rem 0 2rem 2.5rem;
  `;
  export const ListStyleTypeUpperAlpha = styled.li`
    list-style-type: upper-alpha;
  `;
  export const ListStyleTypeCircle = styled.li`
    list-style-type: disc;
  `;
  export const ListStyleTypeNumber = styled.li`
    list-style-type: decimal;
  `;
  export const ListStyleTypeCircle2 = styled.li`
    list-style-type: circle;
  `;
  export const ListStyleTypeRoman = styled.li`
    list-style-type: upper-roman;
  `;

  export const LabelDisabled = styled.label<{ pointer?: boolean }>`
    color: ${etGray};
    font-size: 14px;
    cursor: ${(props) => (props.pointer ? "pointer" : "default")};
  `;

  export const TextModified = styled.p`
    color: ${etGray};
    font-size: 14px;

    span {
      font-weight: 900;
    }
  `;

  export const Indent = styled.div`
    margin-left: 12px;
  `;

  export const IndentInline = styled.span`
    margin-right: 12px;
    display: inline-block;
  `;

  export const BlockMB6 = styled.div`
    margin-bottom: 6px;
  `;

  export const TextPointer = styled.span`
    cursor: pointer;
  `;
}

export default sc;
