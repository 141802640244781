import { TokenInterceptorStore } from "../../stores/TokenInterceptorStore";
import { MAIN_DECK_GROUP_NAME } from "../constants";
import { getAPIBaseUrl } from "../env";
import { IDeckExplore } from "../models/DeckExplore";

export default class {
  constructor(private tokenInterceptorStore: TokenInterceptorStore) {}

  fetchDecks = async (): Promise<IDeckExplore[]> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/deckgroups`,
    });

    return res.data.find((deck) => deck.name === MAIN_DECK_GROUP_NAME)?.decks;
  };
}
