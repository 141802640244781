import { isRunningTest } from "../isRunningTest";

export function isValidUrl(string: string): boolean {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[\\:-a-z\\d%_\\.\\,!()'*:@$#\\[\\]=~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_\\.!()'*:@$#\\[\\]~+=-]*)?" + // query string
      "(\\#[-a-z\\d_\\/\\.!()'*:@$#\\[\\]~+=]*)?$",
    "i",
  );

  if (isRunningTest()) {
    return !!pattern.test(string.replace("localhost", "127.0.0.1"));
  }

  return !!pattern.test(string);
}
