/* eslint-disable unicorn/no-array-push-push */
/* eslint-disable unicorn/consistent-function-scoping */
import { action, computed, makeObservable, observable } from "mobx";
import { ReactNode } from "react";

import { CardIntroDriver } from "../components/CardIntro/CardIntro";
import { IUserSessionStore } from "../components/ModalLogin/UserSessionStore";
import { SystemPageTabsDriver } from "../components/SystemPagesTabs/SystemPagesTabs";
import { FeatureFlaggingData } from "./FeatureFlaggingStore";
import { MobileMenuStore } from "./MobileMenuStore/MobileMenuStore";
import { IModalStore } from "./ModalStore";
import { SystemDeckParam } from "./SystemDeckStore";

export type IWhiteBannerInfo = {
  logo: string;
  title: string;
  subTitle: string;
  guideBtnHref: string;
  blueBtnTitle: string;
  blueBtnHref: string;
};

export type ISecondTabData = {
  href: string;
  dataCy: string;
  title: ReactNode;
};

export enum NamePage {
  Keys = "keys",
  Nature = "nature",
  Spirit = "spirit",
  World = "world",
  Creation = "creation",
  People = "people",
  Today = "today",
  AboutEarthToday = "aboutearthtoday",
  Play = "play",
  UonEarth = "uonearth",
}

export type NameSystemPage = NamePage | SystemDeckParam | null;
export class OverlaySystemPagePresenter
  implements CardIntroDriver, SystemPageTabsDriver
{
  @observable systemPage: NameSystemPage = null;

  @observable vanityName: string = "";

  @observable isHomePage: boolean = false;
  @observable isPlayPage: boolean = false;

  constructor(
    private userSessionStore: IUserSessionStore,
    private modalStore: IModalStore,
    private featureFlagging: FeatureFlaggingData,
    private mobileMenuStore: MobileMenuStore,
  ) {
    makeObservable(this);
  }

  @action.bound updateSystemPage(page: NameSystemPage): void {
    this.systemPage = page;
  }

  @observable systemPageName: string = "";
  @action.bound updateSystemPageName(name: string): void {
    this.systemPageName = name;
  }

  @action.bound openModalDonate(): void {
    this.modalStore.openModal("donate");
  }

  @action.bound openModalCreateCard(): void {
    if (!this.userSessionStore.user) {
      this.modalStore.openModal("loginForm");
      return;
    }
    this.modalStore.openModal("cardCreate");
  }

  @action.bound
  updateSystemPageTabs = (
    deckLinkName: NameSystemPage = null,
    vanityName: string = "",
    isHomePage: boolean = false,
    isPlayPage: boolean = false,
  ) => {
    this.vanityName = vanityName;
    this.updateSystemPage(deckLinkName || null);
    this.isHomePage = isHomePage;
    this.isPlayPage = isPlayPage;
  };

  @computed get isOverlayTodayActive(): boolean {
    return (
      this.isHomePage ||
      !(this.vanityName === "earthtoday" && this.systemPage === NamePage.Today)
    );
  }

  @computed get shouldRenderNewMobileMenu(): boolean {
    return this.mobileMenuStore.shouldRenderNewMobileMenu;
  }

  @computed get isSystemPageTabsActive(): boolean {
    if (this.vanityName === "earthtoday") {
      // anydeck pages that we have for the EarthToday account
      return true;
    }

    if (
      !(Object.values(NamePage) as string[]).includes(this.systemPage || "") ||
      !this.isOverlayTodayActive
    ) {
      return false;
    }

    return true;
  }

  @computed get isSystemDeck(): boolean {
    if (
      (Object.values(SystemDeckParam) as string[]).includes(
        this.systemPage || "",
      ) ||
      this.systemPage === NamePage.AboutEarthToday
    ) {
      return true;
    }
    return false;
  }

  @computed get isChannelPage(): boolean {
    if (
      !(Object.values(NamePage) as string[]).includes(this.systemPage || "") ||
      this.systemPage === NamePage.Today ||
      this.systemPage === NamePage.AboutEarthToday
    ) {
      return false;
    }
    return true;
  }

  @computed get isChannelPagesUpdate(): boolean {
    return this.isChannelPage;
  }

  @computed get whiteBannerInfo(): IWhiteBannerInfo {
    switch (this.systemPage) {
      case SystemDeckParam.NPO: {
        return {
          logo: require("../assets/svgs/whitebanner-npo-logo.svg"),
          title: "systempagetabs.npo.title",
          subTitle: "systempagetabs.npo.sub-title-update",
          guideBtnHref: "https://protect.earthtoday.com",
          blueBtnTitle: "systempagetabs.start-protecting",
          blueBtnHref: "https://protect.earthtoday.com/",
        };
      }
      case SystemDeckParam.BRAND: {
        return {
          logo: require("../assets/svgs/whitebanner-brands-logo.svg"),
          title: "systempagetabs.brand.title-update",
          subTitle: "",
          guideBtnHref: "https://brands.earthtoday.com",
          blueBtnTitle: "Become a partner",
          blueBtnHref: "https://brands.earthtoday.com",
        };
      }
      case SystemDeckParam.PROTECTOR: {
        return {
          logo: require("../assets/svgs/whitebanner-topprotectors-logo.svg"),
          title: "systempagetabs.protector.title-update",
          subTitle: "",
          guideBtnHref: "https://protect.earthtoday.com",
          blueBtnTitle: "systempagetabs.become-member",
          blueBtnHref: this.userSessionStore.user
            ? `/${this.userSessionStore.user.vanityName}`
            : "#",
        };
      }
      default: {
        return {
          logo: "",
          title: "",
          subTitle: "",
          guideBtnHref: "",
          blueBtnTitle: "",
          blueBtnHref: "",
        };
      }
    }
  }

  @action.bound openModalSignUp(): void {
    if (
      this.systemPage === SystemDeckParam.PROTECTOR &&
      !this.userSessionStore.user
    ) {
      this.modalStore.openModal("signup");
    }
  }

  @action.bound closeCardIntro(page: string): void {
    this.userSessionStore.setUserCloseCardIntro(page);
  }
  @computed get flagCharitiesTab(): boolean {
    return this.featureFlagging.flags.enableCharitiesTab;
  }
  @action.bound updatePagesClosedCardIntro(): void {
    this.userSessionStore.updatePagesClosedCardIntro();
  }

  @computed get isVisibleIntroCard(): boolean {
    if (
      this.userSessionStore.disabledCardIntroHistory.includes(
        `${this.systemPage}`,
      ) ||
      this.isCardIntroRemoved
    ) {
      return false;
    }
    return true;
  }
  @computed get tabName(): string {
    if (!this.systemPage) return "";

    switch (this.systemPage) {
      case NamePage.Today: {
        return "systempagetabs.today";
      }

      case NamePage.AboutEarthToday: {
        return "systempagetabs.about";
      }

      case SystemDeckParam.NPO: {
        return "systempagetabs.protect";
      }

      case SystemDeckParam.PROTECTOR: {
        return "systempagetabs.top-protectors";
      }

      case SystemDeckParam.BRAND: {
        return "systempagetabs.brands";
      }

      case NamePage.Play: {
        return "systempagetabs.play";
      }

      case NamePage.UonEarth: {
        return "systempagetabs.uonearth";
      }

      default: {
        return this.systemPageName || this.systemPage;
      }
    }
  }

  @computed get oneMinGuideHref(): string {
    switch (this.systemPage) {
      case NamePage.AboutEarthToday: {
        return "https://about.earthtoday.com/";
      }

      case SystemDeckParam.BRAND: {
        return "https://brands.earthtoday.com/";
      }

      case SystemDeckParam.PROTECTOR: {
        return "https://protect.earthtoday.com/";
      }

      case SystemDeckParam.NPO: {
        return "https://protect.earthtoday.com/";
      }

      default: {
        return "https://play.earthtoday.com/";
      }
    }
  }
  @computed get isShowBecomeBtn(): boolean {
    if (this.isCardIntroRemoved) return false;
    return (
      this.systemPage !== NamePage.Today &&
      this.systemPage !== NamePage.AboutEarthToday &&
      !this.isVisibleIntroCard
    );
  }
  @computed get isCardIntroRemoved(): boolean {
    return (
      this.systemPage === SystemDeckParam.BRAND ||
      this.systemPage === SystemDeckParam.NPO ||
      this.systemPage === SystemDeckParam.PROTECTOR
    );
  }
}
