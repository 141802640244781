import {
  CardPromotionRepostResponseDto,
  DeckDetailResponseDto,
  ImageResponseDto,
} from "@earthtoday/contract";

import { CategoryPrincipleType } from "../../../__generated__/ts-gql/@schema";
import { ActionResource } from "../../components/CardItemAction/CardItemAction";
import { Viewport } from "../../components/OpenCardTypeInfo/findViewport";
import { Deck } from "./Deck";
import { User } from "./User";

export type CardBaseModelContentType =
  | CardType.LINK
  | CardType.LINK_REPOST
  | CardType.CAMPAIGN
  | CardType.REMOVEDCONTENT
  | CardType.USER;

export type CardBaseRegular = {
  id: string;
  contentType: CardBaseModelContentType;
  deck?: Deck; // TODO: any type is for deck id. Need to use other model for such case.

  featured?: boolean;

  state?: string;
  draft?: boolean;
  curator: CuratorData;
  comment?: string;
  content: ContentData;
  posterType?: PosterType;

  metadata?: MetaData;
  published?: string;
  updated: string;
  categoryPrinciples: string[];
  featuredForProfile?: boolean;
  size?: CardSize;
  metaTags?: { [tagName: string]: string };
  valid?: boolean;
  layoutType?: CardArticleLayoutType;
  cardAutoplayId?: string;
};

export type AutoplaySourceCard = Omit<CardBaseRegular, "contentType"> & {
  contentType: CardType.LINK | CardType.LINK_REPOST | CardType.PROFILE_REPOST;
};

export type AutoPlayFollowing = Omit<CardBaseRegular, "contentType"> & {
  contentType: CardType.LINK | CardType.LINK_REPOST;
};

export type CardDeckRepost = Omit<CardBaseRegular, "contentType"> & {
  contentType: CardType.DECK_REPOST;
  deckRepost: DeckDetailResponseDto;
  deckRepostId: string;
};

export type CardProfileRepost = Omit<CardBaseRegular, "contentType"> & {
  contentType: CardType.PROFILE_REPOST;
  profileRepost: User;
};

export type CardAutoplay = Omit<CardBaseRegular, "contentType"> & {
  contentType: CardType.AUTOPLAY;
  autoplaySourceCard?: AutoplaySourceCard;
};

export type CardAutoplayFollowingPersonalization = Omit<
  CardBaseRegular,
  "contentType"
> & {
  contentType: CardType.AUTOPLAY_FOLLOWING_PERSONALIZATION;
  autoplayFollowingPersonalization?: AutoPlayFollowing;
};

export enum CardTextAlignment {
  LEFT = "LEFT",
  CENTER = "CENTER",
}

export enum CardTextColor {
  WHITE = "WHITE",
  BLACK = "BLACK",
}

export type CardAction = {
  id: string;
  contentType: CardType.ACTION;
  title?: string;
  subtitle?: string;
  backgroundImage?: ImageResponseDto;
  buttonText?: string;
  state: CardState;
  textAlignment: CardTextAlignment;
  textColor: CardTextColor;
  size: CardSize;
  publishedAt?: string;
  deck?: Pick<Deck, "id" | "name" | "onFeed" | "path">;
  resource: ActionResource;
  curator: CuratorData;
};

export type CardInfo = {
  id: string;
  contentType: CardType.INFO;
  principleName: CategoryPrincipleType;
  title: string;
  subtitle?: string;
  backgroundImage?: ImageResponseDto;
  backgroundColor?: string;
  enableOpenCardView: boolean;
  textAlignment: CardTextAlignment;
  textColor: CardTextColor;
  size: CardSize;
  state: CardState;
  published?: string;
  deck?: Pick<Deck, "id" | "name" | "onFeed" | "path">;
  curator: CuratorData;
  htmlBundleMetadata: {
    viewports: Viewport[];
  } | null;
  metaTags?: { [tagName: string]: string };
  updatedAt?: string;
};

export type CardRegular =
  | CardBaseRegular
  | CardDeckRepost
  | CardProfileRepost
  | CardAutoplay
  | CardAutoplayFollowingPersonalization;

export type Card =
  | CardRegular
  | CardAction
  | CardInfo
  | CardPromotionRepostResponseDto;

export type ICardSimpleContent =
  | ExternalArticleContentData
  | MusicContentData
  | WikiContentData
  | VideoContentData;

export interface ICardItem {
  id: string;
  state?: string;
  draft?: boolean;
  curator: CuratorData;
  comment?: string;
  content: ICardSimpleContent;

  posterType?: PosterType;
  deck?: Deck; // TODO: any type is for deck id. Need to use other model for such case.
  metadata?: MetaData;
  published?: string;
  updated: string;
  featured?: boolean;
  featuredForProfile?: boolean;
  size?: CardSize;
  metaTags?: { [tagName: string]: string };
  valid?: boolean;
}

export interface ICardFeatures {
  id: string;
  state?: string;
  draft?: boolean;
  curator: CuratorData;
  comment?: string;
  posterType?: PosterType;
  deck?: Deck; // TODO: any type is for deck id. Need to use other model for such case.
  metadata?: MetaData;
  published?: string;
  updated: string;
  categories?: string[];
  featured?: boolean;
  featuredForProfile?: boolean;
  size?: CardSize;
  metaTags?: { [tagName: string]: string };
  content: ICardFeaturesContent;
}

export type ICardFeaturesContent =
  | ExternalArticleContentData
  | MusicContentData
  | WikiContentData
  | VideoContentData
  | LinkRequestData
  | RepostUserContentData;

export enum PosterType {
  CURATOR = "curator",
  ORIGINAL = "original",
  REPOSTER = "reposter",
}

export enum CardSize {
  SINGLE = "single",
  DOUBLE = "double",
  TRIPLE = "triple",
  HEADER = "header",
}

export enum CardArticleLayoutType {
  EDITORIAL = "EDITORIAL",
  PHOTO = "PHOTO",
  TEXT = "TEXT",
}

export enum MasterLinkType {
  LINK_REPOST = "link_repost",
  ARTICLE = "article",
  IMAGE = "image",
  MUSIC = "music",
  VIDEO = "video",
  WIKI = "wiki",
  REMOVED = "removed", // special value only
}

export interface RelatedCards {
  singleDeck: boolean;
  cards: Card[];
}

export interface RepostedCards {
  cards: Card[];
}

export interface CuratorData {
  id: string;
  name: string;
  vanityName: string;
  image: ImageResponseDto;
  decks?: Deck[]; // TODO Don't use it yet
  currentUser: boolean;
}

export type Vote = null | "UP" | "DOWN";
export type VoteResponse = {
  vote: Vote;
  voteSum: number;
};

export interface MetaData {
  starred: boolean;

  // need implement from back-end
  vote?: Vote;
  votesSum?: number;
  reposted?: boolean;
  repostsCount?: number;
  numberVoteUp?: number;
  numberVoteDown?: number;
  numberShare?: number;
  numberSave?: number;
  numberComment?: number;
}

export type ContentData =
  | ExternalArticleContentData
  | ImageContentData
  | MusicContentData
  | VideoContentData
  | WikiContentData
  | RepostContentData;

export interface AuthorData {
  username: string;
  name: string;
  url: string;
  source: SourceData;
  internal: boolean;
}

export interface SourceData {
  name: string;
  icon: string;
  description: string;
  url: string;
}

export interface ExternalArticleContentData extends LinkData {
  id: string;
  type: MasterLinkType.ARTICLE;
  authors: AuthorData[];
  url: string;
}

export enum CardType {
  LINK = "LINK",
  LINK_REPOST = "LINK_REPOST",
  DECK_REPOST = "DECK_REPOST",
  PROFILE_REPOST = "PROFILE_REPOST",
  AUTOPLAY = "AUTOPLAY",
  CAMPAIGN = "CAMPAIGN",
  REMOVEDCONTENT = "REMOVEDCONTENT",
  USER = "USER",
  AUTOPLAY_FOLLOWING_PERSONALIZATION = "AUTOPLAY_FOLLOWING_PERSONALIZATION",
  ACTION = "ACTION",
  INFO = "INFO",
  PROMOTION = "PROMOTION",
  PROMOTION_REPOST = "PROMOTION_REPOST",
}

export type CardTypeRegular =
  | CardType.LINK
  | CardType.LINK_REPOST
  | CardType.DECK_REPOST
  | CardType.PROFILE_REPOST
  | CardType.AUTOPLAY
  | CardType.CAMPAIGN
  | CardType.REMOVEDCONTENT
  | CardType.USER
  | CardType.AUTOPLAY_FOLLOWING_PERSONALIZATION;

export interface LinkData {
  icon: string;
  title: string;
  fontFamily: string;
  summary: string;
  image: ImageResponseDto;
  source: {
    id: string;
    name: string;
    icon: string;
    url: string;
  };
}

export interface ImageContentData extends ImageResponseDto {
  id: string;
  type: MasterLinkType.IMAGE;
  title: string;
  source?: SourceData;
}

export interface MusicContentData {
  id: string;
  type: MasterLinkType.MUSIC;
  title: string;
  url: string;
  image: ImageResponseDto;
  summary?: string;
  source?: SourceData;
  authors: AuthorData[];
}

export interface WikiContentData {
  id: string;
  type: MasterLinkType.WIKI;
  summary?: string;
  title: string;
  url: string;
  image: ImageResponseDto;
  source?: SourceData;
}

export interface RepostContentData {
  type: MasterLinkType.LINK_REPOST;
  isRepost?: boolean;
  id: string;
  comment?: string;
  original?: CardRegular;
  repost?: {
    id: string;
    comment?: string;
    curator?: CuratorData;
    published?: string;
    updated?: string;
  };
}

export interface RepostUserContentData {
  type: CardType.LINK_REPOST;
  isRepost?: boolean;
  id: string;
  comment?: string;
  original?: ICardFeatures;
  repost?: {
    id: string;
    comment?: string;
    curator?: CuratorData;
    published?: string;
    updated?: string;
  };
}

export interface VideoContentData {
  id: string;
  type: MasterLinkType.VIDEO;
  url: string;
  title: string;
  summary?: string;
  image?: ImageResponseDto;
  source?: SourceData;
}

export interface LinkRequestData {
  id: string;
  type: CardType.LINK;
  url: string;
  source?: SourceData;
  image?: ImageResponseDto;
  title?: string;
}

export enum CardState {
  PUBLISHED = "published",
  DRAFTING = "drafting",
}
