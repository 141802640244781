/* eslint-disable unicorn/consistent-function-scoping */
import { ImageResponseDto } from "@earthtoday/contract";
import { action, computed, flow, makeObservable, observable } from "mobx";

import { IUserSessionStore } from "../components/ModalLogin/UserSessionStore";
import { TokenSwitchPayload, TokenType } from "../shared/apis/UserSessionApi";
import { getResizeImageUrl } from "../shared/helpers/getResizeImageUrl";
import {
  CookieConsent,
  GroupInfo,
  GroupMemberRole,
  GroupProfileType,
  IUserCountsCustom,
  UserType,
} from "../shared/models/User";
import { ITokenStore } from "./TokenStore";
import {
  CARD_PROFILE_IMAGE_HEIGHT,
  CARD_PROFILE_IMAGE_WIDTH,
  ProfileSession,
} from "./UserModel";

interface UserDetails {
  name?: string;
  type?: UserType;
  quote?: string;
  uonStoreId?: number;
}

export interface AuthUser {
  id: string;
  vanityName: string;
  firstName: string;
  lastName: string;
  fullName: string;
  darkMode: boolean;
  bio?: string;
  tagline?: string;
  provider: string;
  providerId: string;
  emailAddress: string;
  emailAddressVerified: boolean;
  administrator: boolean;
  joined: Date;
  image: ImageResponseDto;
  cover?: ImageResponseDto;
  details?: UserDetails;
  termsAccepted: boolean;
  termsExpired: boolean;
  cookieConsent: CookieConsent;
  userCounts: IUserCountsCustom;
  website?: string;
  twitterUsername?: string;
  facebookUsername?: string;
  instagramUsername?: string;
  linkedInUsername?: string;
  photoState: "SOCIAL" | "INITIALS" | "CUSTOM";
  curator: boolean;
  isPublished?: boolean;
  metaTags?: { [tagName: string]: string };
  isEmailPublic?: boolean;
  isApiKeyEnabled?: boolean;
  groupMemberRole?: GroupMemberRole;
  group?: GroupInfo;
  meta?: {
    lastLoginAt?: string;
    loginTimes?: number;
  };
  enableGiftLastUon: boolean;
}
export class AuthUserModel {
  @observable private user: AuthUser;
  constructor(
    user: AuthUser,
    private userSessionStore: IUserSessionStore,
    private tokenStore: ITokenStore,
    private profileSession?: ProfileSession,
  ) {
    makeObservable(this);
    this.user = user;
  }

  toJSON(): AuthUser {
    return this.user;
  }

  @computed get id(): string {
    return this.user.id;
  }

  @computed get isEmailPublic(): boolean {
    return this.user.isEmailPublic || false;
  }
  @computed get vanityName(): string {
    return this.user.vanityName;
  }

  @computed get firstName(): string {
    return this.user.firstName;
  }

  @computed get lastName(): string {
    return this.user.lastName;
  }

  @computed get fullName(): string {
    return this.user.fullName;
  }

  @computed get darkMode(): boolean {
    return this.user.darkMode;
  }

  @computed get bio(): string {
    return this.user.bio || "";
  }

  @computed get tagline(): string {
    return this.user.tagline || "";
  }

  @computed get provider(): string {
    return this.user.provider;
  }

  @computed get providerId(): string {
    return this.user.providerId;
  }

  @computed get emailAddress(): string {
    return this.user.emailAddress;
  }

  @computed get emailAddressVerified(): boolean {
    return this.user.emailAddressVerified;
  }

  @computed get administrator(): boolean {
    return this.user.administrator;
  }

  @computed get joined(): Date {
    return this.user.joined;
  }
  @computed get imageData(): ImageResponseDto {
    return this.user.image;
  }

  @computed get cover(): ImageResponseDto | undefined {
    return this.user.cover;
  }
  @computed get details(): UserDetails | undefined {
    return this.user.details;
  }
  @computed get termsAccepted(): boolean {
    return this.user.termsAccepted;
  }
  @computed get termsExpired(): boolean {
    return this.user.termsExpired;
  }
  @computed get cookieConsent(): CookieConsent | null {
    return this.user.cookieConsent;
  }
  @computed get curatedDecks(): number {
    return this.user.userCounts.curatedDecks;
  }
  @computed get followerCounts(): number {
    return this.user.userCounts.followerCounts || 0;
  }
  @computed get draftingCardsCount(): number {
    return this.user.userCounts.draftingCards;
  }

  @computed get userCounts(): IUserCountsCustom {
    return this.user.userCounts;
  }

  @computed get website(): string | undefined {
    return this.user.website;
  }
  @computed get twitterUsername(): string | undefined {
    return this.user.twitterUsername;
  }
  @computed get facebookUsername(): string | undefined {
    return this.user.facebookUsername;
  }
  @computed get instagramUsername(): string | undefined {
    return this.user.instagramUsername;
  }
  @computed get linkedInUsername(): string | undefined {
    return this.user.linkedInUsername;
  }
  @computed get photoState(): "SOCIAL" | "INITIALS" | "CUSTOM" {
    return this.user.photoState;
  }
  @computed get curator(): boolean {
    return this.user.curator;
  }
  @computed get isPublished(): boolean | undefined {
    return this.user.isPublished;
  }

  @computed get isGroupUnpublished(): boolean | undefined {
    return this.isGroupToken && !this.user.isPublished;
  }
  @computed get metaTags(): { [tagName: string]: string } | undefined {
    return this.user.metaTags;
  }

  @computed get image(): string {
    return getResizeImageUrl(this.user.image, {
      width: CARD_PROFILE_IMAGE_WIDTH,
      height: CARD_PROFILE_IMAGE_HEIGHT,
    });
  }

  @computed get userType(): UserType {
    return this.user.details?.type || UserType.CONSUMER;
  }

  @computed get uonCount(): number {
    const { uonCount } = this.user.userCounts;
    return uonCount && uonCount >= 0 ? uonCount : 0;
  }
  @computed get groupRole(): GroupProfileType | undefined {
    if (!this.group) return undefined;
    return this.user.group?.profileType || undefined;
  }

  @computed get userSubTitle(): string {
    if (["earthtoday", "old_brand_earthtoday"].includes(this.user.vanityName)) {
      return "profilecard.role.community";
    }
    if (this.user.details?.type === UserType.UNCLASSIFIED_ORG) {
      return "profilecard.role.org";
    }

    if (this.userType !== UserType.GROUP_ADAPTER) {
      return "";
    }

    if (this.groupRole === GroupProfileType.COMMUNITY) {
      return "profilecard.role.community";
    }

    if (this.groupRole === GroupProfileType.PARTNER) {
      return "profilecard.role.partner";
    }
    if (this.groupRole?.toLowerCase() === GroupProfileType.NPO) {
      return "profilecard.role.npo";
    }
    if (this.groupRole === GroupProfileType.BRAND) {
      return "profilecard.role.brand";
    }

    if (this.groupRole === GroupProfileType.CHARITY) {
      return "profilecard.role.charity";
    }

    // group type unclassified_org not show anything
    return "";
  }

  @computed get userImage(): ImageResponseDto {
    return {
      id: this.user?.image?.id || "",
      url: this.user?.image?.url || "",
      awsKey: this.user?.image?.awsKey || "",
      awsBucket: this.user?.image?.awsBucket || "",
      isTransparent: this.user?.image.isTransparent || null,
    };
  }

  @computed get group(): GroupInfo | undefined {
    return this.user.group;
  }

  @computed get groupMemberRole(): GroupMemberRole | undefined {
    return this.user.groupMemberRole;
  }

  @action.bound switchToken = flow(function* (
    this: AuthUserModel,
    redirect?: () => void,
  ) {
    const payload: TokenSwitchPayload = {
      targetType: this.userSessionStore.isGroupToken
        ? TokenType.USER
        : TokenType.GROUP,
      refreshToken: this.tokenStore?.getRefreshToken(),
      groupId: this.user.group?.id,
    };

    this.userSessionStore.switchToken(payload, redirect);
  });

  @action.bound setCookieConsent = (consent: CookieConsent): void => {
    this.user.cookieConsent = consent;
  };

  @action.bound public setUser = (user: AuthUser): void => {
    this.user = user;
  };

  @action.bound public updateUserPublicEmail = (b: boolean): void => {
    this.user.isEmailPublic = b;
  };

  @action.bound public setCover = (cover: ImageResponseDto): void => {
    this.user.cover = cover;
  };

  @action.bound public setImage = (image: ImageResponseDto): void => {
    this.user.image = image;
  };
  @action.bound setFirstName = (firstName: string): void => {
    this.user.firstName = firstName;
  };
  @action.bound setLastName = (lastName: string): void => {
    this.user.lastName = lastName;
  };
  @action.bound setFullName = (fullName: string): void => {
    this.user.fullName = fullName;
  };
  @action.bound setBio = (bio: string): void => {
    this.user.bio = bio;
  };
  @action.bound setTagline = (tagline: string): void => {
    this.user.tagline = tagline;
  };
  @action.bound setVanityName = (vanity: string): void => {
    this.user.vanityName = vanity;
  };
  @action.bound setDetails = (details: UserDetails): void => {
    this.user.details = details;
  };

  @action.bound setSocialDetails = (
    website: string,
    facebook: string,
    twitter: string,
    instagram: string,
    linkedin: string,
  ): void => {
    this.user.website = website;
    this.user.facebookUsername = facebook;
    this.user.twitterUsername = twitter;
    this.user.instagramUsername = instagram;
    this.user.linkedInUsername = linkedin;
  };
  @action.bound setPublishStatus(isPublished: boolean): void {
    this.user.isPublished = isPublished;
  }

  @action.bound setUserInformation = (info: {
    firstName?: string;
    lastName?: string;
    fullName?: string;
    bio?: string;
    tagline?: string;
  }): void => {
    this.setFirstName(info.firstName || "");
    this.setLastName(info.lastName || "");
    this.setFullName(info.fullName || "");
    this.setBio(info.bio || "");
    this.setTagline(info.tagline || "");
  };

  @action.bound updateDraftingCardCounts(number: 1 | -1): void {
    this.user.userCounts.draftingCards += number;
  }

  @action.bound setDraftingCardCounts(count: number): void {
    this.user.userCounts.draftingCards = count;
  }

  @computed get allowSwitchToken(): boolean {
    return !!this.profileSession?.allowSwitchToken;
  }
  @computed get isGroupToken(): boolean {
    return this.user?.details?.type === UserType.GROUP_ADAPTER;
  }
  @computed get lastLoginAt(): string {
    return this.user?.meta?.lastLoginAt || "";
  }
  @computed get loginTimes(): number {
    return this.user?.meta?.loginTimes || 0;
  }

  @computed get isApiKeyEnabled(): boolean {
    return !!this.user?.isApiKeyEnabled;
  }

  @computed get isFirstLoginToday(): boolean {
    if (!this.user?.meta) {
      return false;
    }
    const { loginTimes, lastLoginAt } = this.user.meta;
    if (loginTimes !== 1) {
      return false;
    }
    if (!lastLoginAt) {
      return false;
    }
    const lastLoginDate = new Date(lastLoginAt);
    const currentDate = new Date();

    return (
      lastLoginDate.getFullYear() === currentDate.getFullYear() &&
      lastLoginDate.getMonth() === currentDate.getMonth() &&
      lastLoginDate.getDate() === currentDate.getDate()
    );
  }

  @computed get enableGiftLastUon(): boolean {
    return this.user.enableGiftLastUon;
  }
  @computed get isXIconVisable(): boolean {
    return !!this.user.twitterUsername;
  }
}
