import {
  action,
  computed,
  flow,
  IObservableArray,
  makeObservable,
  observable,
} from "mobx";
import Router from "next/router";

import { UserSessionStore } from "../components/ModalLogin/UserSessionStore";
import { TheMessageStore } from "../components/TheMessage/TheMessageStore";
import { TheNavbarStore } from "../components/TheNavbarTop/TheNavbarStore/TheNavbarStore";
import { translateAPIError } from "../shared/helpers/translateApiError";
import { Currency, DonateStore } from "./DonateStore/DonateStore";
import { MobileMenuStore } from "./MobileMenuStore/MobileMenuStore";

export interface SectionInfo {
  label: string;
  key: string;
}

export class PoliciesPresenter {
  @observable cookieSocialAccepted: boolean = false;

  @observable sections: IObservableArray<SectionInfo> = observable<SectionInfo>(
    [],
  );

  @observable currentSection: string = "";

  @observable cookieSettingsChanged: boolean = true;

  constructor(
    private donateStore: DonateStore,
    private mobileMenuStore: MobileMenuStore,
    private userSessionStore: UserSessionStore,
    private theMessageStore: TheMessageStore,
    private theNavbarStore: TheNavbarStore,
  ) {
    makeObservable(this);
  }
  @computed get currency(): Currency {
    return this.donateStore.currency;
  }

  @computed get shouldHidePoliciesNavItem(): boolean {
    return this.mobileMenuStore.shouldHidePoliciesNavItem;
  }

  @action
  cookieSocialAcceptedUpdate = (b: boolean): void => {
    this.cookieSocialAccepted = b;
  };

  @action
  sectionsRegister = (sectionInfo: SectionInfo): void => {
    if (
      this.sections.findIndex((section) => section.key === sectionInfo.key) ===
      -1
    ) {
      this.sections.replace([...this.sections, sectionInfo]);
    }
  };

  @action
  currentSectionUpdate = (s: string): void => {
    this.currentSection = s;
  };

  @action
  cookieSettingsChangedUpdate = (b: boolean): void => {
    this.cookieSettingsChanged = b;
    this.cookieSocialAccepted = !this.cookieSocialAccepted;
  };

  @action.bound onSaveCookieSettingsChanged = flow(
    function* onSaveCookieSettingsChanged(this: PoliciesPresenter) {
      try {
        if (
          this.userSessionStore.user &&
          this.userSessionStore.hasAcceptedSocialCookies &&
          !this.cookieSocialAccepted
        ) {
          yield this.userSessionStore.acceptBasicCookies();
          if (this.userSessionStore.user.provider !== "password") {
            yield this.userSessionStore.onLogout(() => Router.push("/"));
            this.theNavbarStore.updateScrollYPosition(0);
          }
        }
        yield this.cookieSocialAccepted
          ? this.userSessionStore.acceptAllCookies()
          : this.userSessionStore.acceptBasicCookies();
        this.cookieSettingsChanged = false;
      } catch (error) {
        this.theMessageStore.showMessage({
          typeMessage: "Error",
          title: "toast-message.general.error",
          content: translateAPIError(error),
        });
      }
    },
  );
}
