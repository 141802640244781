/* eslint-disable unicorn/prefer-logical-operator-over-ternary */
import styled from "styled-components";

import { smallScreenUpperLimit } from "../../shared/breakpoints";
import {
  etBlueLight,
  etBrightLightBlue,
  etDark,
  etGray,
  etGreen,
  etRed,
  etRegentGray,
  etWhite,
} from "../../shared/colors";
import transparentize from "../../shared/helpers/transparentize";
import { IconSvgContainer } from "../IconSvg/IconSvg.styled";
import { FontStretch, FontWeight, TextTransform } from "./Button";

namespace sc {
  export const Container = styled.div<{ margin: string }>`
    position: relative;
    margin: ${(props) => props.margin};
  `;
  export const CoreButton = styled.button<{
    driver: {
      width: string;
      height: string;
      color?: string;
      round: boolean;
      fontSize: string;
      textTransform: TextTransform;
      fontWeight: FontWeight;
      fontStretch: FontStretch;
      mobileHeight: string;
      mobileWidth: string;
      mobileFontsize: string;
      padding: string;
      mobileVisible: boolean;
      fontFamily: string;
      borderColor?: string;
      bgColor?: string;
      bgOnHovered?: string;
      hover?: boolean;
      cursor?: "default" | "pointer";
    };
  }>``;
  export const PrimaryButton = styled(CoreButton)`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: ${(props) => (props.driver.round ? "24px" : "4px")};
    width: ${(props) => props.driver.width};
    height: ${(props) => props.driver.height};
    font-family: ${(props) => props.driver.fontFamily};
    font-stretch: ${(props) => props.driver.fontStretch};
    font-weight: ${(props) => props.driver.fontWeight};
    font-size: ${(props) => props.driver.fontSize};
    text-transform: ${(props) => props.driver.textTransform};
    padding: ${(props) => props.driver.padding};
    user-select: none;
    background-color: ${etBrightLightBlue};
    color: ${(props) => (props.driver.color ? props.driver.color : etWhite)};
    &:hover {
      cursor: ${(props) => props.driver.cursor};
      box-shadow: ${(props) =>
        props.driver.cursor === "default"
          ? "none"
          : "0px 1px 1px 0px rgba(0, 0, 0, 0.1), 0px 2px 3px 0px rgba(0, 0, 0, 0.1)"};
    }

    > ${IconSvgContainer} {
      margin-left: 8px;
      cursor: ${(props) => props.driver.cursor};
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      min-height: ${(props) => props.driver.mobileHeight};
      max-height: ${(props) => props.driver.mobileHeight};
      min-width: ${(props) => props.driver.mobileWidth};
      max-width: ${(props) => props.driver.mobileWidth};
      font-size: ${(props) => props.driver.mobileFontsize};
      ${(props) => !props.driver.mobileVisible && "display: none;"}
    }

    &:disabled {
      box-shadow: none;
      opacity: 0.65;
      background-color: ${etBlueLight};
    }
  `;
  export const SecondaryButton = styled(CoreButton)`
    color: ${(props) => (props.driver.color ? props.driver.color : etWhite)};
    background-color: ${etRegentGray};
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: ${(props) => (props.driver.round ? "24px" : "4px")};
    width: ${(props) => props.driver.width};
    height: ${(props) => props.driver.height};
    font-family: Roboto;
    font-stretch: ${(props) => props.driver.fontStretch};
    font-weight: ${(props) => props.driver.fontWeight};
    font-size: ${(props) => props.driver.fontSize};
    text-transform: ${(props) => props.driver.textTransform};
    padding: ${(props) => props.driver.padding};
    user-select: none;
    &:hover {
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
        0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    }
    &:disabled {
      box-shadow: none;
      opacity: 0.65;
    }

    > ${IconSvgContainer} {
      margin-left: 8px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      min-height: ${(props) => props.driver.mobileHeight};
      max-height: ${(props) => props.driver.mobileHeight};
      min-width: ${(props) => props.driver.mobileWidth};
      max-width: ${(props) => props.driver.mobileWidth};
      font-size: ${(props) => props.driver.mobileFontsize};
      ${(props) => !props.driver.mobileVisible && "display: none;"}
    }
  `;
  export const SuccessButton = styled(CoreButton)`
    background-color: ${etGreen};
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: ${(props) => (props.driver.round ? "24px" : "4px")};
    width: ${(props) => props.driver.width};
    height: ${(props) => props.driver.height};
    font-family: Roboto;
    font-stretch: ${(props) => props.driver.fontStretch};
    font-weight: ${(props) => props.driver.fontWeight};
    font-size: ${(props) => props.driver.fontSize};
    text-transform: ${(props) => props.driver.textTransform};
    padding: ${(props) => props.driver.padding};
    user-select: none;
    &:hover {
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
        0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    }
    &:disabled {
      box-shadow: none;
      opacity: 0.65;
    }

    > ${IconSvgContainer} {
      margin-left: 8px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      min-height: ${(props) => props.driver.mobileHeight};
      max-height: ${(props) => props.driver.mobileHeight};
      min-width: ${(props) => props.driver.mobileWidth};
      max-width: ${(props) => props.driver.mobileWidth};
      font-size: ${(props) => props.driver.mobileFontsize};
      ${(props) => !props.driver.mobileVisible && "display: none;"}
    }
  `;
  export const DangerButton = styled(CoreButton)`
    border: 1px solid ${etRed};
    background-color: transparent;
    color: ${(props) => (props.driver.color ? props.driver.color : etRed)};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${(props) => (props.driver.round ? "24px" : "4px")};
    width: ${(props) => props.driver.width};
    height: ${(props) => props.driver.height};
    font-family: Roboto;
    font-stretch: ${(props) => props.driver.fontStretch};
    font-weight: ${(props) => props.driver.fontWeight};
    font-size: ${(props) => props.driver.fontSize};
    text-transform: ${(props) => props.driver.textTransform};
    padding: ${(props) => props.driver.padding};
    user-select: none;
    &:hover {
      color: ${etWhite};
      background-color: ${etRed};
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
        0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    }
    &:disabled {
      box-shadow: none;
      opacity: 0.65;
      color: ${etRed};
      background-color: ${etWhite};
    }

    > ${IconSvgContainer} {
      margin-left: 8px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      min-height: ${(props) => props.driver.mobileHeight};
      max-height: ${(props) => props.driver.mobileHeight};
      min-width: ${(props) => props.driver.mobileWidth};
      max-width: ${(props) => props.driver.mobileWidth};
      font-size: ${(props) => props.driver.mobileFontsize};
      ${(props) => !props.driver.mobileVisible && "display: none;"}
    }
  `;
  export const OutLineDangerButton = styled(CoreButton)`
    border: 1px solid ${etRed};
    background-color: ${etWhite};
    color: ${(props) => (props.driver.color ? props.driver.color : etRed)};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${(props) => (props.driver.round ? "24px" : "4px")};
    width: ${(props) => props.driver.width};
    height: ${(props) => props.driver.height};
    font-family: Roboto;
    font-stretch: ${(props) => props.driver.fontStretch};
    font-weight: ${(props) => props.driver.fontWeight};
    font-size: ${(props) => props.driver.fontSize};
    text-transform: ${(props) => props.driver.textTransform};
    padding: ${(props) => props.driver.padding};
    user-select: none;
    &:hover {
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
        0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    }
    &:disabled {
      border-color: ${transparentize(etRed, 0.5)};
      color: ${transparentize(etRed, 0.5)};
      background-color: ${etWhite};
      box-shadow: none;
      opacity: 0.65;
    }

    > ${IconSvgContainer} {
      margin-left: 8px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      min-height: ${(props) => props.driver.mobileHeight};
      max-height: ${(props) => props.driver.mobileHeight};
      min-width: ${(props) => props.driver.mobileWidth};
      max-width: ${(props) => props.driver.mobileWidth};
      font-size: ${(props) => props.driver.mobileFontsize};
      ${(props) => !props.driver.mobileVisible && "display: none;"}
    }
  `;
  export const TransparentButton = styled(CoreButton)`
    border: 1px solid ${(props) => props.driver.borderColor || etWhite};
    color: ${(props) =>
      props.driver.color ? props.driver.color : etRegentGray};
    background-color: ${(props) => props.driver.bgColor || "transparent"};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${(props) => (props.driver.round ? "24px" : "4px")};
    width: ${(props) => props.driver.width};
    height: ${(props) => props.driver.height};
    font-family: ${(props) => props.driver.fontFamily};
    font-stretch: ${(props) => props.driver.fontStretch};
    font-weight: ${(props) => props.driver.fontWeight};
    font-size: ${(props) => props.driver.fontSize};
    text-transform: ${(props) => props.driver.textTransform};
    padding: ${(props) => props.driver.padding};
    user-select: none;
    &:hover {
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
        0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    }
    &:disabled {
      border-color: ${transparentize(etGray, 0.5)};
      color: ${transparentize(etRegentGray, 0.5)};
      background-color: transparent;
      box-shadow: none;
      opacity: 0.65;
    }

    > ${IconSvgContainer} {
      margin-left: 8px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      min-height: ${(props) => props.driver.mobileHeight};
      max-height: ${(props) => props.driver.mobileHeight};
      min-width: ${(props) => props.driver.mobileWidth};
      max-width: ${(props) => props.driver.mobileWidth};
      font-size: ${(props) => props.driver.mobileFontsize};
      ${(props) => !props.driver.mobileVisible && "display: none;"}
    }
  `;
  export const LightButton = styled(CoreButton)`
    color: ${(props) => (props.driver.color ? props.driver.color : etDark)};
    background-color: ${etWhite};
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: ${(props) => (props.driver.round ? "24px" : "4px")};
    width: ${(props) => props.driver.width};
    height: ${(props) => props.driver.height};
    font-family: Roboto;
    font-stretch: ${(props) => props.driver.fontStretch};
    font-weight: ${(props) => props.driver.fontWeight};
    font-size: ${(props) => props.driver.fontSize};
    text-transform: ${(props) => props.driver.textTransform};
    padding: ${(props) => props.driver.padding};
    user-select: none;
    &:hover {
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
        0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    }
    &:disabled {
      border-color: ${transparentize(etDark, 0.5)};
      color: ${transparentize(etDark, 0.5)};
      background-color: ${etWhite};
      box-shadow: none;
      opacity: 0.65;
    }

    > ${IconSvgContainer} {
      margin-left: 8px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      min-height: ${(props) => props.driver.mobileHeight};
      max-height: ${(props) => props.driver.mobileHeight};
      min-width: ${(props) => props.driver.mobileWidth};
      max-width: ${(props) => props.driver.mobileWidth};
      font-size: ${(props) => props.driver.mobileFontsize};
      ${(props) => !props.driver.mobileVisible && "display: none;"}
    }
  `;
  export const OutlineLightButton = styled(CoreButton)`
    border: 1px solid ${(props) => props.driver.borderColor || etGray};
    color: ${(props) => (props.driver.color ? props.driver.color : etDark)};
    background-color: ${etWhite};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${(props) => (props.driver.round ? "24px" : "4px")};
    width: ${(props) => props.driver.width};
    height: ${(props) => props.driver.height};
    font-family: Roboto;
    font-stretch: ${(props) => props.driver.fontStretch};
    font-weight: ${(props) => props.driver.fontWeight};
    font-size: ${(props) => props.driver.fontSize};
    text-transform: ${(props) => props.driver.textTransform};
    padding: ${(props) => props.driver.padding};
    user-select: none;
    ${(props) =>
      props.driver.hover &&
      `
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
          0px 2px 3px 0px rgba(0, 0, 0, 0.1);
        background-color: ${
          props.driver.bgOnHovered && props.driver.bgOnHovered
        };`}
    &:hover {
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
        0px 2px 3px 0px rgba(0, 0, 0, 0.1);
      background-color: ${(props) =>
        props.driver.bgOnHovered ? props.driver.bgOnHovered : ""};
    }
    &:disabled {
      box-shadow: none;
      opacity: 0.65;
      border-color: ${transparentize(etDark, 0.5)};
      color: ${transparentize(etDark, 0.5)};
      background-color: ${etWhite};
    }

    > ${IconSvgContainer} {
      margin-left: 8px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      min-height: ${(props) => props.driver.mobileHeight};
      max-height: ${(props) => props.driver.mobileHeight};
      min-width: ${(props) => props.driver.mobileWidth};
      max-width: ${(props) => props.driver.mobileWidth};
      font-size: ${(props) => props.driver.mobileFontsize};
      ${(props) => !props.driver.mobileVisible && "display: none;"}
    }
  `;
  export const LighterButton = styled(CoreButton)`
    border: 1px solid
      ${(props) =>
        props.driver.borderColor ? props.driver.borderColor : etRegentGray};
    color: ${(props) =>
      props.driver.color ? props.driver.color : etRegentGray};
    background-color: ${etWhite};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${(props) => (props.driver.round ? "24px" : "4px")};
    width: ${(props) => props.driver.width};
    height: ${(props) => props.driver.height};
    font-family: Roboto;
    font-stretch: ${(props) => props.driver.fontStretch};
    font-weight: ${(props) => props.driver.fontWeight};
    font-size: ${(props) => props.driver.fontSize};
    text-transform: ${(props) => props.driver.textTransform};
    padding: ${(props) => props.driver.padding};
    user-select: none;

    ${(props) =>
      props.driver.hover &&
      `
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
          0px 2px 3px 0px rgba(0, 0, 0, 0.1);
        background-color: ${
          props.driver.bgOnHovered && props.driver.bgOnHovered
        };`}
    &:hover {
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
        0px 2px 3px 0px rgba(0, 0, 0, 0.1);
      background-color: ${(props) =>
        props.driver.bgOnHovered ? props.driver.bgOnHovered : ""};
    }
    &:disabled {
      box-shadow: none;
      opacity: 0.65;
      border-color: ${transparentize(etRegentGray, 0.85)};
      color: ${transparentize(etRegentGray, 0.85)};
      background-color: ${etWhite};
    }

    > ${IconSvgContainer} {
      margin-left: 8px;
    }

    @media (max-width: ${smallScreenUpperLimit}) {
      min-height: ${(props) => props.driver.mobileHeight};
      max-height: ${(props) => props.driver.mobileHeight};
      min-width: ${(props) => props.driver.mobileWidth};
      max-width: ${(props) => props.driver.mobileWidth};
      font-size: ${(props) => props.driver.mobileFontsize};
      ${(props) => !props.driver.mobileVisible && "display: none;"}
    }
  `;
}

export default sc;
