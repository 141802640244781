/* eslint-disable unicorn/consistent-function-scoping */
import {
  PromotionImageShape,
  UserCountsCustomResponseDto,
} from "@earthtoday/contract";
import { action, computed, flow, makeObservable, observable } from "mobx";
import { NextRouter } from "next/router";
import { toFlowGeneratorFunction } from "to-flow-generator-function";

import { DynamicLazyModalLoader } from "../../components/DynamicModalLoader/DynamicLazyModalLoader";
import { MobileMenuDriverV2 } from "../../components/MobileMenu/MobileMenu";
import { IUserSessionStore } from "../../components/ModalLogin/UserSessionStore";
import {
  ITheMessageStore,
  ToastMessageStatus,
} from "../../components/TheMessage/TheMessageStore";
import { TheNavbarStore } from "../../components/TheNavbarTop/TheNavbarStore/TheNavbarStore";
import { INavbarApi } from "../../shared/apis/NavbarApi";
import { ProfileApi } from "../../shared/apis/ProfileApi";
import { IDeviceStore } from "../DeviceStore";
import FeatureFlaggingStore from "../FeatureFlaggingStore";
import { LazyModalType, ModalStore } from "../ModalStore";
import { MobileMenuItemId } from "./MobileMenuItemId";
export type StaticMenuItem = StaticMenuActionItem | StaticMenuLinkItem;
export enum StaticMenuItemType {
  ACTION = "ACTION",
  LINK = "LINK",
}
export interface StaticMenuActionItem {
  type: StaticMenuItemType.ACTION;
  formatedName: string;
  formatedSubtitle?: string;
  id: MobileMenuItemId;
  borderColor: string;
  backgroundColor: string;
  color: string;
  icon: string;
  onClick: () => void;
}
export interface StaticMenuLinkItem {
  type: StaticMenuItemType.LINK;
  formatedName: string;
  formatedSubtitle?: string;
  id: MobileMenuItemId;
  borderColor: string;
  backgroundColor: string;
  color: string;
  icon: string;
  href: string;
  as: string;
}
export class MobileMenuStore implements MobileMenuDriverV2 {
  @observable isIntroVideoOpen: boolean = false;
  @observable touchStartPos: number = 0;
  @observable touchEndPos: number = 0;
  @observable forceScrollForProfile: boolean = false;
  @observable forceScrollForTabs: boolean = false;
  @observable isNavbarMenuAppActive: boolean = false; // TODO: should update name
  @observable isNavbarMenuPlayActive: boolean = false; // TODO: menu play is deprecated, should remove it
  @observable userCounts: Pick<
    UserCountsCustomResponseDto,
    | "curatedDecks"
    | "followerCounts"
    | "uonCount"
    | "groupCount"
    | "publishedCards"
    | "draftingCards"
  > = {
    curatedDecks: 0,
    followerCounts: 0,
    uonCount: 0,
    groupCount: 0,
    publishedCards: 0,
    draftingCards: 0,
  };
  @observable messagesCount: { total: number; unread: number } = {
    total: 0,
    unread: 0,
  };
  constructor(
    private userSessionStore: IUserSessionStore,
    private deviceStore: IDeviceStore,
    private theMessageStore: ITheMessageStore,
    public modalStore: ModalStore,
    private featureFlaggingStore: FeatureFlaggingStore,
    public theNavbarStore: TheNavbarStore,
    public profileApi: ProfileApi,
    public navbarApi: INavbarApi,
    public router?: NextRouter,
  ) {
    makeObservable(this);
  }

  @computed get vanityName(): string {
    return this.userSessionStore.user?.vanityName || "";
  }

  @computed get image(): string {
    return this.userSessionStore.user?.image || "";
  }

  @computed get isCurator(): boolean {
    return this.userSessionStore.user?.curator || false;
  }

  @computed get isUserLoggedOut(): boolean {
    return !this.userSessionStore.user;
  }

  @computed get isUserVerified(): boolean {
    return this.userSessionStore.user?.emailAddressVerified || false;
  }

  @computed get isCardJoiningOpen(): boolean {
    return this.isNavbarMenuAppActive && this.isUserLoggedOut;
  }

  @computed get isMenuAppOpen(): boolean {
    return this.isNavbarMenuAppActive && !this.isUserLoggedOut;
  }
  @computed get isMenuPlayOpen(): boolean {
    return this.isNavbarMenuPlayActive;
  }

  @computed get shouldFixViewport(): boolean {
    return this.isMenuAppOpen || this.isMenuPlayOpen || this.isCardJoiningOpen;
  }

  @computed get shouldCardBlankClose(): boolean {
    return (
      !this.isMenuAppOpen && !this.isMenuPlayOpen && !this.isCardJoiningOpen
    );
  }

  @computed get shouldShowCookiePolicy(): boolean {
    return !this.userSessionStore.hasAcceptedSocialCookies;
  }

  @computed get shouldHidePoliciesNavItem(): boolean {
    return this.isNavbarMenuAppActive;
  }

  @computed get isMobileDevice(): boolean {
    return this.deviceStore.isMobileDevice;
  }
  @computed get shouldRenderNewMobileMenu(): boolean {
    return (
      this.featureFlaggingStore.flags.enableNewCardMenuItemForMobile &&
      this.isMobileDevice
    );
  }
  @computed get avatarImageUrl(): string | undefined {
    return this.userSessionStore.user?.image;
  }
  @computed get maxHeightOfNewCardMenuItem(): string {
    return this.shouldRenderNewMobileMenu && this.isMenuAppOpen ? "30vh" : "";
  }

  @action.bound
  setIntroVideoOpen(isOpen: boolean): void {
    this.isIntroVideoOpen = isOpen;
  }
  @action.bound onMount = flow(function* (this: MobileMenuStore) {
    try {
      if (!!this.userSessionStore.user) {
        const userCounts = yield* toFlowGeneratorFunction(
          this.profileApi.fetchUserCounts,
        )();
        this.userCounts = userCounts;
      }

      const notifications = yield* toFlowGeneratorFunction(
        this.navbarApi.fetchNotifications,
      )();

      // eslint-disable-next-line unicorn/no-array-reduce
      this.messagesCount.total = notifications.length;
      this.messagesCount.unread = notifications.filter(
        (notification) => !notification.visited,
      ).length;
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error" || "Error",
        content: error as string,
      });
    }
  });

  @action.bound onSearchMenuItemClick(this: MobileMenuStore) {
    this.theNavbarStore.searchInputFocusUpdate(
      !this.theNavbarStore.searchInputFocus,
    );
  }
  @action.bound onCollectMenuItemClick(this: MobileMenuStore) {
    this.modalStore.openLazyModal({
      name: "ModalCreateCardCollectable",
      component: (
        <DynamicLazyModalLoader
          loadComponent={() =>
            // eslint-disable-next-line more/no-then
            import(
              "../../components/ModalCreateCardCollectable/ModalCreateCardCollectableSmart"
            ).then((component) => component.ModalCreateCardCollectableSmart)
          }
          imageShape={PromotionImageShape.SQUARE}
        />
      ),
    });
  }
  @action.bound onMessageMenuItemClick(this: MobileMenuStore) {
    this.theNavbarStore.onMount();
    this.theNavbarStore.showNotificationsToggle();
  }
  @action.bound onLogoutMenuItemClick = flow(function* onLogoutMenuItemClick(
    this: MobileMenuStore,
  ) {
    yield* toFlowGeneratorFunction(this.userSessionStore.onLogout)(() =>
      this.router?.push("/", undefined, { shallow: false }),
    );
    this.isNavbarMenuAppActive = false;
  });

  @action.bound
  openLazyModal(modal: LazyModalType): void {
    if (
      modal === "cardCreate" &&
      !this.userSessionStore.user?.emailAddressVerified
    ) {
      this.theMessageStore.showMessage(
        {
          typeMessage: "Action",
          status: ToastMessageStatus.WARNING,
          title: "toast-message.email-not-verified.title",
          content: "toast-message.email-not-verified.content",
          actions: [
            {
              key: "close",
              name: "toast-message.general.action-close",
              action: () => this.theMessageStore.close(),
            },
            {
              key: "resend",
              name: "toast-message.general.action-resend",
              action: () => this.userSessionStore.resendVerificationEmail(),
            },
          ],
        },
        { closeDuration: "never" },
      );
      return;
    }

    this.modalStore.openModal(modal);
  }

  @action.bound onUserLogout = flow(function* onUserLogout(
    this: MobileMenuStore,
    redirect?: () => void,
  ) {
    yield this.userSessionStore.onLogout(() => redirect && redirect());
    this.isNavbarMenuAppActive = false;
  });

  @action.bound
  updateScrollPermission(
    target: "tabs" | "profile",
    permission: boolean,
  ): void {
    if (target === "tabs") {
      this.forceScrollForTabs = permission;
    }
    if (target === "profile") {
      this.forceScrollForProfile = permission;
    }
  }

  @action.bound
  handleTouchStart(clientY: number): void {
    this.touchStartPos = clientY;
  }

  // TODO: handle touch move (optional)
  @action.bound
  handleOnTouchMove(): void {}

  @action.bound
  handleTouchEnd(clientY: number): void {
    this.touchEndPos = clientY;

    const swipeUpDiff = this.touchStartPos - this.touchEndPos;
    const swipeUpToCloseThreshold = 20;

    if (swipeUpDiff >= swipeUpToCloseThreshold) {
      if (this.isMenuAppOpen || this.isCardJoiningOpen) {
        this.toggleMenuApp();
      }
      if (this.isMenuPlayOpen) {
        this.toggleMenuPlay();
      }
    }
  }

  @action.bound
  handleClick(): void {
    if (this.isMobileDevice) {
      return;
    }
    if (this.isMenuAppOpen || this.isCardJoiningOpen) {
      this.toggleMenuApp();
    }
    if (this.isMenuPlayOpen) {
      this.toggleMenuPlay();
    }
  }
  /* ****************************** Handle navbar ******************************* */

  @action.bound
  turnMenuOff(): void {
    this.isNavbarMenuAppActive = false;
  }

  @action.bound
  toggleMenuApp(): void {
    this.isNavbarMenuAppActive = !this.isNavbarMenuAppActive;
  }

  @action.bound
  toggleMenuPlay(): void {
    this.isNavbarMenuPlayActive = !this.isNavbarMenuPlayActive;
  }

  @action.bound
  onMenuButtonClick(): void {
    this.toggleMenuApp();
    if (this.isNavbarMenuPlayActive) {
      this.toggleMenuPlay();
    }
  }

  @action.bound
  onPlayButtonClick(): void {
    this.toggleMenuPlay();
    if (this.isNavbarMenuAppActive) {
      this.toggleMenuApp();
    }
  }

  @computed get currentYear(): number {
    return new Date().getFullYear();
  }

  @action.bound onCreateCollectableClicked() {
    this.modalStore.openLazyModal({
      name: "ModalCreateCardCollectable",
      component: (
        <DynamicLazyModalLoader
          loadComponent={() =>
            // eslint-disable-next-line more/no-then
            import(
              "../../components/ModalCreateCardCollectable/ModalCreateCardCollectableSmart"
            ).then((component) => component.ModalCreateCardCollectableSmart)
          }
          imageShape={PromotionImageShape.SQUARE}
        />
      ),
    });
    this.toggleMenuApp();
  }

  @action.bound onStartProtectingClicked() {
    if (this.featureFlaggingStore.flags.enableOneFlow) {
      this.modalStore.openModal("oneFlow");
      return;
    }

    this.modalStore.openModal("donate");
  }
}
