import { observer } from "mobx-react-lite";
import dynamic from "next/dynamic";

import { Currency } from "../../stores/DonateStore/DonateStore";
import { sc } from "./LandingPage.styled";
import { SectionEight } from "./SectionEight/SectionEight";
import { SectionFive } from "./SectionFive/SectionFive";
import { SectionFour } from "./SectionFour/SectionFour";
import { SectionOne } from "./SectionOne/SectionOne";
import { SectionSeven } from "./SectionSeven/SectionSeven";
import { SectionSix } from "./SectionSix/SectionSix";
import { SectionThree } from "./SectionThree/SectionThree";

const VimeoPlayer = dynamic(() => import("react-player/vimeo"), { ssr: false });

export interface LandingPageDriver {
  currency: Currency;
  protectedCount: number | null;
  shouldRenderAppStore: boolean;
  shouldRenderGooglePlay: boolean;
  shouldRenderVideoPlayer: boolean;
  onPlayVideoButtonClicked(): void;
  onOverlayClicked(): void;
}

interface IProps {
  driver: LandingPageDriver;
}
export const LandingPage = observer((props: IProps) => {
  return (
    <sc.Container className="visual-reg">
      <sc.SectionWrapper>
        <SectionOne driver={props.driver} />
      </sc.SectionWrapper>
      <sc.SectionWrapper zIndex={1} marginTop={-50}>
        <SectionThree driver={props.driver} />
      </sc.SectionWrapper>
      <sc.SectionWrapper zIndex={2} marginTop={-50}>
        <SectionFour />
      </sc.SectionWrapper>
      <sc.SectionWrapper zIndex={3} marginTop={-100}>
        <SectionFive driver={props.driver} />
      </sc.SectionWrapper>
      <sc.SectionWrapper zIndex={4} marginTop={-50}>
        <SectionSix />
      </sc.SectionWrapper>
      <sc.SectionWrapper zIndex={5} marginTop={-200}>
        <SectionSeven />
      </sc.SectionWrapper>
      <sc.SectionWrapper>
        <SectionEight driver={props.driver} />
      </sc.SectionWrapper>

      {props.driver.shouldRenderVideoPlayer && (
        <sc.MoviePlayer onClick={props.driver.onOverlayClicked}>
          <sc.MovieWrapper>
            <VimeoPlayer
              video="dd703106ee"
              url="https://vimeo.com/890233047/dd703106ee?share=copy"
              height="100%"
              width="100%"
              controls={true}
              config={{
                playerOptions: {
                  autoplay: true,
                  loop: false,
                  quality: "auto",
                  responsive: true,
                },
              }}
            />
          </sc.MovieWrapper>
        </sc.MoviePlayer>
      )}
    </sc.Container>
  );
});
