import { observer } from "mobx-react-lite";
import { NextPage } from "next";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import { LandingPage } from "../../src/components/LayoutLandingPage/LandingPage";
import { LayoutLandingPage } from "../../src/components/LayoutLandingPage/LayoutLandingPage";
import { host } from "../../src/shared/env";
import {
  IClientRenderProps,
  IServerRenderProps,
} from "../../src/shared/helpers/serverRender";
import { LandingPageStore } from "../../src/stores/LandingPageStore/LandingPageStore";
import { useRootStore } from "../../src/stores/rootStore";
export enum UonEarthLandingPageMode {
  NONE = "NONE",
  RELEASE = "RELEASE",
  TEMPORARILY_NOT_AVAILABLE = "TEMPORARILY_NOT_AVAILABLE",
}

export type IWelcomeProps =
  | ({
      pageData: {};
    } & IServerRenderProps)
  | IClientRenderProps;

const WelcomePage: NextPage<IWelcomeProps> = observer<IWelcomeProps>(() => {
  const rootStore = useRootStore();
  const router = useRouter();
  const msgType = router.query.msgType;

  const [landingPageStore] = useState(
    () =>
      new LandingPageStore(
        rootStore.homeApi,
        rootStore.theMessageStore,
        rootStore.featureFlaggingStore,
      ),
  );
  useEffect(() => {
    (async () => landingPageStore.onMount(msgType as string | undefined))();
    landingPageStore.subscribeCounter();

    return () => landingPageStore.unsubscribeCounter();
  }, []);

  return <LandingPage driver={landingPageStore} />;
});

WelcomePage.getLayout = (page) => {
  return <LayoutLandingPage>{page}</LayoutLandingPage>;
};
WelcomePage.displayName = "WelcomePage";
WelcomePage.getInitialProps = async (ctx): Promise<IWelcomeProps> => {
  const { rootStore } = ctx as any;

  rootStore.metaStore.updateMetaTags({
    "og:image": `${host}/thumbnails.png`,
    "fb:app_id": "356721174733993",
    "og:type": "website",
    "og:site_name": "Uon.Earth",
    "og:title": "Uon.Earth",
    "og:url": "https://uon.earth/",
    "og:description": "What’s happening on Uon.Earth",
  });

  return {
    rootData: rootStore.dehydrate(),
    pageData: {},
    isServerRender: true,
  };
};

export default WelcomePage;
