/* eslint-disable unicorn/better-regex */
export type ValidationCheck = {
  valid: boolean;
  msg: string;
  vars?: Record<string, string | number>;
};

export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

export const MIN_LENGTH = 6;
export const ORG_NAME_MAX_LENGTH = 280;
export const emailRegex =
  /^[\dA-Za-z]+([+.-]?[\dA-Za-z_])*@[\dA-Za-z]+([+.-]?[\dA-Za-z-]+)*(\.\w{2,10})+$/;

export function validateUserEmail(
  email: string,
): [boolean, string, { vars?: Record<string, string | number> }] {
  email = email.trim();

  if (!email) {
    return [false, "modaljoin.email-required", {}];
  }

  if (email.length < MIN_LENGTH) {
    return [
      false,
      "modaljoin.min-length-characters",
      {
        vars: { length: MIN_LENGTH },
      },
    ];
  }

  if (!emailRegex.test(email)) {
    return [false, "modaljoin.email-valid", {}];
  }

  return [true, "", {}];
}

export function validateUserPassword(
  password: string,
): [boolean, string, { vars?: Record<string, string | number> }] {
  if (!password) {
    return [false, "modaljoin.password.feedback.required", {}];
  }

  if (password.length < MIN_LENGTH) {
    return [
      false,
      "modaljoin.min-length-characters",
      { vars: { length: MIN_LENGTH } },
    ];
  }

  return [true, "", {}];
}

export function validateFieldPassword(
  field: string,
  password: string,
): [boolean, string, { vars?: Record<string, string | number> }] {
  if (!password) {
    return [false, "modaljoin.password.feedback.required", {}];
  }

  if (password.length < MIN_LENGTH) {
    return [false, "modaljoin.min-length-characters", {}];
  }

  return [true, "", {}];
}

export const validateRequiredData = (
  key: string,
  data: string,
): [boolean, string, { vars?: Record<string, string | number> }] => {
  if (!data.trim()) {
    return [false, `modaljoin.${key}.required`, {}];
  }

  return [true, "", {}];
};

export function validateAlphanumericEmoji(text: string): boolean {
  // eslint-disable-next-line unicorn/no-hex-escape
  const regex = /[\x20-\x7Fà-ūÀ-Ū]|[^\x00-\x7F]|^$/g;
  return regex.test(text);
}

export function validateAlphanumeric(text: string): boolean {
  const regex = /^[\da-z]+$/i;

  return regex.test(text);
}

export function validateNameField(
  text: string,
  field: string,
): [boolean, string] {
  const regex = /^([\d\s!"#$%&'()*+./<=>@\\^_`{|}~-])+$/;

  if (!text) return [false, `${field} is required.`];
  if (regex.test(text[0]))
    return [false, `${field} must start with a character.`];

  return [true, ""];
}

export function validateTaglineField(text: string): boolean {
  if (!text) return true;

  const regex = /[\u0020-\u007FÀ-ū]|[^\u0000-\u007F]/;
  return regex.test(text);
}

export function validateOrgNameField(
  orgName: string,
): [boolean, string, { vars?: Record<string, string | number> }] {
  orgName = orgName.trim();
  const regex = /[\u0020-\u007FÀ-ū]|[^\u0000-\u007F]/;

  if (orgName.length > ORG_NAME_MAX_LENGTH) {
    return [
      false,
      "modal-oneflow.create-org-screen.max-length-characters",
      {
        vars: { length: ORG_NAME_MAX_LENGTH },
      },
    ];
  }

  if (!regex.test(orgName)) {
    return [false, "modal-oneflow.create-org-screen.org-name-invalid", {}];
  }

  return [regex.test(orgName), "", {}];
}
