import { VanityNameGeneratorStrategy } from "./VanityNameGenerator";

export class StrategyFirstLastName implements VanityNameGeneratorStrategy {
  constructor(private nameParts: string[]) {}

  generate(): { vanityName: string; next: boolean } {
    if (this.nameParts.length === 1) {
      return { vanityName: this.nameParts.join(""), next: true };
    }

    const vanityName =
      this.nameParts[0] + this.nameParts[this.nameParts.length - 1];
    return { vanityName, next: true };
  }
}
